import { Location } from '@angular/common';
import { ApplicationRef, Injectable, OnInit, Type, ViewContainerRef } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, NavigationStart, Params, Route, Router } from '@angular/router';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';
import { Observer, firstValueFrom, timer } from 'rxjs';
import { TranslocoAppLoader } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import { InsertionPointDirective } from 'src/modules/sm-base/directives/insertion-point.directive';
import { SmErrorHandler } from 'src/modules/sm-base/misc/sm-error-handler';
import { ButtonDefinition } from 'src/modules/sm-base/models/button-definition.model';
import { PrimeMessageSeverity } from 'src/modules/sm-base/models/prime-message-severity.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmComponent } from 'src/modules/sm-base/models/sm-component';
import { MessageDialogService } from 'src/modules/sm-base/services/message-dialog.service';
import { BackendResponse } from 'src/modules/sm-base/shared/backend-response.model';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { UserInteractionItem } from 'src/modules/utils/shared/user-interaction-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { AppMainComponent } from '../components/app-main/app-main.component';
import { ComponentView } from '../models/component-view.model';
import { Config } from '../models/config.model';
import { AuthenticationService } from 'src/modules/sm-base/services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MainAppService {

    appName: string;
    pageTitle: string;
    breadcrumbsVisible = false;
    breadcrumbsInToolBar = true;
    breadcrumbsHome: MenuItem = null;
    breadcrumbsItems: MenuItem[] = [];
    staticHeaderHtml: string;
    showSidebar = true;
    showTopbar = true;
    showSpinner = false;
    storedErrors: any[] = [];
    unreadNotificationCount = 0;
    debugRoutes = false;
    userInteractionHistory: UserInteractionItem[] = [];
    backendVersion = "";
    loadedDate: Date;
    lastUserInteractionDate: Date;
    mouseX = 0;
    mouseY = 0;
    advancedDeveloper = false;

    toolbarBackButton = false;
    useNotifications = false;
    toolbarHomeButton = false;
    toolbarNotificationsButton = false;
    toolbarButtons: MenuItem[] = [];
    toolbarShowUser = true;

    contextMenu: MenuItem[];

    private keyboardListeners: OrdinaryObject<(KeyboardEvent) => void> = {};
    private keyboardListenersByKey: OrdinaryObject<string[]> = {};

    onSelectionCallback: () => void;

    constructor(
        private titleService: Title,
        private router: Router,
        private location: Location,
        private messageService: MessageService,
        public messageDialog: MessageDialogService,
        private transloco: TranslocoService,
        private applicationRef: ApplicationRef,
        public sanitizer: DomSanitizer,
        public auth: AuthenticationService,
        private deviceDetector: DeviceDetectorService) {

        GuiUtils.checkSingleton(this);
        this.updatePageTitle(Config.get().pageTitle);
        this.showSidebar = Config.get().showSidebar;
        this.toolbarBackButton = Config.get().showBackButton;
        this.toolbarShowUser = !Config.get().allowAnonymous;
        this.breadcrumbsInToolBar = Config.get().breadcrumbsInToolBar;
        this.staticHeaderHtml = Config.get().staticHeaderHtml;
        this.loadedDate = new Date();

        if (Config.get().developer) {
            SmErrorHandler.instance.onError.push(error => this.storedErrors.push(error));
        }

        this.router.events.subscribe(event => {
            if (this.debugRoutes) {
                console.group(`Router event: ${event.constructor.name}`);
                console.log(event);
                console.groupEnd();
            }
            if (event instanceof NavigationStart) {
                this.userInteractionHistory.push(new UserInteractionItem(new Date(), "URL", event.url));
            }
        });

        timer(1_000, 60_000 * 5).subscribe(async _ => {
            await this.checkNotifications();
        });
    }

    async checkNotifications(): Promise<void> {
        if (this.auth.isLoggedIn() && this.useNotifications) {
            this.unreadNotificationCount = await RestEndpoint.main().run("api/app/notification/unreadcount").getNumber();
        }
    }

    getUserName(): string {
        return this.auth.getUserName();
    }

    userInteracted(): void {
        this.lastUserInteractionDate = new Date();
    }

    userKeyPress(event: KeyboardEvent): void {
        this.userInteracted();

        let key = event.key;
        if (key == null) {
            return;
        }

        if (event.shiftKey) {
            key = "Shift-" + key;
        }
        if (event.ctrlKey) {
            key = "Ctrl-" + key;
        }
        if (event.altKey) {
            key = "Alt-" + key;
        }
        key = key.toUpperCase();
        for (let guid of Utils.getSafe(this.keyboardListenersByKey, key, [])) {
            this.keyboardListeners[guid](event);
        }
    }

    addKeyboardListener(key: string, callback: (KeyboardEvent) => void): string {
        key = key.toUpperCase();
        let guid = Utils.generateGuid();
        this.keyboardListeners[guid] = callback;
        if (!(key in this.keyboardListenersByKey)) {
            this.keyboardListenersByKey[key] = [];
        }
        this.keyboardListenersByKey[key] = [...this.keyboardListenersByKey[key], guid];
        return guid;
    }

    removeKeyboardListener(guid: string): void {
        delete this.keyboardListeners[guid];
    }

    async reloadTranslations(): Promise<void> {
        let o = Utils.fromJson(await RestEndpoint.main().run("api/app/translation").getText());
        TranslocoAppLoader.translation = o;
        this.transloco.setActiveLang("en");
        this.transloco.setActiveLang("de");
        await firstValueFrom(this.transloco.load("de"));
    }

    //callback eigentlich vom Typ callback: (params: any) => any, das kollidiert aber mit eslint rule so-unsafe-argument
    subscribeToParamsChanges(route: ActivatedRoute, callback: any): void {
        route.params.subscribe(callback as Partial<Observer<Params>>);
    }

    //callback eigentlich vom Typ callback: (params: any) => any, das kollidiert aber mit eslint rule so-unsafe-argument
    subscribeToQueryParamsChanges(route: ActivatedRoute, callback: any): void {
        route.queryParams.subscribe(callback as Partial<Observer<Params>>);
    }

    backendVersionRetrieved(backendVersion: string): void {
        if (!Utils.isNoe(backendVersion)) {
            this.backendVersion = backendVersion;
        }
    }

    async navigate(commands: any[], extras?: NavigationExtras): Promise<void> {
        await this.router.navigate(commands, extras);
    }

    navigateBack(): void {
        this.location.back();
    }

    async navigateToUrl(url: string): Promise<void> {
        await this.router.navigateByUrl(url);
    }

    async navigateTo(routerLink: any[], replaceUrl?: boolean): Promise<void> {
        await this.router.navigate(routerLink, { replaceUrl });
    }

    updateNavigation(pageTitle: string, breadcrumbsHome: MenuItem, breadcrumbsItems: MenuItem[]): void {
        this.updatePageTitle(pageTitle || (breadcrumbsItems.length == 0 ? "Home" : breadcrumbsItems[breadcrumbsItems.length - 1].label));
        this.updateBreadcrumbs(breadcrumbsHome, Utils.arrayWithoutNull(breadcrumbsItems));
    }

    updateBreadcrumbs(breadcrumbsHome: MenuItem, breadcrumbsItems: MenuItem[]): void {
        this.breadcrumbsHome = { routerLink: ["/"], icon: 'fas fa-home' }; //breadcrumbsHome;
        this.breadcrumbsItems = breadcrumbsItems;
        if (this.breadcrumbsItems.length > 0) {
            delete this.breadcrumbsItems[this.breadcrumbsItems.length - 1].routerLink;
        }
        this.breadcrumbsVisible = Config.get().showBreadcrumbs && breadcrumbsItems.length > 0;
    }

    updatePageTitle(pageTitle: string): void {
        this.pageTitle = pageTitle;
        this.titleService.setTitle(pageTitle);
    }

    async updateQueryParams(key: string, value: any): Promise<void> {
        let queryParams: OrdinaryObject = {};
        queryParams[key] = Utils.toString(value);
        await this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
    }

    getEnvironment(): any {
        return environment;
    }

    getInstance(): this {
        return this;
    }

    createComponent<T>(parent: ViewContainerRef | InsertionPointDirective, type: Type<T>): T {
        let viewContainerRef: ViewContainerRef = parent instanceof InsertionPointDirective ? parent.viewContainerRef : parent;
        viewContainerRef.clear();
        return viewContainerRef.createComponent(type).instance;
    }

    showToast(severity: PrimeMessageSeverity, summary: string, detail: string, duration?: number): void {
        this.messageService.add({ severity, summary, detail, life: duration });
    }

    changeTheme(theme: string): void {
        this.replaceLink(document.getElementById('theme-css') as HTMLLinkElement, theme);
    }

    changeLayout(layout: string): void {
        this.replaceLink(document.getElementById('layout-css') as HTMLLinkElement, layout);
    }

    async copyToClipboardOrDialog(text: string, dialogTitle: string, copyToastText: string): Promise<void> {
        if (await GuiUtils.copyToClipboard(text)) {
            this.showToast("success", "Kopiert", copyToastText);
        }
        else {
            this.messageDialog.textArea(dialogTitle, text);
        }
    }

    replaceLink(linkElement: HTMLLinkElement, href: string): void {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true) as HTMLLinkElement;

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    getRouterDebugString(): string {
        return this.getRouterDebugStringInternal('', this.router.config);
    }

    triggerChangeDetection(): void {
        this.applicationRef.tick();
    }

    validateForm(form: FrontendFormDefinition, additionalValidation: () => OrdinaryObject = null): boolean {
        let result = form.validate();
        if (additionalValidation != null) {
            result = { ...result, ...additionalValidation()};
        }
        if (result != null && Utils.getOwnPropertyNames(result).length > 0) {
            this.showToast("error", "Fehler in der Eingabe", Utils.arrayItemsToString(Utils.objectGetValues(result), "\n\n"));
            return false;
        }
        return true;
    }

    async genericDataHandler(worker: () => Promise<any>, errorTitle?: string, onError?: "toast" | "dialog" | null): Promise<[boolean, any]> {
        try {
            let result = await worker();
            return [true, result];
        }
        catch (ex) {
            if (onError != null) {
                let error = BackendResponse.getFromException(ex);
                if (onError == "dialog") {
                    await this.messageDialog.info(!Utils.isNoe(error.items) ? error.items[0].message : errorTitle, errorTitle);
                }
                else if (onError == "toast") {
                    this.showToast("error", errorTitle, !Utils.isNoe(error.items) ? error.items[0].message : errorTitle);
                }
            }
            return [false, null];
        }
    }

    async loadDataHandler<T>(worker: () => Promise<T>, source: OnInit): Promise<T> {
        try {
            return await worker();
        }
        catch (ex) {
            let error = BackendResponse.getFromException(ex);
            await this.loadDataError(!Utils.isNoe(error.items) ? error.items[0].message : null, !Utils.isNoe(error.items) && error.items[0].recoverable, source);
            throw ex;
        }
    }

    async loadDataError(message: string, recoverable: boolean, source: OnInit): Promise<void> {
        let buttons = [new ButtonDefinition("back", "Zurück", false, "fas fa-backward")];
        if (recoverable) {
            buttons = [new ButtonDefinition("reload", "Erneut versuchen", false, "fas fa-redo-alt"), ...buttons];
        }
        let result = await this.messageDialog.show(message, "Fehler beim Laden der Daten", buttons);
        if (result == "reload") {
            source.ngOnInit();
        }
        else if (result == "back") {
            if (source instanceof SmComponent) {
                (source as SmComponent).navigateBack();
            }
            else {
                this.navigateBack();
            }
        }
    }

    async saveDataHandler(worker: () => Promise<any>, componentView?: ComponentView, successToast?: boolean, navigateBack?: boolean, successTitle?: string, successText?: string): Promise<[boolean, any]> {
        try {
            let result = await worker();

            if (successToast) {
                this.showToast("success", successTitle ?? this.t("general.updatedTitle"), successText ?? this.t("general.updatedText"));
            }
            if (navigateBack) {
                if (componentView != null) {
                    componentView.skipCloseCheck = true;
                }
                this.navigateBack();
            }

            return [true, result];
        }
        catch (ex) {
            let error = BackendResponse.getFromException(ex);
            await this.messageDialog.info(!Utils.isNoe(error.items) ? error.items[0].message : "Es trat ein Fehler beim Speichern auf.", "Fehler beim Speichern");
            return [false, null];
        }
    }

    async deleteDataHandler<T>(worker: () => Promise<T>, componentView?: ComponentView, successToast?: boolean, navigateBack?: boolean, successTitle?: string, successText?: string): Promise<[boolean, T]> {
        try {
            let result = await worker();

            if (successToast) {
                this.showToast("success", successTitle ?? this.t("general.deletedTitle"), successText ?? this.t("general.deletedText"));
            }
            if (navigateBack) {
                if (componentView != null) {
                    componentView.skipCloseCheck = true;
                }
                this.navigateBack();
            }

            return [true, result];
        }
        catch (ex) {
            let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(Utils.toString(ex.error)));
            await this.messageDialog.info(!Utils.isNoe(error.items) ? error.items[0].message : "Es trat ein Fehler beim Löschen auf.", "Fehler beim Löschen");
            return [false, null];
        }
    }

    updateSuccess(): void {
        this.showToast("success", this.t("general.updatedTitle"), this.t("general.updatedText"));
        this.navigateBack();
    }

    public t(key: string, params?: HashMap): string {
        return this.transloco.translateObject(key, params);
    }

    public tCrudTitle(isNew: boolean, item: any, entityPath: string): string {
        let gender = this.t(entityPath + ".typeNameGender") ?? "m";
        gender = gender == "f" ? "e" : gender == "n" ? "es" : "er";
        return isNew ? this.t("crud.new") + gender + " " + this.t(entityPath + ".typeName") : this.t(entityPath + ".typeName") + " " + Utils.toString(item);
    }

    public tForm(entityPath: string, form: FrontendFormDefinition): FrontendFormDefinition {
        for (let field of form.fields) {
            if (Utils.isNoe(field.caption)) {
                field.caption = this.t(entityPath + "." + field.id);
            }
        }
        return form;
    }

    public tTable(entityPath: string, table: TableData): TableData {
        for (let col of table.columns) {
            if (Utils.isNoe(col.caption)) {
                col.caption = this.t(entityPath + "." + col.id);
            }
        }
        return table;
    }

    public getRouteParam(paramName: string): any {
        return this.getRouteParamInternal(this.router.routerState.snapshot.root, paramName);
    }

    private getRouteParamInternal(snapshot: ActivatedRouteSnapshot, paramName: string): any {
        if (Utils.hasProperty(snapshot.params, paramName)) {
            return snapshot.params[paramName];
        }
        else {
            for (let child of snapshot.children) {
                let result = this.getRouteParamInternal(child, paramName);
                if (result != null) {
                    return result;
                }
            }
        }
        return null;
    }

    private getRouterDebugStringInternal(parent: String, config: Route[], level = 0): string {
        let result = "";
        let prefix = " ".repeat(level * 2);
        for (let route of config) {
            result += prefix + parent + '/' + route.path + "\n";
            if (route.children) {
                const currentPath = route.path ? parent + '/' + route.path : parent;
                result += prefix + this.getRouterDebugStringInternal(currentPath, route.children, level + 1) + "\n";
            }
        }
        return result;
    }

    public isMobile(): boolean {
        return this.deviceDetector.isMobile();
    }

    openContextMenu(menu: MenuItem[]): void {
        this.contextMenu = menu;
        GuiUtils.angularTimer(() => {
            AppMainComponent.instance.cm.show(this.getCurrentMousePosEvent());
        });
    }

    getCurrentMousePosEvent(): OrdinaryObject {
        let result: OrdinaryObject = {};
        result.pageX = this.mouseX;
        result.pageY = this.mouseY;
        result.stopPropagation = () => null;
        result.preventDefault = () => null;
        return result;
    }

}
