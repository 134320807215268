import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FirstLoginDto } from 'src/modules/utils/shared/first-login-dto.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendFieldDefinition } from '../../shared/frontend-field-definition.model';
import { FrontendFieldType } from '../../shared/frontend-field-type.enum';
import { FrontendFormDefinition } from '../../shared/frontend-form-definition.model';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { AuthRules } from '../../shared/auth-rules.model';
import { BackendResponse } from '../../shared/backend-response.model';

@Component({
    selector: 'app-first-login',
    templateUrl: './first-login.component.html',
    styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {

    static currentPassword = "";

    form: FrontendFormDefinition;
    authQuestions: string[];
    authRules: AuthRules;

    loaded = false;
    userName = "";
    running = false;
    done = false;

    pwText = "";

    constructor(public app: MainAppService,
        private messageService: MessageService,
        private route: ActivatedRoute) {
    }

    async ngOnInit(): Promise<void> {
        this.userName = this.route.snapshot.queryParams.userName;
        [this.authQuestions, this.authRules] = await Promise.all([
            await RestEndpoint.main().find().run("api/app/auth/authquestions").listStrings(),
            await RestEndpoint.main().find().run("api/app/auth/authrules").get(AuthRules)
        ]);
        this.pwText = this.authRules.passwordRules.getRulesText();

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("currentPassword", "Aktuelles Passwort", FrontendFieldType.password, { mandatory: true, passwordFeedback: false, value: FirstLoginComponent.currentPassword ?? ""}),
            new FrontendFieldDefinition("password", "Neues Passwort", FrontendFieldType.password, { mandatory: true, passwordFeedback: false}),
            new FrontendFieldDefinition("passwordRepeat", "Neues Passwort wiederholen", FrontendFieldType.password, { mandatory: true, passwordFeedback: false}),
            ...Utils.arrayMapWithIndex(this.authRules.useRecoveryQuestions ? this.authQuestions : [], (aq, index) => new FrontendFieldDefinition("authQuestion" + index, aq, FrontendFieldType.text))
        ]);

        FirstLoginComponent.currentPassword = "";

        this.loaded = true;
    }

    async send(): Promise<void> {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return;
        }

        let pw1 = this.form.getValue("password") as string;
        let pw2 = this.form.getValue("passwordRepeat") as string;

        if (pw1 != pw2) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: "Die beiden neuen Passwörter stimmen nicht überein"});
            return;
        }

        if (!this.authRules.passwordRules.isSafe(pw1)) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: "Das neue Passwort stimmt nicht mit den Vorgaben überein. " + this.pwText});
            return;
        }

        let authQuestions = [];
        let authAnswers = [];
        if (this.authRules.useRecoveryQuestions) {
            for (let i = 0; i < this.authQuestions.length; i++) {
                if (!Utils.isNoe(this.form.getField("authQuestion" + i).getValue())) {
                    authQuestions.push(this.authQuestions[i]);
                    authAnswers.push(this.form.getField("authQuestion" + i).getValue());
                }
            }
            if (authQuestions.length < this.authRules.minRecoveryQuestions) {
                this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: "Es müssen mindestens " + this.authRules.minRecoveryQuestions + " Fragen beantwortet werden."});
                return;
            }
        }

        this.running = true;
        try {
            await RestEndpoint.main().post().body(Utils.fromPlain(FirstLoginDto, {
                userName: this.userName,
                currentPassword: this.form.getValue("currentPassword"),
                password: pw1,
                recoveryQuestions: authQuestions,
                recoveryAnswers: authAnswers
            })).run("api/app/auth/firstlogin").get(BackendResponse);
            this.messageService.add({ severity: "success", summary: "Die Aktivierung war erfolgreich.", life: 10000});
            Utils.setTimerOnce(2000, async () => {
                await this.app.navigateTo(["/base", "login"]);
            });
        }
        catch (ex) {
            // let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(ex.error));
            this.messageService.add({ severity: "error", summary: "Die Aktivierung war nicht erfolgreich. Bitte prüfen Sie, ob das eingegebene aktuelle Passwort richtig ist", life: 10000});
        }
        this.running = false;
    }

    cancel(): void {

    }

}
