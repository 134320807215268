import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { JobDescription } from '../../shared/job-description.entity';

@Component({
    selector: 'app-edit-job-descriptions',
    templateUrl: './edit-job-descriptions.component.html',
    styleUrls: ['./edit-job-descriptions.component.scss']
})
export class EditJobDescriptionsComponent implements OnInit {

    listData: JobDescription[];
    table: TableData;

    constructor(private service: EnaioLearnService) {
    }

    async ngOnInit(): Promise<void> {
        [this.listData, this.table] = await this.service.adminInitList<JobDescription>(JobDescription, "lea.jobDescription", "job-descriptions", "job-description", "api/lrn/jobdescription", this,
        [new TableColumn("number", null), new TableColumn("name", null), new TableColumn("group", null)], [new TableSortColumn("number")], o => new TableRow(o, { number: o.number, name: o.name, group: o.group }));
    }
}
