import { Component, OnInit, ViewChild } from '@angular/core';
import { VbaTraceExport } from 'src/modules/enaio/shared/VbaTraceExport';
import { VbaTraceStatement } from 'src/modules/enaio/shared/VbaTraceStatement';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-script-tracing',
  templateUrl: './script-tracing.component.html',
  styleUrls: ['./script-tracing.component.scss']
})
export class ScriptTracingComponent implements OnInit {

    export: VbaTraceExport;
    table: TableData;
    expressionsTable: TableData;

    selectedItem = "";
    sourceLineCount = 0;

    @ViewChild("textAreaSourceCode") textAreaSourceCode: any;

    async ngOnInit(): Promise<void> {
        this.export = await DhTools.backendCall("api/dh/getTrace").get(VbaTraceExport);

        this.table = new TableData([
            new TableColumn("id", "ID", TableCellType.number),
            new TableColumn("line", "Line", TableCellType.number),
            new TableColumn("inFunction", "In Funktion"),
            new TableColumn("text", "Text")
        ], this.export.items.filter(row => !(row as any).value?.includes("tracer_trace")).map(row => new TableRow(row, {
            id: (row as any).itemId,
            line: (row as any).line,
            inFunction: (row as any).inFunction,
            text: (row as any).value
        })));

        this.export.source = this.addLineNumbers(this.export.source, 6);
        this.sourceLineCount = this.export.source.split("\n").length;
    }

    addLineNumbers(s: string, pad = 0): string {
        return Utils.arrayItemsToString(Utils.arrayMapWithIndex(s.split("\n"), (line, index) => Utils.toString(index + 1).padEnd(pad, " ") + line), "\n");
    }

    rowSelect(event: any): void {
        let s = event.raw as VbaTraceStatement;
        this.selectedItem = Utils.arrayItemsToString(s.expressions, "\n", e => e.expression);

        this.expressionsTable = new TableData([
            new TableColumn("id", "ID", TableCellType.number),
            new TableColumn("expressionType", "Type"),
            new TableColumn("expression", "Expression"),
            new TableColumn("result", "Result"),
            new TableColumn("resultType", "Type")
        ], s.expressions.map(row => new TableRow(row, {
            id: row.itemId,
            expressionType: row.expressionType,
            expression: row.expression,
            result: row.result,
            resultType: row.resultType
        })));

        if (s.line > 0) {
            this.jumpToLine(s.line);
        }
    }

    jumpToLine(line: number): void
    {
        let lineHeight = this.textAreaSourceCode.nativeElement.scrollHeight / this.sourceLineCount;
        let jump = (line - 1) * lineHeight - this.textAreaSourceCode.nativeElement.clientHeight / 2;
        this.textAreaSourceCode.nativeElement.scrollTop = jump;

        let selStart = this.export.source.indexOf("\n" + Utils.toString(line).padEnd(6));
        let selEnd = this.export.source.indexOf("\n" + Utils.toString(line + 1).padEnd(6));

        if (selStart >= 0 && selEnd >= 0) {
            //Warum funktioniert das nicht?
            this.textAreaSourceCode.nativeElement.setSelectionRange(selStart, selEnd);
        }
    }
}
