import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { KeyValuePair } from 'src/modules/utils/shared/key-value-pair.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'app-edit-texts',
    templateUrl: './edit-texts.component.html',
    styleUrls: ['./edit-texts.component.scss']
})
export class EditTextsComponent extends ComponentView {

    fileUpload = inject(FileUploadService);

    lines: KeyValuePair[] = [];

    async initParams(): Promise<boolean> {
        let texts = await this.app.loadDataHandler(async () => RestEndpoint.main().run("api/app/translation").getText(), this);
        this.lines = Utils.propertiesToKeyValuePairs(Utils.flattenObjectSimple(Utils.fromJson(texts) as OrdinaryObject));

        this.app.updateNavigation(null, { routerLink: ["/enaio-learn", "home"], icon: 'fas fa-home' }, [
            { label: "Administration", routerLink: ["/enaio-learn", "admin"] },
            { label: "Texte", routerLink: ["/enaio-learn", "admin", "texts"] }
        ]);
        return Promise.resolve(true);
    }

    async save(): Promise<void> {
        await RestEndpoint.main().post().body(this.toTranslationObject()).run("api/app/translation").getText();
        this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
        window.location.reload();
    }

    cancel(): void {
        this.app.navigateBack();
    }

    async restore(): Promise<void> {
        if (await this.app.messageDialog.yesNo("Damit machen Sie alle Ihre Änderungen rückgängig und ersetzen sie durch die Standardeinstellungen. Fortfahren?", this.app.t("general.warning"))) {
            await RestEndpoint.main().post().run("api/app/translation/restore").getText();
            this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
            window.location.reload();
        }
    }

    export(): void {
        let o = this.toTranslationObject();
        GuiUtils.downloadBytesAsFile(GuiUtils.stringAsciiToArrayBuffer(Utils.toJson(o, true)), "Translation.json");
    }

    async import(): Promise<void> {
        try {
            let texts = GuiUtils.bytesToStringAscii((await this.fileUpload.chooseFile())[0].contents);
            this.lines = Utils.propertiesToKeyValuePairs(Utils.flattenObjectSimple(Utils.fromJson(texts) as OrdinaryObject));
        }
        catch (ex) {
            this.app.showToast("error", "Texte konnten nicht eingelesen werden", this.app.t("general.error"));
        }
    }

    toTranslationObject(): OrdinaryObject {
        let result: OrdinaryObject = {};

        for (let line of this.lines) {
            Utils.setPropertyNested(result, line.key, line.value, true);
        }
        return result;
    }
}
