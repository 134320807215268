<div class="grid">
    <div class="col-11 p-inputgroup">
        <input #evaluateTextComponent pInputText type="text" name="evaluateText" [(ngModel)]="evaluateText" (keypress)="keyPress($event)"/>
        <button pButton icon="fas fa-check" label="Eingabe auswerten" (click)="evaluateInput()"></button>
        <button pButton icon="fas fa-check" label="Selektion auswerten" (click)="evaluateSelection()"></button>
        <button pButton icon="fas fa-check" label="Alles auswerten" (click)="evaluateAll()"></button>
        <button pButton icon="fas fa-check" label="AST Debug" (click)="astDebug()"></button>
    </div>
    <input *ngIf="guid != null" class="col-1" pInputText type="text" name="objectIndex" placeholder="Objekt Index" [pTooltip]="'Hier kann eine Objekt-ID eingegeben werden, auf der die Auswertung stattfinden soll. Leerlassen für aktuelle Metadaten'" [(ngModel)]="objectIndex"/>
    <p-message *ngIf="errorMessage != null" class="col-12" severity="error" [text]="errorMessage" [escape]=false></p-message>
    <div class="col-12 grid">
        <p-panel *ngIf="guid == null" class="col-4" header="Metadaten im JSON-Format">
            <textarea pInputTextarea spellcheck=false class="width100p full-height-240x monospace no-resize" [(ngModel)]="metaData"></textarea>
        </p-panel>
        <p-tabView [ngClass]="guid == null ? 'col-8' : 'col-12'" [(activeIndex)]="tabIndex">
            <p-tabPanel header="Baum">
                <p-treeTable [value]="evaluationTree" class="full-height-300" [(contextMenuSelection)]="selectedNode">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <tr [ttRow]="rowNode" [ttContextMenuRow]="rowNode">
                            <td>
                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                {{rowData.key}}
                            </td>
                            <td>{{rowData.value}}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </p-tabPanel>
            <p-tabPanel header="Text">
                <textarea pInputTextarea spellcheck=false class="full-height-220x width100p monospace no-resize" [(ngModel)]="evaluationResult"></textarea>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
