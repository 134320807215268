import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { AuthenticationService } from 'src/modules/sm-base/services/authentication.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TimedCacheMap } from 'src/modules/sm-base/shared/timed-cache-map.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { ClassRegisterSettings } from '../shared/class-register-settings.entity';
import { ClassRegister } from '../shared/class-register.entity';
import { EnaioCertificatesTools } from '../shared/enaio-certificates-tools';
import { Pupil } from '../shared/pupil.model';
import { SchoolClass } from '../shared/school-class.model';
import { SchoolType } from '../shared/school-type.enum';
import { Teacher } from '../shared/teacher.model';

@Injectable({
    providedIn: 'root'
})
export class EnaioCertificateService {

    private classRegisterTimedCache = new TimedCacheMap<number, ClassRegister>(60 * 60 * 1000, async id => RestEndpoint.main().findById(id).run("api/cer/classregister/schoolclass").get(ClassRegister, true));
    private schoolClassTimedCache = new TimedCacheMap<number, SchoolClass>(60 * 60 * 1000, async id => RestEndpoint.main().findById(id).run("api/cer/schoolclass").get(SchoolClass));

    constructor(private app: MainAppService, private auth: AuthenticationService) {
    }

    async isAdminForCertificates(): Promise<boolean> {
        return EnaioCertificatesTools.isAdminForCertificates(await this.auth.getUserDetails());
    }

    async isCertificateEditor(): Promise<boolean> {
        return EnaioCertificatesTools.isCertificateEditor(await this.auth.getUserDetails());
    }

    async isClassRegisterEditor(): Promise<boolean> {
        return EnaioCertificatesTools.isClassRegisterEditor(await this.auth.getUserDetails());
    }

    async isSecretary(): Promise<boolean> {
        return EnaioCertificatesTools.isSecretary(await this.auth.getUserDetails());
    }

    getHome(): MenuItem {
        return { routerLink: ["/enaio-certificates", "h"], icon: 'fas fa-home' };
    }

    getHomeClassRegister(): MenuItem {
        return { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates", "class-registers", "home"] };
    }

    getHomeCertificates(): MenuItem {
        return { label: "Zeugnisse", routerLink: ["/enaio-certificates", "home"] };
    }

    getHomeCovidTests(): MenuItem {
        return { label: "Corona Testungen", routerLink: ["/enaio-certificates", "covid-tests", "home"] };
    }

    getHomeCourses(): MenuItem {
        return { label: "Kursbuch", routerLink: ["/enaio-certificates", "class-registers", "courses"] };
    }

    getHomeGradeBook(): MenuItem {
        return { label: "Notenbuch", routerLink: ["/enaio-certificates", "grade-book", "home"] };
    }

    async updateNavigationClassRegister(classRegisterId: number, additionalMenuItems: MenuItem[]): Promise<void> {
        this.app.updateNavigation(null, this.getHome(), [
            this.getHomeClassRegister(),
            { label: "Klasse " + await this.restGetClassRegisterName(classRegisterId), routerLink: ["/enaio-certificates", "class-registers", "class-register", classRegisterId] },
            ...additionalMenuItems
        ]);
    }

    getSickNoteForm(pupils: Pupil[], allowUnexcused: boolean): FrontendFormDefinition {
        return new FrontendFormDefinition(Utils.arrayWithoutNull([
            pupils != null ? new FrontendFieldDefinition("pupilId", "Schüler", FrontendFieldType.comboBox, { mandatory: true, dropdownAutoComplete: true, listItems: FrontendFieldListItem.sort(pupils.map(p => new FrontendFieldListItem(p.id, p.getFullName())))}) : null,
            new FrontendFieldDefinition("fromDate", "Von", FrontendFieldType.datePicker, {mandatory: true, value: new Date(), guiGroup: "fromDate", guiCols: 6}),
            new FrontendFieldDefinition("fromHour", "Stunde", FrontendFieldType.comboBox, {mandatory: true, value: -1, guiGroup: "fromDate", guiCols: 6, dropdownEditable: false, listItems: [new FrontendFieldListItem(-1, "Ganzer Tag"), ...Utils.getRange(0, EnaioCertificatesTools.getMaxHoursPerDay() - 1).map(hour => new FrontendFieldListItem(hour, "ab " + (hour + 1) + ". Stunde"))]}),
            new FrontendFieldDefinition("toDate", "Bis", FrontendFieldType.datePicker, {mandatory: true, value: new Date(), guiGroup: "toDate", guiCols: 6}),
            new FrontendFieldDefinition("toHour", "Stunde", FrontendFieldType.comboBox, {mandatory: true, value: -1, guiGroup: "toDate", guiCols: 6, dropdownEditable: false, listItems: [new FrontendFieldListItem(-1, "Ganzer Tag"), ...Utils.getRange(0, EnaioCertificatesTools.getMaxHoursPerDay() - 1).map(hour => new FrontendFieldListItem(hour, "bis " + (hour + 1) + ". Stunde"))]}),
            new FrontendFieldDefinition("reason", "Grund der Abmeldung", FrontendFieldType.text, { mandatory: true }),
            allowUnexcused ? new FrontendFieldDefinition("excused", "Entschuldigt", FrontendFieldType.checkBox, { value: true }) : null
        ]));
    }

    async restGetDomains(grade?: number, schoolType?: SchoolType, addEmpty?: boolean, addSpecialForTimeTable?: ClassRegisterSettings, applySettingsRestrictions?: ClassRegisterSettings): Promise<string[]> {
        let fromEnaio = await RestEndpoint.main().query(Utils.objectRemoveNullValues({ grade, schoolType })).run("api/cer/competency/domains").listStrings();
        if (applySettingsRestrictions != null) {
            fromEnaio = fromEnaio.filter(domain => applySettingsRestrictions.allowedDomains.includes(domain));
        }
        return Utils.arraySort([
            ...addEmpty ? [""] : [],
            ...fromEnaio,
            ...addSpecialForTimeTable != null ? EnaioCertificatesTools.getSpecialDomainsForTimeTable(addSpecialForTimeTable) : []
        ]);
    }

    async restGetTeachers(schoolClassId?: number): Promise<Teacher[]> {
        return Utils.arraySort(await RestEndpoint.main().query(Utils.objectRemoveNullValues({schoolClassId})).run("api/cer/classregister/teachers").list(Teacher));
    }

    async restGetPupil(id: number): Promise<Pupil> {
        return RestEndpoint.main().findById(id).run("api/cer/pupil").get(Pupil);
    }

    async restGetPupils(schoolClassId: number, onlyActive = true): Promise<Pupil[]> {
        return Utils.arraySortBy(await RestEndpoint.main().query({schoolClassId, onlyActive}).run("api/cer/pupil").list(Pupil), p => p.lastName);
    }

    async restGetPupilsForYear(yearString: string): Promise<Pupil[]> {
        return Utils.arraySortBy(await RestEndpoint.main().query({schoolYear: yearString}).run("api/cer/pupil").list(Pupil), p => p.lastName);
    }

    async restGetSchoolClasses(yearString: string): Promise<SchoolClass[]> {
        return Utils.arraySort(await RestEndpoint.main().query({ year: yearString }).run("api/cer/schoolclass").list(SchoolClass));
    }

    async restGetSchoolClass(id: number): Promise<SchoolClass> {
        return this.schoolClassTimedCache.get(id);
    }

    async restGetClassRegisterSettings(year: number, isHalfYear: boolean): Promise<ClassRegisterSettings> {
        return RestEndpoint.main().query({ year, isHalfYear }).run("api/cer/classregister/settings").get(ClassRegisterSettings);
    }

    async restGetClassRegisterBasic(id: number, forceFetch?: boolean): Promise<ClassRegister> {
        return this.classRegisterTimedCache.get(id, forceFetch);
    }

    async restGetClassRegister(id: number, relations?: ("history" | "duties" | "instructions" | "teachers" | "holidays" | "fieldTrips" | "timetableEntries")[], lessonsDate?: Date, getEnaioSchoolClass?: boolean): Promise<ClassRegister> {
        let result = await RestEndpoint.main().query(Utils.objectRemoveNullValues({ id, lessons: lessonsDate != null ? Utils.dateFormat(lessonsDate, "yyyymmdd") : null,
            history: relations?.includes("history"),
            duties: relations?.includes("duties"),
            instructions: relations?.includes("instructions"),
            teachers: relations?.includes("teachers"),
            holidays: relations?.includes("holidays"),
            fieldTrips: relations?.includes("fieldTrips"),
            timetableEntries: relations?.includes("timetableEntries") })).run("api/cer/classregister/schoolclass").get(ClassRegister, true);

        if (result != null && getEnaioSchoolClass) {
            result.enaioSchoolClass = await this.restGetSchoolClass(result.schoolClassId);
        }

        return result;
    }

    async classRegisterExists(id: number): Promise<boolean> {
        return await this.restGetClassRegister(id) != null;
    }

    async restGetClassRegisterName(id: number): Promise<string> {
        let classRegister = await this.classRegisterTimedCache.get(id);
        if (classRegister == null) {
            return "";
        }
        return (await this.schoolClassTimedCache.get(classRegister.schoolClassId))?.getDisplayName() ?? "";
    }

    async restGetLessonId(classRegisterId: number, date: Date, hour: number, domain: string, courseId?: number, create = true, copyId = 0): Promise<number> {
        return RestEndpoint.main().query(Utils.objectRemoveNullValues({ classId: classRegisterId, date: Utils.dateFormat(date, "yyyymmdd"), hour, domain, courseId, create, copyId })).run("api/cer/classregister/lessonid").getNumber();
    }

}
