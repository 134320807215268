/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { DatabaseUser } from "src/modules/database/shared/database-user.entity";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, JoinTable, ManyToMany, ManyToOne, OneToMany } from "typeorm";
import { InspectionHistory } from "./inspection-history.entity";
import { InspectionPhase } from "./inspection-phase.entity";
import { LearningContentAttachment } from "./learning-content-attachment.entity";
import { LearningContentCategory } from "./learning-content-category.entity";
import { LearningContentChapter } from "./learning-content-chapter.entity";
import { LearningContentCitation } from "./learning-content-citation.entity";
import { LearningContentFinalQuestion } from "./learning-content-final-question.entity";
import { LearningContentQuestion } from "./learning-content-question.entity";
import { LearningContentType } from "./learning-content-type.entity";
import { LearningContentStatus } from "./learning-content.status.entity";
import { MandatoryItem } from "./mandatory-item.model";
import { MandatoryLinkType } from "./mandatory-link-type.enum";

@Entity()
export class LearningContent extends SmBaseEntity implements CustomInitializer, Comparable<LearningContent> {
    @Column({default: 0})
    historicCurrentId: number = 0;

    @Column({default: 0})
    draftCurrentId: number = 0;

    @Column({default: false})
    deleted: boolean = false;

    @Column({default: 0})
    draftId: number = 0;

    @Column({default: ""})
    title: string = "";

    @Column({default: ""})
    description: string = "";

    @Column({ nullable: true})
    @Type(() => Date)
    deadlineFixed: Date = null;

    @Column({default: -1})
    deadlineRelativeToEntry: number = -1;

    @Column({default: -1})
    deadlinePeriodicMonths: number = -1;

    @Column({default: 0})
    deadlineFirstYear: number = 0;

    @Column({default: ""})
    costCenter: string = "";

    @Column()
    @Type(() => Date)
    creationDate: Date = null;

    @Column({nullable: true})
    @Type(() => Date)
    modifyDate: Date = null;

    @Column({default: ""})
    author: string = "";

    @Column({nullable: true})
    modifyUser: string = "";

    @Column({default: 0})
    version: number = 0;

    @Column({default: 0})
    timeNeeded: number = 0;

    @Column({default: true})
    canCharge: boolean = true;

    @Column({default: 0})
    exposureTime: number = 0;

    @Column({default: '01234', nullable: false})
    mandatoryType: string = "01234";

    @Column("text", {array: true, default: []})
    mandatoryItems: string[];

    @Column("text", {array: true, default: []})
    inspectionPhaseNotifications: string[];

    @Column({default: true})
    draft: boolean = true;

    @Column({default: false})
    finalExam: boolean = false;

    @Column({default: false})
    finalExamAllQuestions: boolean = false;

    @Column({default: 0})
    finalExamNumQuestions: number = 0;

    @Column({default: 70})
    finalExamPassPercentage: number = 70;

    @Column({default: 3})
    finalExamNumTries: number = 3;

    @Column({default: true})
    finalExamFailNotifySupervisor: boolean = true;

    @OneToMany(() => LearningContentFinalQuestion, o => o.learningContent, { cascade: true })
    @Type(() => LearningContentFinalQuestion)
    finalExamQuestions: LearningContentFinalQuestion[];

    @ManyToOne(() => InspectionPhase, o => o.learningContentsInThisPhase, { onDelete: "SET NULL", nullable: true})
    inspectionPhase: InspectionPhase = null;

    @ManyToOne(() => LearningContentType, o => o.learningContents, { onDelete: "SET NULL", nullable: true, orphanedRowAction: "delete"})
    @Type(() => LearningContentType)
    contentType: LearningContentType = null;

    @ManyToOne(() => LearningContentCategory, o => o.learningContents, { onDelete: "SET NULL", nullable: true, orphanedRowAction: "delete"}) //nullable Wegen bestehenden
    @Type(() => LearningContentCategory)
    category: LearningContentCategory = null;

    @ManyToMany(() => DatabaseUser, { cascade: ["remove"] })
    @Type(() => DatabaseUser)
    @JoinTable()
    inspectionResponsible: DatabaseUser[];

    @ManyToMany(() => DatabaseUser, { cascade: ["remove"] })
    @Type(() => DatabaseUser)
    @JoinTable()
    finalExamNotifyUsers: DatabaseUser[];

    @OneToMany(() => LearningContentChapter, o => o.learningContent, { cascade: true })
    @Type(() => LearningContentChapter)
    chapters: LearningContentChapter[];

    @OneToMany(() => InspectionHistory, o => o.learningContent, { cascade: true })
    @Type(() => InspectionHistory)
    inspectionHistory: InspectionHistory[];

    customInitializer(): void {
        this.chapters = Utils.arraySortBy(this.chapters || [], c => c.index);
        this.mandatoryItems = Utils.arrayEnsure(this.mandatoryItems);
        this.inspectionHistory = Utils.arrayEnsure(this.inspectionHistory);
        this.finalExamQuestions = Utils.arrayEnsure(this.finalExamQuestions);
        this.chapters.forEach(c => c.customInitializer());
    }

    getStringRepresentation(): string {
        return this.title;
    }

    getEntityName(): string {
        return "Lerninhalt";
    }

    compareTo(other: LearningContent): number {
        return Utils.cmpMulti([this.category?.name, other.category?.name, this.title, other.title]);
    }

    getMandatoryItems(): MandatoryItem[] {
        return this.mandatoryItems?.map(mi => MandatoryItem.fromInternal(mi)) || [];
    }

    getDeadline(entryDate: Date): Date {
        return this.deadlineFixed != null ? this.deadlineFixed : this.deadlineRelativeToEntry >= 0 ? entryDate != null ? Utils.dateAdd(entryDate, "day", this.deadlineRelativeToEntry) : null :
            this.deadlinePeriodicMonths > 0 ? Utils.dateStartOfNext(Utils.dateAdd(new Date(), "month", this.deadlinePeriodicMonths - new Date().getMonth() % this.deadlinePeriodicMonths), "month") : null;
    }

    getMandatoryTypes(): MandatoryLinkType[] {
        return Utils.stringToCharArray(this.mandatoryType).filter(c => c != '-').map(c => Utils.toNumber(c) as MandatoryLinkType);
    }

    getMandatoryErrors(): OrdinaryObject {
        let result: OrdinaryObject = {};

        if (this.mandatoryType == "-") {
            return result;
        }

        let types = this.getMandatoryTypes();
        let mi = this.getMandatoryItems();
        if (!types.includes(MandatoryLinkType.businessUnit) && !types.includes(MandatoryLinkType.costCenter) && !types.includes(MandatoryLinkType.department) &&
            !mi.some(item => item.type == MandatoryLinkType.businessUnit || item.type == MandatoryLinkType.costCenter || item.type == MandatoryLinkType.department)) {
            result.mandatoryItemsCostCenters = "Es muss mindestens eine Kostenstelle ausgewählt werden";
        }
        if (!types.includes(MandatoryLinkType.jobDescription) && !mi.some(item => item.type == MandatoryLinkType.jobDescription)) {
            result.mandatoryItemsJobDescriptions = "Es muss mindestens eine Dienstart ausgewählt werden";
        }
        if (!types.includes(MandatoryLinkType.userGroup) && !mi.some(item => item.type == MandatoryLinkType.userGroup)) {
            result.mandatoryItemsJobDescriptions = "Es muss mindestens eine Mitarbeitergruppe ausgewählt werden";
        }

        return result;
    }

    matchesFullTextQuery(query: string): boolean {
        query = query.toUpperCase();
        return Utils.isNoe(query) || this.title.toUpperCase().includes(query) || this.description.toUpperCase().includes(query);
    }

    getStatus(): LearningContentStatus {
        return this.draft ? this.inspectionPhase != null ? LearningContentStatus.inInspection : LearningContentStatus.unpublished : LearningContentStatus.published;
    }

    generateHistoric(): LearningContent {
        return this.generateInternal(true);
    }

    generateDraft(): LearningContent {
        return this.generateInternal(false);
    }

    generateInternal(historic: boolean): LearningContent {
        let result = Utils.fromPlain(LearningContent, {
            historicCurrentId: historic ? this.id : 0,
            draftCurrentId: !historic ? this.id : 0,
            title: this.title,
            description: this.description,
            deadlineFixed: this.deadlineFixed,
            deadlineRelativeToEntry: this.deadlineRelativeToEntry,
            deadlinePeriodicMonths: this.deadlinePeriodicMonths,
            deadlineFirstYear: this.deadlineFirstYear,
            costCenter: this.costCenter,
            creationDate: this.creationDate,
            modifyDate: this.modifyDate,
            author: this.author,
            modifyUser: this.modifyUser,
            version: this.version,
            canCharge: this.canCharge,
            timeNeeded: this.timeNeeded,
            exposureTime: this.exposureTime,
            mandatoryType: this.mandatoryType,
            mandatoryItems: Utils.arrayCopy(this.mandatoryItems),
            inspectionPhaseNotifications: Utils.arrayCopy(this.inspectionPhaseNotifications),
            draft: this.draft,
            inspectionPhase: historic ? null : this.inspectionPhase,
            contentType: this.contentType,
            category: this.category,
            inspectionResponsible: Utils.arrayCopy(this.inspectionResponsible),
            finalExamNotifyUsers: Utils.arrayCopy(this.finalExamNotifyUsers),
            finalExam: this.finalExam,
            finalExamAllQuestions: this.finalExamAllQuestions,
            finalExamNumQuestions: this.finalExamNumQuestions,
            finalExamPassPercentage: this.finalExamPassPercentage,
            finalExamNumTries: this.finalExamNumTries,
            finalExamFailNotifySupervisor: this.finalExamFailNotifySupervisor,
            finalExamQuestions: this.finalExamQuestions?.map(q => Utils.fromPlain(LearningContentFinalQuestion, {
                question: q.question,
                correctAnswers: Utils.arrayCopy(q.correctAnswers),
                wrongAnswers: Utils.arrayCopy(q.wrongAnswers)
            })),
            chapters: this.chapters?.map(c => Utils.fromPlain(LearningContentChapter, {
                index: c.index,
                title: c.title,
                text: c.text,
                questions: c.questions?.map(q => Utils.fromPlain(LearningContentQuestion, {
                    question: q.question,
                    correctAnswers: q.correctAnswers,
                    wrongAnswers: q.wrongAnswers
                })),
                citations: c.citations?.map(c2 => Utils.fromPlain(LearningContentCitation, {
                    type: c2.type,
                    citationKeys: [...c2.citationKeys],
                    citationValues: [...c2.citationValues]
                })),
                attachments: c.attachments?.map(c2 => Utils.fromPlain(LearningContentAttachment, {
                    type: c2.type,
                    title: c2.title,
                    fileName: c2.fileName
                }))
            })),
            inspectionHistory: Utils.arrayCopy(this.inspectionHistory)
        });
        result.updateBackReferences();
        return result;
    }

    updateBackReferences(): void {
        let index = 0;
        for (let chapter of this.chapters) {
            if (chapter.id == 0) {
                delete chapter.id;
            }
            chapter.learningContent = this;
            chapter.index = ++index;
            chapter.questions = Utils.arrayEnsure(chapter.questions);
            for (let question of chapter.questions) {
                if (question.id == 0) {
                    delete question.id;
                }
                question.chapter = chapter;
            }
        }
    }

    getFinalExamNumQuestions(): number {
        return this.finalExamNumQuestions > 0 ? this.finalExamNumQuestions : this.finalExamQuestions.length;
    }
}
