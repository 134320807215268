import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AppTab } from '../app-template/models/app-tab.model';
import { MainAppService } from '../app-template/services/main-app.service';
import { PrimeModule } from '../prime/prime.module';
import { SmTableComponent } from '../sm-base/components/sm-table/sm-table.component';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { AppStarterComponent } from './components/app-starter/app-starter.component';
import { BarcodeTesterComponent } from './components/barcode-tester/barcode-tester.component';
import { ConfigEditorComponent } from './components/config-editor/config-editor.component';
import { ConnectionTesterComponent } from './components/connection-tester/connection-tester.component';
import { DatabaseToolsComponent } from './components/database-tools/database-tools.component';
import { DatevDataFetcherComponent } from './components/datev-data-fetcher/datev-data-fetcher.component';
import { DatevInvoiceSearchComponent } from './components/datev-invoice-search/datev-invoice-search.component';
import { DatevInvoiceSummaryComponent } from './components/datev-invoice-summary/datev-invoice-summary.component';
import { DocumentHandlerEvalConsoleComponent } from './components/document-handler-eval-console/document-handler-eval-console.component';
import { DocumentHandlerHelpComponent } from './components/document-handler-help/document-handler-help.component';
import { DocumentHandlerHqComponent } from './components/document-handler-hq/document-handler-hq.component';
import { DocumentHandlerInstanceComponent } from './components/document-handler-instance/document-handler-instance.component';
import { DocumentHandlerMainComponent } from './components/document-handler-main/document-handler-main.component';
import { DocumentHandlerRechercheComponent } from './components/document-handler-recherche/document-handler-recherche.component';
import { DocumentHandlerRunsComponent } from './components/document-handler-runs/document-handler-runs.component';
import { DocumentHandlerToolsComponent } from './components/document-handler-tools/document-handler-tools.component';
import { DocumentHandlerUpdaterComponent } from './components/document-handler-updater/document-handler-updater.component';
import { EnaioIdSearchComponent } from './components/enaio-id-search/enaio-id-search.component';
import { EnaioLicensesComponent } from './components/enaio-licenses/enaio-licenses.component';
import { EnaioObjectDefComponent } from './components/enaio-object-def/enaio-object-def.component';
import { EnaioObjectsComponent } from './components/enaio-objects/enaio-objects.component';
import { EnaioSearchAndReplaceComponent } from './components/enaio-search-and-replace/enaio-search-and-replace.component';
import { EnaioServerInfoComponent } from './components/enaio-server-info/enaio-server-info.component';
import { EnaioUserComponent } from './components/enaio-user/enaio-user.component';
import { EnaioWarningsComponent } from './components/enaio-warnings/enaio-warnings.component';
import { EnaioWatcherComponent } from './components/enaio-watcher/enaio-watcher.component';
import { EnaioWebClientDebuggerComponent } from './components/enaio-web-client-debugger/enaio-web-client-debugger.component';
import { EnaioWhatHappenedComponent } from './components/enaio-what-happened/enaio-what-happened.component';
import { EnaioWorkflowModelsComponent } from './components/enaio-workflow-models/enaio-workflow-models.component';
import { EnaioWorkflowComponent } from './components/enaio-workflow/enaio-workflow.component';
import { EnaioWorkflowsComponent } from './components/enaio-workflows/enaio-workflows.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { MiscToolsComponent } from './components/misc-tools/misc-tools.component';
import { PasswordRetrieverComponent } from './components/password-retriever/password-retriever.component';
import { ScriptTracingComponent } from './components/script-tracing/script-tracing.component';
import { ServerApiCallerComponent } from './components/server-api-caller/server-api-caller.component';
import { SimLogComponent } from './components/sim-log/sim-log.component';
import { SmCodeGeneratorComponent } from './components/sm-code-generator/sm-code-generator.component';
import { StringToolsComponent } from './components/string-tools/string-tools.component';
import { TextToolsComponent } from './components/text-tools/text-tools.component';
import { DocumentHandlerRoutingModule } from './document-handler-routing.module';
import { DhTools } from './models/dh-tools.model';
import { SystemInformationComponent } from './components/system-information/system-information.component';
import { MsGraphToolsComponent } from './components/ms-graph-tools/ms-graph-tools.component';

@NgModule({
    declarations: [
        DocumentHandlerMainComponent,
        EnaioWorkflowsComponent,
        ServerApiCallerComponent,
        EnaioUserComponent,
        EnaioObjectDefComponent,
        ScriptTracingComponent,
        SimLogComponent,
        MetricsComponent,
        DocumentHandlerHqComponent,
        EnaioWarningsComponent,
        EnaioObjectsComponent,
        DatevInvoiceSearchComponent,
        EnaioSearchAndReplaceComponent,
        EnaioIdSearchComponent,
        EnaioServerInfoComponent,
        SmCodeGeneratorComponent,
        EnaioWorkflowModelsComponent,
        DatevDataFetcherComponent,
        PasswordRetrieverComponent,
        DocumentHandlerInstanceComponent,
        DocumentHandlerHelpComponent,
        AppStarterComponent,
        DocumentHandlerEvalConsoleComponent,
        DatevInvoiceSummaryComponent,
        EnaioWhatHappenedComponent,
        MiscToolsComponent,
        TextToolsComponent,
        ConnectionTesterComponent,
        ConfigEditorComponent,
        DocumentHandlerToolsComponent,
        EnaioWorkflowComponent,
        EnaioWebClientDebuggerComponent,
        DocumentHandlerRunsComponent,
        EnaioLicensesComponent,
        DatabaseToolsComponent,
        DocumentHandlerRechercheComponent,
        EnaioWatcherComponent,
        StringToolsComponent,
        DocumentHandlerUpdaterComponent,
        BarcodeTesterComponent,
        SystemInformationComponent,
        MsGraphToolsComponent
    ],
    imports: [
        CommonModule,
        PrimeModule,
        SmBaseModule,
        DocumentHandlerRoutingModule
    ]
})
export class DocumentHandlerModule {

    constructor(private app: MainAppService, private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("documenthandler", "DocumentHandler", "fas fa-th-large", 100, [], ["/documenthandler", "home"]));

        this.app.onSelectionCallback = async () => {
            await DocumentHandlerMainComponent.instance?.openContextMenu(this.getSelectedText());
        };
        this.app.showSidebar = false;
        this.app.showTopbar = false;

        SmTableComponent.defaultActionButtons = true;
        SmTableComponent.defaultNoWrapCell = true;
        SmTableComponent.defaultFilterButtons = false;
        SmTableComponent.convertCsvToExcel = async csv => DhTools.backendCall("api/tools/csvToExcel", { text: csv }).getString();
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("documenthandler", () => DocumentHandlerRoutingModule);
    }

    getSelectedText(): string {
        let text = "";
        let activeEl = document.activeElement;
        let activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
        if (activeElTagName == "textarea" || activeElTagName == "input" && (/^(?:text|search|password|tel|url)$/i).test((activeEl as any).type as string) && typeof (activeEl as any).selectionStart == "number") {
            text = (activeEl as any).value.slice((activeEl as any).selectionStart, (activeEl as any).selectionEnd);
        } else if (window.getSelection) {
            text = window.getSelection().toString();
        }
        return text;
    }


}
