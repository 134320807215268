import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { Pupil } from '../../shared/pupil.model';
import { SchoolClass } from '../../shared/school-class.model';
import { SickNote } from '../../shared/sick-note.entity';

@Component({
  selector: 'app-sick-notes',
  templateUrl: './sick-notes.component.html',
  styleUrls: ['./sick-notes.component.scss']
})
export class SickNotesComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    isAdmin = false;
    id: number;
    year: number;
    isHalfYear: boolean;
    enaioSchoolClass: SchoolClass;
    classRegister: ClassRegister;
    pupils: Pupil[];
    sickNotes: SickNote[];
    table: TableData;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number?", year: "number?", isHalfYear: "bool?" };
    }

    async initParams(): Promise<boolean> {
        if (!this.isGlobal()) {
            this.classRegister = await this.service.restGetClassRegister(this.id);
            this.enaioSchoolClass = await this.service.restGetSchoolClass(this.classRegister.schoolClassId);
            this.year = this.enaioSchoolClass.getYearNumber();
            this.isHalfYear = this.classRegister.isHalfYear;
            await this.loadSickNotes();
        }

        this.isAdmin = await this.service.isSecretary() || this.enaioSchoolClass.hasPermission(this.app.getUserName(), true);

        this.pupils = !this.isGlobal() ? await this.service.restGetPupils(this.enaioSchoolClass.id) : await this.service.restGetPupilsForYear(EnaioCertificatesTools.getSchoolYearFromYear(this.year));

        this.createForm();
        this.updateTable();

        this.app.updateNavigation("Krankmeldungen", this.service.getHome(), [
            this.service.getHomeClassRegister(),
            this.id == null ? { label: "Sekretariat", routerLink: ["/enaio-certificates/class-registers/home"] } : { label: "Klasse " + this.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.classRegister.id] },
            { label: "Krankmeldungen", routerLink: this.id == null ? ["/enaio-certificates", "class-registers", "sick-notes", this.year, this.isHalfYear ? 1 : 0] : ["/enaio-certificates", "class-registers", "sick-notes-class", this.id]}
        ]);
        return true;
    }

    isGlobal(): boolean {
        return this.id == null;
    }

    async loadSickNotes(): Promise<void> {
        this.sickNotes = await RestEndpoint.main().query({ids: this.id}).run("api/cer/classregister/sicknote").list(SickNote);
    }

    async addSickNote(): Promise<void> {
        if (!this.app.validateForm(this.form, () => !Utils.isNumber(this.form.getValue("pupilId")) ? { pupilId: "Der Schüler ist unbekannt" } : null)) {
            return;
        }

        let note = new SickNote();
        this.form.get(note);
        if (this.isGlobal()) {
            note.excused = true;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let [ok, _] = await this.app.saveDataHandler(async () => RestEndpoint.main().post().query({isHalfYear: this.isHalfYear}).body(note).run("api/cer/classregister/sicknote").getText(), this, true, false, "Abgelegt", "Die Krankmeldung wurde abgelegt");
        if (ok) {
            if (!this.isGlobal()) {
                this.sickNotes = [...this.sickNotes, note];
            }
            this.updateTable();
            this.createForm();
        }
    }

    async deleteSickNote(event: any): Promise<void> {
        if ((await this.app.deleteDataHandler(async () => RestEndpoint.main().delete().query({id: (event[0].raw as SickNote).id}).run("api/cer/classregister/sicknote").getText()))[0]) {
            await this.loadSickNotes();
        }
    }

    async sickNoteClicked(event: any): Promise<void> {
        if (event[1] as string == "edit") {
            let sn = (event[0] as TableRow).raw;
            let form = this.service.getSickNoteForm(this.pupils, true);
            form.fields = form.fields.filter(f => f.id != "pupilId"); //TODO Aktuell notwendig, weil AutoComplete Dropdown nicht richtig vorbelegt wird.
            form.fill(sn);
            if (await this.app.messageDialog.form(form, "Krankmeldung bearbeiten") == "ok") {
                let snNew = new SickNote();
                form.get(snNew);
                snNew.id = sn.id;
                snNew.pupilId = sn.pupilId;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let [ok, _] = await this.app.saveDataHandler(async () => RestEndpoint.main().put().query({isHalfYear: this.isHalfYear}).body(snNew).run("api/cer/classregister/sicknote").getText(), this, true, false);
                if (ok) {
                    form.get(sn);
                    if (!this.isGlobal) {
                        this.sickNotes = [...this.sickNotes];
                        this.updateTable();
                    }
                }
            }
        }
    }

    private updateTable(): void {
        this.table = !this.isGlobal() ? new TableData(Utils.arrayWithoutNull([
            new TableColumn("pupil", "Schüler"),
            new TableColumn("fromDate", "Von", TableCellType.date),
            new TableColumn("toDate", "Bis", TableCellType.date),
            new TableColumn("fromHour", "Von Stunde", TableCellType.text),
            new TableColumn("toHour", "Bis Stunde", TableCellType.text),
            new TableColumn("reason", "Grund der Abmeldung"),
            new TableColumn("excused", "Entschuldigt", TableCellType.yesNo),
            this.isAdmin ? new TableColumn("edit", "", TableCellType.button, { width: "50px"}) : null
        ]), this.sickNotes.map(sn => new TableRow(sn, {
            pupil: Utils.arrayFindAndConvert(this.pupils, p => p.id == sn.pupilId, p => p.getFullName(), "<Ungültiger Schüler>"),
            fromDate: sn.fromDate,
            toDate: sn.toDate,
            fromHour: sn.fromHour == -1 ? "-" : sn.fromHour + 1,
            toHour: sn.toHour == -1 ? "-" : sn.toHour + 1,
            reason: sn.reason,
            excused: sn.excused,
            edit: "fas fa-pencil"
        })), [new TableSortColumn("fromDate", false)]) : null;
    }

    private createForm(): void {
        this.form = this.service.getSickNoteForm(this.pupils, !this.isGlobal());
    }

}
