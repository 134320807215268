import { Component, Input, OnDestroy, OnInit, ViewContainerRef, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { CanComponentDeactivate } from "src/modules/sm-base/models/can-component-deactivate.model";
import { SmTimer } from "src/modules/sm-base/models/sm-timer.model";
import { ObjectOfSimpleDataTypes } from "src/modules/utils/shared/object-of-simple-data-types.model";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { MainAppService } from "../services/main-app.service";
import { GuiUtils } from "src/modules/utils/misc/gui-utils";

@Component({
    selector: 'component-view',
    template: ""
})
export abstract class ComponentView implements OnInit, OnDestroy, CanComponentDeactivate {

    app = inject(MainAppService);
    route = inject(ActivatedRoute);
    viewContainerRef = inject(ViewContainerRef);

    @Input()
    embeddedComponent = false;

    neededParams: ObjectOfSimpleDataTypes = {};
    inited = false;
    skipCloseCheck = false;

    timers: SmTimer[] = [];
    keyboardListenerGuids: string[] = [];

    ngOnInit(): void {
        if (this.route != null) {
            this.app.subscribeToParamsChanges(this.route, this.initParamsInternal.bind(this));
        }
    }

    ngOnDestroy(): void {
        this.clearTimers();
        for (let keyboardListenerGuid of this.keyboardListenerGuids) {
            this.app.removeKeyboardListener(keyboardListenerGuid);
        }
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.skipCloseCheck) {
            this.skipCloseCheck = false;
            return true;
        }
        let message = this.getCanDeactivateMessage();
        return message == null || window.confirm(message == "" ? "Wenn Sie fortfahren, gehen alle Änderungen verloren" : message);
    }

    getCanDeactivateMessage(): string {
        return null;
    }

    addTimer(timer: SmTimer): SmTimer {
        this.timers.push(timer);
        return timer;
    }

    clearTimers(): void {
        for (let timer of this.timers) {
            timer.destroy();
        }
    }

    addKeyboardListener(key: string, callback: (KeyboardEvent) => void): void {
        this.keyboardListenerGuids = [...this.keyboardListenerGuids, this.app.addKeyboardListener(key, event2 => {
            callback(event2);
        })];
    }

    async initParamsInternal(params: OrdinaryObject): Promise<void> {
        this.inited = false;
        let p = Utils.parseRequestParams(params, this.neededParams);
        for (let paramName of Utils.getOwnPropertyNames(this.neededParams)) {
            this[paramName] = p[paramName];
        }
        if (!this.embeddedComponent && await this.initParams()) {
            GuiUtils.angularTimer(() => {
                this.inited = true;
            });
        }
    }

    async initEmbedded(): Promise<void> {
        if (!this.inited && await this.initParams()) {
            GuiUtils.angularTimer(() => {
                this.inited = true;
            });
        }
    }

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }
}
