import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { SmFormComponent } from 'src/modules/sm-base/components/sm-form/sm-form.component';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { SimLogQuery } from '../../models/sim-log-query.model';
import { JobRechercheEntry } from 'src/modules/enaio/shared/JobRechercheEntry';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';

@Component({
    selector: 'app-document-handler-recherche',
    templateUrl: './document-handler-recherche.component.html',
    styleUrls: ['./document-handler-recherche.component.scss']
    })
export class DocumentHandlerRechercheComponent extends ComponentView {

    simLogDirs: string[];
    simLogQueries: SimLogQuery[];
    formInput: FrontendFormDefinition;
    actionButtons: MenuItem[];
    tableData: TableData;

    @ViewChild("form") form: SmFormComponent;

    async initParams(): Promise<boolean> {
        this.simLogDirs = await DhTools.backendCall("api/dh/getSimLogDirs").listStrings();
        this.simLogQueries = await DhTools.backendCall("api/dh/getSimLogQueries").list(SimLogQuery);

        this.formInput = new FrontendFormDefinition([
            new FrontendFieldDefinition("query", "Suchtext (enaio ID etc.)", FrontendFieldType.text, { mandatory: true}),
            new FrontendFieldDefinition("beginDate", "Startdatum", FrontendFieldType.datePicker, { value: Utils.dateStartOf(Utils.dateAdd(new Date(), "day", -7), "day"), datePickerWithTime: true}),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { value: Utils.dateEndOf(new Date(), "day"), datePickerWithTime: true})
        ]);

        return true;
    }

    async run(): Promise<void> {
        let endDate = this.formInput.getValue("endDate");
        if (endDate instanceof Date) {
            endDate = Utils.dateEndOf(endDate, "day");
        }
        let result = await DhTools.backendCall("api/dh/recherche", {
            beginDate: this.formInput.getValue("beginDate"),
            endDate,
            query: this.formInput.getValue("query")
        }).list(JobRechercheEntry);
        this.tableData = new TableData([
            new TableColumn("fileName", "Datei"),
            new TableColumn("lineNumber", "Zeile", TableCellType.number),
            new TableColumn("fileDate", "Dateidatum", TableCellType.dateTime),
            new TableColumn("line", "Eintrag")
        ], result.map(item => new TableRow(item, {
            fileName: item.fileName,
            lineNumber: item.lineNumber,
            fileDate: item.fileDate,
            line: item.line
        })));
    }

}
