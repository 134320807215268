import { ApplicationRef, Component, ViewChild, inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Competency } from 'src/modules/enaio-certificates/shared/competency.model';
import { Pupil } from 'src/modules/enaio-certificates/shared/pupil.model';
import { SchoolClass } from 'src/modules/enaio-certificates/shared/school-class.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject, OrdinaryObjectNumber } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { CertificateGrade } from '../../shared/certificate-grade.entity';
import { ConductGrade } from '../../shared/conduct-grade.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { ExamGrade } from '../../shared/exam-grade.entity';
import { ExamType } from '../../shared/exam-type.entity';
import { Exam } from '../../shared/exam.entity';
import { GlobalSettings } from '../../shared/global-settings.entity';
import { GradeBookSchoolClass } from '../../shared/grade-book-school-class.entity';
import { GradeHistory } from '../../shared/grade-history.entity';
import { GradeSettings } from '../../shared/grade-settings.entity';
import { GradeType } from '../../shared/grade-type.enum';
import { NumberFormatMode } from '../../shared/number-format-mode.enum';
import { SchoolTypeSettings } from '../../shared/school-type-settings.entity';
import { Certificate } from '../../shared/certificate.entity';

@Component({
    selector: 'app-class-grades',
    templateUrl: './class-grades.component.html'
})
export class ClassGradesComponent extends ComponentView {

    _Utils = Utils;
    _GuiUtils = GuiUtils;
    _ExamType = ExamType;
    _GradeType = GradeType;
    _NumberFormatMode = NumberFormatMode;

    service = inject(EnaioCertificateService);

    schoolClassId: number;
    isHalfYear: boolean;

    useHeadNotes = false; //Aktuell deaktiviert, da nicht benötigt

    editingAllowed = true;
    isPast = false;
    pupils: Pupil[] = [];
    pupilNamesById: OrdinaryObjectNumber<string>;
    competencies: Competency[] = [];
    domains: SelectItem[] = [];
    enaioSchoolClass: SchoolClass;
    schoolClass: GradeBookSchoolClass;
    oldSchoolClass: GradeBookSchoolClass;
    examsByDomain: OrdinaryObject<Exam[]> = {};
    selectedDomain: string;
    editedGrade: ExamGrade;
    editedExam: Exam;
    settings: SchoolTypeSettings;
    gradeSettings: GradeSettings;
    conductCompetencies: Competency[] = [];
    averageMap: OrdinaryObject<number>;
    averageMapTextualGrade: OrdinaryObject<number>;
    conductGradeMap: OrdinaryObject<ConductGrade>;
    examTypeFactorsTable: TableData;
    copiedHistory: GradeHistory[] = [];
    usesGradeCertificates = false;
    certificates: Certificate[];
    pupilsWithCertificates: number[] = [];
    certificateGrades: CertificateGrade[];
    certificateGradeMap: OrdinaryObject<string>;
    oldCertificateGradeMap: OrdinaryObject<string>;
    showTranslation = false;
    bufferedIsChanged = false;
    certificateGradeItems: FrontendFieldListItem[];
    @ViewChild("opExamTypeFactors") opExamTypeFactors: any;

    constructor(public applicationRef: ApplicationRef) {
        super();
        this.neededParams = { schoolClassId: "number", isHalfYear: "bool" };
    }

    async initParams(): Promise<boolean> {
        this.enaioSchoolClass = await this.service.restGetSchoolClass(this.schoolClassId);
        this.isPast = this.enaioSchoolClass.getYearNumber() < EnaioCertificatesTools.getCurrentYear();
        this.bufferedIsChanged = false;

        this.certificateGradeItems = ["", "1", "2", "3", "4", "5", "6", "erteilt", "nicht bewertbar"].map(gi => new FrontendFieldListItem(gi, gi));

        let globalSettings: GlobalSettings;
        [this.pupils, this.competencies, this.schoolClass, globalSettings] = await Promise.all([
            this.service.restGetPupils(this.schoolClassId),
            RestEndpoint.main().find({ schoolClassId: this.schoolClassId }).run("api/cer/competency").list(Competency),
            RestEndpoint.main().find({ schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear }).run("api/cer/gradebookschoolclass").get(GradeBookSchoolClass),
            RestEndpoint.main().find({}).run("api/cer/globalsettings").get(GlobalSettings)
        ]);

        if (this.isPast) {
            this.pupils = [];
            for (let pupilId of Utils.arrayGetUnique(Utils.arrayExplode(this.schoolClass.exams, e => e.grades).map(g => g.pupilId))) {
                this.pupils = [...this.pupils, await this.service.restGetPupil(pupilId)];
            }
            this.pupils = Utils.arrayWithoutNull(this.pupils);
        }

        this.app.updateNavigation(null,
            { routerLink: ["/enaio-certificates", "grade-book", "home"], icon: 'fas fa-home' }, [
            { label: "Notenbuch", routerLink: ["/enaio-certificates", "grade-book", "home"] },
            { label: "Klasse " + this.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "grade-book", "class", this.enaioSchoolClass.id, this.isHalfYear ? 1 : 0] }
        ]);
        this.settings = await RestEndpoint.main().find({schoolType: this.enaioSchoolClass.getSchoolType(), year: this.enaioSchoolClass.getYearNumber(), isHalfYear: this.isHalfYear}).
            run("api/cer/schooltypesettings").get(SchoolTypeSettings);
        this.gradeSettings = this.settings.getGradeSettings(this.enaioSchoolClass.grade);

        this.editingAllowed = globalSettings.editingAllowed;

        this.conductCompetencies = this.competencies.filter(c => c.isConductGrade());
        this.domains = Utils.arraySort(Utils.arrayGetUnique(this.competencies.filter(c => !c.isConductGrade()).map(c => c.domain))).map(c => ({label: c, value: c}));
        if (this.selectedDomain == null) {
            this.selectedDomain = this.domains[0].value;
        }

        this.usesGradeCertificates = this.enaioSchoolClass.usesGradeCertificates();
        if (this.usesGradeCertificates) {
            this.certificateGrades = await RestEndpoint.main().query({schoolClassId: this.schoolClassId}).run("api/cer/certificate/certificateGrades").list(CertificateGrade);
            this.certificateGradeMap = Utils.arrayToMap(this.certificateGrades, cg => cg.pupilId + '|' + cg.domain, cg => cg.grade);
            this.certificates = await RestEndpoint.main().find({ schoolClassId: this.schoolClassId, onlyStatus: true }).run("api/cer/certificate").list(Certificate);
            this.pupilsWithCertificates = this.certificates.map(item => item.pupilId);
        }

        this.pupilNamesById = {};
        for (let pupil of this.pupils) {
            this.pupilNamesById[pupil.id] = pupil.getFullName();
            if (this.usesGradeCertificates) {
                for (let domain of this.domains) {
                    let key = pupil.id + '|' + domain.value;
                    if (!(key in this.certificateGradeMap)) {
                        this.certificateGradeMap[key] = "";
                    }
                }
            }
        }
        this.oldCertificateGradeMap = Utils.cloneDeep(this.certificateGradeMap);

        this.schoolClass.fillMissingGrades(this.pupils, this.conductCompetencies);
        this.conductGradeMap = this.schoolClass.getConductGradeMap();
        this.examsUpdated();

        //Da dieser Array durch den Benutzer sortiert werden kann, gäbe es sonst Fehlalarm in isChanged()
        this.copiedHistory = [...this.schoolClass.history];
        this.oldSchoolClass = Utils.cloneDeep(this.schoolClass);

        if (Utils.isNoe(this.timers)) {
            this.addTimer(new SmTimer(1000, 1000, () => {
                this.isChanged();
            }));
        }

        return true;
    }

    getCanDeactivateMessage(): string {
        return this.isChanged() ? "Geänderte Noten verwerfen?" : null;
    }

    isChanged(): boolean {
        this.bufferedIsChanged = !Utils.equalsDeep(this.schoolClass, this.oldSchoolClass) || !Utils.equalsDeep(this.certificateGradeMap, this.oldCertificateGradeMap);
        return this.bufferedIsChanged;
    }

    async editGrade(event: any, grade: ExamGrade): Promise<void> {
        this.editedGrade = grade;
        let editGradeForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("title", "Thema (abweichend)", FrontendFieldType.text),
            new FrontendFieldDefinition("date", "Datum (abweichend)", FrontendFieldType.datePicker)
        ]);
        editGradeForm.fill(grade);
        if (await this.app.messageDialog.form(editGradeForm, "Note bearbeiten", [this.app.messageDialog.save, this.app.messageDialog.cancel]) == "save") {
            editGradeForm.get(this.editedGrade);
            this.gradeUpdated();
        }
    }

    async editExam(event: any, exam: Exam, isNew = false): Promise<void> {
        this.editedExam = exam;
        let editExamForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("examType", "Typ", FrontendFieldType.comboBox, { listItems: this.settings.examTypes.filter(et => !et.isInvalidForGrade(this.enaioSchoolClass.grade)).map(et => new FrontendFieldListItem(et.id, et.title)), dropdownEditable: false}),
            new FrontendFieldDefinition("title", "Thema", FrontendFieldType.text, { mandatory: true}),
            new FrontendFieldDefinition("date", "Datum", FrontendFieldType.datePicker, { mandatory: true })
        ]);
        editExamForm.fill(exam);
        //TODO Übersetzung von Unterobjekt in ID notwendig für Forms. Irgendwann irgendwie eleganter lösen bitte
        editExamForm.setValue("examType", exam.examType != null ? exam.examType.id : 0);
        switch (await this.app.messageDialog.form(editExamForm, isNew ? "Leistungsnachweis hinzufügen" : "Leistungsnachweis bearbeiten",
            isNew ? [this.app.messageDialog.ok, this.app.messageDialog.cancel] : [this.app.messageDialog.ok, this.app.messageDialog.cancel, this.app.messageDialog.delete])) {
            case "ok":
                editExamForm.get(this.editedExam);
                this.editedExam.examType = Utils.cloneDeep(this.settings.examTypes.find(et => et.id == this.editedExam.examType as any as number));
                //Change Detection
                this.schoolClass.exams = [...this.schoolClass.exams];
                this.examsUpdated();
                break;
            case "cancel":
                if (isNew) {
                    let index = this.schoolClass.exams.findIndex(e => e == this.editedExam);
                    this.schoolClass.exams.splice(index, 1);
                    this.examsUpdated();
                }
                break;
            case "delete":
                if (await this.app.messageDialog.yesNo("Möchten Sie den ausgewählten Leistungsnachweis wirklich löschen? Alle vergebenen Noten werden nach dem Speichern unwiderruflich gelöscht!", "Warnung")) {
                    let index = this.schoolClass.exams.findIndex(e => e == this.editedExam);
                    this.schoolClass.exams.splice(index, 1);
                    this.examsUpdated();
                }
                break;
        }
    }

    async save(): Promise<void> {
        if (!this.isChanged()) {
            await this.app.messageDialog.info("Es wurden keine Änderungen vorgenommen");
            return;
        }
        this.app.showSpinner = true;
        let changedCertificateGrades: CertificateGrade[] = [];
        if (this.usesGradeCertificates) {
            for (let key of Utils.getOwnPropertyNames(this.certificateGradeMap)) {
                let pupilId = Utils.toNumber(Utils.getSubstringTo(key, "|"));
                let domain = Utils.getSubstringFrom(key, "|");
                let cg = this.certificateGrades.find(item => item.pupilId == pupilId && item.domain == domain);
                let grade = this.certificateGradeMap[key];
                if (cg == null && grade != "") {
                    cg = new CertificateGrade(pupilId, domain, grade);
                    changedCertificateGrades.push(cg);
                }
                else if (cg != null && cg.grade != grade) {
                    cg.grade = grade;
                    changedCertificateGrades.push(cg);
                }
            }

            for (let cg of this.certificateGrades) {
                let s = this.certificateGradeMap[cg.pupilId + "|" + cg.domain];
                if (s != cg.grade) {
                    changedCertificateGrades.push(cg);
                }
            }
        }

        await RestEndpoint.main().body({schoolClass: this.schoolClass, certificateGrades: changedCertificateGrades}).put().run("api/cer/gradebookschoolclass").list(GradeHistory);
        await this.initParams();
        this.app.showSpinner = false;
    }

    export(): void {
        window.open(RestEndpoint.main().query({schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear}).action("api/cer/gradebookschoolclass/export").generateUrl());
    }

    async addExam(event: any): Promise<void> {
        let e = this.newExam(this.selectedDomain);
        this.schoolClass.exams = [...this.schoolClass.exams, e];
        this.examsUpdated();
        await this.editExam(event, e, true);
    }

    newExam(domain: string): Exam {
        let result = Utils.fromPlain(Exam, { domain, date: new Date(), examType: Utils.cloneDeep(this.settings.examTypes[0])});
        result.fillMissingGrades(this.pupils);
        return result;
    }

    showExamTypes(event: any): void {
        let ds = this.settings.domainSettings.find(ds2 => ds2.domain == this.selectedDomain);
        this.examTypeFactorsTable = new TableData([new TableColumn("examType", "Art des Leistungsnachweises"), new TableColumn("factor", "Gewichtung")],
            this.settings.examTypes.map(et => new TableRow(et, {
                examType: et.title,
                factor: Utils.formatNumber(ds.getExamTypeFactorNormalised(et.id, this.settings.examTypes), 2) + "%"
            })));
        this.opExamTypeFactors.show(event);
    }

    formatValue(value: number, overrideRoundingType: NumberFormatMode = null): string {
        return value == null || value == -1 ? "" : this.settings.formatValue(value, overrideRoundingType);
    }

    formatValueBoth(value: number, value2: number, overrideRoundingType: NumberFormatMode = null): string {
        let tg = this.gradeSettings.isUseTextualGrades() ? value2 : null;
        return this.formatValue(value, overrideRoundingType) + (tg != null ? " (" + Utils.formatNumber(tg, 2) + ")" : "");
    }

    formatTextualGrade(value: number, textualGrade?: boolean, forDictation?: boolean): string {
        return value == null || value == -1 ? "" : this.gradeSettings.getTextualGrade(value, textualGrade, forDictation);
    }

    gradeUpdated(): void {
        this.averageMap = this.schoolClass.getPupilAverageMap(this.settings, this.enaioSchoolClass);
        this.averageMapTextualGrade = this.schoolClass.getPupilAverageMapTextualGrade(this.settings, this.enaioSchoolClass.grade, this.enaioSchoolClass);
        this.schoolClass.exams.forEach(exam => exam.calculateAverage());
    }

    selectAll(event: any): void {
        event.target.select();
    }

    private examsUpdated(): void {
        this.examsByDomain = Utils.arrayToMultiMapKeys(this.schoolClass.exams, e => e.domain);
        for (let domain of Object.keys(this.examsByDomain)) {
            this.examsByDomain[domain] = Utils.arraySortBy(this.examsByDomain[domain], e => e.date, false);
        }
        this.gradeUpdated();
    }

}
