import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import 'ace-builds/src-noconflict/ext-language_tools';
import { MenuItem, TreeNode } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { AttachedDocumentHandlerProcess } from 'src/modules/enaio/shared/AttachedDocumentHandlerProcess';
import { DhConfigError } from 'src/modules/enaio/shared/DhConfigError';
import { DhCustomerFile } from 'src/modules/enaio/shared/DhCustomerFile';
import { DhCustomerFileType } from 'src/modules/enaio/shared/DhCustomerFileType';
import { ErrorLogEntry } from 'src/modules/enaio/shared/ErrorLogEntry';
import { ErrorType } from 'src/modules/enaio/shared/ErrorType';
import { JsonPathMapping } from 'src/modules/enaio/shared/JsonPathMapping';
import { AceEditorComponent } from 'src/modules/sm-base/components/ace-editor/ace-editor.component';
import { ButtonDefinition } from 'src/modules/sm-base/models/button-definition.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';
import { BackendResponse } from 'src/modules/sm-base/shared/backend-response.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/shared/config-field-definition.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/shared/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Progressor } from 'src/modules/utils/shared/Progressor';
import { Utils } from 'src/modules/utils/shared/utils';
import { Wrapper } from 'src/modules/utils/shared/wrapper.model';
import { DhCustomer } from '../../models/dh-customer.model';
import { DhRunningInstance } from '../../models/dh-running-instance.model';
import { DhTools } from '../../models/dh-tools.model';
import { HandlerConfigState } from '../../models/handler-config-state.model';
import { DocumentHandlerInstanceComponent } from '../document-handler-instance/document-handler-instance.component';
import { DocumentHandlerMainComponent } from '../document-handler-main/document-handler-main.component';

@Component({
  selector: 'app-document-handler-hq',
  templateUrl: './document-handler-hq.component.html',
  styleUrls: ['./document-handler-hq.component.scss']
})
export class DocumentHandlerHqComponent extends ComponentView {

    _DhCustomerFileType = DhCustomerFileType;

    _Utils = Utils;

    customers: DhCustomer[];
    selectedCustomer = "";

    files: DhCustomerFile[];

    configsTree: TreeNode2[];
    configsTreeSelected: ArrayOrSingle<TreeNode>;
    configsTreeSelectedLast: TreeNode2;

    lastRequestedCursorRow = -1;
    lastRequestedCursorCol = -1;

    lastRequestedText: string = null;
    jsonPathMapping = new JsonPathMapping();
    jsonErrors: DhConfigError[] = [];

    selectedJsonPath = "";

    tabIndex = 0;
    subTabIndex = 0;

    runParamsDebug = true;
    runParamsVerbose = false;
    runParamsSparse = false;
    runParamsSingleStep = false;
    runParamsSingleStepAfterEveryObject = false;
    runParamsOther = "";

    useExperimentalHelp = false;

    savedJson = "";
    currentJson = "";

    currentHelpText = "";

    anyFilters = false;

    runningInstances: DhRunningInstance[] = [];

    errors: ErrorLogEntry[] = [];
    errorTable: TableData;

    openDirItems: MenuItem[] = [];
    analyzeItems: MenuItem[] = [];
    customerItems: MenuItem[] = [];
    configMenuItems: MenuItem[] = [];
    dhDevItems: MenuItem[] = [];

    progressor = new Wrapper<Progressor>();

    @ViewChild("editor", {read: ElementRef}) private editorRef: ElementRef;
    @ViewChild("editor") private editor: AceEditorComponent;
    @ViewChild("configsTreeComponent") configsTreeComponent: Tree;
    @ViewChildren('instancePanels') instancePanels: QueryList<DocumentHandlerInstanceComponent>;

    async initParams(): Promise<boolean> {
        this.addTimer(new SmTimer(1000, 1000, this.onTimer.bind(this), true));
        this.addKeyboardListener("Ctrl-S", event => {
            if (GuiUtils.isComponentVisible(this.editorRef)) {
                GuiUtils.angularTimer(async () => this.save());
                event.preventDefault();
            }
        });
        this.addKeyboardListener("Ctrl-R", event => {
            if (GuiUtils.isComponentVisible(this.editorRef)) {
                GuiUtils.angularTimer(async () => this.run());
                event.preventDefault();
            }
        });

        this.editor.getEditor().getSession().setUseWorker(false);
        this.editor.getEditor().session.on('change', this.onTimer.bind(this));

     /* this.aceEditor.session.selection.on('changeCursor', async _ => {
            if (this.aceEditor.selection.getCursor().row != this.lastRequestedCursorRow || this.aceEditor.selection.getCursor().column != this.lastRequestedCursorCol) {
                this.lastRequestedCursorRow = this.aceEditor.selection.getCursor().row;
                this.lastRequestedCursorCol = this.aceEditor.selection.getCursor().column;
                this.jsonPathMappings = await DhTools.backendCall("api/dh/getJsonPathMappings", { text: this.aceEditor.session.getValue()}).list(JsonPathMapping);
            }
        });
        const langTools = ace.require('ace/ext/language_tools');
        // data stub:
        const sqlTables = [
            { name: 'users', description: 'Users in the system' },
            { name: 'userGroups', description: 'User groups to which users belong' },
            { name: 'customers', description: 'Customer entries' },
            { name: 'companies', description: 'Legal entities of customers' },
            { name: 'loginLog', description: 'Log entries for user log-ins' },
            { name: 'products', description: 'Products offered in the system' },
            { name: 'productCategories', description: 'Different product categories' }
        ];

        const sqlTablesCompleter = {
            getCompletions: (
                editor: ace.Ace.Editor,
                session: ace.Ace.EditSession,
                pos: ace.Ace.Point,
                prefix: string,
                callback: ace.Ace.CompleterCallback
            ): void => {
                callback(
                    null,
                    sqlTables.map((table) => ({
                        caption: `${table.name}: ${table.description}`,
                        value: table.name,
                        meta: 'Table'
                    } as ace.Ace.Completion))
                );
            }
        };
        langTools.addCompleter(sqlTablesCompleter);*/

       /* JSONPaths auf der linken Seite anzeigen

         let self = this;
        let hexNumberRenderer = {
            getText(session, row) {
                return (self.jsonPathMapping == null ? "" : Utils.arrayItemsToString(Utils.arraySort(Utils.objectGetValues(self.jsonPathMapping.items).filter(item => item.line == row + 1).map(item => item.path))) + " ") + Utils.toString(row + 1);
            },
            getWidth(session, lastLineNumber: number, config) {
                return 500;
            },
            update(e, editor) {
                editor.renderer.$loop.schedule(editor.renderer.CHANGE_GUTTER);
            },
            attach(editor) {
                editor.renderer.$gutterLayer.$renderer = this;
                editor.on("changeSelection", this.update);
                this.update(null, editor);
            },
            detach(editor) {
                if (editor.renderer.$gutterLayer.$renderer == this) {
                    editor.renderer.$gutterLayer.$renderer = null;
            }
                editor.off("changeSelection", this.update);
                this.update(null, editor);
            }
        };

        Utils.setTimerOnce(2000, () => hexNumberRenderer.attach(this.editor.getEditor()));
*/
        this.openDirItems = [...[["Config-Pfad öffnen", "configPath"], ["Log-Verzeichnis öffnen", "log"], ["SimLog-Verzeichnis öffnen", "simlog"], ["Work öffnen", "work"], ["Error öffnen", "error"], ["Done öffnen", "done"]].map(item =>
            ({
                label: item[0],
                command: async () => DhTools.backendCall("api/dh/openConfigPath", { customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, type: item[1] }).getString()
            })
        ), {
            label: "Dateien von error in work kopieren",
            command: this.copyErrorToWork.bind(this)
        }];

        this.analyzeItems = [
            {
                label: "Metrics",
                command: async() => {
                    await DocumentHandlerMainComponent.instance.activateTab("metrics");
                    DocumentHandlerMainComponent.instance.viewMetrics.load(Utils.getFileNameWithoutPathAndExtension(this.getSelectedFile().configInfo.name));
                }
            },
            {
                label: "Historie",
                command: async() => {
                    await DocumentHandlerMainComponent.instance.activateTab("documentHandlerRuns");
                    DocumentHandlerMainComponent.instance.viewDocumentHandlerRuns.load(Utils.getFileNameWithoutPathAndExtension(this.getSelectedFile().configInfo.name));
                }
            },
            ...["enaio Objekt-ID", "Eingefügte enaio Objekt-IDs"].map(name =>
            ({
                label: "SimLog " + name,
                command: async() => {
                    await DocumentHandlerMainComponent.instance.activateTab("simLog");
                    await DocumentHandlerMainComponent.instance.viewSimLog.load(this.getSelectedFile().configInfo.name, name);
                }
            }))
        ];

        this.customerItems = [
            {
                label: "Alle Konfigurationen prüfen",
                command: this.verifyAll.bind(this)
            }
        ];

        this.configMenuItems = [
            {
                label: "Neue Konfiguration anlegen",
                command: this.addConfiguration.bind(this)
            },
            {
                label: "Neuen Ordner anlegen",
                command: this.addFolder.bind(this)
            }
        ];

        this.dhDevItems = [
            {
                label: "Konfig parsen & wieder in JSON konvertieren",
                command: this.configToJson.bind(this)
            }
        ];

        this.app.advancedDeveloper = await DhTools.backendCall("api/app/isDeveloper").getBool();
        this.customers = await DhTools.backendCall("api/dh/listCustomers").list(DhCustomer);
        this.selectedCustomer = this.customers.some(c => c.name == "sm") ? "sm" : Utils.arrayGetSafe(this.customers, 0)?.name;
        if (this.customers.length > 1) {
            this.customers = [Utils.fromPlain(DhCustomer, { name: "<ALLE>", path: null}), ...this.customers];
        }
        await this.changeCustomer();

        return true;
    }

    getSelectedConfigCustomer(): string {
        return this.getSelectedFile()?.customer ?? this.selectedCustomer;
    }

    async copyErrorToWork(): Promise<void> {
        let fileNames = await DhTools.backendCall("api/dh/getDocumentFolderFiles", {customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, type: "error"}).listStrings();
        if (fileNames.length == 0) {
            await this.app.messageDialog.info("Es wurden keine Dateien im error-Verzeichnis gefunden");
            return;
        }
        let fileNames2 = await this.app.messageDialog.input<string[]>(new FrontendFieldDefinition("fileNames", "Dateien", FrontendFieldType.comboBoxMulti, { listItems: fileNames.map(fileName => new FrontendFieldListItem(fileName, fileName)), value: fileNames}), "Dateien auswählen");
        if (fileNames2 == null) {
            return;
        }
        await DhTools.backendCall("api/dh/moveFilesToWork", {customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, type: "error", fileNames: fileNames2}).getText();
    }

    async verifyAll(): Promise<void> {
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/dh/verifyAll", { customer: this.selectedCustomer == "<ALLE>" ? null : this.selectedCustomer }).getText(), r => {
            let result = Utils.fromPlainArray(DhConfigError, Utils.fromJson(r.value) as any[]);
            for (let file of this.files) {
                file.verificationFailed = result.some(e => e.customer == file.customer && e.fileName == file.path);
            }
        });
    }

    async onTimer(): Promise<void> {
        if (this.tabIndex == 0) {
            let file = this.getSelectedFile();
            if (file?.configInfo != null && this.currentJson != this.lastRequestedText) {
                this.lastRequestedText = this.currentJson;
                if (this.currentJson != "") {
                    this.jsonPathMapping = await DhTools.backendCall("api/dh/getJsonPathMappings", { customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, text: this.currentJson }).get(JsonPathMapping);
                    this.jsonErrors = await DhTools.backendCall("api/dh/verify", { customer: this.getSelectedConfigCustomer(), configName: file.configInfo.name, overrideFileContents: this.currentJson }).list(DhConfigError);
                    this.editor.getEditor().session.setAnnotations(this.jsonErrors.map(error =>
                        ({
                            row: Math.min(Math.max(0, error.lineNumber - 1), Utils.splitLines(this.currentJson).length - 1),
                            column: error.columnNumber,
                            type: error.errorType == ErrorType.error ? "error" : "warning",
                            text: Utils.stringDef(error.shortMessage, error.message)
                        })));
                }
            }

            if (this.useExperimentalHelp) {
                let cursorPos = this.editor.getEditor().getCursorPosition();
                let selPath = null;
                for (let path of Utils.getOwnPropertyNames(this.jsonPathMapping.items)) {
                    selPath = path;
                    if (this.jsonPathMapping.items[path].line > cursorPos.row || this.jsonPathMapping.items[path].line == cursorPos.row && this.jsonPathMapping.items[path].col >= cursorPos.col) {
                        break;
                    }
                }

                if (selPath != this.selectedJsonPath) {
                    this.selectedJsonPath = selPath;
                    let x = await DhTools.backendCall("api/dh/getConfigPropertyFromJsonPath", { customer: this.getSelectedConfigCustomer(), configName: file.configInfo.name, text: this.currentJson, jsonPath: selPath }).get(ConfigFieldDefinition);
                    console.log(x);

                    this.currentHelpText = x == null ? "" : x.getSimpleTypeName() + " " + x.name + " = " + Utils.toString(x.defaultValue) + (Utils.isNoe(x.helpText) ? "" : ": " + x.helpText);
                }
            }
        }
        else {
            await this.instancePanels.get(this.tabIndex - 1).onTimer();
        }
    }

    async changeCustomer(): Promise<void> {
        let all = this.selectedCustomer == "<ALLE>";
        this.files = await DhTools.backendCall("api/dh/listCustomerFiles", {customer: all ? null : this.selectedCustomer, advanced: !all}).list(DhCustomerFile);
        this.configsTreeSelected = null;
        this.configsTree = GuiUtils.generateTree(this.files, c => all ? [c.customer, ...Utils.split(c.path, "\\")] : Utils.split(c.path, "\\"), (item, key, index) => ({ data: index == key.length - 1 ? item : null, label: key[index], expanded: true}), true);
    }

    isSaved(): boolean {
        return this.currentJson == this.savedJson;
    }

    isCurrentFileActive(): boolean {
        return this.isFileActive(Utils.asSingle(this.configsTreeSelected)?.data as DhCustomerFile);
    }

    isFileActive(file: DhCustomerFile = null): boolean {
        return file == null || file.configInfo == null || Utils.stringEndsWithCi(file.configInfo.name, ".json");
    }

    async selectConfig(): Promise<void> {
        if (this.configsTreeSelectedLast != null && !this.isSaved()) {
            switch (await this.app.messageDialog.show("Die Änderungen wurden noch nicht gespeichert. Wie soll vorgegangen werden?", "Warnung",
            [this.app.messageDialog.save, this.app.messageDialog.cancel, new ButtonDefinition("discard", "Verwerfen", false, "")], { closeOption: "cancel" })) {
                case "save":
                    await this.save(this.configsTreeSelectedLast.data as DhCustomerFile);
                    break;
                case "cancel":
                    this.configsTreeSelected = this.configsTreeSelectedLast;
                    return;
            }
        }

        let file = Utils.asSingle(this.configsTreeSelected)?.data as DhCustomerFile;
        if (file?.type == DhCustomerFileType.config) {
            this.savedJson = await DhTools.backendCall("api/dh/configJson", {customer: this.getSelectedConfigCustomer(), configName: file.configInfo.name}).getString();
        }
        else if (file?.type == DhCustomerFileType.global || file?.type == DhCustomerFileType.asset || file?.type == DhCustomerFileType.template) {
            this.savedJson = await DhTools.backendCall("api/dh/getFileContents", {customer: this.getSelectedConfigCustomer(), fileName: file.path}).getString();
        }
        else {
            this.savedJson = "";
        }
        this.currentJson = this.savedJson;
        this.configsTreeSelectedLast = Utils.asSingle(this.configsTreeSelected);
        this.errors = null;
        this.errorTable = null;
    }

    async run(): Promise<void> {
        let file = this.getSelectedFile();
        let errors = (await DhTools.backendCall("api/dh/verify", { customer: this.getSelectedConfigCustomer(), configName: file.configInfo.name, overrideFileContents: this.currentJson }).list(DhConfigError)).filter(error => error.errorType == ErrorType.error);
        if (!Utils.isNoe(errors) && !await this.app.messageDialog.yesNo("Die Konfiguration scheint fehlerhaft zu sein. Fortfahren?<br><br>" + Utils.arrayItemsToString(errors, "<br>", item => Utils.replaceAll(item.message, "\n", "<br>")), "Warnung")) {
            return;
        }

        let asScheduledTask = this.runParamsOther.includes("asscheduledtask");
        if (asScheduledTask && !this.isSaved() && !await this.app.messageDialog.yesNo("Die Aufgabe soll als geplanter Task ausgeführt werden, aber die aktuelle Konfiguration ist nicht gespeichert. Dies ist zwingend erforderlich, um die aktuellste Version der Konfiguration auszuführen. Dennoch fortfahren?", "Warnung")) {
            return;
        }

        this.runningInstances.push(new DhRunningInstance(await DhTools.backendCall("api/dhinstance/start", Utils.objectRemoveNullValues({
            customer: this.getSelectedConfigCustomer(),
            configName: Utils.stringRemoveSuffix(file.configInfo.name, ".json"),
            params: Utils.arrayItemsToString([
                this.runParamsDebug ? "-d" : "",
                this.runParamsVerbose ? "-v" : "",
                this.runParamsSparse ? "-s" : "",
                this.runParamsSingleStep ? "-t" : "",
                this.runParamsSingleStepAfterEveryObject ? "--singlestepaftereveryobject" : "",
                this.runParamsOther
            ], " "),
            text: asScheduledTask ? null : this.currentJson
        })).get(AttachedDocumentHandlerProcess)));
        GuiUtils.angularTimer(() => {
            this.tabIndex = this.runningInstances.length;
        });
    }

    openFilterDialog(): void {

    }

    getSelectedFile(): DhCustomerFile {
        return Utils.asSingle(this.configsTreeSelected)?.data as DhCustomerFile;
    }

    async save(file: DhCustomerFile = null): Promise<void> {
        file ??= this.getSelectedFile();
        if (file?.type == DhCustomerFileType.config) {
            await DhTools.backendCall("api/dh/saveConfig", {customer: this.getSelectedConfigCustomer(), configName: file.configInfo.name, text: this.currentJson}).getString();
            this.savedJson = this.currentJson;
        }
        else if (file?.type == DhCustomerFileType.global || file?.type == DhCustomerFileType.asset || file?.type == DhCustomerFileType.template) {
            await DhTools.backendCall("api/dh/setFileContents", {customer: this.getSelectedConfigCustomer(), fileName: file.path, text: this.currentJson}).getString();
            this.savedJson = this.currentJson;
        }
    }

    async prettify(): Promise<void> {
        this.currentJson = await DhTools.prettifyJson(this.currentJson);
    }

    async switchConfigActive(): Promise<void> {
        this.getSelectedFile().configInfo.name = await DhTools.backendCall("api/dh/setConfigActive", { customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, active: !this.isCurrentFileActive() }).getString();
        Utils.asSingle(this.configsTreeSelected).label = Utils.getFileNameWithoutPath(this.getSelectedFile().configInfo.name);
    }

    async closeInstance(event: any): Promise<void> {
        let instance = this.runningInstances[event.index - 1];
        let state: HandlerConfigState;
        try {
            state = await DhTools.backendCall("api/dhinstance/state", { instanceGuid: instance.guid }).get(HandlerConfigState);
        }
        catch (ex) {
            let error = BackendResponse.getFromException(ex);
            if (error.isErrorId(1000) || error.isErrorId(1001)) {
                this.runningInstances.splice(event.index - 1, 1);
        //        event.close();
        GuiUtils.angularTimer(() => {
                    this.tabIndex = 0;
                });
            }
            return;
        }
        if (!state.finished && !await this.app.messageDialog.yesNo("Soll die laufende Instanz beendet werden?", "Warnung")) {
            return;
        }
        await DhTools.backendCall("api/dhinstance/exit", { instanceGuid: instance.guid }).getString();
        this.runningInstances.splice(event.index - 1, 1);
        //event.close();
        GuiUtils.angularTimer(() => {
            this.tabIndex = 0;
        });
    }

    tabIndexChange(): void {
        if (this.tabIndex == 0) {
            this.subTabIndexChange();
        }
    }

    subTabIndexChange(focus = true): void {
        if (this.tabIndex == 0 && this.subTabIndex == 0) {
            //this.updateAceEditor(focus); // TODO Warum wird das hier benötigt?
            GuiUtils.angularTimer(() => {
                this.editor.focus();
            });
        }
    }

    updateAceEditor(focus?: boolean): void {
        let json = this.currentJson;
        this.currentJson = "";
        GuiUtils.angularTimer(() => {
            this.currentJson = json;
            if (focus) {
                GuiUtils.angularTimer(() => {
                    this.editor.focus();
                });
            }
        });
    }

    toggleRunParamsDebug(): void {
        this.runParamsDebug = !this.runParamsDebug;
    }

    toggleRunParamsVerbose(): void {
        this.runParamsVerbose = !this.runParamsVerbose;
    }

    toggleRunParamsSparse(): void {
        this.runParamsSparse = !this.runParamsSparse;
    }

    toggleRunParamsSingleStep(): void {
        this.runParamsSingleStep = !this.runParamsSingleStep;
    }

    toggleRunParamsSingleStepAfterEveryObject(): void {
        this.runParamsSingleStepAfterEveryObject = !this.runParamsSingleStepAfterEveryObject;
    }

    toggleUseExperimentalHelp(): void {
        this.useExperimentalHelp = !this.useExperimentalHelp;
    }

    async setCommandLineParams(): Promise<void> {
        let form = new FrontendFormDefinition([
            new FrontendFieldDefinition("noWorkObjectCleanup", "Metadaten bis zum Programmende beibehalten, um Analyse zu erleichtern", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("simulateOnly", "Simulationsmodus", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("dumpHeap", "Nach Beendigung HeapDump erzeugen", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("profile", "Profilingergebnisse in Datei speichern", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("remoteProfile", "Visual Studio Remote Profiling", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("asScheduledTask", "Als geplanten Task ausführen", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("sendMailAfterFinish", "Nach dem Beenden eine Erfolgsmeldung an diese E-Mail-Adresse schicken", FrontendFieldType.text),
            new FrontendFieldDefinition("maxObjects", "Maximale Menge Eingangsobjeke", FrontendFieldType.number),
            new FrontendFieldDefinition("restrictIds", "Ergebnismenge auf diese IDs beschränken", FrontendFieldType.text),
            new FrontendFieldDefinition("restrictIdsAfterGrouping", "ID-Beschränkung nach Gruppierung anwenden", FrontendFieldType.checkBox)
        ]);

        if (await this.app.messageDialog.form(form, "Parameter wählen") == "ok") {
            this.runParamsOther = Utils.arrayItemsToString(Utils.arrayWithoutNull([
                form.getValue("noWorkObjectCleanup") as boolean ? "--noworkobjectcleanup" : null,
                form.getValue("asScheduledTask") as boolean ? "--asscheduledtask" : null,
                !Utils.isNoe(form.getValue("sendMailAfterFinish") as string) ? "--sendmailafterfinish=" + (form.getValue("sendMailAfterFinish") as string) : null,
                form.getValue("simulateOnly") as boolean ? "--simulateonly" : null,
                (form.getValue("maxObjects") as number) > 0 ? "--maxobjects=" + (form.getValue("maxObjects") as number) : null,
                form.getValue("dumpHeap") as boolean ? "--dumpheap" : null,
                form.getValue("profile") as boolean ? "--profile" : null,
                form.getValue("remoteProfile") as boolean ? "--remoteprofile" : null,
                !Utils.isNoe(form.getValue("restrictIds") as string) ? "--restrictids=" + (form.getValue("restrictIds").includes(" ") ? "\"" + form.getValue("restrictIds") + "\"" : form.getValue("restrictIds")) : null,
                form.getValue("restrictIdsAfterGrouping") as boolean ? "--restrictidsaftergrouping" : null
            ]), " ");
        }
    }

    async getErrors(): Promise<void> {
        this.errors = await DhTools.backendCall("api/dh/configErrors", {customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name}).list(ErrorLogEntry);
        this.updateErrorTable();
    }

    updateErrorTable(): void {
        this.errorTable = new TableData([
            new TableColumn("actions", "Aktionen", TableCellType.button),
            new TableColumn("date", "Datum", TableCellType.dateTime),
            new TableColumn("exceptionType", "Exception"),
            new TableColumn("workerName", "Worker"),
            new TableColumn("objectName", "Objekt"),
            new TableColumn("objectIndex", "Objektindex"),
            new TableColumn("errorMessage", "Fehlermeldung")
        ], this.errors.map(error => new TableRow(error, {
            actions: Utils.arrayItemsToString(["fas fa-eye", "fas fa-check"], ","),
            date: error.date,
            exceptionType: error.exceptionType,
            workerName: error.workerName,
            objectName: error.objectName,
            objectIndex: error.objectIndex,
            errorMessage: new TableCell(Utils.replaceAll(error.errorMessage, "\n", "<br>"), { styleClass: "monospace", keepHtml: true})
        })), [new TableSortColumn("date", false)]);
    }

    async confirmAllErrors(): Promise<void> {
        await DhTools.backendCall("api/dh/resolveConfigErrors", {customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, errorIds: "all"}).getString();
        this.errors = [];
        this.getSelectedFile().configInfo.hasRuntimeError = false;
        this.updateErrorTable();
    }

    async errorCellClicked(cell: any): Promise<void> {
        if (cell[1] == "actions") {
            if (cell[2] == "fas fa-eye") {
                this.app.messageDialog.textArea("Details", (cell[0].raw as ErrorLogEntry).raw, null, true, 600, { width: "90%"});
            }
            else if (cell[2] == "fas fa-check") {
                await DhTools.backendCall("api/dh/resolveConfigErrors", {customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, errorIds: (cell[0].raw as ErrorLogEntry).id}).getString();
                this.errors = this.errors.filter(error => error.id != (cell[0].raw as ErrorLogEntry).id);
                this.getSelectedFile().configInfo.hasRuntimeError = this.errors.length > 0;
                this.updateErrorTable();
            }
        }
    }

    async attach(): Promise<void> {
        let processes = await DhTools.backendCall("api/dhinstance/getRunning").list(AttachedDocumentHandlerProcess);
        if (processes.length == 0) {
            await this.app.messageDialog.info("Es wurden keine DocumentHandler-Prozesse zum Anhängen gefunden. Wichtig: Wenn DocumentHandler-Instanzen von einem anderen Benutzer gefunden werden sollen, muss die GUI als Admin ausgeführt werden");
            return;
        }

        let sel = await this.app.messageDialog.input<AttachedDocumentHandlerProcess>(new FrontendFieldDefinition("process", "Prozess", FrontendFieldType.comboBox,
            { mandatory: true, dropdownEditable: false, listItems: processes.map(p => new FrontendFieldListItem(p, p.processId + ": " + p.commandLine))}), "Prozess auswählen");
        if (sel == null) {
            return;
        }
        sel = await DhTools.backendCall("api/dhinstance/attach", { commandLine: sel.commandLine, processId: sel.processId }).get(AttachedDocumentHandlerProcess);

        this.runningInstances.push(new DhRunningInstance(sel));
        GuiUtils.angularTimer(() => {
            this.tabIndex = this.runningInstances.length;
        });
    }

    async addConfiguration(): Promise<void> {
        let folders = Utils.arraySort(Utils.arrayGetUnique([
            ...this.files.filter(ci => ci.type == DhCustomerFileType.config && ci.configInfo.name.includes("\\")).map(ci => Utils.getSubstringToLast(ci.configInfo.name, "\\")),
            ...this.files.filter(ci => ci.type == DhCustomerFileType.configFolder).map(ci => Utils.stringRemovePrefix(ci.path, "handlerConfigs\\"))
        ]));
        let file = this.getSelectedFile();
        let result = await this.app.messageDialog.formGetObject(new FrontendFormDefinition([
            new FrontendFieldDefinition("folder", "Ordner", FrontendFieldType.comboBox, { mandatory: true,
                value: Utils.getSubstringToLast(file == null ? "" : file.configInfo?.name ?? Utils.stringRemovePrefix(file.path, "handlerConfigs\\") ?? "", "\\"),
                listItems: folders.map(name => new FrontendFieldListItem(name, name)) }),
            new FrontendFieldDefinition("name", "Name", FrontendFieldType.text, { mandatory: true })
        ]), "Konfiguration anlegen");
        if (result != null) {
            await DhTools.backendCall("api/dh/addConfig", {customer: this.getSelectedConfigCustomer(), folder: result.folder, name: result.name}).getString();
            await this.changeCustomer();
        }
    }

    async addFolder(): Promise<void> {
        let result = await this.app.messageDialog.formGetObject(new FrontendFormDefinition([
            new FrontendFieldDefinition("name", "Ordner-Name", FrontendFieldType.text, { mandatory: true })
        ]), "Ordner anlegen");
        if (result != null) {
            await DhTools.backendCall("api/dh/addConfigFolder", {customer: this.getSelectedConfigCustomer(), name: result.name}).getString();
            await this.changeCustomer();
        }
    }

    async configToJson(): Promise<void> {
        this.app.messageDialog.textArea("JSON", await DhTools.backendCall("api/dh/configToJson", { customer: this.getSelectedConfigCustomer(), configName: this.getSelectedFile().configInfo.name, overrideFileContents: this.currentJson }).getString());
    }
}
