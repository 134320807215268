import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DatabaseUser } from 'src/modules/database/shared/database-user.entity';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';
import { UserGroup } from '../../shared/user-group.entity';

@Component({
    selector: 'app-edit-user-group',
    templateUrl: './edit-user-group.component.html',
    styleUrls: ['./edit-user-group.component.scss']
  })
export class EditUserGroupComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: UserGroup;
    users: LearningUserFlat[];
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.users = await RestEndpoint.main().find().run("api/lrn/learninguserflat").list(LearningUserFlat);

        let membersListItems = Utils.arraySortBy(this.users.map(u => new FrontendFieldListItem(Utils.fromPlain(DatabaseUser, {id: u.id}), u.lastName + ", " + u.firstName)), u => u.label);
        this.form = this.app.tForm("lea.userGroup", new FrontendFormDefinition([
            new FrontendFieldDefinition("name", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("members", null, FrontendFieldType.comboBoxMulti, { listItems: membersListItems })
        ]));

        this.item = await this.service.adminInitEdit(UserGroup, this.id, this.form, "lea.userGroup", "user-groups", "user-group", "api/lrn/usergroup");
        this.item.members = this.item.members?.map(r => Utils.arrayFindAndConvert(membersListItems, item => item.value.id == r.id, item => item.value as DatabaseUser));
        this.form.fill(this.item);
        return true;
    }

    save(): void {
        this.service.adminUpdate(this.form, UserGroup, this.item, "api/lrn/usergroup");
    }

}
