import { Component, Input } from '@angular/core';
import { saveAs } from 'file-saver';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'enaio-result-list',
    templateUrl: './enaio-result-list.component.html',
    styleUrls: ['./enaio-result-list.component.scss']
})
export class EnaioResultListComponent {

    selectedDocument: EnaioDocument = null;
    selectedDocuments: EnaioDocument[] = [];
    table: TableData;
    private _documents: EnaioDocument[];

    @Input()
    set documents(value: EnaioDocument[]) {
        this._documents = value;
        let cols: TableColumn[] = [];
        for (let doc of value) {
            for (let field of doc.metaData) {
                if (cols.find(col => col.id == field.field.internalName) == null) {
                    cols.push(new TableColumn(field.field.internalName, field.field.name));
                }
            }
        }

        this.table = new TableData(cols, value.map(doc => new TableRow(doc, Utils.arrayConvertToOrdinaryObject(doc.metaData, f => f.field.internalName, f => f.value))));
    }

    rowSelect(event): void {
        this.selectedDocument = event.raw;
    }

    async cellClicked(event): Promise<void> {
        if (event[1] == "_download") {
            await this.downloadDocument(event[0].raw.id as number);
        }
    }


    async downloadDocument(id: number): Promise<void> {
        saveAs(await RestEndpoint.main().query({id, convert: 0}).runBlob("api/doc/document/document"), this.documents.find(doc => doc.id == id).metaData[0].value + ".pdf");
    }
}
