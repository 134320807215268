import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class HandlerConfigState {
    configName: string;
    workObjectsCount = 0;
    currentWorkObjectIndex = 0;
    currentWorkObjectName: number;
    currentWorkerName: string;
    sparse: boolean;
    verbose: boolean;
    singleStep: boolean;
    singleStepAfterEveryObject: boolean;
    breakpointReached: boolean;
    breakpointRequested: boolean;
    globalLoopItem: string;
    finished: boolean;
    errorCount: number;
    actionCount: OrdinaryObject<number> = {};
}
