import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioAssignment } from 'src/modules/enaio/shared/EnaioAssignment';
import { EnaioCallInsert } from 'src/modules/enaio/shared/EnaioCallInsert';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioLocation } from 'src/modules/enaio/shared/EnaioLocation';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { EnaioWorkflowModel } from 'src/modules/enaio/shared/EnaioWorkflowModel';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioInboxDto } from '../../models/enaio-inbox-dto.model';
import { FrontendWorkflowDefinition } from '../../models/frontend-workflow-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';

@Component({
    selector: 'enaio-workflows-main',
    templateUrl: './enaio-workflows-main.component.html',
    styleUrls: ['enaio-workflows-main.component.scss']
})
export class EnaioWorkflowsMainComponent extends ComponentView {

    loading = true;
    startables: EnaioWorkflowModel[] = [];
    inbox: EnaioInboxDto;
    inboxTables: OrdinaryObject<TableData>;
    anyWorkflows = true;
    atWork = true;
    workflowDefs: FrontendWorkflowDefinition[];
    personellFile: EnaioDocument;
    personell: EnaioDocument[];

    async initParams(): Promise<boolean> {
        this.app.updateNavigation("Workflows", { routerLink: ["/enaio-workflows", "home"], icon: 'fas fa-home' }, [
            { label: "Workflows", routerLink: ["/enaio-workflows", "home"] }
        ]);

        this.workflowDefs = await RestEndpoint.main().run("api/wfl/workflow/definitions").list(FrontendWorkflowDefinition);
        this.personellFile = await RestEndpoint.main().run("api/wfl/tbr/getPersonellFile").get(EnaioDocument);
        this.personell = await RestEndpoint.main().run("api/wfl/tbr/getPersonell").list(EnaioDocument);
        this.startables = await RestEndpoint.main().run("api/wfl/workflow/startable").list(EnaioWorkflowModel);
        if (this.personellFile == null || !this.personellFile.getBoolByInternal("Zeitwirtschaft")) {
            this.startables = this.startables.filter(s => s.instanceName != "Gleitzeitantrag");
        }
        this.startables = Utils.arraySortBy(this.startables, s => s.name);

        this.addTimer(new SmTimer(30_000, 30_000, this.refresh.bind(this), true));
        await this.refresh();

        this.loading = false;

        return true;
    }

    async refreshInbox(): Promise<void> {
        this.inbox = await RestEndpoint.main().run("api/wfl/workflow/inbox").get(EnaioInboxDto);

        this.inboxTables = {};
        for (let inbox of this.inbox.inboxes) {
            this.inboxTables[inbox.title] = new TableData(inbox.columns.map(column => new TableColumn(column.title, column.title, column.type)),
                this.inbox.processes.filter(p => p.inboxTitle == inbox.title).map(wi => {
                    let values: OrdinaryObject = {};
                    for (let col of inbox.columns) {
                        values[col.title] = TableCellType.convertValue(wi.columns[col.title], col.type);
                    }
                    return new TableRow(wi, values);
                }));
        }
        this.anyWorkflows = Utils.objectGetValues(this.inboxTables).some(table => table.rows.length > 0);
    }

    async refresh(): Promise<void> {
        await this.refreshInbox();
        this.atWork = await RestEndpoint.main().run("api/wfl/workflow/atWork").getBool();
    }

    async selectRow(event): Promise<void> {
        await this.app.navigateTo(["/enaio-workflows", "workitem", event.raw.id]);
    }

    async changeAtWork(): Promise<void> {
        await RestEndpoint.main().post().query({ absent: !this.atWork }).run("api/wfl/workflow/atWork").getString();
    }

    async startWorkflow(model: EnaioWorkflowModel): Promise<void> {
        this.loading = true;
        let processId = await RestEndpoint.main().post().query({ familyId: model.familyId}).run("api/wfl/workflow/startProcess").getString();
        this.addTimer(new SmTimer(1000, 1000, async () => {
            let workItemId = await RestEndpoint.main().query({ processId}).run("api/wfl/workflow/processReady").getString();
            if (!Utils.isNoe(workItemId)) {
                this.clearTimers();
                this.loading = false;
                await this.app.navigateTo(["/enaio-workflows", "workitem", processId]);
            }
        }, true));
    }

    async openPersonellFile(): Promise<void> {
        await this.app.navigate(['/enaio-documents', 'definition', 'personellFile']);
    }

    async addSickNote(): Promise<void> {
        let form = new FrontendFormDefinition([
            new FrontendFieldDefinition("self", "Für mich selber", FrontendFieldType.checkBox, { value: true, visible: this.personell.length > 0, onValueChanged: () => {
                form.getField("person").visible = !form.getValue("self");
            }}),
            new FrontendFieldDefinition("person", "Mitarbeiter", FrontendFieldType.comboBox, { visible: this.personell.length > 0, mandatory: true, dropdownEditable: false,
                listItems: Utils.arraySortBy(this.personell.map(p => new FrontendFieldListItem(p.id, p.getStringByInternal("Name") + ", " + p.getStringByInternal("Vorname"))), li => li.label)}),
            new FrontendFieldDefinition("beginDate", "Von", FrontendFieldType.datePicker, { mandatory: true, dateIntervalToField: "endDate" }),
            new FrontendFieldDefinition("endDate", "Bis", FrontendFieldType.datePicker, { mandatory: true, dateIntervalFromField: "beginDate" })
        ]);
        form.getField("self").onValueChanged();
        if (await this.app.messageDialog.form(form, "Krankmeldung anlegen") == "cancel") {
            return;
        }

        await RestEndpoint.main().post().body(Utils.fromPlain(EnaioCallInsert, {
            archive: EnaioObjectId.byInternalName("Personal"),
            objectType: EnaioObjectId.byInternalName("Krankmeldung"),
            location: new EnaioLocation(null, form.getValue("self") ? this.personellFile.id : form.getValue("person") as number),
            fields: [
                new EnaioAssignment(EnaioObjectId.byInternalName("Krankmeldungvon"), form.getValue("beginDate")),
                new EnaioAssignment(EnaioObjectId.byInternalName("Krankmeldungbis"), form.getValue("endDate"))
            ]
        })).run("api/wfl/tbr/insert").getString();

        this.app.showToast("success", "Krankmeldung angelegt", "Die Krankmeldung wurde angelegt");
    }

    async addCompensationDay(): Promise<void> {
        let form = new FrontendFormDefinition([
            new FrontendFieldDefinition("person", "Mitarbeiter", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false,
                listItems: Utils.arraySortBy(this.personell.map(p => new FrontendFieldListItem(p.id, p.getStringByInternal("Name") + ", " + p.getStringByInternal("Vorname"))), li => li.label)}),
            new FrontendFieldDefinition("workDate", "Arbeitstag", FrontendFieldType.datePicker, { mandatory: true }),
            new FrontendFieldDefinition("compensationDate", "Ausgleichstag", FrontendFieldType.datePicker, { mandatory: true })
        ]);
        if (await this.app.messageDialog.form(form, "Ausgleichstag anlegen") == "cancel") {
            return;
        }

        await RestEndpoint.main().post().body(Utils.fromPlain(EnaioCallInsert, {
            archive: EnaioObjectId.byInternalName("Personal"),
            objectType: EnaioObjectId.byInternalName("Ausgleichstag"),
            location: new EnaioLocation(null, form.getValue("person") as number),
            fields: [
                new EnaioAssignment(EnaioObjectId.byInternalName("Arbeitstag"), form.getValue("workDate")),
                new EnaioAssignment(EnaioObjectId.byInternalName("Ausgleichstag"), form.getValue("compensationDate"))
            ]
        })).run("api/wfl/tbr/insert").getString();

        this.app.showToast("success", "Ausgleichstag angelegt", "Der Ausgleichstag wurde angelegt");
    }

}
