import { FrontendFieldListItem } from "src/modules/sm-base/shared/frontend-field-list-item.model";
import { TableCellType } from "src/modules/sm-base/shared/table-cell-type.enum";
import { TableColumn } from "src/modules/sm-base/shared/table-column.model";
import { Utils } from "src/modules/utils/shared/utils";
import { EnaioGuiFieldListItem } from "../shared/EnaioGuiFieldListItem";
import { EnaioGuiFieldTableColumn } from "../shared/EnaioGuiFieldTableColumn";
import { EnaioWorkflowDataMember } from "../shared/EnaioWorkflowDataMember";
import { EnaioWorkflowBasicDataType } from "../shared/EnaioWorkflowBasicDataType";
import { FrontendFieldDefinition } from "src/modules/sm-base/shared/frontend-field-definition.model";
import { FrontendFieldType } from "src/modules/sm-base/shared/frontend-field-type.enum";

export class EnaioUtils {

    static guiFieldListItemToFrontend(enaioItem: EnaioGuiFieldListItem, useItemValue: boolean): FrontendFieldListItem {
        return new FrontendFieldListItem(useItemValue ? enaioItem.value : enaioItem.text, enaioItem.text);
    }

    static guiFieldTableColumnToFrontend(enaioItem: EnaioGuiFieldTableColumn, recordMember: EnaioWorkflowDataMember, fullWidth: number): TableColumn {
       // console.log(enaioItem);
       // console.log(recordMember);
        return new TableColumn(
            recordMember != null ? recordMember.name : enaioItem.name,
            enaioItem != null ? enaioItem.name : recordMember.name,
            recordMember?.dataType.basic == EnaioWorkflowBasicDataType.date ? TableCellType.date :
            recordMember?.dataType.basic == EnaioWorkflowBasicDataType.integer ? TableCellType.number :
            TableCellType.text,
            {
                width: Utils.toString(enaioItem != null ? enaioItem.colWidth * 100 / fullWidth : 10),
                editor: Utils.isNoe(enaioItem?.listItems) ? null : new FrontendFieldDefinition("field", "Feld", FrontendFieldType.comboBox, { dropdownEditable: false, listItems: enaioItem.listItems.map(li => new FrontendFieldListItem(li, Utils.toString(li))) })
            });
    }
}
