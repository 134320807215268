<div class="grid">
    <div class="col-2">
        <sm-form  [form]="formSearch">
        </sm-form>
        <p-button icon="fas fa-search" label="Suchen" (onClick)="search()"></p-button>
    </div>
    <div *ngIf="table != null" class="col-10">
        <sm-table-on-push #wfTableComponent [data]="table" [deltaHeight]=200 [virtualScrollHeight]=25>
        </sm-table-on-push>
        <div class="mt-2">
            Anzahl gefundener Einträge: {{table.rows.length}}
        </div>
    </div>
</div>
