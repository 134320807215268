import { plainToClass, plainToClassFromExist } from 'class-transformer';
import dateFormat from "dateformat";
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { Subscription, interval } from 'rxjs';
import { ClassType } from './class-type';
import { Comparable } from './comparable';
import { EnumType } from './enum-type';
import { KeyValuePair } from './key-value-pair.model';
import { ObjectOfSimpleDataTypes } from './object-of-simple-data-types.model';
import { OrdinaryObject, OrdinaryObjectNumber } from './ordinary-object.model';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';

export class UtilsInternal {

    addMissingFields(dest: OrdinaryObject, source: OrdinaryObject): void {
        for (let fieldName of this.getOwnPropertyNames(source)) {
            if (!this.hasProperty(dest, fieldName)) {
                dest[fieldName] = source[fieldName];
            }
            if (this.isObject(dest[fieldName])) {
                this.addMissingFields(dest[fieldName] as OrdinaryObject, source[fieldName] as OrdinaryObject);
            }
        }
    }

    alwaysTrue(): boolean {
        return true;
    }

    arrayConvertToOrdinaryObject<T, U>(arr: T[], toKey: (item: T) => string, toValue: (item: T) => U): OrdinaryObject<U> {
        let result: OrdinaryObject<U> = {};
        for (let item of arr) {
            result[toKey(item)] = toValue(item);
        }
        return result;
    }

    arrayCopy<T>(arr: T[]): T[] {
        return arr == null ? null : [...arr];
    }

    arrayEnsure<T>(arr: T[]): T[] {
        return arr || [];
    }

    arrayEveryAndSome<T>(arr: T[], predicate: (item: T) => boolean): boolean {
        return arr.length > 0 && arr.every(predicate);
    }

    arrayExplode<T, R>(arr: T[], exploder: (item: T) => R[]): R[] {
        let result: R[] = [];
        for (let item of arr) {
            result = [...result, ...exploder(item) ?? []];
        }
        return result;
    }

    arrayFindAndConvert<T, R>(arr: T[], predicate: (item: T) => boolean, converter: (item: T) => R, def: R = null): R {
        let result = arr.find(predicate);
        return result != null ? converter(result) : def;
    }

    arrayFromIt<T>(it: IterableIterator<T>): T[] {
        let result: T[] = [];
        for (let item of it) {
            result.push(item);
        }
        return result;
    }

    arrayGetChanges<T>(arr1: T[], arr2: T[]): { added: T[]; deleted: T[]; eq: boolean } {
        let result: { added: T[]; deleted: T[]; eq: boolean } = { added: [], deleted: [], eq: true};

        for (let item of arr1) {
            if (!arr2.includes(item)) {
                result.deleted.push(item);
            }
        }
        for (let item of arr2) {
            if (!arr1.includes(item)) {
                result.added.push(item);
            }
        }

        result.eq = result.added.length == 0 && result.deleted.length == 0;
        return result;
    }

    arrayGetOrAdd<T>(arr: T[], matcher: (item: T) => boolean, generator: () => T): T {
        let result = arr.find(matcher);
        if (result == null) {
            result = generator();
            arr.push(result);
        }
        return result;
    }

    arrayGetRandom<T>(arr: T[], count: number): T[] {
        let len = arr.length;
        if (count > len) {
            count = len;
        }
        let result: any[] = new Array(count);
        let taken: any[] = new Array(len);
        while (count--) {
            let x = Math.floor(Math.random() * len);
            result[count] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    arrayGetSafe<T>(arr: T[], index: number, defValue: T = null): T {
        return arr != null && index < arr.length ? arr[index] : defValue;
    }

    arrayGetUnique<T>(arr: T[], toKeyConverter: (item: T) => any = null): T[] {
        if (arr == null) {
            return null;
        }
        if (toKeyConverter != null) {
            let result = [];
            let keys = new Set();
            for (let item of arr) {
                let key = toKeyConverter(item);
                if (!keys.has(key)) {
                    keys.add(key);
                    result.push(item);
                }
            }
            return result;
        }
        else {
            return [...new Set(arr)];
        }
    }

    arrayIncludesAny<T>(arr: T[], checkIfIncluded: T[]): boolean {
        for (let c of checkIfIncluded) {
            if (arr.includes(c)) {
                return true;
            }
        }
        return false;
    }

    arrayIsEmpty<T>(arr: T[]): boolean {
        return arr == null || arr.length == 0;
    }

    arrayItemsToString<T>(arr: T[], sep = ", ", converter: (item: T) => string = null, lastSep?: string): string {
        let result = "";
        if (this.arrayIsEmpty(arr)) {
            return result;
        }
        let first = true;
        let i = 0;
        for (let item of arr) {
            result += (first ? "" : lastSep != null && i == arr.length - 1 ? lastSep : sep) + (converter != null ? converter(item) : this.toString(item));
            first = false;
            i++;
        }
        return result;
    }

    arrayMapWithIndex<T, R>(arr: T[], converter: (item: T, index: number) => R): R[] {
        let result: R[] = [];
        for (const [i, element] of arr.entries()) {
            result.push(converter(element, i));
        }
        return result;
    }

    arrayReverse<T>(arr: T[]): T[] {
        let result: T[] = [];
        for (let i = arr.length - 1; i >= 0; i--) {
            result.push(arr[i]);
        }
        return result;
    }

    arraySafeIt<T>(arr: T[]): T[] {
        return arr ?? [];
    }

    arraySetContained<T>(arr: T[], item: T, contained: boolean): T[] {
        if (contained && !arr.includes(item)) {
            return [...arr, item];
        }
        else if (!contained) {
            return this.arrayWithout(arr, item);
        }
        return [...arr];
    }

    arrayShuffle<T>(arr: T[]): T[] {
        for (let i = arr.length - 1; i > 0; i--) {
            let j = Utils.getRandomInt(i + 1);
            let x = arr[i];
            arr[i] = arr[j];
            arr[j] = x;
        }
        return arr;
    }

    arrayShuffleSeeded<T>(arr: T[], seed: string): T[] {
        let gen: () => number = require('seedrandom')(seed);

        for (let i = arr.length - 1; i > 0; i--) {
            let j = Utils.getRandomInt(i + 1, gen);
            let x = arr[i];
            arr[i] = arr[j];
            arr[j] = x;
        }
        return arr;
    }

    arraySort<T>(arr: T[], sorter: (item1: T, item2: T) => number = null): T[] {
        return arr.sort(sorter != null ? sorter : (a, b): number => this.cmp(a, b));
    }

    arraySortBy<T>(arr: T[], converter: (item: T) => any, ascending = true): T[] {
        return arr.sort((a, b) => this.cmp(converter(a), converter(b)) * (ascending ? 1 : -1));
    }

    arraySortByMultiple<T>(arr: T[], converters: ((item: T) => any)[]): T[] {
        return arr.sort((a, b) => {
            for (let converter of converters) {
                let c = this.cmp(converter(a), converter(b));
                if (c != 0) {
                    return c;
                }
            }
            return 0;
        });
    }

    arraySubList<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
        return arr.slice(Math.max(0, fromIndex), Math.min(arr.length, toIndex + 1));
    }

    arraySum(arr: number[]): number {
        return arr.reduce((a, b) => a + b, 0);
    }

    arrayToMap<V, T>(arr: T[], toKey: (item: T) => string, toValue: (item: T) => V): OrdinaryObject<V> {
        let result: OrdinaryObject<V> = {};
        for (let item of arr) {
            result[toKey(item)] = toValue(item);
        }
        return result;
    }

    arrayToMapKeys<V>(arr: V[], toKey: (item: V) => string): OrdinaryObject<V> {
        let result: OrdinaryObject<V> = {};
        for (let item of arr) {
            result[toKey(item)] = item;
        }
        return result;
    }

    arrayToNumberMap<V, T>(arr: T[], toKey: (item: T) => number, toValue: (item: T) => V): OrdinaryObjectNumber<V> {
        let result: OrdinaryObjectNumber<V> = {};
        for (let item of arr) {
            result[toKey(item)] = toValue(item);
        }
        return result;
    }

    arrayToNumberMapKeys<V>(arr: V[], toKey: (item: V) => number): OrdinaryObjectNumber<V> {
        let result: OrdinaryObjectNumber<V> = {};
        for (let item of arr) {
            result[toKey(item)] = item;
        }
        return result;
    }

    arrayToMapMap<K, V, T>(arr: T[], toKey: (item: T) => K, toValue: (item: T) => V): Map<K, V> {
        let result = new Map<K, V>();
        for (let item of arr) {
            result.set(toKey(item), toValue(item));
        }
        return result;
    }

    arrayToMapKeysMap<K, V>(arr: V[], toKey: (item: V) => K): Map<K, V> {
        let result = new Map<K, V>();
        for (let item of arr) {
            result.set(toKey(item), item);
        }
        return result;
    }

    arrayToMultiMap<V, T>(arr: T[], toKey: (item: T) => string, toValue: (item: T) => V): OrdinaryObject<V[]> {
        let result: OrdinaryObject<V[]> = {};
        for (let item of arr) {
            this.getOrAdd(result, toKey(item), () => []).push(toValue(item));
        }
        return result;
    }

    arrayToMultiMapKeys<V>(arr: V[], toKey: (item: V) => string): OrdinaryObject<V[]> {
        let result: OrdinaryObject<V[]> = {};
        for (let item of arr) {
            this.getOrAdd(result, toKey(item), () => []).push(item);
        }
        return result;
    }

    arrayToMultiMapMap<K, V, T>(arr: T[], toKey: (item: T) => K, toValue: (item: T) => V): Map<K, V[]> {
        let result = new Map<K, V[]>();
        for (let item of arr) {
            this.mapGetAdd(result, toKey(item), () => []).push(toValue(item));
        }
        return result;
    }

    arrayToMultiMapKeysMap<K, V>(arr: V[], toKey: (item: V) => K): Map<K, V[]> {
        let result = new Map<K, V[]>();
        for (let item of arr) {
            this.mapGetAdd(result, toKey(item), () => []).push(item);
        }
        return result;
    }

    arrayToPlainMap<V, T>(arr: T[], toKey: (item: T) => string, toValue: (item: T) => V): OrdinaryObject<V> {
        let result: any = {};
        for (let item of arr) {
            result[toKey(item)] = toValue(item);
        }
        return result;
    }

    arrayWithout<T>(arr: T[], toRemove: T): T[] {
        return arr.filter(item => item !== toRemove);
    }

    arrayWithoutNull<T>(arr: T[]): T[] {
        return arr.filter(item => item != null);
    }

    asArray<T>(value: ArrayOrSingle<T>): T[] {
        return this.isArray(value) ? value as T[] : [value as T];
    }

    asSingle<T>(value: ArrayOrSingle<T>): T {
        if (this.isArray(value) && (value as T[]).length > 1) {
            throw new Error("asSingle erwartet Array der Länge 1");
        }
        return this.isArray(value) ? (value as T[]).length == 0 ? null : (value as T[])[0] : value as T;
    }

    base64ToArrayBuffer(base64: string): ArrayBuffer {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.codePointAt(i);
        }
        return bytes.buffer;
    }

    byteToHex(byte: number): string {
        return ('0' + byte.toString(16)).slice(-2);
    }

    bytesToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCodePoint(bytes[i]);
        }
        return window.btoa(binary);
    }

    bytesToUtf8String(buffer: ArrayBuffer): string {
        let result = "";
        let i = 0;
        let c = 0;
        let c2 = 0;
        let c3 = 0;

        let data = new Uint8Array(buffer);

        // If we have a BOM skip it
        if (data.length >= 3 && data[0] === 0xef && data[1] === 0xbb && data[2] === 0xbf) {
            i = 3;
        }

        while (i < data.length) {
            c = data[i];

            if (c < 128) {
                result += String.fromCodePoint(c);
                i++;
            } else if (c > 191 && c < 224) {
                if (i + 1 >= data.length) {
                    throw new Error("UTF-8 Decode failed. Two byte character was truncated.");
                }
                c2 = data[i + 1];
                result += String.fromCodePoint((c & 31) << 6 | c2 & 63);
                i += 2;
            } else {
                if (i + 2 >= data.length) {
                    throw new Error("UTF-8 Decode failed. Multi byte character was truncated.");
                }
                c2 = data[i + 1];
                c3 = data[i + 2];
                result += String.fromCodePoint((c & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                i += 3;
            }
        }
        return result;
    }

    castOrNull<T>(obj: any, type: ClassType<T>): T {
        return obj instanceof type ? obj : null;
    }

    cloneDeep<T>(obj: T): T {
        return require('clone')(obj);
    }

    cmp(o1: any, o2: any): number {
        if (o1 == null) {
            return o2 == null ? 0 : -1;
        }
        else if (o2 == null) {
            return 1;
        }
        else if (typeof o1 === "string" && typeof o2 === "string") {
            return o1.localeCompare(o2);
        }
        else if (o1 instanceof Date && o2 instanceof Date) {
            o1 = o1.getTime();
            o2 = o2.getTime();
            return o1 < o2 ? -1 : o1 > o2 ? 1 : 0;
        }
        else if (typeof o1.compareTo === "function" && typeof o2.compareTo === "function") {
            return (o1 as Comparable<any>).compareTo(o2 as Comparable<any>);
        }
        else {
            return o1 < o2 ? -1 : o1 > o2 ? 1 : 0;
        }
    }

    cmpMulti(items: any[]): number {
        if (items.length % 2 !== 0) {
            throw new Error("Cannot compare item array with length " + items.length);
        }
        for (let i = 0; i < items.length; i += 2) {
            let result = this.cmp(items[i], items[i + 1]);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    }

    cmpMultiWithFactor(items: any[]): number {
        if (items.length % 3 !== 0) {
            throw new Error("Cannot compare item array with length " + items.length);
        }
        for (let i = 0; i < items.length / 3; i += 3) {
            let result = this.cmp(items[i], items[i + 1]);
            if (result !== 0) {
                return result * items[i + 2];
            }
        }
        return 0;
    }

    createArray2Dim<T>(dim1: number, dim2: number, def: T): T[][] {
        let result: T[][] = [];
        for (let i = 0; i < dim1; i++) {
            result.push(Utils.getArrayOfConstant(def, dim2));
        }
        return result;
    }

    createArray3Dim<T>(dim1: number, dim2: number, dim3: number, def: T): T[][][] {
        let result: T[][][] = Utils.getArrayOfConstant(null as T[][], dim1);
        for (let i = 0; i < dim1; i++) {
            result[i] = Utils.getArrayOfConstant(null as T[], dim2);
            for (let j = 0; j < dim2; j++) {
                result[i][j] = Utils.getArrayOfConstant(def, dim3);
            }
        }
        return result;
    }

    crop(s: string, length: number, ellipses = true): string {
        let e = ellipses ? " ..." : "";
        return s.length > length ? s.slice(0, length - e.length) + e : s;
    }

    customInitialize<T extends object>(o: T): T {
        if (this.isObject(o) && 'customInitializer' in o) {
            (o as any).customInitializer();
        }
        return o;
    }

    dateAdd(d: Date, type: 'milli' | 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year', amount: number): Date {
        switch (type) {
            case 'milli':
                return new Date(new Date(d).setMilliseconds(d.getMilliseconds() + amount));
            case 'second':
                return new Date(new Date(d).setSeconds(d.getSeconds() + amount));
            case 'minute':
                return new Date(new Date(d).setMinutes(d.getMinutes() + amount));
            case 'hour':
                return new Date(new Date(d).setHours(d.getHours() + amount));
            case 'day':
                return new Date(new Date(d).setDate(d.getDate() + amount));
            case 'week':
                return new Date(new Date(d).setDate(d.getDate() + amount * 7));
            case 'month':
                return new Date(new Date(d).setMonth(d.getMonth() + amount));
            case 'year':
                return new Date(new Date(d).setFullYear(d.getFullYear() + amount));
        }
    }

    dateCalendarWeek(date: Date): number {
        let currentThursday = new Date(date.getTime() + (3 - (date.getDay() + 6) % 7) * 86_400_000);
        let yearOfThursday = currentThursday.getFullYear();
        let firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - (new Date(yearOfThursday, 0, 4).getDay() + 6) % 7) * 86_400_000);
        return Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86_400_000 / 7);
    }

    dateDayOfWeek(date: Date): number {
        return (date.getDay() + 6) % 7;
    }

    dateDiffMinutes(pastDate: Date, futureDate: Date): number {
        return Math.floor((futureDate.getTime() - pastDate.getTime()) / 60_000);
    }

    dateDiffSeconds(pastDate: Date, futureDate: Date): number {
        return Math.floor((futureDate.getTime() - pastDate.getTime()) / 1_000);
    }

    dateFormat(date: Date, format: string): string {
        return typeof dateFormat === "function" ? (dateFormat as (date: Date, format: string) => string)(date, format) : (dateFormat as any).default(date, format);
    }

    dateFormatDefaultDate(date: Date): string {
        return this.dateFormat(date, "dd.mm.yyyy");
    }

    dateFormatDefault(date: Date): string {
        return this.dateFormat(date, "dd.mm.yyyy HH:MM:ss");
    }

    dateFromString(s: string, format: string, throwIfInvalid = true): Date {
        let dayjs = require("dayjs");
        dayjs.extend((customParseFormat as any).default);
        let result = dayjs(s, format).toDate() as Date;
        if (!this.dateValid(result)) {
            if (throwIfInvalid) {
                throw new Error("Das Datum '" + s + "' kann nicht mit dem Format '" + format + "' geparsed werden");
            }
            else {
                return null;
            }
        }
        return result;
    }

    dateEq(d1: Date, d2: Date): boolean {
        return d1 == null ? d2 == null : d2 != null && d1.getTime() == d2.getTime();
    }

    dateGeq(d1: Date, d2: Date): boolean {
        return d1.getTime() >= d2.getTime();
    }

    dateGreater(d1: Date, d2: Date): boolean {
        return d1.getTime() > d2.getTime();
    }

    dateLess(d1: Date, d2: Date): boolean {
        return d1.getTime() < d2.getTime();
    }

    dateLeq(d1: Date, d2: Date): boolean {
        return d1.getTime() <= d2.getTime();
    }

    dateEndOf(d: Date, type: "day" | "week" | "month" | "year"): Date {
        let dayjs = require("dayjs");
        return dayjs(d).endOf(type).toDate();
    }

    dateStartOf(d: Date, type: "day" | "week" | "month" | "year"): Date {
        //Montag erster Tag der Woche
        let dayjs = require("dayjs");
        dayjs.Ls.en.weekStart = 1;
        return dayjs(d).startOf(type).toDate();
    }

    dateStartOfNext(d: Date, type: "day" | "week" | "month" | "year"): Date {
        //Montag erster Tag der Woche
        let dayjs = require("dayjs");
        dayjs.Ls.en.weekStart = 1;
        return dayjs(d).endOf(type).add(1, "millisecond").toDate();
    }

    dateValid(d: Date): boolean {
        return d == null || !Number.isNaN(d.getTime());
    }

    dateWithoutTime(d: Date): Date {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    }

    datesAreSameDay(d1: Date, d2: Date): boolean {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }

    debounce<F extends Function>(func: F, wait: number): F {
        let timeoutID: number;

        if (!Number.isInteger(wait)) {
            console.warn("Called debounce without a valid number");
            wait = 300;
        }

        return ((thiss: any, ...args: any[]) => {
            clearTimeout(timeoutID);
            const context = thiss;

            timeoutID = this.setTimerOnce(wait, () => func.apply(context, args));
        }) as any;
    }

    divSafe(nom: number, denom: number): number {
        return nom / (denom != 0 ? denom : 1);
    }

    ensureMap<K, V>(input: Map<K, V>): Map<K, V> {
        return input instanceof Map ? input : new Map<K, V>(Object.entries(input) as any as Iterable<readonly [K, V]>);
    }

    equalsDeep(a: any, b: any, debugOutput?: boolean, debugPath = "this"): boolean {
        if (a === b) {
            return true;
        }

        if (a && b && typeof a == 'object' && typeof b == 'object') {
            if (a.constructor !== b.constructor) {
                if (debugOutput) {
                    console.log("equalsDeep: Änderung gefunden: " + debugPath + ".constructor");
                }
                return false;
            }

            let length;
            if (Array.isArray(a)) {
                length = a.length;
                if (length != b.length) {
                    if (debugOutput) {
                        console.log("equalsDeep: Änderung gefunden: " + debugPath + ".length");
                    }
                    return false;
                }
                for (let i = length; i-- !== 0;) {
                    if (!this.equalsDeep(a[i], b[i], debugOutput, debugOutput ? debugPath + "[" + i + "]" : debugPath)) {
                        if (debugOutput) {
                            console.log("equalsDeep: Änderung gefunden: " + debugPath);
                        }
                        return false;
                    }
                }
                return true;
            }


            if (a instanceof Map && b instanceof Map) {
                if (a.size !== b.size) {
                    if (debugOutput) {
                        console.log("equalsDeep: Änderung gefunden: " + debugPath + ".size");
                    }
                    return false;
                }
                for (let i of a.entries()) {
                    if (!b.has(i[0])) {
                        if (debugOutput) {
                            console.log("equalsDeep: Änderung gefunden: " + debugPath + "." + i[0]);
                        }
                        return false;
                    }
                }
                for (let i of a.entries()) {
                    if (!this.equalsDeep(i[1], b.get(i[0]))) {
                        if (debugOutput) {
                            console.log("equalsDeep: Änderung gefunden: " + debugPath + "." + i[0]);
                        }
                        return false;
                    }
                }
                return true;
            }

            if (a instanceof Set && b instanceof Set) {
                if (a.size !== b.size) {
                    if (debugOutput) {
                        console.log("equalsDeep: Änderung gefunden: " + debugPath + ".size");
                    }
                    return false;
                }
                for (let i of a.entries()) {
                    if (!b.has(i[0])) {
                        if (debugOutput) {
                            console.log("equalsDeep: Änderung gefunden: " + debugPath + "." + i[0]);
                        }
                        return false;
                    }
                }
                return true;
            }

            if (a.constructor === RegExp) {
                let result = a.source === b.source && a.flags === b.flags;
                if (debugOutput) {
                    console.log("equalsDeep: Änderung gefunden: " + debugPath);
                }
                return result;
            }
            if (a.valueOf !== Object.prototype.valueOf) {
                let result = a.valueOf() === b.valueOf();
                if (debugOutput) {
                    console.log("equalsDeep: Änderung gefunden: " + debugPath);
                }
                return result;
            }

            let keys = Object.keys(a as OrdinaryObject);
            length = keys.length;
            if (length !== Object.keys(b as OrdinaryObject).length) {
                if (debugOutput) {
                    console.log("equalsDeep: Änderung gefunden: " + debugPath + ".keys.length");
                }
                return false;
            }

            for (let i = length; i-- !== 0;) {
                if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
                    if (debugOutput) {
                        console.log("equalsDeep: Änderung gefunden: " + debugPath + "." + keys[i]);
                    }
                    return false;
                }
            }
            for (let i = length; i-- !== 0;) {
                if (!this.equalsDeep(a[keys[i]], b[keys[i]])) {
                    if (debugOutput) {
                        console.log("equalsDeep: Änderung gefunden: " + debugPath + "." + keys[i]);
                    }
                    return false;
                }
            }

            return true;
        }

        return Number.isNaN(a as number) && Number.isNaN(b as number);
    }

    errorToString(error: any): string {
        return error.message + "\n" + error.stack;
    }

    escapeHtml(unsafe: string): string {
        return Utils.replaceAll(Utils.replaceAll(Utils.replaceAll(Utils.replaceAll(Utils.replaceAll(unsafe, "&", "&amp;"), "<", "&lt;"), ">", "&gt;"), "\"", "&quot;"), "'", "&#039;");
    }

    expandNumber(n: number, length: number): string {
        let result = this.toString(n);
        while (result.length < length) {
            result = "0" + result;
        }
        return result;
    }

    flatten(input: any, path = ''): OrdinaryObject {
        if (this.isArray(input)) {
          return input.reduce((output: any, value: any, index: any) => ({ ...output, ...this.flatten(value, `${path}[${index}]`) }), {});
        }
        else if (this.isObject(input)) {
          return Object.keys(input as OrdinaryObject).reduce((output, key) => ({ ...output, ...this.flatten(input[key], path ? `${path}.${key}` : key) }), {});
        }
        else {
          return { [path]: input };
        }
    }


    flattenObjectSimple(o: OrdinaryObject): OrdinaryObject {
        let result: OrdinaryObject = {};

        for (let i in o) {
            if (!this.hasProperty(o, i)) {
                continue;
            }

            if (typeof o[i] == 'object' && o[i] !== null) {
                let flatObject = this.flattenObjectSimple(o[i] as OrdinaryObject);
                for (let x in flatObject) {
                    if (!this.hasProperty(flatObject, x)) {
                        continue;
                    }

                    result[i + '.' + x] = flatObject[x];
                }
            } else {
                result[i] = o[i];
            }
        }
        return result;
    }

    formatNumber(n: number, numDecimalDigits: number): string {
        return n.toLocaleString('en-US', { maximumFractionDigits: numDecimalDigits, useGrouping: false });
    }

    fromJson(json: string): any {
        try {
            return JSON.parse(json);
        }
        catch (error) {
            let pos = this.toNumber(this.getSubstringFrom((error as Error).message, "at position "));
            if (pos >= 0) {
                let [row, col] = this.getRowAndColFromPos(json, pos);
                throw new Error("Fehler beim Parsen des JSONs an Index " + pos + " (" + row + ":" + col + ") Orginal-Fehlermeldung: " + (error as Error).message);
            }
            else {
                throw error;
            }
        }

    }

    fromJsonIfString(json: any): any {
        return this.isString(json) ? this.fromJson(json as string) : json;
    }

    fromPlainUnsafe<T extends object>(type: ClassType<T>, plain: any): T {
        return this.customInitialize(plainToClass(type, plain, { enableCircularCheck: true }));
    }

    fromPlainArray<T extends object>(type: ClassType<T>, plain: any[]): T[] {
        return plain.map(item => this.fromPlainUnsafe(type, item));
    }

    fromPlainFill<T>(existing: T, plain: any): T {
        return plainToClassFromExist(existing, plain);
    }

    fromPlain<T extends object>(cls: ClassType<T>, plain: Partial<T>): T {
        return this.fromPlainUnsafe(cls, plain);
    }

    generateGuid(): string {
        return require("uuid").v1();
    }

    getArrayOfConstant<T>(constant: T, count: number): T[] {
        let result: T[] = [];
        for (let i = 0; i < count; i++) {
            result.push(constant);
        }
        return result;
    }

    getColorComponents(color: number): number[] {
        return [color >> 24 & 0xff, color >> 16 & 0xff, color >> 8 & 0xff, color & 0xff];
    }

    getDbRelationObject(id: number): any {
        return id == null || id == 0 || id == -1 ? null : { id };
    }

    getEnumNames(cls: EnumType): string[] {
        return Object.keys(cls).filter((item) => !this.stringIsInt(item));
    }

    getEnumNamesAndValues(cls: EnumType): { index: number; name: string }[] {
        return this.getEnumValues(cls).map(v => ({index: v, name: Utils.toString(cls[v])}));
    }

    getEnumValues(cls: EnumType): number[] {
        return Object.keys(cls).filter((item) => this.stringIsInt(item)).map(item => Utils.toNumber(item));
    }

    getFileNameWithoutPath(fileName: string): string {
        let index = Math.max(fileName.indexOf("/"), fileName.indexOf("\\"));
        return index == -1 ? fileName : fileName.slice(index + 1);
    }

    getFileNameWithoutPathAndExtension(fileName: string): string {
        return this.getSubstringTo(this.getFileNameWithoutPath(fileName), ".");
    }

    getFontColorForBackground(background: number): number {
        let compos = this.getColorComponents(background);
        return compos[0] * 0.299 + compos[1] * 0.587 + compos[1] * 0.114 > 186 ? 0 : 0xffffff;
    }

    getMaterialColors(): number[] {
        return [
            0xf44336,
            0xe91e63,
            0x9c27b0,
            0x673ab7,
            0x3f51b5,
            0x2196f3,
            0x03a9f4,
            0x00bcd4,
            0x009688,
            0x4caf50,
            0x8bc34a,
            0xcddc39,
            0xffeb3b,
            0xffc107,
            0xff9800,
            0xff5722,
            0x795548,
            0x9e9e9e,
            0x607d8b
        ];
    }

    getMonthName(monthIndex: number): string {
        return ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"][monthIndex];
    }

    getMonthNameShort(monthIndex: number): string {
        return ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"][monthIndex];
    }

    getOnlyUpdatedEntries<T>(newEntries: T[], oldEntries: T[], matchFunction: (newItem: T, oldItem: T) => boolean, updatePred: (newItem: T, oldItem: T) => boolean): T[] {
        let result: T[] = [];
        for (let newItem of newEntries) {
            let oldItem = oldEntries.find(item => matchFunction(item, newItem));
            if (oldItem == null || updatePred(newItem, oldItem)) {
                result.push(newItem);
            }
        }
        return result;
    }

    getOrAdd<V>(map: OrdinaryObject<V>, key: string, generator: () => V): V {
        let existing: V;
        if (!this.hasProperty(map, key)) {
            existing = generator();
            map[key] = existing;
        }
        else {
            existing = map[key];
        }
        return existing;
    }

    async getOrAddAsync<V>(map: OrdinaryObject<V>, key: string, generator: () => Promise<V>): Promise<V> {
        let existing: V;
        if (!this.hasProperty(map, key)) {
            existing = await generator();
            map[key] = existing;
        }
        else {
            existing = map[key];
        }
        return existing;
    }

    getOwnPropertyNames<T>(obj: T): string[] {
        let result = [];

        for (let prop in obj) {
            if (Utils.hasProperty(obj, prop)) {
                result.push(prop);
            }
        }
        return result;
    }

    getProperty<T>(on: any, propName: string, fail = false): T {
        if (Utils.hasProperty(on, propName)) {
            return on[propName] as T;
        }
        else if (fail) {
            throw new Error("Die Eigenschaft " + propName + " wurde nicht gefunden in Objekt" + this.toJson(on, false));
        }
        else {
            return null;
        }
    }

    getPropertyDef<T>(on: any, propName: string, def: T): T {
        return Utils.hasProperty(on, propName) ? on[propName] as T : def;
    }

    regexGetGroup(on: string, regex: RegExp, groupIndex: number): string {
        const matches = on.matchAll(regex);

        // eslint-disable-next-line no-unreachable-loop
        for (let match of matches) {
            return Utils.arrayGetSafe(match, groupIndex);
        }
        return null;
    }

    getPropertyNested(on: any, propertyName: string): any {
        for (let part of propertyName.split(".")) {
            let propName = part;
            let arrayIndex = this.regexGetGroup(propName, /.*\[(\d+)]$/g, 1);
            if (arrayIndex != null) {
                propName = this.regexGetGroup(propName, /(.*)\[\d+]$/g, 1);
            }
            if (!this.hasProperty(on, propName)) {
                return null;
            }
            let v = on[propName];
            on = arrayIndex != null && this.isArray(v) ? this.arrayGetSafe(v as any[], this.toNumber(arrayIndex)) : v;
        }
        return on;
    }

    getRandomHash(len = 40): string {
        let result = '';
        const characters = '0123456789ABCDEF';
        for (let i = 0; i < len; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    getRandomInt(max: number, generator: () => number = null): number {
        return Math.floor(generator != null ? generator() : Math.random() * Math.floor(max));
    }

    getRandomPassword(length: number): string {
        let lowerChars = Utils.arrayWithout(Utils.arrayWithout(this.getRangeChar("a", "z"), "i"), "l");
        let upperChars = Utils.arrayWithout(Utils.arrayWithout(this.getRangeChar("A", "Z"), "I"), "O");
        let digitChars = this.getRangeChar("2", "9");
        //let specialChars = ["_", ".", "*", "-", "+", ":", "#", "!", "?", "%", "{", "}", "|", "@", "[", "]", ";", "=", "&", "$", "\\", "/", ",", "(", ")"];
        let chars = [...lowerChars, ...upperChars, ...digitChars/* ...specialChars*/];
        let result: string[] = [...this.arrayGetRandom(lowerChars, 1), ...this.arrayGetRandom(upperChars, 1), ...this.arrayGetRandom(digitChars, 1)/*, ...this.arrayGetRandom(specialChars, 1)*/, ...this.arrayGetRandom(chars, length - 4)];
        return this.arrayShuffle(result).reduce((s1, s2) => s1 + s2, "");
    }

    getRange(from: number, to: number): number[] {
        let result = [];
        for (let i = from; i <= to; i++) {
            result.push(i);
        }
        return result;
    }

    getRangeChar(from: string, to: string): string[] {
        let result = [];
        for (let i = from.codePointAt(0); i <= to.codePointAt(0); i++) {
            result.push(String.fromCodePoint(i));
        }
        return result;
    }

    getRowAndColFromPos(s: string, pos: number): [number, number] {
        let sub = s.slice(0, pos);
        let lines = sub.split("\n");
        return [lines.length, sub.length - sub.lastIndexOf("\n")];
    }

    getSafe<T>(on: OrdinaryObject<T>, paramName: string, def: T = null): T {
        return this.hasProperty(on, paramName) ? on[paramName] : def;
    }

    getStackTrace(): string {
        try {
            throw new Error("getStackTrace");
        }
        catch (error: any) {
            return error.stack as string;
        }
    }

    getStringHash(s: string): number {
        return [...s].reduce((a, b) => {
            a = (a << 5) - a + b.codePointAt(0);
            return a & a;
        }, 0);
    }

    getSubstring(s: string, from: number, to: number): string {
        return s.slice(from >= 0 ? from : 0, to <= s.length ? to : s.length);
    }

    getSubstringBetween(s: string, from: string, to: string): string {
        return this.getSubstringTo(this.getSubstringFrom(s, from), to);
    }

    getSubstringFrom(s: string, from: string): string {
        let index = s.indexOf(from);
        return index === -1 ? s : s.slice(index + from.length);
    }

    getSubstringFromLast(s: string, from: string): string {
        let index = s.lastIndexOf(from);
        return index === -1 ? s : s.slice(index + from.length);
    }

    getSubstringTo(s: string, to: string): string {
        let index = s.indexOf(to);
        return index === -1 ? s : s.slice(0, index);
    }

    getSubstringToLast(s: string, to: string): string {
        let index = s.lastIndexOf(to);
        return index === -1 ? s : s.slice(0, index);
    }

    getTemplateFunction(template: string): (_: any) => string {
 //       return () => "Not Implemented";
        const handlebars = require("handlebars");
        return handlebars.compile(template);
    }

    getTypeName(instance: object): string {
        if (instance == null) {
            return null;
        }
        let result = instance.constructor.name;
        if (result === "Function" && this.hasProperty(instance, "prototype")) {
            result = (instance as any).prototype.constructor.name;
        }
        return result;
    }

    getWeekDayNames(): string[] {
        return ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    }

    getWeekDayName(weekDay: number): string {
        return this.getWeekDayNames()[weekDay];
    }

    getYesNoString(b: boolean): string {
        return b ? "Ja" : "Nein";
    }

    hasProperty(on: any, name: string): boolean {
        // eslint-disable-next-line no-prototype-builtins
        return on?.hasOwnProperty(name);
    }

    httpQueryItemsToString(items: string[]): string {
        return items.length === 0 ? "" : "?" + this.arrayItemsToString(items, "&");
    }

    isArray(object: any): boolean {
        return Array.isArray(object);
    }

    isBool(object: any): boolean {
        return typeof object === "boolean";
    }

    isDate(object: any): boolean {
        return object instanceof Date;
    }

    isInt(object: any): boolean {
        return this.isNumber(object) && Number.isInteger(object as number);
    }

    isIntString(s: string, trim = true): boolean {
        try {
            return Number.isInteger(this.toNumber(trim ? s.trim() : s));
        }
        catch {
            return false;
        }
    }

    isIntOrIntString(object: any, trim = true): boolean {
        return this.isInt(object) || this.isString(object) && this.isIntString(trim ? (object as string).trim() : object as string);
    }

    isNode(): boolean {
        return typeof process !== 'undefined' && process.release.name === 'node';
    }

    isNoe(object: any): boolean {
        return object == null || this.isArray(object) && object.length === 0 || object === "";
    }

    isNumber(object: any): boolean {
        return typeof object === "number";
    }

    isNumberString(s: string): boolean {
        try {
            this.toNumber(s);
            return true;
        }
        catch {
            return false;
        }
    }

    isNumberOrNumberString(object: any): boolean {
        return this.isNumber(object) || this.isString(object) && this.isNumberString(object as string);
    }

    isObject(object: any): boolean {
        return typeof object === 'object' && object !== null;
    }

    isRegexValid(regex: string): boolean {
        try {
            // eslint-disable-next-line no-new
            new RegExp(regex);
            return true;
        }
        catch {
            return false;
        }
    }

    isString(object: any): boolean {
        return typeof object === "string";
    }

    lowerFirst(s: string): string {
        return s.length == 0 ? s : s.charAt(0).toLowerCase() + s.slice(1);
    }

    makeFunctionFromCode(code: string): Function {
        // eslint-disable-next-line no-new-func
        return new Function(code);
    }

    mapTwo<T1, T2, R>(list1: T1[], list2: T2[], converter: (item1: T1, item2: T2) => R): R[] {
        let result: R[] = [];
        for (let i = 0; i < Math.max(list1.length, list2.length); i++) {
            result.push(converter(this.arrayGetSafe(list1, i), this.arrayGetSafe(list2, i)));
        }
        return result;
    }

    mapConvert<K, V, KR, VR>(map: Map<K, V>, keyConverter: (item: K) => KR, valueConverter: (item: V) => VR): Map<KR, VR> {
        let result = new Map<KR, VR>();
        for (let key of map.keys()) {
            result.set(keyConverter(key), valueConverter(map.get(key)));
        }
        return result;
    }

    mapGetAdd<K, V>(map: Map<K, V>, key: K, generator: () => V): V {
        let existing = map.get(key);
        if (existing == null) {
            existing = generator();
            map.set(key, existing);
        }
        return existing;
    }

    mapGetSafe<K, V>(map: Map<K, V>, key: K, def: V): V {
        let existing = map.get(key);
        return existing != null ? existing : def;
    }

    mapItemsToString<K, V>(map: Map<K, V>, sep = ", ", keyValueSep = " = ", keyToString: (key: K) => string = null, valueToString: (value: V) => string = null): string {
        return map.size == 0 ? "" : this.arrayItemsToString([...map.keys()], sep, key =>
            (keyToString != null ? keyToString(key) : this.toString(key)) + keyValueSep + (valueToString != null ? valueToString(map.get(key)) : this.toString(map.get(key))));
    }

    mapValuesToList<K, V>(map: Map<K, V>): V[] {
        return this.arrayFromIt(map.values());
    }

    mapsEquals<K, V>(map1: Map<K, V>, map2: Map<K, V>): boolean {
        if (map1.size !== map2.size) {
            return false;
        }
        for (let [key, val] of map1) {
            let testVal = map2.get(key);
            if (testVal !== val || testVal == null && !map2.has(key)) {
                return false;
            }
        }
        return true;
    }

    mapToOrdinaryObject<V>(map: Map<string, V>): OrdinaryObject<V> {
        let result: OrdinaryObject<V> = {};
        for (let key of map.keys()) {
            result[key] = map.get(key);
        }
        return result;
    }

    matchesFullTextQuery(value: string, query: string): boolean {
        query = query.toUpperCase();
        return Utils.isNoe(query) || value.toUpperCase().includes(query);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    nop() {
    }

    objectGetValues<X>(obj: OrdinaryObject<X>): X[] {
        return this.getOwnPropertyNames(obj).map(key => obj[key]);
    }

    objectRemoveNullValues<T extends OrdinaryObject>(obj: T, recursive?: boolean): T {
        for (let propName of this.getOwnPropertyNames(obj)) {
            if (obj[propName] == null) {
                delete obj[propName];
            }
            else if (recursive && this.isObject(obj[propName])) {
                this.objectRemoveNullValues(obj[propName], recursive);
            }
        }
        return obj;
    }

    objectWithout<T extends OrdinaryObject>(obj: T, remove: string[]): T {
        let result = {...obj};
        for (let propName of remove) {
            delete result[propName];
        }
        return result;
    }

    parseRequestParams(params: any, neededParams: ObjectOfSimpleDataTypes): OrdinaryObject {
        let result: OrdinaryObject = {};
        if (neededParams != null) {
            for (let neededParam of Utils.getOwnPropertyNames(neededParams)) {
                let paramValue: any = params[neededParam];
                let isOptional = neededParams[neededParam].endsWith("?");
                let type = Utils.stringRemoveSuffix(neededParams[neededParam], "?");
                let isArray = type.endsWith("[]");
                type = Utils.stringRemoveSuffix(type, "[]");
                if (paramValue == null) {
                    if (!isOptional) {
                        throw new Error("Parameter '" + neededParam + "' fehlt");
                    }
                }
                else {
                    try {
                        if (isArray) {
                            switch (type) {
                                case "string":
                                    paramValue = Utils.split(Utils.toString(paramValue), ";").map(item => Utils.toString(item));
                                    break;
                                case "number":
                                    paramValue = Utils.split(Utils.toString(paramValue), ";").map(item => Utils.toNumber(item));
                                    break;
                                case "bool":
                                    paramValue = Utils.split(Utils.toString(paramValue), ";").map(item => Utils.toBool(item));
                                    break;
                                case "date":
                                    paramValue = Utils.split(Utils.toString(paramValue), ";").map(item => Utils.dateFromString(item, "YYYYMMDD"));
                            }
                        }
                        else {
                            switch (type) {
                                case "string":
                                    paramValue = Utils.toString(paramValue);
                                    break;
                                case "number":
                                    paramValue = Utils.toNumber(paramValue);
                                    break;
                                case "bool":
                                    paramValue = Utils.toBool(paramValue);
                                    break;
                                case "date":
                                    paramValue = Utils.dateFromString(paramValue as string, "YYYYMMDD");
                            }
                        }
                    }
                    catch (error: any) {
                        throw new Error("Der Anfrageparameter " + neededParam + " muss vom Typ " + type + " sein. Der übergebene Wert " + paramValue + " konnte nicht konvertiert werden\n" + this.errorToString(error));
                    }
                }
                result[neededParam] = paramValue;
            }
        }
        for (let key of Utils.getOwnPropertyNames(params)) {
            if (!Utils.hasProperty(result, key)) {
                result[key] = params[key];
            }
        }
        return result;
    }

    propertiesToKeyValuePairs(o: OrdinaryObject): KeyValuePair[] {
        return this.getOwnPropertyNames(o).map(key => new KeyValuePair(key, o[key] as string));
    }

    regexMatches(s: string, regex: string, caseInsensitive = true): boolean {
        return new RegExp("^" + regex + "$", caseInsensitive ? "i" : "").test(s);
    }

    replaceAll(s: string, pattern: string, replace: string): string {
        return s.replace(new RegExp(pattern, 'g'), replace);
    }

    restrictDecimalDigits(value: number, numDigits: number): string {
        return value.toFixed(numDigits);
    }

    sep2(item1: string, item2: string, sep: string): string {
        return !Utils.isNoe(item1) && !Utils.isNoe(item2) ? item1 + sep + item2 : item1 + item2;
    }

    serializeForm(obj: any, prefix: string): string {
        let result = [];
        for (let p of Utils.getOwnPropertyNames(obj)) {
            let k = prefix ? prefix + "[" + p + "]" : p;
            let v = obj[p];
            result.push(v != null && typeof v === "object" ?
                this.serializeForm(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v as string));
        }
        return result.join("&");
    }

    setPropertyNested(on: any, propertyName: string, value: any, createSubObjects?: boolean): boolean {
        let parts = propertyName.split(".");
        for (let i = 0; i < parts.length - 1; i++) {
            if (!this.hasProperty(on, parts[i])) {
                if (createSubObjects) {
                    on[parts[i]] = {};
                }
                else {
                    return false;
                }
            }
            on = on[parts[i]];
        }
        on[parts[parts.length - 1]] = value;
        return true;
    }

    setTimer(millis: number, onTimer: () => void): Subscription {
        return interval(millis).subscribe(_ => onTimer());
    }

    setTimerOnce(millis: number, onTimer: () => void): any {
        // eslint-disable-next-line no-restricted-syntax
        return setTimeout(onTimer, millis);
    }

    async sleep(ms: number): Promise<any> {
        return new Promise((resolve) => {
            this.setTimerOnce(ms, () => resolve);
        });
    }

    split(s: string, delim: string): string[] {
        return this.isNoe(s) ? [] : s.split(delim);
    }

    splitLines(s: string): string[] {
        return this.isNoe(s) ? [] : s.split(/\r?\n/);
    }

    splitOnce(s: string, delim: string): string[] {
        return s == null ? [] : s.includes(delim) ? [this.getSubstringTo(s, delim), this.getSubstringFrom(s, delim)] : [s];
    }

    stretchNumbers(data: number[], sum: number): number[] {
        let factor = sum / this.arraySum(data);
        return data.map(n => n * factor);
    }

    stringCombine(s1: string, s2: string, sep: string): string {
        return !this.isNoe(s1) && !this.isNoe(s2) ? s1 + sep + s2 : s1 + s2;
    }

    stringDef(s: string, def = ""): string {
        return !this.isNoe(s) ? s : def;
    }

    stringEndsWithCi(s1: string, s2: string): boolean {
        return s1.toUpperCase().endsWith(s2.toUpperCase());
    }

    stringEqualsCi(s1: string, s2: string): boolean {
        return s1.localeCompare(s2, "de", { sensitivity: "base" }) === 0;
    }

    stringIncludesCi(s1: string, s2: string): boolean {
        return s1.toLowerCase().includes(s2.toLowerCase());
    }

    stringIsInt(s: string): boolean {
        return Number.isInteger(Number(s));
    }

    stringRemovePrefix(s: string, prefix: string, recursive = false): string {
        if (this.isNoe(prefix)) {
            return s;
        }
        while (s.startsWith(prefix)) {
            s = s.slice(prefix.length);
            if (!recursive) {
                break;
            }
        }
        return s;
    }

    stringRemoveSuffix(s: string, suffix: string, recursive = false): string {
        if (this.isNoe(suffix)) {
            return s;
        }
        while (s.endsWith(suffix)) {
            s = s.slice(0, s.length - suffix.length);
            if (!recursive) {
                break;
            }
        }
        return s;
    }

    stringToCharArray(s: string): string[] {
        let result = [];
        for (const element of s) {
            result.push(element);
        }
        return result;
    }

    stripBom(s: string): string {
        return s.codePointAt(0) === 0xFEFF ? s.slice(1) : s;
    }

    toBinaryString(n: number): string {
        return (n >>> 0).toString(2);
    }

    toBool(object: any, def: boolean = null): boolean {
        if (typeof object === "boolean") {
            return object;
        }
        else if (typeof object === "number") {
            if (object === 0) {
                return false;
            }
            else if (object === 1) {
                return true;
            }
            else {
                if (def != null) {
                    return def;
                }
                throw new Error("Konnte '" + object + "' nicht in boolean konvertieren");
            }
        }
        else if (this.isString(object)) {
            if (object === "0" || object === "false") {
                return false;
            }
            else if (object === "1" || object === "true") {
                return true;
            }
            else {
                if (def != null) {
                    return def;
                }
                throw new Error("Konnte '" + object + "' nicht in boolean konvertieren");
            }
        }
        else {
            if (def != null) {
                return def;
            }
            throw new Error("Konnte '" + object + "' nicht in boolean konvertieren");
        }
    }

    toByteSize(value: number, exact = true): [number, string] {
        if (value == 0) {
            return [0, "Bytes"];
        }
        let names1 = ["Bytes", "KB", "MB", "GB", "TB"];
        let names2 = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
        for (let i = 4; i >= 1; i--) {
            if (value % 1_024 ** i == 0) {
                return [value / 1_024 ** i, names1[i]];
            }
            if (value % 1_000 ** i == 0) {
                return [value / 1_000 ** i, names2[i]];
            }
        }
        if (!exact) {
            for (let i = 4; i >= 1; i--) {
                if (value / 1_024 ** i >= 10) {
                    return [value / 1_024 ** i, names1[i]];
                }
            }
        }
        return [value, names1[0]];
    }

    toByteSizeString(value: number, exact = true): string {
        if (value == 1 || value == 0) {
            return value + " Byte";
        }
        let result = this.toByteSize(value, exact);
        return this.restrictDecimalDigits(result[0], 0)/*.replace(".00", "")*/ + " " + result[1];
    }

    toDuration(value: number): [number, string] {
        if (value == 0) {
            return [0, "Sekunden"];
        }
        if (value % (60 * 60 * 24) == 0) {
            return [value / (60 * 60 * 24), "Tage"];
        }
        if (value % (60 * 60) == 0) {
            return [value / (60 * 60), "Stunden"];
        }
        if (value % 60 == 0) {
            return [value / 60, "Minuten"];
        }
        return [value, "Sekunden"];
    }

    toDurationString(value: number): string {
        if (value == 1) {
            return value + " Sekunde";
        }
        let result = this.toDuration(value);
        return result[0] + " " + (result[0] == 1 ? this.stringRemoveSuffix(this.stringRemoveSuffix(result[1], "e"), "n") : result[1]);
    }

    toDurationStringAdvanced(millis: number, numSignificant: number, short = false): string {
        const millisPerSecond = 1_000;
        const millisPerMinute = 60 * millisPerSecond;
        const millisPerHour = 60 * millisPerMinute;
        const millisPerDay = 24 * millisPerHour;

        const days = Math.floor(millis / millisPerDay);
        const hours = Math.floor(millis % millisPerDay / millisPerHour);
        const minutes = Math.floor(millis % millisPerHour / millisPerMinute);
        const seconds = Math.floor(millis % millisPerMinute / millisPerSecond);
        const millis2 = Math.floor(millis % millisPerSecond);

        const units = [
          { value: days, label: short ? "d" : "Tag", pluralLabel: short ? "d" : "Tage" },
          { value: hours, label: short ? "h" : "Stunde", pluralLabel: short ? "h" : "Stunden" },
          { value: minutes, label: short ? "m" : "Minute", pluralLabel: short ? "m" : "Minuten" },
          { value: seconds, label: short ? "s" : 'Sekunde', pluralLabel: short ? "s" : "Sekunden" },
          { value: millis2, label: short ? "ms" : 'Millisekunde', pluralLabel: short ? "ms" : "Millisekunden" }
        ];

        let result = '';
        let count = 0;
        for (const unit of units) {
          if (unit.value > 0) {
            const label = unit.value === 1 ? unit.label : unit.pluralLabel;
            result += `${unit.value} ${label}, `;
            count++;
            if (count === numSignificant) {
              break;
            }
          }
        }

        return result.trim().slice(0, -1);
    }

    toColorHexString(color: number | string): string {
        return this.isNumber(color) ? "#" + (color as number).toString(16).padStart(6, "0") : (color as string);
    }

    toColorNumber(color: string | number): number {
        return this.isNumber(color) ? color as number : Number.parseInt((color as string).replace("#", "0x"), 16);
    }

    toJson(obj: any, pretty = false): string {
        const seen = new WeakSet();
        const replacer = (key: any, value: any): any => {
            if (typeof value === "object" && value != null) {
                if (seen.has(value as object)) {
                    return;
                }
                seen.add(value as object);
            }
            return value;
        };
        return pretty ? JSON.stringify(obj, replacer, 2) : JSON.stringify(obj, replacer);
    }

    toNumber(object: any, nullIs0 = false, emptyIs0 = false): number {
        if (typeof object === "boolean") {
            return object ? 1 : 0;
        }
        else if (typeof object === "number") {
            return object;
        }
        else if (this.isString(object)) {
            if (emptyIs0 && object == "") {
                return 0;
            }
            let result = Utils.regexMatches(object as string, "-?[0-9]+(\\.[0-9]+)?") ? Number.parseFloat(object as string) : null;
            if (result == null || Number.isNaN(result)) {
                throw new TypeError("Konnte '" + object + "' nicht in number konvertieren");
            }
            return result;
        }
        else if (object == null && nullIs0) {
            return 0;
        }
        else {
            throw new Error("Konnte '" + object + "' nicht in number konvertieren");
        }
    }

    toString(object: any): string {
        return object == null ? "null" : this.isString(object) ? object as string : typeof object === "number" ? object.toString() : typeof object.getStringRepresentation === "function" ? object.getStringRepresentation() : this.toJson(object);
    }

    unrollJsonObject(o: OrdinaryObject): OrdinaryObject {
        for (let propName of this.getOwnPropertyNames(o)) {
            let parts = this.splitOnce(propName, ".");
            if (parts.length == 2) {
                if (o[parts[0]] == null) {
                    o[parts[0]] = {};
                }
                o[parts[0]][parts[1]] = o[propName];
                delete o[propName];
            }
        }
        for (let propName of this.getOwnPropertyNames(o)) {
            if (this.isObject(o[propName])) {
                this.unrollJsonObject(o[propName] as OrdinaryObject);
            }
        }
        return o;
    }

    upperFirst(s: string): string {
        return s.length == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    }

}

export let Utils = new UtilsInternal();
