import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { TreeNode } from 'primeng/api';
import { ConfigDefinition } from '../../shared/config-definition.model';
import { ConfigFieldType } from '../../shared/config-field-type.enum';
import { ConfigProperty } from '../../shared/config-property.model';
import { ArrayOrSingle } from '../../shared/array-or-single.model';

@Component({
    selector: 'app-config-editor-tree',
    templateUrl: './config-editor-tree.component.html',
    styleUrls: ['./config-editor-tree.component.scss']
})
export class ConfigEditorTreeComponent implements OnInit {

    _ConfigFieldType = ConfigFieldType;
    _Utils = Utils;

    @Input()
    def: ConfigDefinition;
    @Input()
    showInfoColumn = true;
    @Input()
    speakingNames = true;
    @Input()
    quickValues = true;
    @Input()
    propColumnWidth = "550px";
    @Input()
    helpText = "";
    @Output()
    helpTextChange = new EventEmitter<string>();

    config: ConfigProperty;

    nodes: TreeNode2[];
    selectedNode: ArrayOrSingle<TreeNode>;

    ngOnInit(): void {
    }

    hover(field: ConfigProperty, _: any): void {
        this.helpText = field == null ? "" : field.def.helpText;
        this.helpTextChange.emit(this.helpText);
    }

    nodeSelect(event): void {
        this.helpText = event.node.data.def.helpText;
        this.helpTextChange.emit(this.helpText);
    }

    valueChanged(_: any): void {
        this.updateNodes(true);
    }

    setValue(field: ConfigProperty, _: any, value: any): void {
        field.setValue(value);
        this.updateNodes();
    }

    removeObject(field: ConfigProperty, _: any): void {
        field.setValue(null);
        this.updateNodes();
    }

    addObject(field: ConfigProperty, node: any): void {
        field.setValue(this.def.getByName(field.def.typeName).makeDefaultObject(), this.def.getByName(field.def.typeName), this.def);
        node.node.expanded = true;
        this.updateNodes();
    }

    addArrayEntry(field: ConfigProperty, node: any): void {
        if (field.value == null) {
            field.value = [];
        }
        field.value.push(this.def.getByName(field.def.typeName).makeDefaultObject());
        field.setValue(field.value, this.def.getByName(field.def.typeName), this.def);
        node.node.expanded = true;
        this.updateNodes();
    }

    removeArrayEntry(field: ConfigProperty, node: any): void {
        let parent = node?.parent?.data as ConfigProperty;
        if (parent != null && field.indexOnArray >= 0) {
            (parent.value as any[]).splice(field.indexOnArray, 1);
            parent.setValue(parent.value, this.def.getByName(parent.def.typeName), this.def);
        }
        this.updateNodes();
    }

    debug(field: ConfigProperty, _: any): void {
        this.helpText = Utils.toJson(field.value, true);
        this.helpTextChange.emit(this.helpText);
        console.log(field);
    }

    resetToDefault(field: ConfigProperty, _: any): void {
        field.resetToDefault();
        this.updateNodes();
    }

    setNull(field: ConfigProperty, _: any): void {
        field.setValue(null);
        this.updateNodes();
    }

    toggleTemplateString(field: ConfigProperty, _: any): void {
        field.useTemplateString = !field.useTemplateString;
        this.updateNodes();
    }

    changeType(field: ConfigProperty, node: any, event: any): void {
        field.setValue(this.def.getByName(event.value as string).makeDefaultObject(), this.def.getByName(event.value as string), this.def);
        node.node.expanded = true;
        this.updateNodes();
    }

    toTreeNode(input: ConfigProperty, oldNode: TreeNode2, checkForErrors?: boolean): TreeNode2 {
        input.calculate(this.def);
        if (checkForErrors) {
            input.checkForErrors();
        }
        return {
            data: input,
            children: input.value != null && input.properties != null ? Utils.arrayMapWithIndex(input.properties, (p, index) => this.toTreeNode(p, oldNode != null ? Utils.arrayGetSafe(oldNode.children, index) : null)) : [],
            leaf: input.value == null || Utils.isNoe(input.properties) || input.def.arrayDimensions > 0 && (!Utils.isArray(input.value) || input.value.length == 0),
            expanded: oldNode?.expanded
        };
    }

    setObject(config: ConfigProperty): void {
        this.config = config;
        this.updateNodes();
    }

    updateNodes(checkForErrors?: boolean): void {
        let base = this.config; //Utils.arrayGetSafe(this.selectedWorkers, 0) as ConfigProperty;
        this.nodes = base == null ? [] : Utils.arrayMapWithIndex(base.properties, (prop, index) => this.toTreeNode(prop, Utils.arrayGetSafe(this.nodes, index), checkForErrors));
        //this.json = Utils.toJson(this.plain, true);
    }

    getObject(): OrdinaryObject {
        let result: OrdinaryObject = {};
        for (let p of this.config.properties) {
            result[p.def.name] = p.value;
        }
        return result;
    }


}
