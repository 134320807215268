import { Component, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendEnaioMapper } from 'src/modules/enaio/models/frontend-enaio-mapper.model';
import { EnaioCallGetWorkflowModels } from 'src/modules/enaio/shared/EnaioCallGetWorkflowModels';
import { EnaioWorkflowApplicationDefinition } from 'src/modules/enaio/shared/EnaioWorkflowApplicationDefinition';
import { EnaioWorkflowDataField } from 'src/modules/enaio/shared/EnaioWorkflowDataField';
import { EnaioWorkflowMaskFieldDefinition } from 'src/modules/enaio/shared/EnaioWorkflowMaskFieldDefinition';
import { EnaioWorkflowModel } from 'src/modules/enaio/shared/EnaioWorkflowModel';
import { EnaioWorkflowModelState } from 'src/modules/enaio/shared/EnaioWorkflowModelState';
import { SmTableOnPushComponent } from 'src/modules/sm-base/components/sm-table-on-push/sm-table-on-push.component';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-enaio-workflow-models',
    templateUrl: './enaio-workflow-models.component.html',
    styleUrls: ['./enaio-workflow-models.component.scss']
  })
  export class EnaioWorkflowModelsComponent extends ComponentView {

    models: EnaioWorkflowModel[];
    sel: EnaioWorkflowModel;
    selectedMask: string;
    families: { id: string; name: string }[];
    formFilter: FrontendFormDefinition;
    fieldsTable: TableData;
    variablesTable: TableData;
    masksGui: FrontendFieldListItem[] = [];
    maskForm: FrontendFormDefinition;
    @ViewChild("wfTableComponent") fieldsTableComponent: SmTableOnPushComponent;

    async initParams(): Promise<boolean> {
        this.models = Utils.arraySortBy(await DhTools.enaioCall<EnaioWorkflowModel[]>(new EnaioCallGetWorkflowModels()), m => m.version);
        this.families = Utils.arraySort(Utils.arrayGetUnique(this.models.map(m => ({id: m.family.id, name: m.family.name})), m => m.id));
        this.formFilter = new FrontendFormDefinition([
            new FrontendFieldDefinition("familyName", "Workflow-Familie", FrontendFieldType.comboBox, { value: this.families[0], mandatory: true, dropdownEditable: false,
                listItems: this.families.map(f => new FrontendFieldListItem(f, f.name)), onValueChanged: this.workflowFamilyChanged.bind(this)}),
            new FrontendFieldDefinition("modelName", "Workflow-Modell", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false})
        ]);
        this.workflowFamilyChanged();

        return true;
    }

    workflowFamilyChanged(): void {
        let familyId = this.formFilter.getValue("familyName")?.id;
        let items = Utils.arraySort(this.models.filter(m => m.family.id == familyId).map(m => new FrontendFieldListItem(m.id, (m.state == EnaioWorkflowModelState.active ? "[Aktiv] " : m.state == EnaioWorkflowModelState.inUse ? "[In Benutzung] " : "") + m.name + " (v" + m.version + ")")));
        this.formFilter.getField("modelName").listItems = items;
        this.formFilter.setValue("modelName", Utils.arrayGetSafe(items, 0)?.value);
    }

    async open(): Promise<void> {
        let call = Utils.fromPlain(EnaioCallGetWorkflowModels, Utils.objectRemoveNullValues({
            modelIds: [this.formFilter.getValue("modelName")],
            getDefinition: true
        }));
        this.sel = Utils.arrayGetSafe(await DhTools.enaioCall(call), 0);
        if (this.sel != null) {
            this.masksGui = Utils.arraySortBy(this.sel.definition.masks, m => m.name).map(mask => new FrontendFieldListItem(mask.id, mask.name));
            this.selectedMask = this.masksGui[0].value;
            this.changeMask();
        }

        this.variablesTable = new TableData([
            new TableColumn("name", "Name", TableCellType.text),
            new TableColumn("id", "DataField ID", TableCellType.text),
            new TableColumn("description", "Beschreibung", TableCellType.text),
            //new TableColumn("dataType", "Datentyp", TableCellType.text),
            new TableColumn("length", "Länge", TableCellType.number)
        ], this.sel.definition.dataFields.map(df => new TableRow(df, {
            name: df.name,
            id: df.id,
            description: df.description,
           // dataType: df.dataType,
            length: df.length
        })));
    }

    getDataFieldsForMaskField(apps: EnaioWorkflowApplicationDefinition[], maskField: EnaioWorkflowMaskFieldDefinition): EnaioWorkflowDataField[] {
        let result: EnaioWorkflowDataField[] = [];
        for (let app of apps) {
            for (let activity of this.sel.definition.activities) {
                for (let app2 of activity.applications) {
                    if (app2.id == app.id) {
                        for (let p of app.formalParameters) {
                            if (p.id == maskField.id) {
                                let variableId = Utils.arrayGetSafe(app2.actualParameters, p.index - 1);
                                if (variableId != null) {
                                    let dataField = this.sel.definition.dataFields.find(df => df.id == variableId);
                                    if (dataField != null) {
                                        result = [...result, dataField];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return result;
    }

    changeMask(): void {
        let mask = this.sel.definition.masks.find(mask2 => mask2.id == this.selectedMask);
        let apps = this.sel.definition.applications.filter(a => a.maskId == mask.id);

        this.fieldsTable = new TableData([
            new TableColumn("name", "Name", TableCellType.text),
            new TableColumn("variableNames", "Variablennamen", TableCellType.text),
            new TableColumn("dataFieldIds", "DataField IDs", TableCellType.text),
            new TableColumn("dataType", "Datentyp", TableCellType.text),
            new TableColumn("addOnParams", "AddOn-Parameter", TableCellType.text),
            new TableColumn("_edit", "", TableCellType.button)
        ], mask.fields.map(f => {
            let dataFields = this.getDataFieldsForMaskField(apps, f);
            return new TableRow(f, {
                name: f.name,
                variableNames: Utils.arrayItemsToString(Utils.arrayGetUnique(dataFields.map(df => df.name))),
                dataFieldIds: Utils.arrayItemsToString(Utils.arrayGetUnique(dataFields.map(df => df.id))),
                dataType: f.dataType,
                addOnParams: f.maskFieldVal,
                _edit: "fas fa-pencil"
            } as OrdinaryObject);
        }));
        this.maskForm = FrontendEnaioMapper.enaioToFrontendForm(mask.fields.map(f => f.guiField), [], true);

        for (let field of this.maskForm.fields) {
            let mf = mask.fields.find(f => f.guiField.wfFieldId == field.id);
            let dataFields = this.getDataFieldsForMaskField(apps, mf);
            field.tooltip = Utils.arrayItemsToString(Utils.arrayGetUnique(dataFields.map(df => df.name + " (" + df.id + ")")), "\n");
            field.tooltipOnControl = true;
        }
    }

    async editField(event: [TableRow, string, string]): Promise<void> {
        if (event[1] == "_edit") {
            let form = new FrontendFormDefinition([
                new FrontendFieldDefinition("addOnParams", "AddOn-Parameter", FrontendFieldType.textArea, { value: event[0].raw.maskFieldVal, monospaced: true })
            ]);
            if (await this.app.messageDialog.form(form, "Addon-Parameter ändern", [this.app.messageDialog.save, this.app.messageDialog.cancel]) == "save") {
                let v = form.getValue("addOnParams");
                if (v != event[0].raw.maskFieldVal && await this.app.messageDialog.yesNo("WIRKLICH ÄNDERN? AUF EIGENE GEFAHR!!!", "WARNUNG")) {
                    await DhTools.backendCall("api/enaio/changeWfMaskField", { valuesId: event[0].raw.valuesId, maskFieldVal: v}).getString();
                }
            }
        }
    }

    async select(modelId: string): Promise<void> {
        let model = this.models.find(m => m.id == modelId);
        if (model != null) {
            this.formFilter.setValue("familyName", this.families.find(item => item.id == model.familyId));
            this.workflowFamilyChanged();
            this.formFilter.setValue("modelName", model.id);
            await this.open();
        }
    }
}
