<ng-container *ngIf="inited">
    <div class="grid">
        <sm-toolbar class="col-12">
            <p-button icon="fas fa-check" label="Weiterleiten" (onClick)="forward()">
            </p-button>
            <p-button *ngIf="app.getEnvironment().developer" icon="fas fa-check" label="Weiterleiten (Simulieren)"
                (onClick)="forwardSimulate()"></p-button>
            <p-button *ngIf="workflowActivityDef.allowSaveButton" icon="fas fa-save" label="Speichern" (onClick)="save()"></p-button>
            <p-button icon="fas fa-times" label="Abbrechen" (onClick)="cancel()"></p-button>
        </sm-toolbar>
    </div>
    <p-tabView>
        <p-tabPanel header="Workflow">
            <sm-form class="col-12" [form]="workflowForm != null ? workflowForm.form : null" [style.position]="'relative'">
            </sm-form>
        </p-tabPanel>
        <p-tabPanel *ngIf="useTray" header="Dokumente">
            <div class="grid">
                <sm-toolbar class="col-12">
                    <p-button icon="fas fa-plus" label="Dokument anhängen" (onClick)="attachDocument()">
                    </p-button>
                </sm-toolbar>
                <sm-table class="col-8" [data]="documentsTable" (onRowSelect)="selectDocument($event)">
                </sm-table>
                <enaio-document-file-preview class="col-4 full-height-300x" [documentId]="selectedDocument != null ? selectedDocument.id : 0" [anonymousGuid]="workItem.anonymousGuid">

                </enaio-document-file-preview>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="app.getEnvironment().developer" header="Developer">
            <div *ngIf="workflowForm != null" class="grid"
                [innerHTML]="_Utils.replaceAll(_Utils.toJson(workflowForm.workItem.getParameterObject(), true), '\n', '<br>') | keepHtml">
            </div>
        </p-tabPanel>
    </p-tabView>
</ng-container>
