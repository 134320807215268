<div class="grid">
    <div class="col-12">
        <p-button icon="fas fa-run" label="Holen" (onClick)="getLicenses()"></p-button>
    </div>
    <p-tabView *ngIf="lic != null" class="col-12">
        <p-tabPanel header="Lizenzverwaltung">
            <sm-table-on-push #table *ngIf="tableData != null" class="col-12" [data]="tableData" [deltaHeight]=160></sm-table-on-push>
        </p-tabPanel>
        <p-tabPanel header="Lizenzdatei">
            <textarea *ngIf="app.advancedDeveloper " pInputTextarea spellcheck=false class="width100p full-height-200x monospace no-resize" [ngModel]="lic.raw"></textarea>
        </p-tabPanel>
    </p-tabView>
</div>
