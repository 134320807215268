import { Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent extends ComponentView {

    messageService = inject(MessageService);

    done = false;
    uploaded: string;
    form: FrontendFormDefinition;

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("name", "Name", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("department", "Abteilung", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: ["Betreuung", "Buchhaltung", "IT", "Pflege"].map(dep => new FrontendFieldListItem(dep, dep)) }),
            new FrontendFieldDefinition("category", "Kategorie", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: ["Büroausstattung", "Hardware", "Anderes"].map(o => new FrontendFieldListItem(o, o)) }),
            new FrontendFieldDefinition("note", "Bemerkung", FrontendFieldType.textArea, { mandatory: true })
        ]);
        return Promise.resolve(true);
    }

    async send(): Promise<void> {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 3000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return;
        }

        let item: OrdinaryObject = {};
        this.form.get(item);
        item.uploaded = this.uploaded;

        await RestEndpoint.main().body(item).post().body(item).run("api/tst/test/insertOrder").getText();
        this.done = true;
        this.messageService.add({ severity: "success", summary: "Gestartet", life: 3000, detail: "Ein Bestell-Workflow wurde in enaio gestartet"});
    }
}
