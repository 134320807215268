import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { EnaioCertificatesTools } from './enaio-certificates-tools';
import { GuiMarkAssignment } from './gui-mark-assignment.model';
import { Pupil } from './pupil.model';
import { SchoolTypeSettings } from './school-type-settings.entity';
import { SchoolType } from './school-type.enum';

export class GuiCertificateDomain {
    id = 0;
    domain = "";
    teacher = "";
    topics = "";
    averageGrade: number;
    hasLevel2 = false;
    marks: Map<string, Map<string, GuiMarkAssignment[]>> = new Map<string, Map<string, GuiMarkAssignment[]>>();
    marksFlat: GuiMarkAssignment[] = [];
    level1SortPrios: OrdinaryObject<number> = {};
    level2SortPrios: OrdinaryObject<number> = {};

    getSortPriority(): number {
        let mark = this.marksFlat.find(m => m.sortDomain > 0);
        return mark != null ? mark.sortDomain : 999_999;
    }

    showTrend(pupil: Pupil): boolean {
        return pupil.isSpecialReqLearning() && (pupil.getSchoolType() === SchoolType.elementary ? this.domain === "Mathematik" || this.domain === "Deutsch" : this.domain !== "Kompetenzentwicklung");
    }

    getMarkName(mark: number, schoolType: SchoolType, grade: number, settings: SchoolTypeSettings, html = true): string {
        return this.getMarkNames(schoolType, grade, settings, html)[mark];
    }

    getMarkNames(schoolType: SchoolType, grade: number, settings: SchoolTypeSettings, html = true): string[] {
        return EnaioCertificatesTools.getMarkNames(schoolType, grade, this.domain, settings, html);
    }

    hasAnyValidMarks(pupil: Pupil): boolean {
        return this.marksFlat.some(mark => mark.isValid(pupil));
    }

    hasAnyVisibleMarks(pupil: Pupil): boolean {
        return this.marksFlat.some(mark => mark.isVisible(pupil));
    }

}
