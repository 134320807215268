import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentParticipantDto } from '../../shared/learning-content-participant.dto';

@Component({
    selector: 'app-analyses-open-items',
    templateUrl: './analyses-open-items.component.html',
    styleUrls: ['./analyses-open-items.component.scss']
  })
  export class AnalysesOpenItemsComponent extends ComponentView {

    service = inject(EnaioLearnService);

  data: LearningContentParticipantDto[];
  table: TableData;

  async initParams(): Promise<boolean> {
    this.service.updateNavigation(null, [
      { label: this.app.t("lea.analyses.title"), routerLink: ["/enaio-learn", "analyses"] },
      { label: this.app.t("lea.analyses.openItems.title"), routerLink: ["/enaio-learn", "analyses", "open-items"] }
    ]);
    await this.getData();
    return true;
  }

  async getData(): Promise<void> {
    this.data = await RestEndpoint.main().query({ open: true, closed: false }).run("api/lrn/learningcontent/analysesParticipants").list(LearningContentParticipantDto);

    this.table = new TableData([
      new TableColumn("learningContentTitle", "Lerninhalt"),
      new TableColumn("userName", "Person"),
      new TableColumn("department", "Einrichtung"),
      new TableColumn("deadlineDate", "Deadline", TableCellType.date),
      new TableColumn("finalExamFailCount", "Anzahl Fehlversuche", TableCellType.number)
  ], this.data.map(p => new TableRow(p, {
      learningContentTitle: p.learningContentTitle,
      userName: p.userName,
      department: p.department,
      deadlineDate: p.deadlineDate,
      finalExamFailCount: p.finalExamFailCount
  })), [new TableSortColumn("deadlineDate", false)]);
  }
}
