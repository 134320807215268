import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallGetProcessesEx } from 'src/modules/enaio/shared/EnaioCallGetProcessesEx';
import { EnaioProcess } from 'src/modules/enaio/shared/EnaioProcess';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableCell } from 'src/modules/sm-base/shared/table-cell.model';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { EnaioProcessHistory } from 'src/modules/enaio/shared/EnaioProcessHistory';

@Component({
    selector: 'app-enaio-workflow',
    templateUrl: './enaio-workflow.component.html',
    styleUrls: ['./enaio-workflow.component.scss']
})
export class EnaioWorkflowComponent extends ComponentView {

    processId = "";
    p: EnaioProcess;
    variablesTree: TreeNode2[];
    historyTable: TableData;
    showVariables = true;
    unrollVariables: boolean;
    onlyChanges: boolean;
    markChanges = true;
    onlyUserActivities: boolean;
    variablesPerHistoryEntry: OrdinaryObject<OrdinaryObject> = {};
    compareEntries: string[] = [];

    async initParams(): Promise<boolean> {
       return Promise.resolve(true);
    }

    async load(): Promise<void> {
        this.p = Utils.arrayGetSafe(await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetProcessesEx, {
            processId: this.processId,
            includeFinishedWorkflows: true,
            getVariables: true,
            getDocumentIds: true,
            getInboxUsers: true,
            getLastActivityDateDetailed: true,
            getHistory: true,
            getHistoryVariables: true
        })), 0);

        let vars: OrdinaryObject = {};
        for (let h of Utils.arraySortBy(this.p.history, item => item.index)) {
            Object.assign(vars, h.variables);
            this.variablesPerHistoryEntry[h.id] = Utils.cloneDeep(vars);
        }

        this.variablesTree = GuiUtils.objectToTree(this.p.variables);
        this.fillTable();
    }

    fillTable(): void {
        let allVars = Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(this.p.history, h => Utils.getOwnPropertyNames(this.unrollVariables ? Utils.flatten(h.variables) : h.variables))));

        let varDiff: string[] = [];
        if (this.compareEntries.length > 1) {
            for (let v of allVars) {
                let ex = null;
                for (let cmp of this.compareEntries) {
                    let all = this.variablesPerHistoryEntry[cmp];
                    if (ex == null) {
                        ex = all[v];
                    }
                    else if (Utils.toJson(ex) != Utils.toJson(all[v])) {
                        varDiff.push(v);
                    }
                }
            }
        }

        let rows: TableRow[] = [];
        let last: EnaioProcessHistory;
        for (let h of Utils.arraySortBy(this.p.history, item => item.index)) {
            let isUser = !h.userName.startsWith("WF-System");
            if (this.onlyUserActivities && !isUser) {
                continue;
            }
            rows.push(new TableRow(h, ({
                compare: this.compareEntries.includes(h.id),
                index: h.index,
                date: h.date,
                userName: new TableCell(h.userName, { styleClass: isUser ? "bold" : null }),
                activityName: h.activityName,
                activityId: h.activityId,
                historyType: EnaioHelper.processHistoryTypeToString(h.historyType),
                ...this.showVariables ? Utils.arrayToPlainMap(allVars, v => "variable_" + v, v => {
                    let value = Utils.getPropertyNested(this.onlyChanges ? h.variables : this.variablesPerHistoryEntry[h.id], v);
                    return new TableCell(value != null ? Utils.replaceAll(Utils.toString(value), "\\r?\\n", " ") : "---", { styleClass: this.compareEntries.includes(h.id) && varDiff.includes(v) ? "xbg-yellow-400" :
                        this.markChanges && last != null && Utils.toJson(Utils.getPropertyNested(this.variablesPerHistoryEntry[h.id], v)) != Utils.toJson(Utils.getPropertyNested(this.variablesPerHistoryEntry[last.id], v)) ? "xbg-red-400" : null });
                }) : []
            }) as OrdinaryObject));
            last = h;
        }

        this.historyTable = new TableData(Utils.arrayWithoutNull([
            new TableColumn("compare", "Vergl.", TableCellType.yesNo, { width: "60px", editable: true }),
            new TableColumn("index", "Index", TableCellType.number, { width: "35px" }),
            new TableColumn("date", "Datum", TableCellType.dateTime, { width: "130px" }),
            new TableColumn("userName", "Benutzer", TableCellType.text, { width: "150px" }),
            new TableColumn("activityId", "Aktivitäts-ID", TableCellType.number, { width: "250px" }),
            new TableColumn("activityName", "Aktivität", TableCellType.text, { width: "250px" }),
            new TableColumn("historyType", "Typ", TableCellType.text, { width: "250px" }),
            ...this.showVariables ? allVars.map(v => new TableColumn("variable_" + v, v, TableCellType.text, { width: "200px", noWrap: true })) : []
        ]), rows);
    }

    cellEdited(event: any): void {
        if (event[1] == "compare") {
            this.compareEntries = this.historyTable.rows.filter(r => r.values.compare).map(r => (r.raw as EnaioProcessHistory).id);
            this.fillTable();
        }
    }

}
