import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { SchoolClass } from '../../shared/school-class.model';
import { SchoolType } from '../../shared/school-type.enum';

@Component({
  selector: 'app-class-registers-main',
  templateUrl: './class-registers-main.component.html',
  styleUrls: ['./class-registers-main.component.scss']
})
export class ClassRegistersMainComponent extends ComponentView {

    _SchoolType = SchoolType;

    service = inject(EnaioCertificateService);

    year: number;

    schoolClasses: SchoolClass[] = [];
    settings: ClassRegisterSettings;
    years: number[];
    isHalfYear: boolean;
    userName = "";
    isAdmin = false;
    noSchoolType = false;

    constructor() {
        super();
        this.neededParams = { year: "number?" };
        this.isHalfYear = EnaioCertificatesTools.getCurrentIsHalfYear();
        this.years = [2023, 2022, 2021]; //TODO;
    }

    async initParams(): Promise<boolean> {
        if (this.year == null) {
            this.year = EnaioCertificatesTools.getCurrentYear();
        }
        this.isAdmin = await this.service.isSecretary();
        this.userName = this.app.getUserName();
        this.app.updateNavigation(null, this.service.getHome(), [this.service.getHomeClassRegister()]);
        this.settings = await this.service.restGetClassRegisterSettings(this.year, this.isHalfYear);
        this.noSchoolType = !this.isAdmin && !this.settings.getTeacher(this.userName)?.isForAnySchoolType();
        this.schoolClasses = (await this.service.restGetSchoolClasses(EnaioCertificatesTools.getSchoolYearFromYear(this.year))).filter(sc => sc.hasPermissionClassRegister(this.userName, this.isAdmin, this.settings));
        return true;
    }

    getExistingGrades(): number[] {
        return Utils.arrayGetUnique(this.schoolClasses.map(sc => sc.grade));
    }

    async adminClick(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "admin", this.year, this.isHalfYear ? 1 : 0]);
    }

    async sickNotesClick(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "sick-notes", this.year, this.isHalfYear ? 1 : 0]);
    }

    async selectedYearChanged(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "home", this.year]);
    }
}
