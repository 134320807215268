import { Component } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DatabaseUser } from 'src/modules/database/shared/database-user.entity';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';

@Component({
    selector: 'app-edit-general-users',
    templateUrl: './edit-general-users.component.html',
    styleUrls: ['./edit-general-users.component.scss']
  })
  export class EditGeneralUsersComponent {

    listData: DatabaseUser[];
    table: TableData;

    constructor(private app: MainAppService) {
    }

    async ngOnInit(): Promise<void> {
        this.listData = await this.app.loadDataHandler(async () => RestEndpoint.main().find({source: ""}).run("api/app/user").list(DatabaseUser), this);

        this.table = new TableData([
            new TableColumn("userName", "Benutzername"),
            new TableColumn("lastName", "Nachname"),
            new TableColumn("firstName", "Vorname"),
            new TableColumn("mail", "E-Mail"),
            new TableColumn("isAdmin", "Administrator", TableCellType.yesNo)
        ], this.listData.map(o => new TableRow(o, {
            userName: o.userName,
            lastName: o.lastName,
            firstName: o.firstName,
            email: o.mail,
            isAdmin: o.isAdmin
        })), [new TableSortColumn("userName")]);
        this.app.updateNavigation(null, { routerLink: ["/"], icon: 'fas fa-home' }, [
            { label: "Administration", routerLink: ["/app", "admin"] },
            { label: "Benutzer", routerLink: ["/app", "admin", "editGeneralUsers"] }
        ]);
    }
}
