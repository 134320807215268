import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Messages } from 'primeng/messages';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-applicant-form',
  templateUrl: './applicant-form.component.html',
  styleUrls: ['./applicant-form.component.scss']
})
export class ApplicantFormComponent extends ComponentView {

    messageService = inject(MessageService);
    fileUpload = inject(FileUploadService);

    done = false;
    uploaded: string;
    form: FrontendFormDefinition;

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("name", "Name", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("vorname", "Vorname", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("namenszusatz", "Namenszusatz", FrontendFieldType.text),
            new FrontendFieldDefinition("titel", "Titel", FrontendFieldType.text),
            new FrontendFieldDefinition("geburtsdatum", "Geburtsdatum", FrontendFieldType.datePicker, { mandatory: true }),
            new FrontendFieldDefinition("strasse", "Straße", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("plz", "PLZ", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("ort", "Ort", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("email", "E-Mail", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("telefon", "Telefon", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("mobil", "Mobil", FrontendFieldType.text, { mandatory: true })
        ]);
        return Promise.resolve(true);
    }

    async upload(): Promise<void> {
        this.uploaded = Utils.bytesToBase64((await this.fileUpload.chooseFile())[0].contents);
    }

    async send(): Promise<void> {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 3000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return;
        }

        let item: OrdinaryObject = {};
        this.form.get(item);
        item.uploaded = this.uploaded;

        await RestEndpoint.main().body(item).post().body(item).run("api/tst/test/insertApplicant").getText();
        this.done = true;
    }
}
