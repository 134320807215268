<div class="grid">
    <div class="col-2">
        <sm-form #form [form]="formInput">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="run()"></p-button>
    </div>
    <div class="col-10">
        <sm-toolbar *ngIf="data?.length > 0">
            <p-checkbox name="oneColumnPerConfig" [(ngModel)]="oneColumnPerConfig" [binary]="true" (ngModelChange)="setOneColumnPerConfig()"></p-checkbox>
            <label class="form-label mr-3">Eine Spalte pro Konfiguration</label>
            <p-checkbox name="oneRowPerConfig" [(ngModel)]="oneRowPerConfig" [binary]="true" (ngModelChange)="setOneRowPerConfig()"></p-checkbox>
            <label class="form-label mr-3">Eine Zeile pro Konfiguration</label>
            <p-checkbox *ngIf="oneRowPerConfig" name="onlySelectedConfigs" [(ngModel)]="onlySelectedConfigs" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label *ngIf="oneRowPerConfig" class="form-label mr-3">Nur ausgewählte Konfigurationen</label>
            <p-checkbox name="onlyWithWork" [(ngModel)]="onlyWithWork" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Nur mit bearbeiteten Objekten</label>
            <p-checkbox name="onlyWithError" [(ngModel)]="onlyWithError" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Nur mit Fehler-Objekten</label>
        </sm-toolbar>
        <sm-table-on-push #table *ngIf="tableData != null" [data]="tableData" [deltaHeight]=200 [useTableSizer]=true></sm-table-on-push>
    </div>
</div>
