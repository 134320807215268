import { Component, OnInit, ViewChild } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { PayrollDocument } from '../../shared/payroll-document.model';

@Component({
    selector: 'app-enaio-payroll-main',
    styleUrls: ['./enaio-payroll-main.component.scss'],
    templateUrl: './enaio-payroll-main.component.html'
})
export class EnaioPayrollMainComponent implements OnInit {

    _Utils = Utils;

    monthOnly = true;
    sortedMonths: string[];
    byMonths: OrdinaryObject<PayrollDocument[]> = {};
    documents: PayrollDocument[];
    visibleDocuments: PayrollDocument[];
    filterText: string = null;
    filterFromDate: Date;
    filterToDate: Date;
    uiBlocked = false;

    displayModal: boolean;
    @ViewChild('viewer') viewer;

    constructor(private app: MainAppService) {
    }

    async ngOnInit(): Promise<void> {
        this.app.showSpinner = true;
        this.documents = Utils.arraySort(await RestEndpoint.main().find().run("api/pay/payroll").list(PayrollDocument));
        for (let doc of this.documents) {
            doc.date = new Date(doc.date);
        }

        this.filterChanged();

        this.app.updateNavigation("Dokumente für " + this.app.getUserName(), { url: "/enaio-payroll/home", icon: 'fas fa-home' }, [
            { label: "Dokumente für " + this.app.getUserName(), url: "/enaio-documents/payroll" }
        ]);
        this.app.showSpinner = false;
    }

    async download(document: PayrollDocument): Promise<void> {
        GuiUtils.saveFileAs(await RestEndpoint.main().query({id: document.id}).runBlob("api/pay/payroll/get"), document.getFileName("pdf"));
    }

    async downloadAll(): Promise<void> {
        this.uiBlocked = true;

        try {
            let processId = Utils.generateGuid();
            let dialog = this.app.messageDialog.waitingForm("Dokumente werden vorbereitet", "Bitte haben Sie einen Moment Geduld, während " + this.visibleDocuments.length +
                " Dokumente zum Download vorbereitet werden.<br>Je nach Anzahl der Dokumente kann dies etwas Zeit in Anspruch nehmen.", "api/app/backendprocess?id=" + processId, null);

            let result = await RestEndpoint.main().post().query({processId}).body(this.visibleDocuments.map(doc => doc.id)).runBlob("api/pay/payroll/getMulti");

            dialog.close();

            GuiUtils.saveFileAs(result, "Dokumente.zip");
        }
        finally {
            this.uiBlocked = false;
        }
    }

    async openDocument(document: PayrollDocument): Promise<void> {
        this.viewer.pdfSrc = await RestEndpoint.main().query({id: document.id, convert: true}).runBlob("api/pay/payroll/get");
        this.viewer.refresh();
        this.displayModal = true;
    }

    filterChanged(): void {
        this.visibleDocuments = this.documents.filter(doc =>
            (this.filterText == null || doc.title.toUpperCase().includes(this.filterText.toUpperCase())) &&
            (this.filterFromDate == null || this.filterFromDate <= doc.date) &&
            (this.filterToDate == null || this.filterToDate >= doc.date));

        this.byMonths = Utils.arrayToMultiMapKeys(this.visibleDocuments, doc => doc.date.getFullYear() + Utils.expandNumber(doc.date.getMonth(), 2));
        this.sortedMonths = Utils.arrayReverse(Utils.arraySort(Object.keys(this.byMonths)));
    }


}
