<div class="grid">
    <div class="col-4">
        <sm-form #form [form]="formIdSearch">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="searchId()"></p-button>
    </div>
    <div class="col-8">
        <p-message *ngIf="anythingFound === false" severity="warn" text="Es wurden keine Ergebnisse gefunden"></p-message>
        <p-panel  *ngIf="tableDataIdSearchUsers != null" header="Benutzer">
            <sm-table [data]="tableDataIdSearchUsers"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchOrganisations != null" header="Organisationen">
            <sm-table [data]="tableDataIdSearchOrganisations"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchOrgObjects != null" header="Organisationsobjekte">
            <sm-table [data]="tableDataIdSearchOrgObjects"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchWfFamilies != null" header="Workflowfamilien">
            <sm-table [data]="tableDataIdSearchWfFamilies"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchWfModels != null" header="Workflowmodelle">
            <sm-table [data]="tableDataIdSearchWfModels"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchProcesses != null" header="Workflows">
            <sm-table [data]="tableDataIdSearchProcesses"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchObjectTypes != null" header="Objekttypen">
            <sm-table [data]="tableDataIdSearchObjectTypes"></sm-table>
        </p-panel>
        <p-panel  *ngIf="tableDataIdSearchFields != null" header="Felder">
            <sm-table [data]="tableDataIdSearchFields"></sm-table>
        </p-panel>
    </div>
</div>
