import { ViewContainerRef } from "@angular/core";
import { Config } from "src/modules/app-template/models/config.model";
import { EnaioCall } from "src/modules/enaio/shared/EnaioCall";
import { HttpResult } from "src/modules/sm-base/models/http-connector.model";
import { RestEndpoint } from "src/modules/sm-base/models/rest-endpoint.model";
import { SmTimer } from "src/modules/sm-base/models/sm-timer.model";
import { FrontendFormDefinition } from "src/modules/sm-base/shared/frontend-form-definition.model";
import { GuiUtils } from "src/modules/utils/misc/gui-utils";
import { Progressor } from "src/modules/utils/shared/Progressor";
import { ProgressorResult } from "src/modules/utils/shared/Progressor-Result";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Wrapper } from "src/modules/utils/shared/wrapper.model";

export class DhTools {

    static async enaioCall<T>(options: EnaioCall<T>): Promise<T> {
        let result = await this.backendCall("api/enaio/call", {className: "CsUtils." + options.constructor.name, options}).getText();
        return options.getModelType().fromPlainUnsafe(Utils.fromJson(result)) as T;
    }

    static backendCall(url: string, body: OrdinaryObject = null): HttpResult {
        return this.rest().get().post().body(body).run(url);
    }

    static rest(): RestEndpoint {
        return new RestEndpoint(Config.get().backendUrl);
    }

    static async prettifyJson(json: string): Promise<string> {
        return this.backendCall("api/tools/prettifyJson", {json}).getText();
    }

    static async prettifyXml(xml: string): Promise<string> {
        return this.backendCall("api/tools/prettifyXml", {xml}).getText();
    }

    static async decodeUtf16(s: string): Promise<string> {
        return this.backendCall("api/tools/decodeUtf16", {s}).getText();
    }

    static async openDialog(fileName?: string, selectFolder?: boolean): Promise<string> {
        return this.backendCall("api/system/openSaveDialog", Utils.objectRemoveNullValues({ openDialog: true, fileName, selectFolder })).getString();
    }

    static async saveDialog(fileName?: string, selectFolder?: boolean): Promise<string> {
        return this.backendCall("api/system/openSaveDialog", Utils.objectRemoveNullValues({ fileName, selectFolder })).getString();
    }

    static showDateIntervalOptions(evt: MouseEvent, viewContainerRef: ViewContainerRef, form: FrontendFormDefinition, beginDateFieldName = "beginDate", endDateFieldName = "endDate"): void {
        let now = new Date();
        let model = [
            {
                label: "Heute",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateStartOf(now, "day"));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Gestern",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateAdd(Utils.dateStartOf(now, "day"), "day", -1));
                    form.setValue(endDateFieldName, Utils.dateAdd(Utils.dateEndOf(now, "day"), "day", -1));
                }
            },
            {
                label: "Seit Gestern",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateAdd(Utils.dateStartOf(now, "day"), "day", -1));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Diese Woche",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateStartOf(now, "week"));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Eine Woche",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateAdd(Utils.dateStartOf(now, "day"), "week", -1));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Dieser Monat",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateStartOf(now, "month"));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Ein Monat",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateAdd(Utils.dateStartOf(now, "day"), "month", -1));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Dieses Jahr",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateStartOf(now, "year"));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            },
            {
                label: "Ein Jahr",
                command: () => {
                    form.setValue(beginDateFieldName, Utils.dateAdd(Utils.dateStartOf(now, "day"), "year", -1));
                    form.setValue(endDateFieldName, Utils.dateEndOf(now, "day"));
                }
            }
        ];
        GuiUtils.showContextMenu(evt, viewContainerRef, model);
    }

    static startProgressor(progressor: Wrapper<Progressor>, guid: string, onFinished?: (result: ProgressorResult) => void, onError?: (value: string) => void): void {
        let timer = new SmTimer(0, 100, async () => {
            progressor.value = (await DhTools.backendCall("api/dh/getProgressors").list(Progressor)).find(p => p.guid == guid);

            if (progressor.value == null) {
                timer.destroy();
                let result = await DhTools.backendCall("api/dh/getProgressorResult", { guid }).get(ProgressorResult);
                if (result.error && onError != null) {
                    onError(result.value);
                }
                else {
                    onFinished?.(result);
                }
            }
        }, true);
    }

}
