<p-tabView>
    <p-tabPanel header="Klassen">
        <div class="grid">
            <p-tree #tree *ngIf="helpTree != null" class="col-3 full-height-180" selectionMode="single" [value]="helpTree.items" [(selection)]="helpTree.selected"
            [filter]=true [filterBy]="'label,data'" (onNodeSelect)="selectClass($event)"></p-tree>
            <div class="col-9">
                <div class="mb-2"><span *ngFor="let item of selClassHistory" class="mr-6"><a href="#" (click)="selectClass(null, item); $event.preventDefault()">{{item.getSimpleName()}}</a></span></div>
                <sm-table *ngIf="fieldsTable != null" [deltaHeight]=180 [data]="fieldsTable" [allowSelection]=false (onCellClicked)="fieldCellClicked($event)">
                </sm-table>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Template-Funktionen">
        <sm-table *ngIf="functionsTable != null" [deltaHeight]=180 [data]="functionsTable" [allowSelection]=false (onCellClicked)="fieldCellClicked($event)">
        </sm-table>
    </p-tabPanel>
</p-tabView>
