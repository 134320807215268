import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendFieldDefinition } from '../../shared/frontend-field-definition.model';
import { TableCellType } from '../../shared/table-cell-type.enum';

@Component({
    selector: 'sm-tree-table-cell',
    templateUrl: './sm-tree-table-cell.component.html',
    styleUrls: ['./sm-tree-table-cell.component.scss']
})
export class SmTreeTableCellComponent {

    _Utils = Utils;

    _TableCellType = TableCellType;
    @Input()
    type: TableCellType;
    @Input()
    value: any;
    @Input()
    editable = false;
    @Input()
    showNull = true;
    @Input()
    editor: FrontendFieldDefinition;
    @Output()
    clicked = new EventEmitter<any>();
    @Output()
    valueChange = new EventEmitter<any>();

    constructor(private app: MainAppService) {
    }

    buttonClick(): void {
        this.clicked.emit(null);
    }

    switchBool(): void {
        this.setValue(!this.value);
    }

    afterEdit(): void {
        this.valueChange.emit(this.value);
    }

    private setValue(value: any): void {
        this.value = value;
        this.valueChange.emit(value);
    }

    getListItemSpeakingName(): string {
        if (this.editor?.listItems == null) {
            return this.value;
        }
        let result = this.editor.listItems.find(li => li.value == this.value);
        return result != null ? result.label : this.value;
    }

}
