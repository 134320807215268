import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { TabView } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallDelete } from 'src/modules/enaio/shared/EnaioCallDelete';
import { SmTableCellComponent } from 'src/modules/sm-base/components/sm-table-cell/sm-table-cell.component';
import { ObjectAction } from 'src/modules/sm-base/shared/object-action.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { AppStarterComponent } from '../app-starter/app-starter.component';
import { BarcodeTesterComponent } from '../barcode-tester/barcode-tester.component';
import { ConnectionTesterComponent } from '../connection-tester/connection-tester.component';
import { DatabaseToolsComponent } from '../database-tools/database-tools.component';
import { DatevDataFetcherComponent } from '../datev-data-fetcher/datev-data-fetcher.component';
import { DatevInvoiceSearchComponent } from '../datev-invoice-search/datev-invoice-search.component';
import { DatevInvoiceSummaryComponent } from '../datev-invoice-summary/datev-invoice-summary.component';
import { DocumentHandlerEvalConsoleComponent } from '../document-handler-eval-console/document-handler-eval-console.component';
import { DocumentHandlerHelpComponent } from '../document-handler-help/document-handler-help.component';
import { DocumentHandlerHqComponent } from '../document-handler-hq/document-handler-hq.component';
import { DocumentHandlerRechercheComponent } from '../document-handler-recherche/document-handler-recherche.component';
import { DocumentHandlerRunsComponent } from '../document-handler-runs/document-handler-runs.component';
import { DocumentHandlerToolsComponent } from '../document-handler-tools/document-handler-tools.component';
import { DocumentHandlerUpdaterComponent } from '../document-handler-updater/document-handler-updater.component';
import { EnaioIdSearchComponent } from '../enaio-id-search/enaio-id-search.component';
import { EnaioLicensesComponent } from '../enaio-licenses/enaio-licenses.component';
import { EnaioObjectDefComponent } from '../enaio-object-def/enaio-object-def.component';
import { EnaioObjectsComponent } from '../enaio-objects/enaio-objects.component';
import { EnaioSearchAndReplaceComponent } from '../enaio-search-and-replace/enaio-search-and-replace.component';
import { EnaioServerInfoComponent } from '../enaio-server-info/enaio-server-info.component';
import { EnaioUserComponent } from '../enaio-user/enaio-user.component';
import { EnaioWarningsComponent } from '../enaio-warnings/enaio-warnings.component';
import { EnaioWatcherComponent } from '../enaio-watcher/enaio-watcher.component';
import { EnaioWebClientDebuggerComponent } from '../enaio-web-client-debugger/enaio-web-client-debugger.component';
import { EnaioWhatHappenedComponent } from '../enaio-what-happened/enaio-what-happened.component';
import { EnaioWorkflowModelsComponent } from '../enaio-workflow-models/enaio-workflow-models.component';
import { EnaioWorkflowComponent } from '../enaio-workflow/enaio-workflow.component';
import { EnaioWorkflowsComponent } from '../enaio-workflows/enaio-workflows.component';
import { MetricsComponent } from '../metrics/metrics.component';
import { MiscToolsComponent } from '../misc-tools/misc-tools.component';
import { MsGraphToolsComponent } from '../ms-graph-tools/ms-graph-tools.component';
import { PasswordRetrieverComponent } from '../password-retriever/password-retriever.component';
import { ServerApiCallerComponent } from '../server-api-caller/server-api-caller.component';
import { SimLogComponent } from '../sim-log/sim-log.component';
import { SmCodeGeneratorComponent } from '../sm-code-generator/sm-code-generator.component';
import { SystemInformationComponent } from '../system-information/system-information.component';
import { TextToolsComponent } from '../text-tools/text-tools.component';

@Component({
    selector: 'app-document-handler-main',
    templateUrl: './document-handler-main.component.html',
    styleUrls: ['./document-handler-main.component.scss']
})
export class DocumentHandlerMainComponent extends ComponentView {

    static instance: DocumentHandlerMainComponent;

    contextMenu: MenuItem[] = [];

    tabIndex = 0;
    subTabIndex: number[] = [0, 0, 0, 0];
    displayModalSelectionAction = false;
    versionString = "";

    stateSubscription: Subscription;

    @ViewChild("cm") cm: ContextMenu;
    @ViewChild("mainTabView") mainTabView: TabView;
    @ViewChild("viewDocumentHandlerHq") viewDocumentHandlerHq: DocumentHandlerHqComponent;
    @ViewChild("viewSimLog") viewSimLog: SimLogComponent;
    @ViewChild("viewMetrics") viewMetrics: MetricsComponent;
    @ViewChild("viewDocumentHandlerRuns") viewDocumentHandlerRuns: DocumentHandlerRunsComponent;
    @ViewChild("viewDocumentHandlerRecherche") viewDocumentHandlerRecherche: DocumentHandlerRechercheComponent;
    @ViewChild("viewDocumentHandlerHelp") viewDocumentHandlerHelp: DocumentHandlerHelpComponent;
    @ViewChild("viewDocumentHandlerEvalConsole") viewDocumentHandlerEvalConsole: DocumentHandlerEvalConsoleComponent;
    @ViewChild("viewDocumentHandlerTools") viewDocumentHandlerTools: DocumentHandlerToolsComponent;
    @ViewChild("viewDocumentHandlerUpdater") viewDocumentHandlerUpdater: DocumentHandlerUpdaterComponent;
    @ViewChild("viewEnaioObjectDef") viewEnaioObjectDef: EnaioObjectDefComponent;
    @ViewChild("viewEnaioWorkflows") viewEnaioWorkflows: EnaioWorkflowsComponent;
    @ViewChild("viewEnaioWorkflow") viewEnaioWorkflow: EnaioWorkflowComponent;
    @ViewChild("viewEnaioWorkflowModels") viewEnaioWorkflowModels: EnaioWorkflowModelsComponent;
    @ViewChild("viewEnaioObjects") viewEnaioObjects: EnaioObjectsComponent;
    @ViewChild("viewEnaioUsers") viewEnaioUsers: EnaioUserComponent;
    @ViewChild("viewEnaioSearchAndReplace") viewEnaioSearchAndReplace: EnaioSearchAndReplaceComponent;
    @ViewChild("viewEnaioServerApiCaller") viewEnaioServerApiCaller: ServerApiCallerComponent;
    @ViewChild("viewEnaioWarnings") viewEnaioWarnings: EnaioWarningsComponent;
    @ViewChild("viewEnaioServerInfo") viewEnaioServerInfo: EnaioServerInfoComponent;
    @ViewChild("viewEnaioIdSearch") viewEnaioIdSearch: EnaioIdSearchComponent;
    @ViewChild("viewEnaioLicenses") viewEnaioLicenses: EnaioLicensesComponent;
    @ViewChild("viewEnaioWatcher") viewEnaioWatcher: EnaioWatcherComponent;
    @ViewChild("viewEnaioWhatHappened") viewEnaioWhatHappened: EnaioWhatHappenedComponent;
    @ViewChild("viewEnaioWebClientDebugger") viewEnaioWebClientDebugger: EnaioWebClientDebuggerComponent;
    @ViewChild("viewDatevInvoiceSearch") viewDatevInvoiceSearch: DatevInvoiceSearchComponent;
    @ViewChild("viewDatevInvoiceSummary") viewDatevInvoiceSummary: DatevInvoiceSummaryComponent;
    @ViewChild("viewDatevDataFetcher") viewDatevDataFetcher: DatevDataFetcherComponent;
    @ViewChild("viewAppStarter") viewAppStarter: AppStarterComponent;
    @ViewChild("viewSystemInformation") viewSystemInformation: SystemInformationComponent;
    @ViewChild("viewConnectionTester") viewConnectionTester: ConnectionTesterComponent;
    @ViewChild("viewSmCodeGenerator") viewSmCodeGenerator: SmCodeGeneratorComponent;
    @ViewChild("viewPasswordRetriever") viewPasswordRetriever: PasswordRetrieverComponent;
    @ViewChild("viewDatabaseTools") viewDatabaseTools: DatabaseToolsComponent;
    @ViewChild("viewMsGraphTools") viewMsGraphTools: MsGraphToolsComponent;
    @ViewChild("viewBarcodeTester") viewBarcodeTester: BarcodeTesterComponent;
    @ViewChild("viewTextTools") viewTextTools: TextToolsComponent;
    @ViewChild("viewMiscTools") viewMiscTools: MiscToolsComponent;

    tabs: string[][] = [
        [
            "documentHandlerHq",
            "simLog",
            "metrics",
            "documentHandlerRuns",
            "documentHandlerRecherche",
            "documentHandlerHelp",
            "documentHandlerEvalConsole",
            "documentHandlerTools",
            "documentHandlerUpdater"
        ],
        [
            "enaioObjectDef",
            "enaioWorkflows",
            "enaioWorkflow",
            "enaioWorkflowModels",
            "enaioObjects",
            "enaioUsers",
            "enaioSearchAndReplace",
            "enaioServerApiCaller",
            "enaioWarnings",
            "enaioServerInfo",
            "enaioIdSearch",
            "enaioWhatHappened",
            "enaioLicenses",
            "enaioWatcher",
            "enaioWebClientDebugger"
        ],
        [
            "datevInvoiceSearch",
            "datevInvoiceSummary",
            "datevDataFetcher"
        ],
        [
            "appStarter",
            "systemInformation",
            "connectionTester",
            "smCodeGenerator",
            "passwordRetriever",
            "databaseTools",
            "msGraphTools",
            "barcodeTester",
            "textTools",
            "miscTools"
        ]
    ];

    constructor() {
        super();
        DocumentHandlerMainComponent.instance = this;
        SmTableCellComponent.globalActions = [...SmTableCellComponent.globalActions, ...this.getContextMenuActions()];
    }

    async initParams(): Promise<boolean> {
        this.versionString = await DhTools.backendCall("api/dh/getVersionString").getText();
        GuiUtils.angularTimer(async () => this.activeTabChanged());
        return Promise.resolve(true);
    }

    getContextMenuActions(): ObjectAction[] {
        return [
            {
                getCaption: s => "enaio Objekt mit der ID " + s + " öffnen",
                isUsable: s => Utils.isIntOrIntString(s),
                perform: async s => DhTools.backendCall("api/enaio/openDocument", { id: Utils.toNumber(s) }).getText()
            },
            {
                getCaption: s => Utils.splitLines(Utils.toString(s)).length + " enaio Objekte löschen",
                isUsable: s => Utils.arrayEveryAndSome(Utils.splitLines(Utils.toString(s)), line => Utils.isIntString(line)),
                perform: async s => {
                    if (await this.app.messageDialog.yesNo("Sollen diese enaio Objekte wirklich gelöscht werden?", "Warnung") && await this.app.messageDialog.yesNo("WIRKLICH???", "WARNUNG")) {
                        for (let id of Utils.splitLines(Utils.toString(s))) {
                            await DhTools.enaioCall(Utils.fromPlain(EnaioCallDelete, { objectId: Utils.toNumber(id.trim()) }));
                        }
                    }
                    return null;
                }
            },
            {
                getCaption: s => "enaio Trefferliste mit " + (Utils.arrayEveryAndSome(Utils.splitLines(Utils.toString(s)), line => Utils.isIntString(line)) ? Utils.splitLines(Utils.toString(s)).length : Utils.split(Utils.toString(s), ",").length) + " Objekten öffnen",
                isUsable: s => Utils.arrayEveryAndSome(Utils.splitLines(Utils.toString(s)), line => Utils.isIntString(line)) || Utils.arrayEveryAndSome(Utils.split(Utils.toString(s), ","), line => Utils.isIntString(line.trim())),
                perform: async s => DhTools.backendCall("api/enaio/openResultList", { ids:
                    (Utils.arrayEveryAndSome(Utils.splitLines(Utils.toString(s)), line => Utils.isIntString(line)) ? Utils.splitLines(Utils.toString(s)) : Utils.split(Utils.toString(s), ",")).join(";") }).getText()
            },
            {
                getCaption: _ => "Datei öffnen",
                isUsable: s => Utils.regexMatches(Utils.toString(s), "([A-Z]:(\\\\\\\\|\\\\|/)|\\\\{4}|\\\\{2}|/).*"),
                perform: async s => DhTools.backendCall("api/system/openFile", { fileName: Utils.replaceAll(Utils.toString(s), "\\\\\\\\", "\\") }).getText()
            },
            {
                getCaption: _ => "Datei in Notepad++ öffnen",
                isUsable: s => Utils.regexMatches(Utils.toString(s), "([A-Z]:(\\\\\\\\|\\\\|/)|\\\\{4}|\\\\{2}|/).*"),
                perform: async s => DhTools.backendCall("api/system/openFileInNotepadPlusPlus", { fileName: Utils.replaceAll(Utils.toString(s), "\\\\\\\\", "\\") }).getText()
            },
            {
                getCaption: _ => "Verzeichnis in Explorer öffnen",
                isUsable: s => Utils.regexMatches(Utils.toString(s), "([A-Z]:(\\\\\\\\|\\\\|/)|\\\\{4}|\\\\{2}|/).*(\\\\\\\\|\\\\|/).*"),
                perform: async s => DhTools.backendCall("api/system/openDir", { fileName: Utils.replaceAll(Utils.toString(s), "\\\\\\\\", "\\") }).getText()
            },
            {
                getCaption: _ => "JSON formatieren",
                isUsable: s => {
                    s = s.trim();
                    return s.startsWith("{") && s.endsWith("}") || s.startsWith("[") && s.endsWith("]");
                },
                perform: async s => DhTools.prettifyJson(Utils.toString(s))
            },
            {
                getCaption: _ => "XML formatieren",
                isUsable: s => {
                    s = s.trim();
                    return s.startsWith("<") && s.endsWith(">");
                },
                perform: async s => DhTools.prettifyXml(Utils.toString(s))
            }
        ];
    }

    async openContextMenu(text: string): Promise<void> {
        this.contextMenu = await this.generateContextMenu(text, true);
        this.cm.show(this.app.getCurrentMousePosEvent());
    }

    async generateContextMenu(text: string, includeClipboard?: boolean): Promise<MenuItem[]> {
        let result: MenuItem[] = this.getContextMenuActions().filter(a => a.isUsable(text)).map(a => ({
            label: a.getCaption(text),
            icon: "fas fa-open",
            command: async _ => a.perform(text)
        }));

        if (includeClipboard) {
            if (GuiUtils.isClipboardSupported()) {
                let clip = await GuiUtils.fromClipboard();
                if (clip != text) {
                    let clipboardSubMenu = await this.generateContextMenu(clip, false);
                    if (clipboardSubMenu.length > 0) {
                        result = [...result, {
                            label: "CLIPBOARD",
                            icon: "fas fa-clipboard",
                            items: clipboardSubMenu
                        }];
                    }
               }
            }
            else {
                result = [...result, {
                    label: "CLIPBOARD NOT SUPPORTED (FIREFOX?)",
                    icon: "fas fa-clipboard"
                }];
            }
        }

        return result;
    }

    async openDir(dir: string): Promise<void> {
        await DhTools.backendCall("api/system/openDir", {dir}).getText();
    }

    async openFile(fileName: string): Promise<void> {
        await DhTools.backendCall("api/system/openFile", {fileName}).getText();
    }

    async activateTab(name: string): Promise<void> {
        for (let i = 0; i < this.tabs.length; i++) {
            for (let j = 0; j < this.tabs[i].length; j++) {
                if (this.tabs[i][j] == name) {
                    this.tabIndex = i;
                    this.subTabIndex[i] = j;
                    await this.activeTabChanged();
                    return;
                }
            }
        }
        throw new Error("Ungültiger Tab-Name: " + name);
    }

    async activeTabChanged(): Promise<void> {
        let cv = this["view" + Utils.upperFirst(this.tabs[this.tabIndex][this.subTabIndex[this.tabIndex]])] as ComponentView;
        await cv.initEmbedded();
        if (cv instanceof DocumentHandlerHqComponent) {
            cv.tabIndexChange();
        }
    }

}
