import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentCategory } from '../../shared/learning-content-category.entity';
import { LearningContentParticipant } from '../../shared/learning-content-participant.entity';
import { LearningContent } from '../../shared/learning-content.entity';
import { LearningContentStatus } from '../../shared/learning-content.status.entity';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';

@Component({
    selector: 'app-learning-home',
    templateUrl: './learning-home.component.html',
    styleUrls: ['./learning-home.component.scss']
})
export class LearningHomeComponent implements OnInit {

    _Utils = Utils;
    _LearningContentStatus = LearningContentStatus;

    profile: LearningUserFlat;
    mayInspect: number[] = [];
    isAuthor: boolean = null;
    listForStudent: LearningContentParticipant[];
    listForStudentGui: LearningContentParticipant[];
    listForAuthor: LearningContent[];
    listForAuthorGui: LearningContent[];
    listTypeStudent = 0;
    listTypeAuthor = 0;
    categories: LearningContentCategory[];
    categoriesGui: SelectItem[];
    selectedCat = 0;
    fullTextSearchTerm = "";
    isTableView = false;
    tableAuthor: TableData;
    tableStudent = new TableData([], []);

    constructor(private app: MainAppService, public service: EnaioLearnService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.isTableView = Utils.toBool(localStorage.getItem("isTableView"), false);
        this.profile = await this.service.getMyProfile();
        this.mayInspect = await Utils.fromJson(await RestEndpoint.main().run("api/lrn/inspectionphase/mayinspectlist").getText()) as number[];
        this.isAuthor = params.isAuthor != null ? Utils.toBool(params.isAuthor) : null;
        this.categories = Utils.arraySort(await this.app.loadDataHandler(async () => RestEndpoint.main().find().run("api/lrn/learningcontentcategory").list(LearningContentCategory), this));
        this.listForStudent = Utils.arraySort(await this.app.loadDataHandler(async () => RestEndpoint.main().find({all: true}).run("api/lrn/learningcontent/personal").list(LearningContentParticipant), this)).filter(lc => lc.learningContent.inspectionPhase == null);
        this.listForAuthor = Utils.arraySort(await this.app.loadDataHandler(async () => RestEndpoint.main().find().run("api/lrn/learningcontent").list(LearningContent), this)).filter(c => this.profile.mayModifyContent(c, this.mayInspect));

        this.categoriesGui = [{label: this.app.t("lea.home.allCategories"), value: 0}, ...this.categories.map(cat => ({ label: cat.name, value: cat.id}))];

        this.tableAuthor = new TableData([
            new TableColumn("title", this.app.t("lea.learningContent.title")),
            new TableColumn("author", this.app.t("lea.learningContent.author")),
            new TableColumn("category", this.app.t("lea.learningContent.category")),
            new TableColumn("creationDate", this.app.t("lea.learningContent.creationDate"), TableCellType.date),
            new TableColumn("canCharge", this.app.t("lea.learningContent.canCharge"), TableCellType.yesNo)
        ], [], [new TableSortColumn("title")]);

        this.setListTypeStudent(this.listTypeStudent);
        this.setListTypeAuthor(this.listTypeAuthor);

        this.service.updateNavigation(this.isAuthor, []);
    }

    async participate(item: LearningContentParticipant): Promise<void> {
        if (!item.isStarted()) {
            if (!await this.app.messageDialog.yesNo(this.app.t("lea.attend.messages.askStart", {name: item.learningContent.title}), this.app.t("general.confirmation"))) {
                return;
            }
            await RestEndpoint.main().query({ id: item.learningContent.id}).post().run("api/lrn/learningcontentparticipant/start").getText();
        }
        await this.app.navigateTo(["/enaio-learn", "attend", Utils.toString(item.learningContent.id)]);
    }

    setListTypeStudent(value: number): void {
        this.listTypeStudent = value;
        this.listForStudentGui = this.listForStudent.filter(o => (this.selectedCat == 0 || this.selectedCat == o.learningContent.category?.id) && o.learningContent.matchesFullTextQuery(this.fullTextSearchTerm) && (o.isFinished() && this.listTypeStudent == 3 || this.listTypeStudent == 2 || !o.isFinished() && (this.listTypeStudent == 0 && (o.mandatory || o.isStarted()) || this.listTypeStudent == 1 && !(o.mandatory || o.isStarted()))));

        this.tableStudent = new TableData(
            Utils.arrayWithoutNull([
                new TableColumn("title", "Titel"),
                new TableColumn("author", "Autor"),
             //   new TableColumn("contentType", "Typ"),
                new TableColumn("mandatory", "Pflichtinhalt", TableCellType.yesNo),
                new TableColumn("deadlineDate", "Deadline", TableCellType.date),
                this.listTypeStudent != 1 ? new TableColumn("startDate", "begonnen am", TableCellType.date) : null,
                this.listTypeStudent == 3 ? new TableColumn("finishedDate", "abgeschlossen am", TableCellType.date) : null,
                new TableColumn("canCharge", "Anrechenbar", TableCellType.yesNo)
            ]),
            Utils.arrayWithoutNull(this.listForStudentGui.map(o => new TableRow(o, {
                title: o.learningContent.title,
                author: o.learningContent.author,
             //   contentType: o.learningContent.contentType?.name ?? "",
                mandatory: o.mandatory,
                deadlineDate: o.deadlineDate,
                startDate: this.listTypeStudent != 1 ? o.startDate : null,
                finishedDate: this.listTypeStudent == 3 ? o.finishedDate : null,
                canCharge: o.learningContent.canCharge
            }))), [new TableSortColumn("title")]);
    }

    setListTypeAuthor(value: number): void {
        this.listTypeAuthor = value;
        this.listForAuthorGui = this.listForAuthor.filter(c => (this.selectedCat == 0 || this.selectedCat == c.category?.id) && c.matchesFullTextQuery(this.fullTextSearchTerm) && (this.listTypeAuthor == 2 || this.listTypeAuthor == 0 && c.author == this.app.getUserName() || this.listTypeAuthor == 1 && this.mayInspect.includes(c.id)));

        this.tableAuthor.rows = this.listForAuthorGui.map(o => new TableRow(o, {
            title: o.title,
            author: o.author,
            category: o.category?.name ?? "",
            creationDate: o.creationDate,
            canCharge: o.canCharge
        }));
    }

    updateFilter(): void {
        if (this.isAuthor) {
            this.setListTypeAuthor(this.listTypeAuthor);
        }
        else {
            this.setListTypeStudent(this.listTypeStudent);
        }
    }

    async attend(row: TableRow): Promise<void> {
        let item = row.raw as LearningContentParticipant;
        if (!item.isStarted()) {
            if (!await this.app.messageDialog.yesNo("Möchten Sie mit dem Lerninhalt " + item.learningContent.title + " beginnen?", "Bestätigung")) {
                return;
            }
            await RestEndpoint.main().query({ id: item.learningContent.id}).post().run("api/lrn/learningcontentparticipant/start").getText();
        }
        await this.app.navigateTo(["/enaio-learn", "attend", Utils.toString(item.learningContent.id)]);
    }

    tableViewChanged(): void {
        localStorage.setItem("isTableView", Utils.toString(this.isTableView));
    }
}
