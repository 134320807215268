import { Component, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallGetLicense } from 'src/modules/enaio/shared/EnaioCallGetLicense';
import { EnaioCallGetLockedResources } from 'src/modules/enaio/shared/EnaioCallGetLockedResources';
import { EnaioLicense } from 'src/modules/enaio/shared/EnaioLicense';
import { EnaioLockedResource } from 'src/modules/enaio/shared/EnaioLockedResource';
import { SmTableOnPushComponent } from 'src/modules/sm-base/components/sm-table-on-push/sm-table-on-push.component';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/shared/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { EnaioResource } from 'src/modules/enaio/shared/EnaioResource';
import { EnaioCallGetResources } from 'src/modules/enaio/shared/EnaioCallGetResources';

@Component({
  selector: 'app-enaio-licenses',
  templateUrl: './enaio-licenses.component.html',
  styleUrls: ['./enaio-licenses.component.scss']
})
export class EnaioLicensesComponent extends ComponentView {

    _DhTools = DhTools;

    lic: EnaioLicense;
    important = ["ADM", "ASC", "ASE", "WFA", "WFE", "WFG"];
    tableData: TableData;

    @ViewChild("table") table: SmTableOnPushComponent;

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }

    async getLicenses(): Promise<void> {
        this.lic = await DhTools.enaioCall<EnaioLicense>(new EnaioCallGetLicense());
        let data = await DhTools.enaioCall<EnaioLockedResource[]>(new EnaioCallGetLockedResources());
        let res = await DhTools.enaioCall<EnaioResource[]>(new EnaioCallGetResources());

        this.tableData = new TableData([
            new TableColumn("module", "Modul"),
            new TableColumn("name", "Name"),
            new TableColumn("available", "Verfügbar", TableCellType.number),
            new TableColumn("used", "Verwendet", TableCellType.number),
            new TableColumn("userNames", "Benutzer")
        ], Utils.arraySort(this.lic.modules, (m1, m2) => Utils.cmpMulti([!this.important.includes(m1.name), !this.important.includes(m2.name), m1.name, m2.name])).map(item => new TableRow(item, {
            module: item.name,
            name: res.find(item2 => item2.resourceName == item.name)?.description,
            available: item.count,
            used: new TableCell(data.filter(item2 => item2.resourceName == item.name).length, { styleClass: data.filter(item2 => item2.resourceName == item.name).length >= item.count ? "xbg-red-400" : null}),
            userNames: Utils.arrayItemsToString(Utils.arraySort(data.filter(item2 => item2.resourceName == item.name).map(item2 => item2.userName + " (" + item2.stationName + ")")))
        })));
    }

}
