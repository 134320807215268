import { Component, inject } from '@angular/core';
import { saveAs } from 'file-saver';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioAssignment } from 'src/modules/enaio/shared/EnaioAssignment';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioDocumentFrontendResult } from '../../shared/enaio-document-frontend-result.model';
import { EnaioFrontendInsertData } from '../../shared/enaio-frontend-insert-data';

@Component({
    selector: 'app-enaio-documents-definition',
    templateUrl: './enaio-documents-definition.component.html',
    styleUrls: ['./enaio-documents-definition.component.scss']
})
export class EnaioDocumentsDefinitionComponent extends ComponentView {

    fileUpload = inject(FileUploadService);

    def: EnaioDocumentFrontendResult = null;
    columns: any[] = [];
    parentId = 0;
    anonymousGuid: string;
    defId = "";
    selectedDocument: EnaioDocument = null;
    selectedDocuments: EnaioDocument[] = [];

    uploadedFileContents: ArrayBuffer;
    displayModal: boolean;
    modalForm: FrontendFormDefinition = null;
    allRows: TableRow[];
    table: TableData;
    fullTextSearchTerm = "";
    selection: any;

    input = "";

    constructor() {
        super();
        this.neededParams = { defId: "string", parentId: "number?", anonymousGuid: "string?" };
    }

    async initParams(): Promise<boolean> {
        this.parentId ||= 0;
        await this.reload();
        return true;
    }

    async reload(): Promise<void> {
        this.def = !this.isAnonymous() ? await RestEndpoint.main().query({ defId: this.defId, parentId: this.parentId }).run("api/doc/def/search").get(EnaioDocumentFrontendResult) :
            await RestEndpoint.main().query({ anonymousGuid: this.anonymousGuid }).run("api/doc/def/anonymousresults").get(EnaioDocumentFrontendResult);
        this.defId = this.def.defId; //Überschreiben, weil bei AnonymousSearch die defID vorher nicht zwingend feststeht
        this.columns = this.def.columns.map(col => ({ field: (data: EnaioDocument): string => data.getByInternal(col.internalName), header: col.name}));

        this.table = new TableData(this.def.columns.map(col => new TableColumn((col.onParent ? "parent." : "") + col.internalName, col.name, TableCellType.text)),
            this.def.documents.map(doc => new TableRow(doc, {_objectType: doc.objectType.name, ...Utils.arrayConvertToOrdinaryObject(this.def.columns, col => (col.onParent ? "parent." : "") + col.internalName, col => {
                let result = (col.onParent ? doc.parent : doc).getByInternal(col.internalName).replace("T00:00:00", "");
                return result == "0001-01-01" ? "" : result;
            })})));

        if (this.def.childDefId !== "") {
            this.table.columns = Utils.arrayWithoutNull([
                new TableColumn("_open", "", TableCellType.button, { sortable: false, filterable: false }),
                this.def.displayObjectTypeColumn ? new TableColumn("_objectType", "Objekttyp") : null,
                ...this.table.columns
            ]);
            for (let row of this.table.rows) {
                row.values._open = "fas fa-search";
            }
        }

        if (this.def.hasDocuments) {
            this.table.columns = Utils.arrayWithoutNull([
                new TableColumn("_download", "", TableCellType.button, { width: "40px", sortable: false, filterable: false }),
                this.def.displayObjectTypeColumn ? new TableColumn("_objectType", "Objekttyp") : null,
                ...this.table.columns
            ]);
            for (let row of this.table.rows) {
                row.values._download = "fas fa-file-download";
            }
        }
        this.allRows = this.table.rows;

        if (this.allRows.length > 1) {
            this.selection = this.allRows[0];
            this.rowSelect(this.selection);
        }

        this.app.updateNavigation("Dokumente - " + this.def.name, { routerLink: ["/enaio-documents", "home"], icon: 'fas fa-home' }, this.isAnonymous() ? [] : [
            { label: "Dokumente", routerLink: ["/enaio-documents", "home"] },
            //   this.result.parentName !== "" ? { label: this.result.parentName, routerLink: ["/enaio-documents/definition", this.result.defId, 0] } : null,
            { label: this.def.name, routerLink: ["/enaio-documents/definition", this.def.defId, this.def.parentId]}
        ]);
        //}
    }

    isAnonymous(): boolean {
        return !Utils.isNoe(this.anonymousGuid);
    }

    async searchDocuments(): Promise<void> {
        let searchResult = await RestEndpoint.main().query({ defId: this.defId, parentId: this.parentId, quickSearch: this.input }).run("api/doc/def/search").get(EnaioDocumentFrontendResult);

        if (searchResult.documents.length == 0) {
            this.app.showToast("info", "Nicht gefunden", "Es wurde kein " + searchResult.nameSingular + " gefunden");
            return;
        }
        if (searchResult.documents.length > 1) {
            this.app.showToast("info", "Mehrere gefunden", "Es wurden mehrere " + searchResult.name + " zu dieser Abfrage gefunden. Es wird ein eindeutiges Ergebnis benötigt");
            return;
        }

        await this.app.navigateTo(["/enaio-documents", "definition", this.def.childDefId, searchResult.documents[0].id]);
    }

    async newDocument(_): Promise<void> {
        this.uploadedFileContents = (await this.fileUpload.chooseFile())[0].contents;

        this.displayModal = true;
        this.modalForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("title", "Titel", FrontendFieldType.text),
            new FrontendFieldDefinition("document_number", "DokumentNr", FrontendFieldType.text),
            new FrontendFieldDefinition("doc_type", "Dokumenttyp", FrontendFieldType.comboBox)
        ]);

        this.modalForm.getField("doc_type").listItems = [
            new FrontendFieldListItem("Allgemeine Beschreibung", "Allgemeine Beschreibung"),
            new FrontendFieldListItem("Verfahrensanweisung", "Verfahrensanweisung"),
            new FrontendFieldListItem("Arbeitsanweisung", "Arbeitsanweisung"),
            new FrontendFieldListItem("Formular", "Formular"),
            new FrontendFieldListItem("Checkliste", "Checkliste"),
            new FrontendFieldListItem("Ausgabe an Kunden", "Ausgabe an Kunden"),
            new FrontendFieldListItem("Weitere Unterlagen", "Weitere Unterlagen"),
            new FrontendFieldListItem("Anforderungen", "Anforderungen"),
            new FrontendFieldListItem("Betriebsanweisung", "Betriebsanweisung"),
            new FrontendFieldListItem("Sicherheitsdatenblatt", "Sicherheitsdatenblatt"),
            new FrontendFieldListItem("Aushang", "Aushang"),
            new FrontendFieldListItem("Organigramm", "Organigramm"),
            new FrontendFieldListItem("Stellenbeschreibung", "Stellenbeschreibung"),
            new FrontendFieldListItem("gemeinsame Verantwortung", "gemeinsame Verantwortung"),
            new FrontendFieldListItem("ADV", "ADV"),
            new FrontendFieldListItem("TOM", "TOM"),
            new FrontendFieldListItem("DSFA", "DSFA")
        ];
    }

    async insertDocument(): Promise<void> {
        let insert = new EnaioFrontendInsertData();
        insert.defId = this.def.defId;
        insert.parentId = this.parentId;
        insert.fields = [];
        for (let field of this.modalForm.fields) {
            insert.fields.push(new EnaioAssignment(EnaioObjectId.byInternalName(field.id), field.value));
        }
        insert.documentBase64 = Utils.bytesToBase64(this.uploadedFileContents);
        insert.documentFileName = "document.pdf";
        await RestEndpoint.main().post().body(insert).run("api/doc/def/document").getText();
        this.displayModal = false;
        await this.reload();
    }

    rowSelect(event): void {
        this.selectedDocument = event.raw;
    }

    async cellClicked(event): Promise<void> {
        if (event[1] == "_open") {
            await this.open(event[0].raw.id as number);
        }
        else if (event[1] == "_download") {
            await this.downloadDocument(event[0].raw.id as number);
        }
    }

    async open(id: number): Promise<void> {
        await this.app.navigateTo(["/enaio-documents", "definition", this.def.childDefId, Utils.toString(id)]);
    }

    async downloadDocument(id: number): Promise<void> {
        let doc = this.def.documents.find(d => d.id == id);
        if (this.isAnonymous()) {
            saveAs(await RestEndpoint.main().query({anonymousGuid: this.anonymousGuid, id: doc.id, convert: 0}).runBlob("api/doc/def/anonymousdocument"), doc.metaData[0].value + ".pdf");
        }
        else {
            saveAs(await RestEndpoint.main().query({id, convert: 0}).runBlob("api/doc/document/document"), doc.metaData[0].value + ".pdf");
        }
    }

    async downloadAll(): Promise<void> {
        if (this.isAnonymous()) {
            saveAs(await RestEndpoint.main().post().query({anonymousGuid: this.anonymousGuid}).body(this.table.rows.map(row => (row.raw as EnaioDocument).id)).runBlob("api/doc/def/anonymousgetMulti"), "Dokumente.zip");
        }
        else {
            saveAs(await RestEndpoint.main().post().body(this.def.documents.map(doc => doc.id)).runBlob("api/doc/document/getMulti"), "Dokumente.zip");
        }
    }

    updateFilter(): void {
        this.table.rows = this.allRows.filter(row => Utils.objectGetValues(row.values).find(value => Utils.matchesFullTextQuery(value as string, this.fullTextSearchTerm)));
    }

}
