import { FrontendFieldDefinition } from "src/modules/sm-base/shared/frontend-field-definition.model";
import { FrontendFieldListItem } from "src/modules/sm-base/shared/frontend-field-list-item.model";
import { FrontendFieldType } from "src/modules/sm-base/shared/frontend-field-type.enum";
import { TableCellType } from "src/modules/sm-base/shared/table-cell-type.enum";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { ConfigClassDefinition } from "./config-class-definition.model";
import { ConfigDefinition } from "./config-definition.model";
import { ConfigFieldDefinition } from "./config-field-definition.model";
import { ConfigFieldType } from "./config-field-type.enum";
import { FieldFlags } from "./field-flags.enum";

export class ConfigProperty {

    private static internalIdCounter = 0;

    static getActualTypeName(typeName: string, typeNameFromDef: string): string {
        if (typeNameFromDef == "DocumentHandler.BaseImportSource" && !typeName.includes("ImportSource")) {
            return "DocumentHandler.ImportSource" + Utils.stringRemovePrefix(typeName, "DocumentHandler.");
        }
        else {
            return typeName;
        }
    }

    internalId: number;
    value: any;
    typeName: string;
    isSet: boolean;
    useTemplateString: boolean;
    def: ConfigFieldDefinition;
    properties: ConfigProperty[];
    indexOnArray = -1;
    plainParent: OrdinaryObject;
    errorMessage: string;
    fieldDefinition: FrontendFieldDefinition;

    constructor(value: any, typeName: string, isSet: boolean, def: ConfigFieldDefinition, properties: ConfigProperty[], plainParent: OrdinaryObject, indexOnArray = -1) {
        this.internalId = ConfigProperty.internalIdCounter++;
        this.value = value;
        this.isSet = isSet;
        this.def = def;
        this.typeName = ConfigProperty.getActualTypeName(typeName, this.def?.typeName);
        this.properties = properties;
        this.plainParent = plainParent;
        this.indexOnArray = indexOnArray;
    }

    getPropertyName(useSpeaking: boolean): string {
        return this.indexOnArray == -1 ? this.def.getPropertyName(useSpeaking) : "Eintrag [" + this.indexOnArray + "]";
    }

    isDefault(): boolean {
        return this.value == this.def.defaultValue;
    }

    isTemplateString(): boolean {
        return Utils.isString(this.value) && (this.value as string).includes("{{") && (this.value as string).includes("}}");
    }

    resetToDefault(): void {
        this.setValue(this.def.defaultValue);
    }

    setValue(value: any, classDef: ConfigClassDefinition = null, globalDef: ConfigDefinition = null): void {
        this.value = value;
        if (classDef != null) {
            this.typeName = classDef.name;
            this.properties = this.def.getProperties(classDef, value, globalDef);
        }
        this.checkForErrors();
        this.transferToPlain();
    }

    getTableCellType(): TableCellType {
        return this.useTemplateString ? TableCellType.text : this.def.getTableCellType();
    }

    transferToPlain(): void {
        this.plainParent[this.def.name] = this.value;
    }

    calculate(globalDef: ConfigDefinition): void {
        if (this.def.type == ConfigFieldType.enum) {
            let enumClass = globalDef.getByName(this.def.typeName);
            this.fieldDefinition = new FrontendFieldDefinition(this.def.name, this.def.name, FrontendFieldType.comboBox, { listItems: enumClass == null ? [] : enumClass.fields.map(enumValue => new FrontendFieldListItem(enumValue.defaultValue, Utils.toString(enumValue.name))) });
        }
    }

    checkForErrors(): void {
        this.errorMessage = null;
        if (this.isTemplateString()) {
            //
        }
        else if (this.def.mandatory && (this.value == null || this.value == "")) {
            this.errorMessage = "Dieses Feld ist ein Pflichtfeld";
        }
        else if (this.def.flags == FieldFlags.regex && this.value != null && !Utils.isRegexValid(this.value as string)) {
            this.errorMessage = "Der Wert ist kein gültiges Regex";
        }
        else if (this.def.type == ConfigFieldType.date && !(this.value instanceof Date && Utils.dateValid(this.value) || Utils.isString(this.value) && Utils.dateValid(Utils.dateFromString(this.value as string, "dd.MM.yyyy")))) {
            this.errorMessage = "Der Wert ist kein gültiges Datum";
        }
    }
}
