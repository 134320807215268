import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Utils } from 'src/modules/utils/shared/utils';
import { ButtonDefinition } from '../../models/button-definition.model';
import { FrontendFormDefinition } from '../../shared/frontend-form-definition.model';

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: []
})
export class FormDialogComponent {

    @Input()
    form: FrontendFormDefinition = null;
    @Input()
    buttons: ButtonDefinition[] = [];

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public messageService: MessageService) {
        this.form = config.data.form || "";
        this.buttons = config.data.buttons || [];
    }

    click(button: ButtonDefinition): void {
        if (button.isSuccess) {
            let errors = this.form.validate();
            if (errors != null) {
                this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 5000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
                return;
            }
        }

        this.ref.close(button.id);
    }

}
