import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';
import { ClassTeacher } from '../../shared/class-teacher.entity';
import { SchoolType } from '../../shared/school-type.enum';
import { TeacherAdditional } from '../../shared/teacher-additional.entity';
import { Teacher } from '../../shared/teacher.model';

@Component({
    selector: 'app-class-teacher',
    templateUrl: './class-teacher.component.html',
    styleUrls: ['./class-teacher.component.scss']
})
export class ClassTeacherComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;

    schoolClassId: number;
    domains: string[] = [];
    teachers: ClassTeacher[] = [];
    rows: ClassTeacher[] = [];
    orgRows: ClassTeacher[] = [];
    classTeachers: Teacher[];
    teachersGui: FrontendFieldListItem[];
    allowedTeachers: string[] = [];
    classRegister: ClassRegister;
    isSecondary: boolean;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["teachers"], null, true);
        this.classTeachers = [new Teacher("", ""), ...await this.service.restGetTeachers(this.classRegister.schoolClassId)];
        let settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.domains = await this.service.restGetDomains(this.classRegister.enaioSchoolClass.grade, this.classRegister.enaioSchoolClass.getSchoolType(), true, null, settings);
        this.isSecondary = this.classRegister.enaioSchoolClass.getSchoolType() == SchoolType.secondary && this.classRegister.enaioSchoolClass.grade >= 7;

        let schoolType = this.classRegister.enaioSchoolClass.getSchoolType();
        let ta: TeacherAdditional[] = [];
        if (schoolType == SchoolType.elementary) {
            ta = settings.teacher.filter(s => s.forElementary);
            for (let at of ta) {
                this.allowedTeachers.push(at.userName);
            }
        }
        else if (schoolType == SchoolType.secondary) {
            ta = settings.teacher.filter(s => s.forSecondary);
            for (let at of ta) {
                this.allowedTeachers.push(at.userName);
            }
        }
        else if (schoolType == SchoolType.special) {
            ta = settings.teacher.filter(s => s.forSpecial);
            for (let at of ta) {
                this.allowedTeachers.push(at.userName);
            }
        }
        this.teachersGui = this.classTeachers.filter(t => t.userName == "" || this.allowedTeachers.includes(t.userName)).map(t => new FrontendFieldListItem(t.userName, t.fullName));

        this.rows = [];
        for (let domain of this.domains) {
            if (domain == "") {
                continue;
            }
            let ct: ClassTeacher = new ClassTeacher();
            ct.domain = domain;
            this.rows.push(ct);
        }

        for (let entry of this.classRegister.teachers) {
            this.rows[this.rows.findIndex(e => e.domain == entry.domain)] = entry;
        }
        this.orgRows = Utils.cloneDeep(this.rows);

        this.app.updateNavigation(null,
            { routerLink: ["/enaio-certificates", "home"], icon: 'fas fa-home' }, [
            { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates", "class-registers", "home"] },
            { label: "Klasse " + this.classRegister.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.id] },
            { label: "Klassentagebuch", routerLink: ["/enaio-certificates", "class-registers", "teacher", this.id] }
        ]);

        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.rows, this.orgRows);
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id }).body(this.rows).post().run("api/cer/classregister/teachers").getText(), this, true, true);
    }

    addRow(): void {
        this.rows.push(new ClassTeacher());
    }

    removeRow(row: ClassTeacher): void {
        this.rows.splice(this.rows.indexOf(row), 1);
    }

    changeDomain(event: any, row: ClassTeacher): void {
        row.domain = event;
    }

    changeTeacher(event: any, row: ClassTeacher): void {
        row.teacher = event;
    }

    changeTeacherSecond(event: any, row: ClassTeacher): void {
        row.teacherSecond = event;
    }

    changeTeacherRegional(event: any, row: ClassTeacher): void {
        row.teacherRegional = event;
    }

    changeTeacherRegionalSecond(event: any, row: ClassTeacher): void {
        row.teacherRegionalSecond = event;
    }

    getTeacherByUsername(username: string): string {
        return this.classTeachers.find(t => t.userName == username).fullName;
    }
}
