import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TemplateFieldPersistentData } from '../../shared/template-field-persistent-data.entity';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditTemplateFieldComponent extends ComponentView {

    id: number;
    item: TemplateFieldPersistentData;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("caption", "Bezeichnung", FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("fieldName", "Feld", FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("toolTip", "Tooltip", FrontendFieldType.textArea)
        ]);

        this.item = await RestEndpoint.main().findById(this.id).run("api/tpl/templatefieldpersistentdata").get(TemplateFieldPersistentData);
        this.form.fill(this.item);
        return Promise.resolve(true);
    }

    save(): void {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        RestEndpoint.main().upsert(this.item).run("api/tpl/templatefieldpersistentdata");
        this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
        this.app.navigateBack();
    }
}
