import { EnaioUtils } from 'src/modules/enaio/misc/enaio-utils';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { EnaioGuiControlType } from 'src/modules/enaio/shared/EnaioGuiControlType';
import { EnaioGuiField } from 'src/modules/enaio/shared/EnaioGuiField';
import { EnaioWorkflowBasicDataType } from 'src/modules/enaio/shared/EnaioWorkflowBasicDataType';
import { EnaioWorkItemParameter } from 'src/modules/enaio/shared/EnaioWorkItemParameter';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';

export class FrontendEnaioMapper {

    static enaioToFrontendForm(fields: EnaioGuiField[], wfParams: EnaioWorkItemParameter[] = null, toUpperLeft = true, responsive = false): FrontendFormDefinition {
        let minX = toUpperLeft ? Math.min(...fields.map(f => Math.min(f.fieldPos.left, f.inputPos.left))) : 0;
        let minY = toUpperLeft ? Math.min(...fields.map(f => Math.min(f.fieldPos.top, f.inputPos.top))) : 0;

        let result = new FrontendFormDefinition(Utils.arraySortBy(Utils.arrayWithoutNull(fields.map(f =>
            FrontendEnaioMapper.enaioToFrontendField(f, wfParams.find(param => param.formFieldId === f.wfFieldId), -minX, -minY, responsive))), f => f.tabOrder), !responsive);
        result.mandatoryFieldsRedAsterisk = true;
        return result;
    }

    static enaioToFrontendField(field: EnaioGuiField, wfParam: EnaioWorkItemParameter, shiftX = 0, shiftY = 0, responsive = false): FrontendFieldDefinition {
        let scaleFactor = 2.2;

        let result = new FrontendFieldDefinition(Utils.stringDef(field.wfFieldId, field.internalName), field.name, FrontendFieldType.text);
        result.tabOrder = field.tabOrder;
        result.fixedLabelBounds = field.fieldPos.toBounds(scaleFactor, shiftX * scaleFactor, shiftY * scaleFactor);
        result.fixedControlBounds = field.inputPos.toBounds(scaleFactor, shiftX * scaleFactor, shiftY * scaleFactor);
        result.hasLabel = field.needsLabel && field.isLabelVisible && result.fixedLabelBounds.width > 0 && result.fixedLabelBounds.height > 0;
        result.hasControl = field.isControlVisible && result.fixedControlBounds.width > 0 && result.fixedControlBounds.height > 0;
        result.enaioSource = field;
        result.mandatory = field.mandatory;
        result.maxLength = field.maxLength;
        result.upperCase = field.upperCase;
        result.labelRightAligned = !responsive && field.isLabelRightAligned;
        result.enabled = !field.isReadOnly;
        result.regexMask = field.regularExpression;
        result.listItems = field.listItems != null ? [new FrontendFieldListItem("", ""), ...field.listItems.map(item => EnaioUtils.guiFieldListItemToFrontend(item, field.listUseItemValue))] : [];
        if (field.listOrder) {
            result.listItems = Utils.arraySortBy(result.listItems, li => li.label);
        }
        result.enaioSectionName = field.sectionName;

        switch (field.controlType2) {
            case EnaioGuiControlType.button:
                result.type = FrontendFieldType.button;
                result.hasControl = true;
                result.hasLabel = false;
                result.fixedControlBounds = result.fixedLabelBounds;
                break;
            case EnaioGuiControlType.checkBox:
                result.fixedControlBounds.width *= 1.4;
                result.type = FrontendFieldType.checkBox;
                break;
            case EnaioGuiControlType.radioButton:
                result.fixedControlBounds.width *= 1.4;
                result.type = FrontendFieldType.radioButton;
                result.radioButtonGroup = field.radioButtonGroup;
                result.radioButtonValue = field.radioButtonValue;
                break;
            case EnaioGuiControlType.datePicker:
                result.type = FrontendFieldType.datePicker;
                break;
            case EnaioGuiControlType.table:
                result.type = FrontendFieldType.table;
                let recordMembers = wfParam?.value.types != null && Utils.getOwnPropertyNames(wfParam.value.types).length > 0 ? wfParam.value.types[Utils.getOwnPropertyNames(wfParam.value.types)[0]].recordMembers : [];
                result.tableColumns = Utils.mapTwo(field.tableColumns, recordMembers,
                    (item, recordMember) => EnaioUtils.guiFieldTableColumnToFrontend(item, recordMember, result.fixedControlBounds.width));
                result.fixedLabelBounds.x += 5 * scaleFactor;
                result.fixedLabelBounds.y -= 3 * scaleFactor;
                result.generateEmptyTableItem = (): any => wfParam != null ? wfParam.generateRecordItem() : {};
                break;
            case EnaioGuiControlType.comboBox:
                result.type = field.listMultiSelect ? FrontendFieldType.comboBoxMulti : FrontendFieldType.comboBox;
                break;
            case EnaioGuiControlType.textArea:
                result.type = FrontendFieldType.textArea;
                break;
            case EnaioGuiControlType.textBox:
                if (result.regexMask !== "" && result.regexMask != null) {
                    result.type = FrontendFieldType.maskField;
                }
                break;
            case EnaioGuiControlType.groupBox:
                return null;
         /*       result.type = FrontendFieldType.groupBox;
                result.hasControl = true;
                result.hasLabel = false;
                result.fixedControlBounds = result.fixedLabelBounds;
                result.fixedControlBounds.y -= 25;
                result.fixedControlBounds.width -= 30;
                result.fixedControlBounds.height -= 50;
                break;
           */ case EnaioGuiControlType.pageControl:
                result.type = FrontendFieldType.textArea;
                result.value = "PageControls aktuell nicht unterstützt";
                result.hasControl = true;
                result.hasLabel = false;
                break;
            default:
                console.log("Unbekannter ControlType: " + field.controlType2 + " für Feld " + field.name);
        }

        if (responsive) {
            result.fixedControlBounds = null;
            result.fixedLabelBounds = null;
        }

        return result;
    }

    static frontendToEnaioValue(from: FrontendFieldDefinition, allFields: FrontendFieldDefinition[], allParams: EnaioWorkItemParameter[]): void {
        let to: EnaioWorkItemParameter;
        if (from.type === FrontendFieldType.radioButton) {
            let from2 = allFields.find(item => item.caption === from.radioButtonGroup);
            if (from2 == null) {
                throw new Error("Keine Radio Button Gruppe mit dem Namen " + from.radioButtonGroup + " gefunden");
            }
            to = allParams.find(param => param.formFieldId === from2.id);
        }
        else {
            to = allParams.find(param => param.formFieldId === from.id);
        }

        let value = from.value instanceof Date ? Utils.dateFormatDefaultDate(from.value) : from.value;

        to.value.value = value;

        if (to.value.value instanceof FrontendFieldListItem) {
            to.value.value = to.value.value.label;
        }
        if (Utils.isArray(to.value.value) && to.value.value.length > 0) {
            if (to.value.value[0] instanceof FrontendFieldListItem) {
                to.value.value = (to.value.value as FrontendFieldListItem[]).map(v => v.label);
            }
            else if (to.value.dataType.basic === EnaioWorkflowBasicDataType.array) {
                to.value.value = Utils.cloneDeep(to.value.value);
                for (let row of (to.value.value as any[])) {
                    for (let key of Utils.getOwnPropertyNames(row)) {
                        row[key] = row[key] instanceof Date ? Utils.dateFormatDefaultDate(row[key] as Date) : row[key];
                    }
                }
            }
        }
        if (to.value.value instanceof Date && (to.name === "AbreiseAm" || to.name === "RueckkehrAm" || to.name === "start" || to.name === "ende")) {
            to.value.value = EnaioHelper.toString(to.value.value);
        }
        if (Utils.isBool(to.value.value) && to.value.dataType.basic === EnaioWorkflowBasicDataType.integer) {
            to.value.value = Utils.toNumber(to.value.value);
        }
    }
}
