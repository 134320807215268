<div *ngIf="def != null && def.useSearchBar" class="grid" style="margin-bottom: 10px">
    <input pInputText [(ngModel)]="input" style="margin-left: 8px">
    <p-button icon="fas fa-search" [label]="def.searchBarLabel" (onClick)="searchDocuments()"></p-button>
</div>
<div *ngIf="def != null" class="grid">
    <sm-toolbar class="col-12">
        <p-button *ngIf="def.canUpload" icon="fas fa-cloud-upload-alt" label="Dokument hochladen" (onClick)="newDocument($event)"></p-button>
        <p-button icon="fas fa-file-download" label="Alle herunterladen" (onClick)="downloadAll()"></p-button>
        <p-button icon="fas fa-file-download" label="Als CSV exportieren" (onClick)="tDocuments.exportCsv()"></p-button>
        <input pInputText class="width400" type="text" placeholder="Suche" [(ngModel)]="fullTextSearchTerm" (ngModelChange)="updateFilter()">
    </sm-toolbar>
</div>
<div class="grid" style="overflow-y : auto; height: 100%">
    <sm-table class="col-8 oya compact" #tDocuments [data]="table" [allowExport]="false" [gridLines]="true" [deltaHeight]="240" [(selection)]="selection" (selectionChange)="rowSelect($event)" (onCellClicked)="cellClicked($event)"></sm-table>
    <p-tabView *ngIf="def != null" class="col-4 p-data">
        <p-tabPanel *ngIf="def.hasDocuments" header="Vorschau">
            <enaio-document-file-preview class="full-height-240x display-block" [anonymousGuid]="anonymousGuid" [documentId]="selectedDocument != null ? selectedDocument.id : 0"></enaio-document-file-preview>
        </p-tabPanel>
        <p-tabPanel *ngIf="def != null && def.showMetaData" header="Indexdaten">
            <enaio-document-meta-data [anonymousGuid]="anonymousGuid" [document]="selectedDocument"></enaio-document-meta-data>
        </p-tabPanel>
  </p-tabView>
</div>

<p-dialog header="Dokument hochladen" [(visible)]="displayModal" [modal]="true" [style]="{width: '700px', height: '400px'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <sm-form [form]="modalForm">
    </sm-form>
        <p-footer>
            <button type="button" pButton icon="fas fa-check" (click)="insertDocument();" label="Anlegen"></button>
            <button type="button" pButton icon="fas fa-times" (click)="displayModal=false" label="Abbrechen" class="ui-button-secondary"></button>
        </p-footer>
</p-dialog>
