<app-wait-container [style]="'overflow-x: clip'" [loading]="loading">
    <ng-container *ngIf="!loading">
        <div class="grid">
            <div class="col-12 lg:col-8 left-panel">
                <p-message *ngIf="!anyWorkflows" class="mt-2" severity="info" text="Es befinden sich aktuell keine Workflows in Ihrem Eingangskorb"></p-message>
                <ng-container *ngFor="let inbox of inbox.inboxes">
                    <p-panel *ngIf="inboxTables[inbox.title].rows.length > 0" class="mb-2" [header]="inbox.title">
                        <sm-table [data]="inboxTables[inbox.title]" (onRowSelect)="selectRow($event)">
                        </sm-table>
                    </p-panel>
                </ng-container>
            </div>
        <div class="grid flex-col col-12 lg:col-4">
                <p-panel class="col-12" header="Mein Status">
                    <app-sm-switch textFalse="Abwesend" textTrue="Anwesend" [(value)]="atWork" (valueChange)="changeAtWork()"></app-sm-switch>
                </p-panel>
                <p-panel class="col-12" header="Urlaubskonto">
                    Urlaubsanspruch: <b>{{personellFile.getNumberByInternal("UrlaubsanspruchaktuellesJahr")}}</b> Resturlaub: <b>{{personellFile.getNumberByInternal("UrlaubsanspruchaktuellesJahr") - personellFile.getNumberByInternal("UrlaubsanspruchTageaktuellesJahr")}}</b>
                </p-panel>
                <p-panel class="col-12" header="Workflow starten">
                    <ul>
                        <li *ngFor="let startable of startables"><a href=# (click)="startWorkflow(startable); $event.preventDefault()">{{startable.instanceName}}</a></li>
                    </ul>
                </p-panel>
                <p-panel class="col-12" header="Personalakte">
                    <div class="grid">
                        <p-button class="col-12 lg:col-4" icon="fas fa-user" label="Personalakte öffnen" (onClick)="openPersonellFile()">
                        </p-button>
                        <p-button class="col-12 lg:col-4" icon="fas fa-virus" label="Neue Krankmeldung" (onClick)="addSickNote()">
                        </p-button>
                        <p-button class="col-12 lg:col-4" icon="fas fa-calendar-day" label="Neuer Ausgleichstag" (onClick)="addCompensationDay()">
                        </p-button>
                    </div>
                </p-panel>
            </div>
        </div>
    </ng-container>
</app-wait-container>
