import { Type } from "class-transformer";
import { DbConnectionConfig } from "./DbConnectionConfig";
import { EnaioConnectionType } from "./EnaioConnectionType";

export class EnaioConfig {
    id = "";
    connectionType: EnaioConnectionType;
    server = "";
    port = 4_000;
    userName = "";
    password = "";
    passwordEncrypted = false;
    readOnly = false;
    notifications = false;
    detailedErrorMessages = true;
    @Type(() => DbConnectionConfig)
    lowLevelDatabase: DbConnectionConfig;
}
