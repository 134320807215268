import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { SmTableComponent } from '../sm-table/sm-table.component';

@Component({
    selector: 'sm-table-on-push',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../sm-table/sm-table.component.html',
    styleUrls: ['../sm-table/sm-table.component.scss']
})
export class SmTableOnPushComponent extends SmTableComponent {

    constructor(app: MainAppService, elementRef: ElementRef, renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {
        super(app, elementRef, renderer);
    }

    updateChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

}
