import { Component, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { ConfigDefinition } from 'src/modules/sm-base/shared/config-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { ConfigChangerConfigComponent } from '../config-changer-config/config-changer-config.component';
import { ConfigFieldType } from 'src/modules/sm-base/shared/config-field-type.enum';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-config-changer-main',
  templateUrl: './config-changer-main.component.html',
  styleUrls: ['./config-changer-main.component.scss']
})
export class ConfigChangerMainComponent extends ComponentView {

    def: ConfigDefinition;
    value: OrdinaryObject = {};
    @ViewChild("changer") changer: ConfigChangerConfigComponent;

    async initParams(): Promise<boolean> {
        this.app.updateNavigation("Konfigurations-Editor", { routerLink: ["/config-changer", "home"], icon: 'fas fa-home' }, [
            { label: "Konfigurations-Editor", routerLink: ["/config-changer", "home"] }
        ]);
        this.def = await RestEndpoint.main().query({id: "main"}).run("api/cfc/definition").get(ConfigDefinition);
        this.def.init();
        this.value = await RestEndpoint.main().query({id: "main"}).run("api/cfc/value").getOrdinaryObject(Object);

        return true;
    }

    async save(): Promise<void> {
        for (let prop of this.changer.config.properties) {
            prop.checkForErrors();
            if (prop.errorMessage != null) {
                this.app.showToast("error", "Fehler", prop.errorMessage);
                return;
            }
            if (prop.def.type == ConfigFieldType.date) {
                this.value[prop.def.name] = Utils.dateFormatDefaultDate(Utils.dateFromString(this.value[prop.def.name] as string, "DD.MM.YYYY", true));
            }
        }

        await this.app.saveDataHandler(async () => RestEndpoint.main().query({id: "main"}).put().body(this.value).run("api/cfc/value").getText(), this, true);
    }
}
