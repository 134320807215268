import { CertificateGrade } from './certificate-grade.entity';
import { CertificateStatus } from './certificate.status.enum';
import { GuiCertificateDomain } from './gui-certificate-domain.model';

export class GuiCertificate {
    id: number;
    pupilId = 0;
    schoolClassId = 0;
    isHalfYear: boolean;
    status: CertificateStatus;
    domains: Map<string, GuiCertificateDomain>;
    certificateGrades: CertificateGrade[];

}
