export class EnaioFrontendColumn {
    name = "";
    internalName = "";
    onParent = false;

    constructor(name: string, internalName: string) {
        this.name = name;
        this.internalName = internalName;
    }
}
