import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { JobDescription } from '../../shared/job-description.entity';

@Component({
    selector: 'app-edit-job-description',
    templateUrl: './edit-job-description.component.html',
    styleUrls: ['./edit-job-description.component.scss']
})
export class EditJobDescriptionComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: JobDescription;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = this.app.tForm("lea.jobDescription", new FrontendFormDefinition([
            new FrontendFieldDefinition("number", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("name", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("group", null, FrontendFieldType.text, { mandatory: true })
        ]));
        this.item = await this.service.adminInitEdit(JobDescription, this.id, this.form, "lea.jobDescription", "job-descriptions", "job-description", "api/lrn/jobdescription");
        return true;
    }

    save(): void {
        this.service.adminUpdate(this.form, JobDescription, this.item, "api/lrn/jobdescription");
    }

}
