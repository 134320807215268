import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallDescription } from 'src/modules/enaio/shared/EnaioCallDescription';
import { EnaioCallGeneric } from 'src/modules/enaio/shared/EnaioCallGeneric';
import { EnaioJobParam } from 'src/modules/enaio/shared/EnaioJobParam';
import { EnaioParamType } from 'src/modules/enaio/shared/EnaioParamType';
import { JobResult } from 'src/modules/enaio/shared/JobResult';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';

@Component({
  selector: 'app-server-api-caller',
  templateUrl: './server-api-caller.component.html',
  styleUrls: ['./server-api-caller.component.scss']
})
export class ServerApiCallerComponent extends ComponentView {

    callTypes: string[];
    formInput: FrontendFormDefinition;
    result: string;
    storedIndexSearch: string;
    @Input()
    instanceGuid: string;

    async initParams(): Promise<boolean> {
        this.callTypes = Utils.arraySort(await DhTools.backendCall("api/enaio/getServerApiCallTypes").listStrings());

        this.formInput = new FrontendFormDefinition([
            new FrontendFieldDefinition("callType", "Typ", FrontendFieldType.comboBox, { value: this.callTypes[0], mandatory: true, dropdownAutoComplete: true, dropdownEditable: false, listItems: this.callTypes.map(m => new FrontendFieldListItem(m, m))}),
            new FrontendFieldDefinition("xml", "XML", FrontendFieldType.textArea, { value: "", monospaced: true }),
            new FrontendFieldDefinition("params", "Parameter (1: string, 2: int, 3: bool, 4: double, 5: dateTime, 6: xml/binary)", FrontendFieldType.table, { value: [], tableColumns: [
                new TableColumn("name", "Name"),
                new TableColumn("value", "Wert"),
                new TableColumn("type", "Typ", TableCellType.number, { width: "30px" })
            ], generateEmptyTableItem: (): any => Utils.fromPlain(EnaioJobParam, { type: EnaioParamType.string })}),
            new FrontendFieldDefinition("paramsFromEm", "Parameter von Enterprise Manager Ausgabe holen", FrontendFieldType.button, { hasLabel: false, onClick: this.getParamsFromEm.bind(this)})
        ]);
        return true;
    }

    async getParamsFromEm(): Promise<void> {
        let text = await this.app.messageDialog.input(new FrontendFieldDefinition("", "Enterprise Manager Ausgabe", FrontendFieldType.textArea), "");
        if (text == null) {
            return;
        }
        let params = await DhTools.backendCall("api/enaio/getJobParamsFromEnterpriseManagerOutput", { text }).list(EnaioJobParam);
        this.formInput.setValue("params", params);
    }

    async run(): Promise<void> {
        try {
            let result = await DhTools.enaioCall<JobResult>(Utils.fromPlain(EnaioCallGeneric, {
                type: this.formInput.getValue("callType"),
                input: this.formInput.getValue("params"),
                xml: this.formInput.getValue("xml")
            }));
            let s = "";
            for (let key of Utils.getOwnPropertyNames(result.items)) {
                let val = Utils.toString(result.items[key]);
                if (key == "fileContents" && Utils.isArray(result.items[key])) {
                    let idx = 0;
                    for (let fc of result.items[key]) {
                        let s2 = window.atob(fc as string);
                        s2 = await this.getParamString("fileContents[" + idx++ + "]", s2);

                        s += s2;
                    }
                }
                else {
                    s += await this.getParamString(key, val);
                }
            }
            this.result = s;
        }
        catch (ex) {
            this.result = ex.error;
        }
    }

    async getParamString(key: string, val: string): Promise<string> {
        if (val.startsWith("ÿþ")) {
            val = await DhTools.decodeUtf16(val.substring(2));
        }
        if (val.startsWith("<?xml") || val.startsWith("<") && val.endsWith(">")) {
            val = await DhTools.prettifyXml(val);
        }
        return key + "\n" + val + "\n\n";
    }

    async getStoredIndex(): Promise<void> {
        let call = await DhTools.backendCall("api/dhinstance/getStoredCall", { instanceGuid: this.instanceGuid, index: Utils.toNumber(this.storedIndexSearch)}).get(EnaioCallDescription);
        this.formInput.getField("callType").setValue(this.callTypes.find(s => Utils.stringEqualsCi(s, call.type)));
        this.formInput.setValue("xml", call.xml);
        this.formInput.setValue("params", call.parameters);
    }

}
