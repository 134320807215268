<div class="grid">
    <p-panel class="col-3" header="Verbindungs-Parameter">
        <sm-toolbar>
            <p-splitButton styleClass="mb-3" icon="fas fa-filter" label="Verbindung laden ..." [model]="connectionItems" (onClick)="loadConnection()" (onDropdownClick)="updateConnectionItems()"></p-splitButton>
            <p-button icon="fas fa-database" label="ODBC konvertieren" (onClick)="odbcToRaw()"></p-button>
        </sm-toolbar>
        <app-config-editor-tree #configEditorTree [def]="def" [showInfoColumn]="false" [propColumnWidth]="'250px'">
        </app-config-editor-tree>
        <p-button class="mt-3" icon="fas fa-database" label="Als JSON in Zwischenablage kopieren" (onClick)="copyJsonToClipboard()"></p-button>
    </p-panel>
    <p-tabView class="col-9">
        <p-tabPanel header="Verschiedenes">
            <p-button icon="fas fa-database" label="Datenbank in DBeaver öffnen" (onClick)="openDbeaver()"></p-button>
        </p-tabPanel>
        <p-tabPanel header="Werte finden">
            <div class="grid">
                <div class="col-2">
                    <sm-form  [form]="findValueForm">
                    </sm-form>
                    <p-button class="mt-3" icon="fas fa-filter" label="Finden" (onClick)="findValues()"></p-button>
                </div>
                <div class="col-10">
                    <sm-table *ngIf="findValueTable != null" [deltaHeight]=250 [data]="findValueTable" [allowSelection]=false>
                    </sm-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Dump">
            <div class="grid">
                <div class="col-2">
                    <sm-form  [form]="dumpForm">
                    </sm-form>
                    <p-button class="mt-3" icon="fas fa-filter" label="Dump" (onClick)="dump()"></p-button>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Vergleich">
            <div class="grid">
                <div class="col-2">
                    <p-button class="mt-3" icon="fas fa-filter" label="Dumps neu laden" (onClick)="reloadDumps()"></p-button>
                    <sm-form  [form]="compareForm">
                    </sm-form>
                    <p-button class="mt-3" icon="fas fa-filter" label="Vergleichen" (onClick)="compare()"></p-button>
                </div>
                <div class="col-10">
                    <textarea pInputTextarea spellcheck=false class="width100p full-height-200x monospace no-resize" [ngModel]="changes"></textarea>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>

</div>
<app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay>
