import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { ObjectAction } from 'src/modules/sm-base/shared/object-action.model';
import { DhTools } from '../../models/dh-tools.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';

@Component({
    selector: 'app-ms-graph-tools',
    templateUrl: './ms-graph-tools.component.html',
    styleUrls: ['./ms-graph-tools.component.scss']
  })
  export class MsGraphToolsComponent extends ComponentView {

    text = "";
    actionButtons: ObjectAction[];

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }

    async getTenantId(): Promise<void> {
        let domainName = await this.app.messageDialog.input(new FrontendFieldDefinition("", "Domain-Name", FrontendFieldType.text), "Eingabe");
        if (domainName == null) {
            return;
        }
        await this.app.copyToClipboardOrDialog(await DhTools.backendCall("api/tools/msGraphGetTenantId", { domainName }).getString(), "Tenant-ID", "Die Tenant-ID befindet sich in der Zwischenablage");
    }

    async getAccessUrl(): Promise<void> {
        let form = new FrontendFormDefinition([
            new FrontendFieldDefinition("clientId", "Client-ID", FrontendFieldType.text),
            new FrontendFieldDefinition("scope", "Scope", FrontendFieldType.comboBox, { mandatory: true, listItems: [new FrontendFieldListItem("offline_access%20user.read%20Mail.ReadWrite%20Mail.Send%20Mail.ReadWrite.Shared%20Mail.Send.Shared", "Lesen/Schreiben Mails")]})
        ]);
        if (await this.app.messageDialog.form(form, "Optionen") != "ok") {
            return;
        }
        let url = "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=" + form.getValue("clientId") +
            "&response_type=code&redirect_uri=https://login.microsoftonline.com/common/oauth2/nativeclient&scope=" + form.getValue("scope");

        await this.app.copyToClipboardOrDialog(url, "URL", "Die URL befindet sich in der Zwischenablage");
    }
}
