import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DatabaseUserAttribute } from 'src/modules/database/shared/database-user.attribute';
import { DatabaseUser } from 'src/modules/database/shared/database-user.entity';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'app-edit-general-user',
    templateUrl: './edit-general-user.component.html',
    styleUrls: ['./edit-general-user.component.scss']
    })
    export class EditGeneralUserComponent extends ComponentView {

    id: number;
    item: DatabaseUser;
    form: FrontendFormDefinition;
    attribs: FrontendFieldDefinition[];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.attribs = await this.app.loadDataHandler(async () => RestEndpoint.main().run("api/app/additionalUserAttribute").list(FrontendFieldDefinition), this);
        this.form = new FrontendFormDefinition(Utils.arrayWithoutNull([
            new FrontendFieldDefinition("userName", "Benutzername", FrontendFieldType.text),
            new FrontendFieldDefinition("firstName", "Vorname", FrontendFieldType.text),
            new FrontendFieldDefinition("lastName", "Nachname", FrontendFieldType.text),
            new FrontendFieldDefinition("mail", "E-Mail", FrontendFieldType.text),
            new FrontendFieldDefinition("isAdmin", "Ist Administrator", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("password", this.id > 0 ? "Passwort (eintippen für PW-Wechsel)" : "Passwort", FrontendFieldType.password, { mandatory: !(this.id > 0) }),
            ...this.attribs.map(a => {
                let result = Utils.cloneDeep(a);
                result.id = "attrib_" + result.id;
                return result;
            })
        ]));

        this.item = await this.app.loadDataHandler(async () => RestEndpoint.main().findByIdOrEmpty(DatabaseUser, this.id).run("api/app/user").get(DatabaseUser), this);

        this.form.fill(this.item);
        for (let attrib of this.attribs) {
            let a = this.item.attributes.find(a2 => a2.key == attrib.id);
            if (a != null) {
                let field = this.form.getField("attrib_" + attrib.id);
                let v = field.type == FrontendFieldType.checkBox ? Utils.toBool(a.value) : a.value;
                field.setValue(v);
            }
        }
        this.app.updateNavigation(null, { routerLink: ["/"], icon: 'fas fa-home' }, [
            { label: "Administration", routerLink: ["/base2", "admin", "editGeneralUsers"] },
            { label: "Benutzer", routerLink: ["/base2", "admin", "editGeneralUsers", this.id] }
        ]);
        return true;
    }

    async save(): Promise<void> {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);

        if (!Utils.isNoe((this.item as any).password)) {
            if (this.id > 0 && !await this.app.messageDialog.yesNo("Soll das Passwort des Benutzers geändert werden?", "Warnung")) {
                return;
            }
            (this.item as any).attrib____passwordChange = (this.item as any).password;
        }

        for (let attrib of [...this.attribs, new FrontendFieldDefinition("___passwordChange", "", FrontendFieldType.text)]) {
            if (Utils.hasProperty(this.item, "attrib_" + attrib.id)) {
                let a = this.item.attributes.find(a2 => a2.key == attrib.id);
                if (a == null) {
                    a = Utils.fromPlain(DatabaseUserAttribute, {
                        key: attrib.id
                    });
                    this.item.attributes.push(a);
                }
                a.value = this.item["attrib_" + attrib.id];
                delete this.item["attrib_" + attrib.id];
            }
        }


        await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.item).run("api/app/user").getText(), this, true, true);
    }

}
