import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Progressor } from 'src/modules/utils/shared/Progressor';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { Wrapper } from 'src/modules/utils/shared/wrapper.model';

@Component({
  selector: 'app-document-handler-tools',
  templateUrl: './document-handler-tools.component.html',
  styleUrls: ['./document-handler-tools.component.scss']
})
export class DocumentHandlerToolsComponent extends ComponentView {

    _Utils = Utils;
    progressor = new Wrapper<Progressor>();

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }

    async openPersistentStorage(): Promise<void> {
        await DhTools.backendCall("api/dh/openPersistentStorage").getText();
    }

    async grantAllFileSystemRights(): Promise<void> {
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/dh/grantAllFileSystemRights").getText());
    }

}
