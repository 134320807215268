import { Component, inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { OrdinaryObjectNumber } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { CovidTestDay } from '../../shared/covid-test-day.entity';
import { CovidTest } from '../../shared/covid-test.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { Pupil } from '../../shared/pupil.model';
import { SchoolClass } from '../../shared/school-class.model';

interface RowKey {
    pupilId: number;
    person: string;
    tests: CovidTest[];
}

@Component({
  selector: 'app-covid-tests',
  templateUrl: './covid-tests.component.html',
  styleUrls: ['./covid-tests.component.scss']
})
export class CovidTestsComponent extends ComponentView {

    _Utils = Utils;

    service = inject(EnaioCertificateService);

    schoolClassId: number;
    isHalfYear: boolean;
    history: boolean;

    isSecretary = false;
    isClassTeacher = false;
    enaioSchoolClass: SchoolClass;
    covidTestDays: CovidTestDay[];
    covidTestDaysOriginal: CovidTestDay[];
    vaccinationMapOriginal: OrdinaryObjectNumber<boolean>;
    pupils: Pupil[] = [];
    pupilsById: OrdinaryObjectNumber<Pupil>;
    rows: RowKey[] = [];
    personOptions: SelectItem[] = [];
    calendarWeeks: number[] = [];

    constructor() {
        super();
        this.neededParams = { schoolClassId: "number", isHalfYear: "bool", history: "bool" };
    }

    async initParams(): Promise<boolean> {
        this.isSecretary = await this.service.isSecretary();

        if (this.history && !this.isSecretary) {
            await this.app.messageDialog.info("Sie besitzen nicht die nötigen Rechte für diese Ansicht", "Fehler");
            this.app.navigateBack();
            return false;
        }

        this.enaioSchoolClass = await this.service.restGetSchoolClass(this.schoolClassId);

        this.isClassTeacher = this.enaioSchoolClass.hasPermission(this.app.getUserName(), true);

        this.pupils = await this.service.restGetPupils(this.schoolClassId);
        this.pupilsById = {};
        for (let pupil of this.pupils) {
            this.pupilsById[pupil.id] = pupil;
        }
        this.vaccinationMapOriginal = this.getVaccinationMap();

        this.covidTestDays = Utils.arrayReverse(Utils.arraySort(await this.app.loadDataHandler(async () => this.history ?
            RestEndpoint.main().query({ schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear }).run("api/cer/covidtestday").list(CovidTestDay) :
            [await RestEndpoint.main().query({ schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear }).post().run("api/cer/covidtestday").get(CovidTestDay)], this)));

        this.covidTestDaysOriginal = Utils.cloneDeep(this.covidTestDays);

        let tempRows = Utils.arrayExplode(this.covidTestDays, d => d.tests.map(t => ({ pupilId: t.pupilId, person: t.person, tests: Utils.getArrayOfConstant(null, this.covidTestDays.length)})));

        this.rows = [];
        for (let row of tempRows) {
            if (row.pupilId == 0 || this.rows.find(r => r.pupilId == row.pupilId) == null) {
                this.rows.push(row);
            }
        }

        for (let i = 0; i < this.covidTestDays.length; i++) {
            for (let test of this.covidTestDays[i].tests) {
                let row = this.rows.find(r => r.pupilId == test.pupilId && r.person == test.person && r.tests[i] == null);
                if (row != null) {
                    row.tests[i] = test;
                }
            }
        }

        this.rows = Utils.arraySort(this.rows.filter(r => r.tests.find(t => t != null) != null && (r.pupilId != 0 && r.pupilId in this.pupilsById || !Utils.isNoe(r.person))), (row1, row2) => {
            let cmp = Utils.cmp(row1.pupilId == 0, row2.pupilId == 0);
            return cmp != 0 ? cmp : row1.pupilId == 0 ? this.history ? Utils.cmp(row1.person, row2.person) : 0 : Utils.cmp(this.pupilsById[row1.pupilId].lastName, this.pupilsById[row2.pupilId].lastName);
        });

        this.personOptions = Utils.arraySort(EnaioCertificatesTools.getCovidPersonGroups()).map(c => ({label: c, value: c}));

        this.calendarWeeks = Utils.arrayGetUnique(this.covidTestDays.map(day => Utils.dateCalendarWeek(day.day)));

        this.app.updateNavigation(null, this.service.getHome(), [
            this.service.getHomeCovidTests(),
            { label: "Klasse " + this.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "covid-tests", "class", "add", this.enaioSchoolClass.id, this.isHalfYear ? 1 : 0] }
        ]);

        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.getChanged().length > 0 ? "" : null;
    }

    getChanged(): CovidTest[] {
        let result: CovidTest[] = [];
        for (let i = 0; i < this.covidTestDays.length; i++) {
            result = result.concat(Utils.getOnlyUpdatedEntries(this.covidTestDays[i].tests, this.covidTestDaysOriginal[i].tests, (item1, item2) => item1.id === item2.id, (item1, item2) => item2.wasUpdated(item1)));
        }
        return result;
    }

    getVaccinationMap(): OrdinaryObjectNumber<boolean> {
        return Utils.arrayToMap(this.pupils, p => Utils.toString(p.id), p => p.vaccinated);
    }

    async save(): Promise<void> {
        let vaccinationMap = this.getVaccinationMap();
        let vaccChanged = !Utils.equalsDeep(this.vaccinationMapOriginal, vaccinationMap);
        let dataChanged = this.getChanged().length > 0;

        if (!vaccChanged && !dataChanged) {
            await this.app.messageDialog.info("Es wurden keine Daten geändert");
            return;
        }

        if (this.history) {
            if (dataChanged && !(await this.app.saveDataHandler(async () => RestEndpoint.main().body(this.covidTestDays).put().run("api/cer/covidtestday/saveall").getText()))[0]) {
                return;
            }
            this.covidTestDaysOriginal = Utils.cloneDeep(this.covidTestDays);
        }
        else if (!dataChanged || await this.app.messageDialog.yesNo("Nach dem Speichern der Testtabelle kann diese nur noch vom Sekretariat verändert werden. Fortfahren?", "Warnung")) {
            if (!(await this.app.saveDataHandler(async () => RestEndpoint.main().body(this.covidTestDays[0]).put().run("api/cer/covidtestday").getText()))[0]) {
                return;
            }
            this.app.navigateBack();
        }
        this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
    }

    changePerson(event: any, row: RowKey): void {
        for (let test of row.tests) {
            test.person = event;
        }
        row.person = event;
    }

    addRow(): void {
        let test = Utils.fromPlain(CovidTest, {
            person: ""
        });
        this.covidTestDays[0].tests.push(test);
        this.rows.push({ pupilId: 0, person: "", tests: [test] });
    }

    getSum(dayIndex: number, week: number, person: string[], type: number): number {
        let tests: CovidTest[] = [];
        for (let row of this.rows) {
            if (person == null || person.includes(row.person)) {
                for (let i = 0; i < row.tests.length; i++) {
                    if (row.tests[i] != null && (dayIndex == null || dayIndex == i) && (week == null || week == Utils.dateCalendarWeek(this.covidTestDays[i].day))) {
                        tests.push(row.tests[i]);
                    }
                }
            }
        }

        return tests.reduce((prev, t) => prev + (type == -1 ? t.numNasal + t.numSaliva + t.numSelf : type == 0 ? t.numNasal : type == 1 ? t.numSaliva : t.numSelf), 0);
    }
}
