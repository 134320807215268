import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { BackendResponse } from 'src/modules/sm-base/shared/backend-response.model';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { SchoolClass } from '../../shared/school-class.model';

@Component({
  selector: 'app-class-register',
  templateUrl: './class-register.component.html',
  styleUrls: ['./class-register.component.scss']
})
export class ClassRegisterComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    isHalfYear: boolean;
    isManager: boolean;
    settings: ClassRegisterSettings;
    classRegister: ClassRegister;
    schoolClass: SchoolClass;

    constructor() {
        super();
        this.neededParams = { id: "number", isHalfYear: "bool?" };
    }

    async initParams(): Promise<boolean> {
        if (this.isHalfYear != null) {
            let id = await RestEndpoint.main().query({ schoolClassId: this.id, isHalfYear: this.isHalfYear, create: true }).run("api/cer/classregister/schoolclassid").getNumber();
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "class-register", id], true);
            return false;
        }
        this.classRegister = await this.service.restGetClassRegisterBasic(this.id);

        this.app.updateNavigation(null,
            { routerLink: ["/enaio-certificates", "home"], icon: 'fas fa-home' }, [
            { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates", "class-registers", "home"] },
            { label: "Klasse " + await this.service.restGetClassRegisterName(this.id), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.id] }
        ]);

        if (this.classRegister == null) {
            await this.app.loadDataHandler(() => {
                throw BackendResponse.error("Dieses Klassenbuch scheint nicht zu existieren");
            }, this);
            return true;
        }
        this.schoolClass = await this.service.restGetSchoolClass(this.classRegister.schoolClassId);
        this.settings = await this.service.restGetClassRegisterSettings(this.schoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.isManager = this.schoolClass.isManager(this.app.getUserName(), this.settings) || await this.service.isSecretary();

        return true;
    }

    async contractOut(): Promise<void> {
        await RestEndpoint.main().post().query({ id: this.id }).run("api/cer/classregister/contractOut").getText();
        this.classRegister = await this.service.restGetClassRegisterBasic(this.id, true);
        await this.initParams();
    }
}
