import { Component, Input } from '@angular/core';
import { Progressor } from 'src/modules/utils/shared/Progressor';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-progressor-overlay',
  templateUrl: './progressor-overlay.component.html',
  styleUrls: ['./progressor-overlay.component.scss']
})
export class ProgressorOverlayComponent {

    _Utils = Utils;

    @Input()
    progressor: Progressor;
    @Input()
    showCalculated = true;

}
