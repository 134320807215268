import { Injectable, OnInit } from '@angular/core';
import { ClassConstructor } from 'class-transformer';
import { MenuItem } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { LearningUserFlat } from '../shared/learning-user-flat.model';

@Injectable({
    providedIn: 'root'
})
export class EnaioLearnService {

    constructor(private app: MainAppService) {
    }

    async getMyProfile(): Promise<LearningUserFlat> {
        let result = await RestEndpoint.main().find().run("api/lrn/myprofile").get(LearningUserFlat);
        result.userName = this.app.getUserName();
        return result;
    }

    updateNavigation(isAuthor: boolean, breadcrumbsItems: MenuItem[]): void {
        let nav: MenuItem[] = [];
        if (isAuthor == null && breadcrumbsItems.length == 0) {
            nav.push({ label: this.app.t("lea.titleHome"), routerLink: ["/enaio-learn", "home"] });
        }
        else if (isAuthor) {
            nav.push({ label: this.app.t("lea.titleAuthor"), routerLink: ["/enaio-learn", "home", "1"] });
        }
        else if (isAuthor === false) {
            nav.push({ label: this.app.t("lea.titleStudent"), routerLink: ["/enaio-learn", "home", "0"] });
        }
        this.app.updateNavigation(null, { routerLink: ["/enaio-learn", "home"], icon: 'fas fa-home' }, [...nav, ...breadcrumbsItems]);
    }

    updateAdminNavigation(entityPath: string, item: any, listUrl: string, editUrl: string): void {
        this.updateNavigation(null, Utils.arrayWithoutNull([
            { label: this.app.t("lea.admin.title"), routerLink: ["/enaio-learn", "admin"]},
            { label: this.app.t(entityPath + ".typeNamePlural"), routerLink: ["/enaio-learn", "admin", listUrl] },
            item != null ? { label: this.app.tCrudTitle(item.id == 0, item, entityPath), routerLink: ["/enaio-learn", "admin", editUrl, item.id] } : null]));
    }

    async adminInitList<T>(type: ClassConstructor<any>, entityPath: string, listUrl: string, editUrl: string, restUrl: string, component: OnInit, tableColumns: TableColumn[], tableSortColumns: TableSortColumn[], tableRowConverter: (item: T) => TableRow): Promise<[T[], TableData]> {
        let listData = await this.app.loadDataHandler(async () => RestEndpoint.main().find().run(restUrl).list(type), component);
        let table = this.app.tTable(entityPath, new TableData(tableColumns, listData.map(item => tableRowConverter(item as T)), tableSortColumns));
        this.updateAdminNavigation(entityPath, null, listUrl, editUrl);
        return [listData, table];
    }

    async adminInitEdit<T>(type: ClassConstructor<any>, id: any, form: FrontendFormDefinition, entityPath: string, listUrl: string, editUrl: string, restUrl: string): Promise<T> {
        let item = await RestEndpoint.main().findByIdOrEmpty(type, Utils.toNumber(id)).run(restUrl).get(type);
        form.fill(item);
        this.updateAdminNavigation(entityPath, item, listUrl, editUrl);
        return item;
    }

    adminUpdate(form: FrontendFormDefinition, type: ClassConstructor<any>, item: any, restUrl: string): void {
        if (!this.app.validateForm(form)) {
            return;
        }

        form.get(item);
        RestEndpoint.main().upsert(item).run(restUrl);
        this.app.updateSuccess();
    }
}
