import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { GeneratedTemplate } from 'src/modules/enaio/shared/GeneratedTemplate';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldAddon } from 'src/modules/sm-base/shared/frontend-field-addon.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldGuiHint } from 'src/modules/sm-base/shared/frontend-field-gui-hint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { TemplateFieldDataType } from '../../shared/template-field-datatype.enum';
import { TemplateField } from '../../shared/template-field.model';
import { Template } from '../../shared/template.model';

@Component({
    selector: 'app-enaio-fill-template',
    templateUrl: './enaio-fill-template.component.html',
    styleUrls: [
        './enaio-fill-template.component.scss'
    ]
})
export class EnaioFillTemplateComponent implements OnInit {

    Utils = Utils;

    mainTemplate: Template = null;
    template: Template = null;
    form: FrontendFormDefinition = null;
    generated: GeneratedTemplate = null;
    previewBlob: Blob = null;
    sourcesString: string;
    finished = false;

    debouncedPreview: any;

    lastDynamicQuery: OrdinaryObject = null;

    @ViewChild('pdfViewer') pdfViewer: any;

    constructor(public app: MainAppService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.debouncedPreview = Utils.debounce(async () => this.preview(), 3000);

        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.sourcesString = params.sources;
        this.mainTemplate = await this.app.loadDataHandler(async () => RestEndpoint.main().query({ id: params.id, sources: this.sourcesString }).run("api/tpl/template").get(Template), this);
        this.mainTemplate.recalculate();
        this.template = this.mainTemplate;
        this.form = this.templateToForm(this.template);
        await this.recalculateForm();
        await this.preview();

        this.app.updateNavigation("Dokument erzeugen - " + this.template.id, { url: "/enaio-templates/home", icon: 'fas fa-home' }, [
            { label: "Dokument erzeugen - " + this.template.id, routerLink: ["/enaio-templates/fill-template", this.template.id, this.sourcesString] }
        ]);
    }

    templateToForm(template: Template): FrontendFormDefinition {
        let fields: FrontendFieldDefinition[] = Utils.arraySortByMultiple(template.fields.filter(field => !field.internal), [tf => tf.prio, tf => tf.caption]).map(field => {
            let f = new FrontendFieldDefinition(field.getTemplateFieldName(), field.caption, FrontendFieldType.text);
            if (field.dataType === TemplateFieldDataType.date) {
                f.type = FrontendFieldType.datePicker;
            }
            else if (field.dataType == TemplateFieldDataType.text && field.multiLine) {
                f.type = FrontendFieldType.textArea;
            }
            if (!Utils.isNoe(field.possibilities)) {
                f.type = FrontendFieldType.comboBox;
                f.dropdownEditable = field.dropdownEditable;
                f.dropdownWordWrap = true;
                f.addOnButtons = field.multiLine && field.dropdownEditable ? [new FrontendFieldAddon("edit", null, "fas fa-pen", true)] : null;
            }
            f.enabled = !field.isCalculated();
            f.tooltip = Utils.isNoe(field.toolTip) ? "" : field.toolTip; // + "Name im Template: " + field.getTemplateFieldName();

            f.value = field.formatValueForForm();
            f.guiGroup = field.guiGroup;
            f.guiCols = field.guiCols;

            f.onValueChanged = async (): Promise<any> => {
                await this.recalculateForm();
                this.debouncedPreview();
            };

            f.onAddOnClick = async item => this.editMultiLineText(f, item);

            return f;
        });

        if (!Utils.isNoe(this.mainTemplate.consistsOf)) {
            fields = [new FrontendFieldDefinition("templateName", "Vorlage", FrontendFieldType.comboBox, {
                listItems: [new FrontendFieldListItem("", ""), ...this.mainTemplate.consistsOf.map(co => new FrontendFieldListItem(co.id, co.title))],
                dropdownEditable: false,
                onValueChanged: this.templateChanged.bind(this),
                value: this.template != null ? this.template.selectedTemplate ?? (this.template != this.mainTemplate ? this.template.id : null) : null
            }), ...fields];
        }

        return new FrontendFormDefinition(fields);
    }

    async templateChanged(): Promise<void> {
        this.template = await RestEndpoint.main().query({ id: this.form.getValue("templateName"), sources: this.sourcesString }).run("api/tpl/template").get(Template);
        this.form = this.templateToForm(this.template);
        await this.recalculateForm();
        await this.preview();
    }

    async editMultiLineText(f: FrontendFieldDefinition, item: FrontendFieldAddon): Promise<void> {
        if (item.id == "edit") {
            let text = await this.app.messageDialog.input(new FrontendFieldDefinition("text", "Text", FrontendFieldType.textArea, { value: f.value}), "Text editieren");
            if (text == null) {
                return;
            }
            f.value = text;
            await this.recalculateForm();
            this.debouncedPreview();
        }
    }

    formToTemplate(form: FrontendFormDefinition, template: Template): Template {
        let tn = form.getField("templateName", false);
        if (tn != null) {
            template.selectedTemplate = tn.getValue();
        }
        for (let field of form.fields) {
            let templateField = template.fields.find(f => f.getTemplateFieldName() === field.id);
            if (templateField != null) {
                templateField.value = field.getValue();
            }
        }
        return template;
    }

    async updateDynamic(): Promise<void> {
        let dynamicQuery: OrdinaryObject = {};
        for (let field of this.template.fields) {
            if (field.dynamicRest) {
                dynamicQuery[field.caption] = field.value;
            }
        }
        if (Utils.getOwnPropertyNames(dynamicQuery).length > 0 && (this.lastDynamicQuery == null || !Utils.equalsDeep(this.lastDynamicQuery, dynamicQuery))) {
            let fields = await RestEndpoint.main().body(dynamicQuery).post().run("api/tpl/template/dynamic").list(TemplateField);
            for (let field of fields) {
                let of = this.template.fields.find(f => f.getTemplateFieldName() == field.getTemplateFieldName());
                if (of != null) {
                    of.value = field.value;
                }
                else {
                    this.template.fields.push(field);
                }
            }
            this.lastDynamicQuery = dynamicQuery;
        }
    }

    async recalculateForm(): Promise<void> {
        this.formToTemplate(this.form, this.template).recalculate();
        await this.updateDynamic();
        let data = this.template.toDataObject(true);
        for (let field of this.template.fields) {
            let f = this.form.getField(field.getTemplateFieldName(), false);
            if (f != null) {
                f.value = field.formatValueForForm();
                field.recalculate(data);
                if (f != null) {
                    let nli = Utils.arraySort(field.possibilities.filter(p => p.visible).map(p => {
                        let value = Utils.getTemplateFunction(p.caption)(data);
                        return new FrontendFieldListItem(value, Utils.escapeHtml(value));
                    }));
                    if (!Utils.equalsDeep(nli, f.listItems)) {
                        f.listItems = nli;
                        let v = f.value;
                        GuiUtils.angularTimer(() => {
                            f.value = v;
                        });
                    }
                    let mainF = this.form.getMainField(f);
                    mainF.guiHints = [];
                    if (f.listItems.length > 0 && f.value != "" && f.listItems.find(li => li.value == f.value) == null) {
                        mainF.guiHints.push(new FrontendFieldGuiHint("*", "fa fa-asterisk", "red", "Benutzerdefinierter Text"));
                    }
                }
            }
        }
    }

    async preview(): Promise<void> {
        if (!Utils.isNoe(this.template.consistsOf) && this.template.selectedTemplate == null) {
            return;
        }
        this.formToTemplate(this.form, this.template);
        this.generated = await RestEndpoint.main().post().body(this.template).run("api/tpl/template/preview").get(GeneratedTemplate);
        this.previewBlob = new Blob([GuiUtils.base64ToArrayBuffer(this.generated.fileBase64)], { type: 'application/pdf' });
        if (this.pdfViewer != null) {
            this.pdfViewer.pdfSrc = this.previewBlob;
            this.pdfViewer.refresh();
        }
    }

    async save(): Promise<void> {
        this.formToTemplate(this.form, this.template);
        this.template.id = this.mainTemplate.id;
        await RestEndpoint.main().post().body(this.template).query({sources: this.sourcesString}).run("api/tpl/template/save").getText();
        await this.app.messageDialog.info("Der aktuelle Stand des Dokuments wurde gespeichert");
    }

    async saveAsWordDocument(): Promise<void> {
        this.formToTemplate(this.form, this.template);
        this.generated = await RestEndpoint.main().post().body(this.template).query({word: true}).run("api/tpl/template/preview").get(GeneratedTemplate);

        GuiUtils.saveFileAs(new Blob([GuiUtils.base64ToArrayBuffer(this.generated.fileBase64)], { type: 'application/docx' }), this.template.id + ".docx");
    }

    async openPdf(): Promise<void> {
        this.formToTemplate(this.form, this.template);
        this.generated = await RestEndpoint.main().post().body(this.template).run("api/tpl/template/preview").get(GeneratedTemplate);

        let fileURL = window.URL.createObjectURL(new Blob([GuiUtils.base64ToArrayBuffer(this.generated.fileBase64)], { type: 'application/pdf' }));
        let tab = window.open();
        tab.location.href = fileURL;
    }

    async finish(): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Nach dem Ablegen in der Personalakte kann das Dokument nicht mehr verändert werden. Möchten Sie fortfahren?", "Warnung")) {
            return;
        }

        this.formToTemplate(this.form, this.template);
        await RestEndpoint.main().post().body(this.template).query({sources: this.sourcesString}).run("api/tpl/template/finish").getText();
        await this.app.messageDialog.info("Das Dokument wurde in enaio abgelegt");
        this.finished = true;
    }

    async reset(): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Alle getätigten Eingaben werden verworfen und alle Feldinhalte werden auf die Ausgangswerte zurückgesetzt. Möchten Sie fortfahren?", "Warnung")) {
            return;
        }
        await RestEndpoint.main().post().body(this.template).query({sources: this.sourcesString}).run("api/tpl/template/reset").getText();
        await this.app.messageDialog.info("Der aktuelle Stand des Dokuments wurde gelöscht");
        await this.init({id: this.template.id, sources: this.sourcesString});
    }


    async editFields(): Promise<void> {
        await this.app.navigateTo(["/enaio-templates", "edit-template-fields", this.template.id]);
    }
}

//http://localhost:4200/enaio-templates/fill-template/Dienstzeugnis/document_592935
//http://localhost:4200/enaio-templates/fill-template/Vakanz/workflow_A47F654166534CA8B12B6719A400234A
