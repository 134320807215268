import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioCertificateService } from 'src/modules/enaio-certificates/services/enaio-certificate.service';
import { EnaioCertificatesTools } from 'src/modules/enaio-certificates/shared/enaio-certificates-tools';
import { SchoolClass } from 'src/modules/enaio-certificates/shared/school-class.model';
import { SchoolType } from 'src/modules/enaio-certificates/shared/school-type.enum';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'app-grade-book-main',
    templateUrl: './grade-book-main.component.html'
})
export class GradeBookMainComponent implements OnInit {

    _SchoolType = SchoolType;

    schoolClasses: SchoolClass[] = [];
    year: number;
    years: number[];
    isHalfYear: boolean;
    userName = "";
    isAdmin = false;

    constructor(private app: MainAppService, public service: EnaioCertificateService, public route: ActivatedRoute) {
        this.isHalfYear = EnaioCertificatesTools.getCurrentIsHalfYear();
        this.years = [2023, 2022, 2021]; //TODO;
    }

    async ngOnInit(): Promise<void> {
        this.isAdmin = await this.service.isAdminForCertificates();
        this.userName = this.app.getUserName();
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.year = Utils.toNumber(params.year ?? EnaioCertificatesTools.getCurrentYear());
        this.app.updateNavigation("Notenbuch", this.service.getHome(), [this.service.getHomeGradeBook()]);
        this.schoolClasses = (await this.service.restGetSchoolClasses(EnaioCertificatesTools.getSchoolYearFromYear(this.year))).filter(sc => sc.isForGradeBook());
    }

    getExistingGrades(): number[] {
        return Utils.arrayGetUnique(this.schoolClasses.map(sc => sc.grade));
    }

    async adminClick(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "globalsettings"]);
    }

    async selectedYearChanged(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "grade-book", "home", this.year]);
    }

}
