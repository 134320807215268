import { Component, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { ErrorLogEntry } from 'src/modules/enaio/shared/ErrorLogEntry';
import { ProfilingEntry } from 'src/modules/enaio/shared/ProfilingEntry';
import { ProfilingSet } from 'src/modules/enaio/shared/ProfilingSet';
import { WorkObjectAnalysesOptions } from 'src/modules/enaio/shared/WorkObjectAnalysesOptions';
import { BackendResponse } from 'src/modules/sm-base/shared/backend-response.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/shared/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { HandlerConfigState } from '../../models/handler-config-state.model';
import { ServerApiCallerComponent } from '../server-api-caller/server-api-caller.component';
import { DocumentHandlerEvalConsoleComponent } from '../document-handler-eval-console/document-handler-eval-console.component';

@Component({
  selector: 'app-document-handler-instance',
  templateUrl: './document-handler-instance.component.html',
  styleUrls: ['./document-handler-instance.component.scss']
})
export class DocumentHandlerInstanceComponent extends ComponentView {

    _Utils = Utils;

    @Input()
    guid = "";

    form: FrontendFormDefinition;
    formWorkObjectsAnalyses: FrontendFormDefinition;
    contextMenu: MenuItem[] = [];
    tabIndex = 0;
    state: HandlerConfigState = new HandlerConfigState();
    errors: ErrorLogEntry[] = [];
    errorTable: TableData;
    actionCountTable: TableData;
    workObjectsAnalyses: OrdinaryObject[];
    workObjectsAnalysesTable: TableData;
    stackTrace: string;
    activeIndexProfiling = 0;
    workerProfilingRefresh = true;
    workerProfilingGroupObject = false;
    workerProfilingGroupWorker = false;
    enaioCallsProfilingRefresh = true;
    enaioCallsProfilingGroupParams = false;

    templateProfiling: ProfilingSet;
    enaioCallsProfiling: ProfilingSet;
    workerProfiling: ProfilingEntry[];
    tableTemplateProfiling: TableData;
    tableWorkerProfiling: TableData;
    tableEnaioCallsProfiling: TableData;
    debugTemplateCallStacks: OrdinaryObject<string> = {};
    requestedTemplateCallStacks: string[] = [];
    actionItems: MenuItem[];

    dev = false;

    @ViewChild("viewEnaioServerApiCaller") viewEnaioServerApiCaller: ServerApiCallerComponent;
    @ViewChild("viewEvalConsole") viewEvalConsole: DocumentHandlerEvalConsoleComponent;

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("active", "Status aktualisieren aktiv", FrontendFieldType.checkBox, { value: true}),
            new FrontendFieldDefinition("singleStep", "Single Step", FrontendFieldType.checkBox, { onValueChanged: this.singleStepChanged.bind(this) }),
            new FrontendFieldDefinition("singleStepAfterEveryObject", "Single Step nach jedem Objekt", FrontendFieldType.checkBox, { onValueChanged: this.singleStepAfterEveryObjectChanged.bind(this) }),
            new FrontendFieldDefinition("loggingLevelVerbose", "LogLevel: Verbose", FrontendFieldType.checkBox, { onValueChanged: async () => this.loggingLevelChanged("verbose") }),
            new FrontendFieldDefinition("loggingLevelNormal", "LogLevel: Normal", FrontendFieldType.checkBox, { onValueChanged: async () => this.loggingLevelChanged("normal") }),
            new FrontendFieldDefinition("loggingLevelSparse", "LogLevel: Sparse", FrontendFieldType.checkBox, { onValueChanged: async () => this.loggingLevelChanged("sparse") })
        ]);

        this.formWorkObjectsAnalyses = new FrontendFormDefinition([
            new FrontendFieldDefinition("grouper", "Gruppieren nach", FrontendFieldType.text, { value: "" }),
            new FrontendFieldDefinition("findDuplicates", "Duplikate finden", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("filter", "Filterbedingung", FrontendFieldType.textArea, { value: "", fixedHeight: "100px" }),
            new FrontendFieldDefinition("selectFields", "Felder im Ergebnis (ein Feld pro Zeile)", FrontendFieldType.textArea, { value: "", fixedHeight: "100px" }),
            new FrontendFieldDefinition("labelOr", "---ODER---", FrontendFieldType.text, { hasControl: false}),
            new FrontendFieldDefinition("collectValues", "Werte sammeln (Scriban-Skript, das einen Array liefert)", FrontendFieldType.textArea, { value: "", fixedHeight: "100px" })
        ]);

        return Promise.resolve(true);
    }

    async updateActionItems(): Promise<void> {
        let isRemoteProfiling = await DhTools.backendCall("api/dhinstance/isRemoteProfiling", { instanceGuid: this.guid}).getBool();
        this.actionItems = Utils.arrayWithoutNull([
            {
                label: "Temporäres Verzeichnis öffnen",
                command: this.openTempDir.bind(this)
            },
            {
                label: "Heap-Dump erstellen",
                command: this.createHeapDump.bind(this)
            },
            isRemoteProfiling ? null : {
                label: "Remote-Profiling starten",
                command: this.startRemoteProfiling.bind(this)
            },
            !isRemoteProfiling ? null : {
                label: "Remote-Profiling stoppen und Ausgabedatei erzeugen",
                command: this.stopRemoteProfiling.bind(this)
            }
        ]);
    }

    async onTimer(): Promise<boolean> {
        switch (this.tabIndex) {
            case 0:
                if (this.form.getValue("active")) {
                    try {
                        this.state = await DhTools.backendCall("api/dhinstance/state", { instanceGuid: this.guid }).get(HandlerConfigState);
                        this.form.setValue("loggingLevelVerbose", this.state.verbose);
                        this.form.setValue("loggingLevelNormal", !this.state.verbose && !this.state.sparse);
                        this.form.setValue("loggingLevelSparse", !this.state.verbose && this.state.sparse);
                        this.form.setValue("singleStep", this.state.singleStep);
                        this.form.setValue("singleStepAfterEveryObject", this.state.singleStepAfterEveryObject);
                        if (this.state.errorCount > this.errors.length) {
                            this.errors = [...this.errors, ...await DhTools.backendCall("api/dhinstance/getErrors", { instanceGuid: this.guid, fromIndex: this.errors.length }).list(ErrorLogEntry)];
                            this.updateErrorTable();
                        }
                        this.actionCountTable = new TableData([
                            new TableColumn("type", "Typ"),
                            new TableColumn("count", "Anzahl")
                        ], Utils.getOwnPropertyNames(this.state.actionCount).map(type => new TableRow(type, {
                            type,
                            count: this.state.actionCount[type]
                        })));
                    }
                    catch (ex) {
                        let error = BackendResponse.getFromException(ex);
                        if (error.isErrorId(1000) || error.isErrorId(1001)) {
                            if (this.state == null) {
                                this.state = new HandlerConfigState();
                            }
                            this.state.finished = true;
                            this.form.setValue("active", false);
                            return false;
                        }
                    }
                }
                break;
            case 3:
                switch (this.activeIndexProfiling) {
                    case 0:
                        this.templateProfiling = await DhTools.backendCall("api/dhinstance/templateProfiling", { instanceGuid: this.guid }).get(ProfilingSet);
                        this.debugTemplateCallStacks = this.requestedTemplateCallStacks.length == 0 ? {} :
                            Utils.fromJson(await DhTools.backendCall("api/dhinstance/templateCallStacks", { instanceGuid: this.guid }).getText());
                        this.tableTemplateProfiling = new TableData([
                            new TableColumn("template", "Template"),
                            new TableColumn("count", "Anzahl Aufrufe", TableCellType.number),
                            new TableColumn("duration", "Dauer", TableCellType.duration),
                            new TableColumn("durationPerCall", "Dauer/Aufruf", TableCellType.duration),
                            new TableColumn("requestCallStack", "Call Stack holen", TableCellType.yesNo, { editable: true}),
                            new TableColumn("callStack", "CallStack", TableCellType.button)
                        ], Utils.getOwnPropertyNames(this.templateProfiling.data).map(template => new TableRow(template, {
                            template,
                            count: this.templateProfiling.data[template].count,
                            duration: this.templateProfiling.data[template].nanos / 1000_000,
                            durationPerCall: this.templateProfiling.data[template].nanos / 1000_000 / this.templateProfiling.data[template].count,
                            requestCallStack: this.requestedTemplateCallStacks.includes(template),
                            callStack: Utils.getSafe(this.debugTemplateCallStacks, template, "") != "" ? "fas fa-eye" : null
                        }), [new TableSortColumn("duration", false)]));
                        break;
                    case 1:
                        if (this.workerProfilingRefresh) {
                            this.workerProfiling = await DhTools.backendCall("api/dhinstance/workerProfiling", { instanceGuid: this.guid }).list(ProfilingEntry);
                            this.fillWorkerProfilingTable();
                        }
                        break;
                    case 2:
                        if (this.enaioCallsProfilingRefresh) {
                            await this.fillEnaioCallsProfilingTable();
                        }
                        break;
                    }
                break;
            case 4:
                this.stackTrace = await DhTools.backendCall("api/dhinstance/getStackTrace", { instanceGuid: this.guid }).getString();
                break;
        }
        return true;
    }

    fillWorkerProfilingTable(): void {
        let entries: ProfilingEntry[] = this.workerProfiling;
        if (this.workerProfilingGroupObject || this.workerProfilingGroupWorker) {
            entries = [];
            for (let p of this.workerProfiling) {
                let o = this.workerProfilingGroupWorker ? "<Alle>" : p.objectId;
                let w = this.workerProfilingGroupObject ? "<Alle>" : p.workerName;
                let e = entries.find(e2 => e2.objectId == o && e2.workerName == w);
                if (e == null) {
                    e = Utils.fromPlain(ProfilingEntry, {
                        objectId: o,
                        workerName: w,
                        nanos: 0
                    });
                    entries.push(e);
                }
                e.nanos += p.nanos;
            }
        }

        this.tableWorkerProfiling = new TableData([
            new TableColumn("objectId", "Objekt"),
            new TableColumn("workerName", "Worker"),
            new TableColumn("detail", "Detail"),
            new TableColumn("duration", "Dauer", TableCellType.duration)
        ], entries.map(p => new TableRow(p, {
            objectId: p.objectId,
            workerName: p.workerName,
            detail: p.detail,
            duration: p.nanos / 1_000 / 1_000
        }), [new TableSortColumn("duration", false)]));
    }

    async fillEnaioCallsProfilingTable(): Promise<void> {
        this.enaioCallsProfiling = await DhTools.backendCall("api/dhinstance/enaioCallsProfiling", { instanceGuid: this.guid, groupParams: this.enaioCallsProfilingGroupParams }).get(ProfilingSet);

        this.tableEnaioCallsProfiling = new TableData([
            new TableColumn("count", "Aufrufe", TableCellType.number, { width: "80px" }),
            new TableColumn("duration", "Dauer", TableCellType.duration, { width: "120px" }),
            new TableColumn("durationPerCall", "Dauer/Aufruf", TableCellType.duration, { width: "120px" }),
            new TableColumn("call", "Aufruf", TableCellType.text, { })
        ], Utils.getOwnPropertyNames(this.enaioCallsProfiling.data).map(call => new TableRow(call, {
            call: Utils.stringRemovePrefix(call, "Enaio Server API Aufruf\n"),
            count: this.enaioCallsProfiling.data[call].count,
            duration: this.enaioCallsProfiling.data[call].nanos / 1000_000,
            durationPerCall: this.enaioCallsProfiling.data[call].nanos / 1000_000 / this.enaioCallsProfiling.data[call].count
        }), [new TableSortColumn("duration", false)]));
    }

    async editTemplateProfilingCell(event: any): Promise<void> {
        if (event[1] == "requestCallStack") {
            let template = (event[0] as TableRow).raw as string;
            let request = (event[0] as TableRow).values.requestCallStack as boolean;
            this.requestedTemplateCallStacks = Utils.arraySetContained(this.requestedTemplateCallStacks, template, request);
            await DhTools.backendCall("api/dhinstance/templateRequestCallStack", {instanceGuid: this.guid, template, request}).getString();
        }
    }

    clickTemplateProfilingCell(event: any): void {
        if (event[1] == "callStack") {
            this.app.messageDialog.textArea("Call Stack", this.debugTemplateCallStacks[(event[0] as TableRow).raw as string], null, true);
        }
    }

    async activeTabChanged(): Promise<void> {
        if (this.tabIndex == 5) {
            await this.viewEnaioServerApiCaller.initEmbedded();
        }
    }

    async loggingLevelChanged(type: string): Promise<void> {
        this.form.setValue("loggingLevelVerbose", type == "verbose");
        this.form.setValue("loggingLevelNormal", type == "normal");
        this.form.setValue("loggingLevelSparse", type == "sparse");
        await DhTools.backendCall("api/dhinstance/setGlobal", { instanceGuid: this.guid, sparse: type == "sparse", verbose: type == "verbose"}).getText();
    }

    async singleStepChanged(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/setGlobal", { instanceGuid: this.guid, singleStep: this.form.getValue("singleStep")}).getText();
    }

    async singleStepAfterEveryObjectChanged(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/setGlobal", { instanceGuid: this.guid, singleStepAfterEveryObject: this.form.getValue("singleStepAfterEveryObject")}).getText();
    }

    async break(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/setGlobal", { instanceGuid: this.guid, breakpointRequested: true}).getText();
    }

    async continue(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/setGlobal", { instanceGuid: this.guid, continueRequested: true}).getText();
    }

    async openTempDir(): Promise<void> {
        let tempPath = await DhTools.backendCall("api/dhinstance/getTempPath", { instanceGuid: this.guid}).getText();
        await DhTools.backendCall("api/system/openFile", { fileName: tempPath }).getText();
    }

    async createHeapDump(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/dumpHeap", { instanceGuid: this.guid}).getText();
        this.app.showToast("info", "Info", "Heap Dump wurde erstellt");
    }

    async startRemoteProfiling(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/startRemoteProfiling", { instanceGuid: this.guid}).getText();
        this.app.showToast("info", "Info", "Remote-Profiling gestartet");
    }

    async stopRemoteProfiling(): Promise<void> {
        await DhTools.backendCall("api/dhinstance/stopRemoteProfiling", { instanceGuid: this.guid}).getText();
        this.app.showToast("info", "Info", "Remote-Profiling gestoppt und Ausgabedatei erzeugt");
    }

    updateErrorTable(): void {
        this.errorTable = new TableData([
            new TableColumn("_tools", "", TableCellType.button, { width2: "2d" }),
            new TableColumn("date", "Datum", TableCellType.dateTime, { width2: "qc" }),
            new TableColumn("exceptionType", "Exception", TableCellType.text, { width2: "150px" }),
            new TableColumn("errorMessage", "Fehlermeldung", TableCellType.text, { width2: "ra" }),
            new TableColumn("workerName", "Worker", TableCellType.text, { width2: "150px" }),
            new TableColumn("objectName", "Objekt", TableCellType.text, { width2: "300px" }),
            new TableColumn("objectIndex", "Objektindex", TableCellType.number, { width2: "6d" })
        ], this.errors.map(error => new TableRow(error, {
            _tools: Utils.arrayItemsToString(["fas fa-eye", "fas fa-code"], ","),
            date: error.date,
            exceptionType: error.exceptionType,
            errorMessage: new TableCell(Utils.replaceAll(error.errorMessage, "\n", "<br>"), { styleClass: "monospace"}),
            workerName: error.workerName,
            objectName: error.objectName,
            objectIndex: error.objectIndex
        })), [new TableSortColumn("date")]);
    }

    async analyseWorkObjects(): Promise<void> {
        let collectValues = (this.formWorkObjectsAnalyses.getValue("collectValues") as string)?.trim();
        let options = Utils.fromPlain(WorkObjectAnalysesOptions, {
            grouper: this.formWorkObjectsAnalyses.getValue("grouper"),
            findDuplicates: this.formWorkObjectsAnalyses.getValue("findDuplicates"),
            filter: this.formWorkObjectsAnalyses.getValue("filter"),
            selectFields: Utils.splitLines(this.formWorkObjectsAnalyses.getValue("selectFields") as string).filter(f => f != ""),
            collectValues
        });
        this.workObjectsAnalyses = await DhTools.backendCall("api/dhinstance/analyseWorkObjects", {instanceGuid: this.guid, options: Utils.toJson(options)}).listObjects();

        if (!Utils.isNoe(collectValues)) {
            this.workObjectsAnalysesTable = new TableData(Utils.arrayWithoutNull([
                new TableColumn("value", "Wert"),
                new TableColumn("count", "Anzahl")
            ]), this.workObjectsAnalyses.map(wo => new TableRow(wo, wo)), [new TableSortColumn("count")]);
        }
        else {
            this.workObjectsAnalysesTable = new TableData(Utils.arrayWithoutNull([
                new TableColumn("index", "Index", TableCellType.number),
                new TableColumn("id", "ID"),
                !Utils.isNoe(options.grouper) ? new TableColumn("itemCount", "Anzahl Objekte", TableCellType.number) : null,
                ...options.selectFields.map(f => new TableColumn(f, f))
            ]), this.workObjectsAnalyses.map(wo => new TableRow(wo, wo)), [new TableSortColumn("index")]);
        }
    }

    async workObjectsToCsv(unique: boolean): Promise<void> {
        let fileName = await DhTools.saveDialog("workObjects.csv");

        await DhTools.backendCall("api/dhinstance/workObjectsToCsv", { instanceGuid: this.guid, fileName, unique}).getText();
    }

    async errorCellClicked(event): Promise<void> {
        let e = event[0].raw as ErrorLogEntry;
        if (event[1] == "_tools") {
            if (event[2] == "fas fa-eye") {
                this.app.messageDialog.textArea("Fehler", e.raw, null, true);
            }
            else if (event[2] == "fas fa-code") {
                this.viewEvalConsole.objectIndex = Utils.toString(e.objectIndex);
                await this.viewEvalConsole.evaluateAll();
                this.tabIndex = 2;
            }
        }
    }

}
