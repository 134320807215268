import { Component, ViewChild } from '@angular/core';
import { Tree } from 'primeng/tree';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { ScribanFunctionDescription } from 'src/modules/enaio/shared/ScribanFunctionDescription';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { TreeSelectionHelper } from 'src/modules/utils/misc/tree-selection-helper';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { ConfigClassDefinition } from '../../../sm-base/shared/config-class-definition.model';
import { DhTools } from '../../models/dh-tools.model';
import { ConfigDefinition } from 'src/modules/sm-base/shared/config-definition.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/shared/config-field-definition.model';

@Component({
  selector: 'app-document-handler-help',
  templateUrl: './document-handler-help.component.html',
  styleUrls: ['./document-handler-help.component.scss']
})
export class DocumentHandlerHelpComponent extends ComponentView {

    _Utils = Utils;

    def: ConfigDefinition;
    helpTree: TreeSelectionHelper;
    selClass: ConfigClassDefinition;
    selClassHistory: ConfigClassDefinition[] = [];
    classesByCat: OrdinaryObject<ConfigClassDefinition[]> = {};
    fieldsTable: TableData;
    functions: ScribanFunctionDescription[];
    functionsTable: TableData;

    helpCategories = [
        {
            name: "ImportSource",
            baseClass: "DocumentHandler.BaseImportSource",
            tos: (s: string) => s
        },
        {
            name: "Worker",
            baseClass: "DocumentHandler.WorkerClassConfig",
            tos: (s: string) => s.replace("+Config", "")
        },
        {
            name: "Rest",
            baseClass: "*",
            tos: (s: string) => s
        }
    ];

    @ViewChild("tree") tree: Tree;

    async initParams(): Promise<boolean> {
        if (this.def == null) {
            this.def = await DhTools.backendCall("api/config/definition").get(ConfigDefinition);
            this.def.init();
            this.classesByCat = Utils.arrayToMultiMapKeys(this.def.classes, c => Utils.arrayFindAndConvert(this.helpCategories, hc => c.isDerivedFrom(hc.baseClass, this.def), hc => hc.name, "Rest"));
            for (let cat of Utils.getOwnPropertyNames(this.classesByCat)) {
                this.classesByCat[cat] = Utils.arraySortBy(this.classesByCat[cat], c => c.getSimpleName());
            }
            this.helpTree = new TreeSelectionHelper(this.tree);
            let items: TreeNode2[] = this.helpCategories.map(c => ({ label: c.name, data: "c" + c.name, children: [] }));
            for (let clazz of this.def.classes) {
                let cat = this.helpCategories.find(hc => clazz.isDerivedFrom(hc.baseClass, this.def));
                let p = items.find(it => it.label == (cat != null ? cat.name : "Rest"));
                if (p != null) {
                    p.children.push({label: cat != null ? cat.tos(clazz.getSimpleName()) : clazz.getSimpleName(), data: clazz});
                }
            }
            for (let c of items) {
                c.children = Utils.arraySortBy(c.children, i => i.label);
            }

            this.helpTree.setItems(items, true);

            this.functions = await DhTools.backendCall("api/config/getTemplateFunctions").list(ScribanFunctionDescription);
            this.functionsTable = new TableData([
                new TableColumn("name", "Funktion"),
                new TableColumn("paramString", "Parameter")
            ], this.functions.map(f => new TableRow(f, {
                name: f.name,
                paramString: f.ParamString
            })), [new TableSortColumn("name")]);
        }
        return true;
    }

    selectClass(node?: any, selClass?: ConfigClassDefinition): void {
        if (selClass != null) {
            this.selClass = selClass;
        }
        if (node != null) {
            this.selClass = Utils.castOrNull(node.node.data, ConfigClassDefinition);
        }
        if (this.selClass != null) {
            this.selClassHistory = Utils.arraySubList([this.selClass, ...Utils.arrayWithout(this.selClassHistory, this.selClass)], 0, 4);
        }
        this.fieldsTable = new TableData([
            new TableColumn("name", "Eigenschaft"),
            new TableColumn("type", "Typ", TableCellType.link),
            new TableColumn("defaultValue", "Default"),
            new TableColumn("helpText", "Beschreibung")
        ], this.selClass.fields.map(f => new TableRow(f, {
            name: f.name,
            type: f.getSimpleTypeName() + "[]".repeat(f.arrayDimensions),
            defaultValue: f.defaultValue,
            helpText: f.helpText
        }), [new TableSortColumn("name")]));
    }

    fieldCellClicked(cell: any): void {
        if (cell[1] == "type") {
            let typeName = (cell[0].raw as ConfigFieldDefinition).typeName;
            let c = this.def.getByName(typeName);
            if (c != null) {
                this.selClass = c;
                this.selectClass();
            }
        }
    }

}
