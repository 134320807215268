import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioDocumentFrontendResult } from '../../shared/enaio-document-frontend-result.model';

@Component({
    selector: 'app-enaio-documents-main',
    templateUrl: './enaio-documents-main.component.html',
    styleUrls: []
})
export class EnaioDocumentsMainComponent extends ComponentView {

    definitions: EnaioDocumentFrontendResult[] = [];

    async initParams(): Promise<boolean> {
        this.app.updateNavigation("Dokumente", { routerLink: ["/enaio-documents/home"], icon: 'fas fa-home' }, [
            { label: "Dokumente", routerLink: ["/enaio-documents/home"] }
        ]);
        this.definitions = Utils.arraySortBy(await RestEndpoint.main().query({ includeResultCounts: true }).run("api/doc/def").list(EnaioDocumentFrontendResult), def => def.name);
        if (this.definitions.length > 0 && this.definitions.find(def => !def.anonymousOnly) == null) {
            await this.app.navigateTo(["/enaio-documents", "as", this.definitions[0].defId]);
        }
        return true;
    }

    async click(def): Promise<void> {
        await this.app.navigateTo(["/enaio-documents", "definition", def.defId]);
    }

}
