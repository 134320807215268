import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassFieldTrip } from '../../shared/class-field-trip.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';


@Component({
    selector: 'app-class-field-trips',
    templateUrl: './class-field-trips.component.html',
    styleUrls: ['./class-field-trips.component.scss']
  })
  export class ClassFieldTripsComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    orgData: ClassFieldTrip[];
    table: TableData;
    isManager = false;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["fieldTrips"], null, true);
        let settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.isManager = this.classRegister.enaioSchoolClass.isManager(this.app.getUserName(), settings);

        this.orgData = Utils.cloneDeep(this.classRegister.fieldTrips);

        this.table = new TableData([
            new TableColumn("fromDate", "Von", TableCellType.date),
            new TableColumn("toDate", "Bis", TableCellType.date),
            new TableColumn("fromHour", "Von Stunde", TableCellType.text),
            new TableColumn("toHour", "Bis Stunde", TableCellType.text),
            new TableColumn("title", "Bezeichnung", TableCellType.text),
            new TableColumn("approved", "Freigabe", TableCellType.text),
            new TableColumn("edit", "", TableCellType.button, { width: "130px"})
        ], this.classRegister.fieldTrips.map(h => new TableRow(h,
              {
                ... h,
                fromHour: h.fromHour == -1 ? "Ganzer Tag" : h.fromHour + 1,
                toHour: h.toHour == -1 ? "Ganzer Tag" : h.toHour + 1,
                approved: h.approvedDate != null ? h.approvedBy + " " + Utils.dateFormatDefaultDate(h.approvedDate) : this.isManager ? "NEIN (Haken klicken für Freigabe)" : "NEIN",
                edit: (this.isManager && h.approvedDate == null ? "fas fa-check," : "") + "fas fa-pencil,fas fa-trash"
            }
        )), [new TableSortColumn("from")]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Unterrichtsgänge", routerLink: ["/enaio-certificates", "class-registers", "field-trips", this.id] }
        ]);
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.fromGui(), this.orgData);
    }

    fromGui(): ClassFieldTrip[] {
        return this.table.rows.map(h => h.raw as ClassFieldTrip);
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id }).body(this.fromGui()).post().run("api/cer/classregister/fieldTrips").getText(), this, true, true);
    }

    getForm(): FrontendFormDefinition {
        return new FrontendFormDefinition([
            new FrontendFieldDefinition("fromDate", "Von", FrontendFieldType.datePicker, {mandatory: true, value: new Date(), guiGroup: "fromDate", guiCols: 6}),
            new FrontendFieldDefinition("fromHour", "Stunde", FrontendFieldType.comboBox, {mandatory: true, value: -1, guiGroup: "fromDate", guiCols: 6, dropdownEditable: false, listItems: [new FrontendFieldListItem(-1, "Ganzer Tag"), ...Utils.getRange(0, EnaioCertificatesTools.getMaxHoursPerDay() - 1).map(hour => new FrontendFieldListItem(hour, "ab " + (hour + 1) + ". Stunde"))]}),
            new FrontendFieldDefinition("toDate", "Bis", FrontendFieldType.datePicker, {mandatory: true, value: new Date(), guiGroup: "toDate", guiCols: 6}),
            new FrontendFieldDefinition("toHour", "Stunde", FrontendFieldType.comboBox, {mandatory: true, value: -1, guiGroup: "toDate", guiCols: 6, dropdownEditable: false, listItems: [new FrontendFieldListItem(-1, "Ganzer Tag"), ...Utils.getRange(0, EnaioCertificatesTools.getMaxHoursPerDay() - 1).map(hour => new FrontendFieldListItem(hour, "bis " + (hour + 1) + ". Stunde"))]}),
            new FrontendFieldDefinition("title", "Bezeichnung", FrontendFieldType.text, { mandatory: true })
        ]);
    }

    async addFieldTrip(): Promise<void> {
        let fieldTrip = new ClassFieldTrip();
        let form = this.getForm();
        form.fill(fieldTrip);
        if (await this.app.messageDialog.form(form, "Unterrichtsgang anlegen") == "ok") {
            form.get(fieldTrip);
            let [ok, _] = await this.app.saveDataHandler(async () => RestEndpoint.main().post().query({id: this.classRegister.id}).body(fieldTrip).run("api/cer/classregister/fieldTrip").getText(), this, true, false);
            if (ok) {
                await this.initParams();
            }
        }
    }

    async fieldTripClicked(event: any): Promise<void> {
        if (event[2] as string == "fas fa-pencil") {
            let fieldTrip = (event[0] as TableRow).raw as ClassFieldTrip;
            let form = this.getForm();
            form.fill(fieldTrip);
            if (await this.app.messageDialog.form(form, "Unterrichtsgang bearbeiten") == "ok") {
                form.get(fieldTrip);
                let [ok, _] = await this.app.saveDataHandler(async () => RestEndpoint.main().put().query({id: this.classRegister.id}).body(fieldTrip).run("api/cer/classregister/fieldTrip").getText(), this, true, false);
                if (ok) {
                    await this.initParams();
                }
            }
        }
        else if (event[2] as string == "fas fa-trash" && await this.app.messageDialog.yesNo("Wirklich löschen?", "Warnung") && (await this.app.deleteDataHandler(async () => RestEndpoint.main().delete().query({id: (event[0].raw as ClassFieldTrip).id}).run("api/cer/classregister/fieldTrip").getText()))[0]) {
            await this.initParams();
        }
        else if (event[2] as string == "fas fa-check" && await this.app.messageDialog.yesNo("Möchten Sie diesen Unterrichtsgang freigeben?", "Frage")) {
            let fieldTrip = (event[0] as TableRow).raw as ClassFieldTrip;
            let [ok, _] = await this.app.saveDataHandler(async () => RestEndpoint.main().post().query({id: fieldTrip.id}).body(fieldTrip).run("api/cer/classregister/fieldTripApprove").getText(), this, true, false);
            if (ok) {
                await this.initParams();
            }
        }
    }
}
