import { Component, OnInit } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { SchoolClass } from '../../shared/school-class.model';
import { SchoolType } from '../../shared/school-type.enum';

@Component({
    selector: 'app-covid-tests-main',
    templateUrl: './covid-tests-main.component.html',
    styleUrls: ['./covid-tests-main.component.scss']
})
export class CovidTestsMainComponent implements OnInit {

    _SchoolType = SchoolType;

    schoolClasses: SchoolClass[] = [];
    year: number;
    isHalfYear: boolean;
    isClassRegisterEditor = false;
    isSecretary = false;

    constructor(private app: MainAppService, private service: EnaioCertificateService) {
        this.year = EnaioCertificatesTools.getCurrentYear();
        this.isHalfYear = EnaioCertificatesTools.getCurrentIsHalfYear();
    }

    async ngOnInit(): Promise<void> {
        this.isClassRegisterEditor = await this.service.isClassRegisterEditor();
        this.isSecretary = await this.service.isSecretary();
        this.schoolClasses = await this.service.restGetSchoolClasses(EnaioCertificatesTools.getCurrentSchoolYear());
        this.app.updateNavigation("Corona Testungen", this.service.getHome(), [this.service.getHomeCovidTests()]);
    }

    getExistingGrades(): number[] {
        return Utils.arrayGetUnique(this.schoolClasses.map(sc => sc.grade));
    }

    async analysesClick(): Promise<void> {
        await this.app.navigate(["/enaio-certificates", "covid-tests", "analyses"]);
    }


}
