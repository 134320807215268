import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';

@Component({
    selector: 'app-edit-external-user',
    templateUrl: './edit-external-user.component.html',
    styleUrls: ['./edit-external-user.component.scss']
})
export class EditExternalUserComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: LearningUserFlat;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = this.app.tForm("lea.user", new FrontendFormDefinition([
            new FrontendFieldDefinition("userName", null, FrontendFieldType.text),
            new FrontendFieldDefinition("firstName", null, FrontendFieldType.text),
            new FrontendFieldDefinition("lastName", null, FrontendFieldType.text),
            new FrontendFieldDefinition("email", null, FrontendFieldType.text),
            new FrontendFieldDefinition("joinedDate", null, FrontendFieldType.datePicker),
            new FrontendFieldDefinition("isAdmin", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("mayModifyItems", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("mayRunAnalyses", null, FrontendFieldType.checkBox)
        ]));

        this.item = await this.app.loadDataHandler(async () => RestEndpoint.main().findByIdOrEmpty(LearningUserFlat, this.id).run("api/lrn/learninguserflat").get(LearningUserFlat), this);

        this.form.fill(this.item);
        this.service.updateAdminNavigation("lea.externalUser", this.item, "external-users", "external-user");
        return true;
    }

    async save(): Promise<void> {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        this.item.source = "learnExternal";
        await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.item).run("api/lrn/learninguserflat").getText(), this, true, true);
    }
}
