import { Type } from "class-transformer";
import { EnaioOrganisationObject } from "./EnaioOrganisationObject";
import { EnaioUserGroup } from "./EnaioUserGroup";

export class EnaioUser {
    userName = "";
    guid = "";
    fullName = "";
    id = 0;
    accountType = 0;
    description = "";
    isServerOrAnonymous = false;
    changed = false;
    languageId = 0;
    locked = false;
    loginCount = 0;
    loginStation = "";
    @Type(() => Date)
    loginTime: Date;
    mail = "";
    userProfile = 0;
    serverId = 0;
    station = "";
    supervisor = false;
    @Type(() => Date)
    validFrom: Date;
    @Type(() => Date)
    validTo: Date;
    password = "";
    @Type(() => EnaioUserGroup)
    userGroups: EnaioUserGroup[];
    sysRoles: number[];
    @Type(() => EnaioOrganisationObject)
    wfUser: EnaioOrganisationObject;
}
