import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioCondition } from "./EnaioCondition";
import { EnaioProcess } from "./EnaioProcess";
import { EnaioProcessState } from "./EnaioProcessState";
import { EnaioUserIdentification } from "./EnaioUserIdentification";

export class EnaioCallGetProcessesEx extends EnaioCall <EnaioProcess[]> {
    organisationId: string;
    processId: string;
    processIds: string[];
    processName: string;
    workItemId: string;
    forObjectId = 0;
    @Type(() => EnaioUserIdentification)
    forUserInbox: EnaioUserIdentification;
    familyName: string;
    modelName: string;
    modelNameEqOrNewer = false;
    @Type(() => Date)
    startDateFrom: Date;
    @Type(() => Date)
    startDateTo: Date;
    @Type(() => Date)
    endDateFrom: Date;
    @Type(() => Date)
    endDateTo: Date;
    @Type(() => Date)
    actionDateFrom: Date;
    @Type(() => Date)
    actionDateTo: Date;
    onlyStates: EnaioProcessState[];
    onlyWithCurrentActivity: string[];
    includeRunningWorkflows = true;
    includeFinishedWorkflows = false;
    finishedWorkflowsMaxAge: string;
    getModelInformation = false;
    getVariables = false;
    getMaskFieldValues = false;
    getVariableNames: string[];
    getDocumentIds = false;
    getHistory = false;
    getHistoryVariables = false;
    getInboxUsers = false;
    getLastActivityDateDetailed = false;
    @Type(() => EnaioCondition)
    conditions: EnaioCondition[];
    useModelCache = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioProcess, 1);
    }

}
