import { ApplicationRef, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioAssignment } from 'src/modules/enaio/shared/EnaioAssignment';
import { EnaioCallCompleteWorkItem } from 'src/modules/enaio/shared/EnaioCallCompleteWorkItem';
import { EnaioCallInsert } from 'src/modules/enaio/shared/EnaioCallInsert';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioLocation } from 'src/modules/enaio/shared/EnaioLocation';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { EnaioObjectType } from 'src/modules/enaio/shared/EnaioObjectType';
import { EnaioObjectTypeId } from 'src/modules/enaio/shared/EnaioObjectTypeId';
import { EnaioProcess } from 'src/modules/enaio/shared/EnaioProcess';
import { EnaioUpsertResult } from 'src/modules/enaio/shared/EnaioUpsertResult';
import { EnaioUserIdentification } from 'src/modules/enaio/shared/EnaioUserIdentification';
import { EnaioWorkflowDefinition } from 'src/modules/enaio/shared/EnaioWorkflowDefinition';
import { EnaioWorkflowDocument } from 'src/modules/enaio/shared/EnaioWorkflowDocument';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { BrowserUploadedFile } from 'src/modules/sm-base/models/uploaded-file.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { ScriptService } from 'src/modules/sm-base/services/script-service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendEnaioMapper } from '../../../enaio/models/frontend-enaio-mapper.model';
import { EnaioWorkItemDto } from '../../models/enaio-work-item-dto.model';
import { FrontendWorkflowActivityDefinition } from '../../models/frontend-workflow-activity-definition.model';
import { FrontendWorkflowDefinition } from '../../models/frontend-workflow-definition.model';
import { WorkflowForm } from '../../models/workflow-form.model';

@Component({
    selector: 'enaio-work-item',
    templateUrl: './enaio-work-item.component.html',
    styleUrls: []
})
export class EnaioWorkItemComponent extends ComponentView {

    _Utils = Utils;

    processId = "";
    workItem: EnaioWorkItemDto;
    process: EnaioProcess;
    workflowModel: EnaioWorkflowDefinition;
    workflowDef: FrontendWorkflowDefinition;
    workflowActivityDef: FrontendWorkflowActivityDefinition;
    workflowForm: WorkflowForm = null;
    personellFile: EnaioDocument;
    selectedDocument: EnaioWorkflowDocument;
    useTray: boolean;
    documentsTable: TableData;

    constructor(private applicationRef: ApplicationRef, private scriptService: ScriptService, private messageService: MessageService, private fileUpload: FileUploadService) {
        super();
        this.neededParams = { processId: "string" };
    }

    async initParams(): Promise<boolean> {
        this.workItem = await RestEndpoint.main().query({ processId: this.processId }).run("api/wfl/workflow/workitem").get(EnaioWorkItemDto);
        this.personellFile = await RestEndpoint.main().run("api/wfl/tbr/getPersonellFile").get(EnaioDocument);
        this.process = await RestEndpoint.main().query({ processId: this.workItem.workItem.processId}).run("api/wfl/workflow/process").get(EnaioProcess);
        this.workflowModel = await RestEndpoint.main().query({ familyId: this.process.model.familyId, modelId: this.process.model.id }).run("api/wfl/workflow/workflowDefinition").get(EnaioWorkflowDefinition);
        this.workflowDef = (await RestEndpoint.main().run("api/wfl/workflow/definitions").list(FrontendWorkflowDefinition)).find(fwd => fwd.familyName == this.workflowModel.familyPackage.name) ?? new FrontendWorkflowDefinition();
        this.workflowActivityDef = this.workflowDef.getActivity(this.workItem.workItem.activityName);
        this.useTray = this.workflowDef.allowedAttachmentObjectTypes == null || this.workflowDef.allowedAttachmentObjectTypes.length > 0;
        this.useTray = this.workflowDef.allowedAttachmentObjectTypes?.length == 1;

        this.workflowForm = new WorkflowForm(this.workItem.workItem, this.workflowModel,
            this.app.getUserName(), this.app, this.scriptService, this.messageService, this.fileUpload, this.applicationRef);

        await this.workflowForm.init();

        this.updateDocumentsTable();

        this.app.updateNavigation("Workflow - " + this.workflowForm.workItem.processName, { routerLink: ["/enaio-workflows", "home"], icon: 'fas fa-home' }, [
            { label: "Workflows", routerLink: ["/enaio-workflows", "home"] },
            { label: this.workflowForm.workItem.processName, routerLink: ["/enaio-workflows/workitem", this.workflowForm.workItem.id] }
        ]);
        return true;
    }

    async forward(): Promise<void> {
        if (await this.workflowForm.forward()) {
            await this.app.navigateTo(["/enaio-workflows", "home"]);
        }
    }

    async forwardSimulate(): Promise<void> {
        await this.workflowForm.forward(true);
    }

    async save(): Promise<void> {
        if (await this.workflowForm.save()) {
            await this.app.navigateTo(["/enaio-workflows", "home"]);
        }
    }

    async cancel(): Promise<void> {
        if (await this.app.messageDialog.yesNo("Sind Sie sicher, dass Sie abbrechen möchten?", "Bestätigung")) {
            await RestEndpoint.main().post().query({ processId: this.processId }).run("api/wfl/workflow/workItemCancel").getString();
            await this.app.navigateTo(["/enaio-workflows", "home"]);
        }
    }

    updateDocumentsTable(): void {
        //HACK TODO Hardcodierte Feldnamen
        this.documentsTable = new TableData([new TableColumn("Typ", "Typ"), new TableColumn("Bemerkung", "Bemerkung")], this.workItem.documents.map(file => new TableRow(file, {
            Typ: file.getStringByInternal("Typ"),
            Bemerkung: file.getStringByInternal("Bemerkung")
        })), [new TableSortColumn("type")]);
    }

    async attachDocument(): Promise<void> {
        let ot: EnaioObjectTypeId = null;
        if (this.workflowDef.allowedAttachmentObjectTypes?.length == 1) {
            ot = this.workflowDef.allowedAttachmentObjectTypes[0];
        }
        else {
            //let all = this.workflowDef.allowedAttachmentObjectTypes ?? [];
            throw new Error("Nicht implementiert - TODO");
        }

        let otd = await RestEndpoint.main().body(ot).post().run("api/wfl/dms/objectTypeDefinition").get(EnaioObjectType);

        //Hack: Unnötige Felder ausfiltern -> TODO konfigurierbar machen
        let fields = otd.getAllFields().filter(f => f.name != "Personalnr" && f.name != "Name" && f.name != "Vorname");
        let form = FrontendEnaioMapper.enaioToFrontendForm(fields, [], true, true);
        form.fields.push(new FrontendFieldDefinition("document", "Dokument", FrontendFieldType.fileUpload, { mandatory: true }));


        if (await this.app.messageDialog.form(form, "Indexdaten") == "ok") {
            let wfParams = this.workflowForm.workItem.getParameterObject();
            let callInsert = Utils.fromPlain(EnaioCallInsert, {
                archive: ot.archive,
                objectType: ot.objectType,
                location: new EnaioLocation(null, this.personellFile.id),
                fields: [
                    ...fields.map(f => new EnaioAssignment(EnaioObjectId.byInternalName(f.internalName), Utils.stringDef(form.getValue(f.internalName) as string))),
                    new EnaioAssignment(EnaioObjectId.byName("Personalnr"), wfParams.personellNumber),
                    new EnaioAssignment(EnaioObjectId.byName("Name"), wfParams.lastname),
                    new EnaioAssignment(EnaioObjectId.byName("Vorname"), wfParams.firstname)
                ],
                documentBase64: Utils.bytesToBase64((form.getValue("document") as BrowserUploadedFile[])[0].contents),
                documentFileName: (form.getValue("document") as BrowserUploadedFile[])[0].legacy.name
            });

            let upsertResult = await RestEndpoint.main().post().body(callInsert).run("api/wfl/tbr/insert").get(EnaioUpsertResult);

            let call = Utils.fromPlain(EnaioCallCompleteWorkItem, {
                user: EnaioUserIdentification.byUserName(this.app.getUserName()),
                workItemId: this.workItem.workItem.id,
                send: false,
                documents: [Utils.fromPlain(EnaioWorkflowDocument, {
                    id: upsertResult.documentId,
                    objectTypeId: otd.Id,
                    isNew: true
                })]
            });
            await RestEndpoint.main().post().body(call).run("api/wfl/workflow/workitem").getText();

            let workItem = await RestEndpoint.main().query({ processId: this.processId }).run("api/wfl/workflow/workitem").get(EnaioWorkItemDto);
            this.workItem.workItem.files = workItem.workItem.files;
            this.workItem.documents = workItem.documents;
            this.workItem.anonymousGuid = workItem.anonymousGuid;

            this.updateDocumentsTable();
        }
    }

    selectDocument(event: any): void {
        let doc = event.raw as EnaioWorkflowDocument;
        this.selectedDocument = doc;
    }

    onUpload(event: any): void {
        console.log(event);
    }

}
