import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild, inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Config } from 'src/modules/app-template/models/config.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { ButtonDefinition } from 'src/modules/sm-base/models/button-definition.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { EnaioLearnTools } from '../../shared/enaio-learn-tools';
import { FinishContentDto } from '../../shared/finish-content-dto.dto';
import { LearningContentAttachmentType } from '../../shared/learning-content-attachment-type.enum';
import { LearningContentAttachment } from '../../shared/learning-content-attachment.entity';
import { LearningContentChapter } from '../../shared/learning-content-chapter.entity';
import { LearningContentParticipant } from '../../shared/learning-content-participant.entity';
import { LearningContentQuestion } from '../../shared/learning-content-question.entity';
import { LearningContent } from '../../shared/learning-content.entity';
import { MessageToAuthor } from '../../shared/message-to-author.model';
import { QuestionsComponent } from '../questions/questions.component';

@Component({
    selector: 'app-attend-content',
    templateUrl: './attend-content.component.html',
    styleUrls: ['./attend-content.component.scss'],
    animations: [
        trigger('smiley', [
            state('visible', style({
                opacity: 1
            })),
            state('hidden', style({
                opacity: 0
            })),
            transition('visible => hidden', [
                animate('5s')
            ]),
            transition('hidden => visible', [
                animate('0.01s')
            ])
        ])
    ]
})
export class AttendContentComponent extends ComponentView {

    _Utils = Utils;
    _LearningContentAttachmentType = LearningContentAttachmentType;

    service = inject(EnaioLearnService);

    id: number;

    part: LearningContentParticipant;
    content: LearningContent;
    selectedChapterIndex = 0;
    selectedChapter: LearningContentChapter;
    chapterText: SafeHtml;
    guiChapters: MenuItem[] = [];
    guiChaptersMobile: FrontendFieldListItem[] = [];
    showQuestions = false;
    chapterQuestions: LearningContentQuestion[] = [];
    smileyStatus = 0;
    displayPdf = false;
    displayAudio = false;
    displayAudioFile = "";
    displayedAttachment: LearningContentAttachment;
    isMobile = false;
    mayStartFinalExam = false;

    displayModal: boolean;

    @ViewChild('viewer')
    viewer;
    @ViewChild("questionsCompo")
    questionsCompo: QuestionsComponent;

    constructor() {
        super();
        this.neededParams = { id: "number" };
        this.isMobile = this.app.isMobile();
    }

    async initParams(): Promise<boolean> {
        this.part = await RestEndpoint.main().findByIdOrEmpty(LearningContentParticipant, this.id).run("api/lrn/learningcontent/personal").get(LearningContentParticipant);
        this.content = this.part.learningContent;
        while (this.part.doneChapters.length < this.content.chapters.length) {
            this.part.doneChapters.push(false);
        }
        this.updateGuiChapters();

        if (!this.part.isStarted()) {
            await RestEndpoint.main().query({ id: this.id }).post().run("api/lrn/learningcontentparticipant/start").getText();
        }

        this.chapterChanged(this.getNextUnread(0) ?? 0);
        this.updateButtonStatus();

        if (this.getNextUnread(0) == null && !this.part.isFinished() && !this.mayStartFinalExam) {
            await this.finish();
        }

        this.addTimer(new SmTimer(60_000, 60_000, () => this.checkActivity()));

        this.service.updateNavigation(false, [{ label: this.content.title, routerLink: ["/enaio-learn", "attend", this.content.id] }]);

        return true;
    }

    getNextUnread(fromIndex: number): number {
        for (let i = 0; i < this.content.chapters.length; i++) {
            if (!Utils.arrayGetSafe(this.part.doneChapters, (fromIndex + i) % this.content.chapters.length, false)) {
                return (fromIndex + i) % this.content.chapters.length;
            }
        }
        return null;
    }

    updateGuiChapters(): void {
        this.guiChaptersMobile = [];
        for (let i = 0; i < this.content.chapters.length; i++) {
            this.guiChaptersMobile.push(new FrontendFieldListItem(i, this.content.chapters[i].title, { styleClass: Utils.arrayGetSafe(this.part.doneChapters, i, false) ? "chapter-done" : null }));
        }
        this.guiChapters = [];
        for (let i = 0; i < this.content.chapters.length; i++) {
            this.guiChapters.push({ label: this.content.chapters[i].title, styleClass: Utils.arrayGetSafe(this.part.doneChapters, i, false) ? "chapter-done" : null });
        }
    }

    selectChapter(event: any): void {
        this.selectedChapter = event.value[0];
    }

    chapterChanged(index: number): void {
        this.selectedChapterIndex = index;
        this.selectedChapter = this.content.chapters[this.selectedChapterIndex];
        this.showQuestions = false;
        this.updateGuiChapters();
        this.chapterQuestions = Utils.arraySubList(Utils.arrayShuffle(this.selectedChapter.questions.filter(q => q.answersValid())), 0, this.selectedChapter.getNumQuestions() - 1);
        let ct = this.selectedChapter.text;
        ct = ct.replace(/(<img.*) height=".*?"/, "$1");
        ct = ct.replace(/(<img.*)height:\s*[0-9]+px;(.*)/, "$1 $2");
        if (this.selectedChapter.citations.length > 0) {
            ct += "<br><hr><br><b>Quellen</b><br><br>" + Utils.arrayItemsToString(this.selectedChapter.citations, "<br>", c => c.format());
        }

        this.chapterText = this.app.sanitizer.bypassSecurityTrustHtml(ct);
    }

    finishQuestion(): void {
        this.questionsCompo.finishQuestion();
    }

    async finishChapter(): Promise<void> {
        if (this.chapterQuestions.length > 0) {
            this.showQuestions = true;
        }
        else {
            await this.chapterDone();
        }
    }

    async chapterDone(): Promise<void> {
        this.part.doneChapters[this.selectedChapterIndex] = true;
        await RestEndpoint.main().query({ id: this.part.id, chapterIndex: this.selectedChapterIndex }).post().run("api/lrn/learningcontentparticipant/chapterRead").getText();
        let nextUnread = this.getNextUnread(this.selectedChapterIndex + 1);
        if (nextUnread == null && !this.part.isFinished()) {
            await this.finish();
        }
        else {
            this.chapterChanged(nextUnread || 0);
        }
        this.updateButtonStatus();
    }

    updateButtonStatus(): void {
        this.mayStartFinalExam = this.getNextUnread(0) == null && !this.part.isFinished() && this.content.finalExam && this.part.finalExamPassScore == -1;
    }

    async startFinalExam(): Promise<void> {
        await this.app.navigate(["/enaio-learn", "final-exam", this.id]);
    }

    async finish(): Promise<void> {
        if (this.part.isFinished()) {
            return;
        }
        if (this.content.finalExam && this.part.finalExamPassScore == -1) {
            if (!await this.app.messageDialog.yesNo(this.app.t("lea.attend.messages.startFinalExam"), this.app.t("general.info"))) {
                await this.app.messageDialog.info(this.app.t("lea.attend.messages.startFinalExamLater"));
                return;
            }
            await this.startFinalExam();
        }
        else {
            let doneDuringOfficeHours = await this.app.messageDialog.show(this.app.t("lea.attend.messages.doneDuringOfficeHours"), this.app.t("general.question"), [
                new ButtonDefinition("yes", this.app.t("lea.attend.messages.doneDuringOfficeHoursYes"), true, ""),
                new ButtonDefinition("no", this.app.t("lea.attend.messages.doneDuringOfficeHoursNo"), true, "")
            ]) == "yes";

            let ratingTexts = EnaioLearnTools.getRatingTexts();
            let rateForm = new FrontendFormDefinition(Utils.arrayExplode(Utils.arrayMapWithIndex(ratingTexts, (text, index) => [
                new FrontendFieldDefinition("rating" + index, text, FrontendFieldType.rating, { maxValue: 4, mandatory: true }),
                new FrontendFieldDefinition("comment" + index, this.app.t("lea.attend.ratingComment", { name: text }), FrontendFieldType.text)
            ]), l => l));
            this.part.finishedDate = new Date();
            if (await this.app.messageDialog.form(rateForm, this.app.t("lea.attend.messages.pleaseRate"), this.app.messageDialog.ok) == "ok") {
                await RestEndpoint.main().body(Utils.fromPlain(FinishContentDto, {
                    contentParticipantId: this.part.id,
                    ratings: Utils.arrayMapWithIndex(ratingTexts, (text, index) => Utils.toNumber(rateForm.getField("rating" + index).value, true)),
                    ratingTexts: Utils.arrayMapWithIndex(ratingTexts, (_, _2) => ""),
                    doneDuringOfficeHours
                })).post().run("api/lrn/learningcontentparticipant/finish").getText();

                if (await this.app.messageDialog.show(this.app.t("lea.attend.messages.finished"), this.app.t("lea.attend.messages.finished_title"),
                    [new ButtonDefinition("back", this.app.t("lea.attend.messages.finished_back"), true, "fas fa-back"), new ButtonDefinition("stay", this.app.t("lea.attend.messages.finished_stay"), true, "fas fa-map-marker")]) == "back") {

                    await this.app.navigateTo(["/enaio-learn", "home", "0"]);
                }
            }
        }
    }

    backToChapter(): void {
        this.showQuestions = false;
    }

    async questionsDone(): Promise<void> {
        this.showQuestions = false;
        await this.chapterDone();
    }

    setSmiley(value: number): void {
        this.smileyStatus = value;
        Utils.setTimerOnce(500, () => {
            this.smileyStatus = -value;
        });
    }

    async questionAuthor(): Promise<void> {
        let text = await this.app.messageDialog.input<string>(new FrontendFieldDefinition("text", this.app.t("lea.attend.questionAuthorText"), FrontendFieldType.textArea, { mandatory: true }),
            this.app.t("lea.attend.messages.questionAuthor_title", { authorName: this.part.learningContent.author }));
        if (text == null) {
            return;
        }

        try {
            await RestEndpoint.main().body(Utils.fromPlain(MessageToAuthor, {
                contentId: this.part.learningContent.id,
                subject: "",
                text
            })).post().run("api/lrn/learningcontent/questionAuthor").getText();
            this.app.showToast("success", this.app.t("lea.attend.messages.sendMessageOk_title"), this.app.t("lea.attend.messages.sendMessageOk"));
        }
        catch (ex) {
            await this.app.messageDialog.info(this.app.t("lea.attend.messages.sendMessageError"), this.app.t("general.error"));
        }
    }

    openAttachment(event, attachment: LearningContentAttachment): void {
        switch (attachment.type) {
            case LearningContentAttachmentType.pdf:
                this.viewer.pdfSrc = this.getFileUrl(attachment.fileName);
                this.viewer.refresh();
                this.displayPdf = true;
                break;
            case LearningContentAttachmentType.audio:
                this.displayAudioFile = this.getFileUrl(attachment.fileName);
                this.displayAudio = true;
                break;
            default:
                break;
        }
        this.displayedAttachment = attachment;
        event.preventDefault();
    }

    getFileUrl(fileName: string): string {
        return Config.get().backendUrl + "/api/app/fileupload/get/" + encodeURIComponent(fileName);
    }

    checkActivity(): void {

    }

}
