import { Component, OnInit } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { SchoolClass } from 'src/modules/enaio-certificates/shared/school-class.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';

@Component({
    selector: 'enaio-certificates-main',
    templateUrl: './certificates-main.component.html',
    styleUrls: []
})
export class CertificatesMainComponent implements OnInit {

    schoolClasses: SchoolClass[] = [];
    isHalfYear: boolean;
    userName = "";
    isAdmin = false;

    constructor(private app: MainAppService, public service: EnaioCertificateService) {
        this.isHalfYear = EnaioCertificatesTools.getCurrentIsHalfYear();
    }

    async ngOnInit(): Promise<void> {
        this.isAdmin = await this.service.isAdminForCertificates();
        this.app.updateNavigation("Zeugnisse", this.service.getHome(), [this.service.getHomeCertificates()]);
        this.schoolClasses = (await this.service.restGetSchoolClasses(EnaioCertificatesTools.getCurrentSchoolYear())).filter(sc => sc.isForCertificates());
        this.isAdmin = await this.service.isAdminForCertificates();
        this.userName = this.app.getUserName();
    }

    getExistingGrades(): number[] {
        return Utils.arrayGetUnique(this.schoolClasses.map(sc => sc.grade));
    }

    async adminClick(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "globalsettings"]);
    }
}
