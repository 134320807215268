import { ApplicationRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioCallCompleteWorkItem } from 'src/modules/enaio/shared/EnaioCallCompleteWorkItem';
import { EnaioScriptType } from 'src/modules/enaio/shared/EnaioScriptType';
import { EnaioUserIdentification } from 'src/modules/enaio/shared/EnaioUserIdentification';
import { EnaioWorkItem } from 'src/modules/enaio/shared/EnaioWorkItem';
import { EnaioWorkflowDefinition } from 'src/modules/enaio/shared/EnaioWorkflowDefinition';
import { EnaioWorkflowEventDefinition } from 'src/modules/enaio/shared/EnaioWorkflowEventDefinition';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { ScriptService } from 'src/modules/sm-base/services/script-service';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { FormHelper } from './form-helper.model';
import { FrontendEnaioMapper } from '../../enaio/models/frontend-enaio-mapper.model';

export class WorkflowForm {

    form: FrontendFormDefinition;
    clientScriptFunctions: OrdinaryObject<Function> = {};
    scriptFormHelper: FormHelper;
    scriptGlobals: OrdinaryObject;
    scriptScriptingStorage: OrdinaryObject;

    constructor(public workItem: EnaioWorkItem, public workflowModel: EnaioWorkflowDefinition, public enaioUserName: string,
        public app: MainAppService, public scriptService: ScriptService,
        public messageService: MessageService, public fileUpload: FileUploadService, public applicationRef: ApplicationRef) {
    }

    async init(): Promise<void> {
        this.form = FrontendEnaioMapper.enaioToFrontendForm(this.workItem.masks[0].fields, this.workItem.parameters, true, this.app.isMobile());

        let temp = {};
        for (let param of this.workItem.parameters) {
            temp[param.name] = param.getPlainValue();
            param.value.value = temp[param.name];
            let formField = this.form.fields.find(f => f.id === param.formFieldId);
            if (formField != null) {
                formField.value = param.value.value;
            }
        }

        for (let field of this.form.fields) {
            field.onClick = async () => {
                await this.scriptButtonClick(this.workItem.activityId, field.caption);
            };
            field.onValueChanged = async () => {
                FrontendEnaioMapper.frontendToEnaioValue(field, this.form.fields, this.workItem.parameters);
                if (field.type === FrontendFieldType.table) {
                    await this.scriptCellValueChanged(this.workItem.activityId, field.caption);
                }
                else {
                    await this.runScript(this.workItem.activityId, 10, field.caption);
                }
            };
        }

        this.scriptFormHelper = new FormHelper(this.app, this);
        this.scriptGlobals = {};
        this.scriptScriptingStorage = {};

        (window as any).isWorkflowPortal = true;
        (window as any).clientScriptFunctions = this.clientScriptFunctions;
        for (let event of this.workflowModel.events) {
            if (event.appId == this.workItem.activityId && (event.clientTypeId == "D4C24904B784420C922B66E012624D42" || event.clientTypeId == "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF" && event.language == 2) || event.scriptType == EnaioScriptType.clientGlobal) {
                let id = this.getClientScriptNameByEvent(event);
                let usesDone = event.type == 1 || event.type == 5;
                let code = "window.clientScriptFunctions['" + id + "'] = (function anonymous(formHelper,globals,scriptingStorage" + (usesDone ? ",done" : "") + ") {\n" + event.code + "\n})";
                this.loadScript(id, code);
            }
        }

        delete (window as any).clientScriptFunctions;

        await this.runScript("", 1000001);

        return new Promise((resolve, _) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.scriptBeforeOpen(this.workItem.activityId, __ => {
                resolve();
            });
        });
    }

    getClientScriptNameByEvent(event: EnaioWorkflowEventDefinition): string {
        return "clientScript" + event.appId + "_" + event.type + "_" + event.param;
    }

    loadScript(name: string, code: string): void {
        const script = document.createElement('script');
        script.textContent = code + `\n//# sourceURL=${name}`;
        document.body.appendChild(script);
    }

    validate(): boolean {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return false;
        }
        return true;
    }

    async forward(test = false): Promise<boolean> {
        if (this.validate() && await this.scriptBeforeForward(this.workItem.activityId) === true) {
            await this.callForward(!test);
            this.app.showToast("info", "Information", "Der Workflow wurde weitergeleitet");
            return true;
        }
        return false;
    }

    async save(): Promise<boolean> {
        if (!this.validate()) {
            return false;
        }
        await this.callForward(false);
        this.app.showToast("info", "Information", "Der aktuelle Stand wurde gespeichert");
        return true;
    }

    async saveAndWait(): Promise<void> {
        await this.callForward(false);
    }

    async runScriptByName(eventName: string, onDone?: (value: number) => void): Promise<boolean> {
        let fun = this.clientScriptFunctions[eventName];
        if (fun != null) {
            console.log("Skript ausführen: " + eventName);
            (window as any).formHelper = this.scriptFormHelper;
            this.scriptGlobals.isAwpPortal = true;
            let done = null;
            await fun(this.scriptFormHelper, this.scriptGlobals, this.scriptScriptingStorage, (value: number) => {
                if (onDone != null) {
                    onDone(value);
                }
                done = value > 0;
            });
            return done;
        }
        return null;
    }

    async runScript(appId: string, type: number, param?: string, onDone?: (value: number) => any): Promise<boolean> {
        let event = this.workflowModel.events.find(evt => evt.appId == appId && (evt.clientTypeId == "D4C24904B784420C922B66E012624D42" || evt.clientTypeId == "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF" && evt.language == 2) && evt.type == type && (param == null || param == evt.param));
        return event != null ? this.runScriptByName(this.getClientScriptNameByEvent(event), onDone) : null;
    }

    async scriptBeforeOpen(appId: string, onDone?: (value: number) => any): Promise<void> {
        await this.runScript(appId, 5, null, onDone);
    }

    async scriptBeforeForward(appId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, _) => {
            this.runScript(appId, 1, null, result => resolve(result >= 0 || result == null)).catch(__ => 0);
        });
    }

    async scriptButtonClick(appId: string, param: string): Promise<void> {
        throw new Error("Nicht unterstützt");
    }

    async scriptValueChanged(appId: string, param: string): Promise<void> {
        throw new Error("Nicht unterstützt");
    }

    async scriptCellValueChanged(appId: string, param: string): Promise<void> {
        await this.runScript(appId, 14, param);
    }

    private async callForward(send: boolean): Promise<string> {
        let call = Utils.fromPlain(EnaioCallCompleteWorkItem, {
            user: EnaioUserIdentification.byUserName(this.enaioUserName),
            workItemId: this.workItem.id,
            send,
            parameters: this.workItem.parameters.filter(p => !Utils.isArray(p.value.value)).map(p => p.toAssignment()),
            tableParameters: this.workItem.parameters.filter(p => Utils.isArray(p.value.value)).map(p => p.toTableAssignment()),
            documents: this.workItem.files
        });
        return RestEndpoint.main().post().body(call).run("api/wfl/workflow/workitem").getText();
    }

}
