import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FrontendFieldDefinition } from '../../shared/frontend-field-definition.model';
import { FrontendFormDefinition } from '../../shared/frontend-form-definition.model';
import { SmFormFieldComponent } from '../sm-form-field/sm-form-field.component';

@Component({
    selector: 'sm-form',
    templateUrl: './sm-form.component.html',
    styleUrls: []
})
export class SmFormComponent {

    _form: FrontendFormDefinition = null;
    _fields: FrontendFieldDefinition[][] = [];

    @ViewChildren('fields') fields: QueryList<SmFormFieldComponent>;

    @Input()
    set form(value: FrontendFormDefinition) {
        this._form = value;
        this._fields = value != null ? value.getGroupedFields() : [];
    }

    focus(): void {
        if (this.fields.length > 0) {
            this.fields[0].focus();
        }
    }

}
