import { Component, OnInit } from '@angular/core';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { InspectionPhase } from '../../shared/inspection-phase.entity';

@Component({
    selector: 'app-edit-inspection-phases',
    templateUrl: './edit-inspection-phases.component.html',
    styleUrls: ['./edit-inspection-phases.component.scss']
})
export class EditInspectionPhasesComponent implements OnInit {
    listData: InspectionPhase[];
    table: TableData;

    constructor(private service: EnaioLearnService) {
    }

    async ngOnInit(): Promise<void> {
        [this.listData, this.table] = await this.service.adminInitList<InspectionPhase>(InspectionPhase, "lea.inspectionPhase", "inspection-phases", "inspection-phase", "api/lrn/inspectionphase", this,
        [new TableColumn("name", null), new TableColumn("index", null), new TableColumn("forCategory", null), new TableColumn("authorMayChoose", null, TableCellType.yesNo)], [new TableSortColumn("index"), new TableSortColumn("name")],
        o => new TableRow(o, { name: o.name, index: o.index, forCategory: o.forCategory == null ? "<Alle>" : o.forCategory.name, authorMayChoose: o.authorMayChoose }));
    }
}
