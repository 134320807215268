import { Component, ViewChild, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DateIntv } from 'src/modules/enaio/shared/DateIntv';
import { EnaioAssignment } from 'src/modules/enaio/shared/EnaioAssignment';
import { EnaioCallGetDocument } from 'src/modules/enaio/shared/EnaioCallGetDocument';
import { EnaioCallGetObjectDef } from 'src/modules/enaio/shared/EnaioCallGetObjectDef';
import { EnaioCallSelect } from 'src/modules/enaio/shared/EnaioCallSelect';
import { EnaioCallUpdate } from 'src/modules/enaio/shared/EnaioCallUpdate';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioIndexDataSelectParams } from 'src/modules/enaio/shared/EnaioIndexDataSelectParams';
import { EnaioMetaDataField } from 'src/modules/enaio/shared/EnaioMetaDataField';
import { EnaioObjectDef } from 'src/modules/enaio/shared/EnaioObjectDef';
import { EnaioObjectHistoryEntry } from 'src/modules/enaio/shared/EnaioObjectHistoryEntry';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { SimLogResultEntry } from 'src/modules/enaio/shared/SimLogResultEntry';
import { SmTableOnPushComponent } from 'src/modules/sm-base/components/sm-table-on-push/sm-table-on-push.component';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/shared/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { Progressor } from 'src/modules/utils/shared/Progressor';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Wrapper } from 'src/modules/utils/shared/wrapper.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-enaio-objects',
  templateUrl: './enaio-objects.component.html',
  styleUrls: ['./enaio-objects.component.scss']
})
export class EnaioObjectsComponent extends ComponentView {

    fileUpload = inject(FileUploadService);

    table: TableData;
    tableMetaData: TableData;
    tableBaseData: TableData;
    tableHistory: TableData;
    tableIndexDataHistory: TableData;
    objects: EnaioDocument[];
    sel: EnaioDocument;
    includeTrash = false;
    indexDataInTable = false;
    objectIds = "";
    objectDef: EnaioObjectDef;
    changed: TableRow[] = [];
    notFoundText: string = null;
    tabIndex = 0;
    simLogResult: SimLogResultEntry[];
    objectHistoryWithIndexData: EnaioDocument;
    aiPrompt: string;
    @ViewChild("tableComponent") tableComponent: SmTableOnPushComponent;
    progressor = new Wrapper<Progressor>();

    async filter(): Promise<void> {
        let call = Utils.fromPlain(EnaioCallSelect, {
            selectMultipleObjectIds: Utils.splitLines(this.objectIds).map(id => Utils.getSubstringTo(id, ";").trim()).filter(id => id != "").map(id => Utils.toNumber(id)),
            getBaseParams: true,
            getFileProperties: true,
            getDocStats: true,
            lowLevel: true,
            includeTrash: this.includeTrash
        });
        this.objects = await DhTools.enaioCall<EnaioDocument[]>(call);

        let notFoundIds = Utils.splitLines(this.objectIds).filter(id => !this.objects.some(o => o.id == Utils.toNumber(id)));
        this.notFoundText = notFoundIds.length == 0 ? null : Utils.arrayItemsToString(notFoundIds);

        this.fillTable();
    }

    fillTable(): void {
        if (this.indexDataInTable) {
            let allVars = Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(this.objects, o => o.metaData.map(md => md.field.name))));
            this.table = new TableData([
                new TableColumn("id", "ID", TableCellType.number),
                ...allVars.map(v => new TableColumn("variable_" + v, v, TableCellType.text, { width: "200px", noWrap: true }))
            ], this.objects.map(o => new TableRow(o, ({
                id: o.id,
                ...Utils.arrayToPlainMap(allVars, v => "variable_" + v, v => {
                    let value = o.getByName(v);
                    return value != null ? Utils.replaceAll(Utils.toString(value), "\\r?\\n", " ") : "---";
            }) as OrdinaryObject}))));
        }
        else {
            this.table = new TableData([
                new TableColumn("id", "ID", TableCellType.number),
                new TableColumn("creationDate", "Erstelldatum", TableCellType.dateTime),
                new TableColumn("createdBy", "Erstellt von"),
                new TableColumn("modificationDate", "Änderungsdatum", TableCellType.dateTime),
                new TableColumn("modificationUser", "Geändert von")
            ], this.objects.map(o => new TableRow(o, ({
                id: o.id,
                creationDate: o.baseParams?.creationDate ?? "???",
                createdBy: o.baseParams?.createdBy ?? "???",
                modificationDate: o.baseParams?.modificationDate ?? "???",
                modificationUser: o.baseParams?.modificationUser ?? "???"
            }) as OrdinaryObject)));
        }
        this.tableComponent?.updateChanges();
    }

    async aiPromptKeyPress(event: any): Promise<void> {
        if (event.keyCode == 13) {
            this.objects = await DhTools.backendCall("api/enaio/aiSearch", { prompt: this.aiPrompt, openInClient: true }).list(EnaioDocument)
            if (this.objects.length == 0) {
                this.app.showToast("warn", "Keine Ergebnisse", "Es wurden keine Ergebnisse zu dieser Suche gefunden");
            }
            else {
                this.objectIds = Utils.arrayItemsToString(this.objects, "\n", o => Utils.toString(o.id));
                this.indexDataInTable = true;
                this.fillTable();
            }
        }
    }

    async selectObject(event): Promise<void> {
        if (this.objectDef == null) {
            this.objectDef = await DhTools.enaioCall<EnaioObjectDef>(new EnaioCallGetObjectDef());
        }

        this.changed = [];
        this.sel = event.raw as EnaioDocument;

        let otd = Utils.arrayExplode(this.objectDef.cabinets, c => c.objectTypes).find(ot => ot.internalName == this.sel.objectType.internalName);

        this.tableMetaData = new TableData([
            new TableColumn("name", "Name", TableCellType.text),
            new TableColumn("internalName", "interner Name", TableCellType.text),
            new TableColumn("value", "Wert", TableCellType.text, { editable: true }),
            new TableColumn("visible", "Sichtbar", TableCellType.yesNo)
        ], this.sel.metaData.map(f => new TableRow(f, {
                name: f.field.name,
                internalName: f.field.internalName,
                value: new TableCell(f.value, {styleClass: this.changed.some(item => (item.raw as EnaioMetaDataField).field.internalName == f.field.internalName) ? "xbg-red-400" : null}),
                visible: otd?.getAllFields().find(f2 => f2.internalName == f.field.internalName)?.isControlVisible
            })));

        this.tableBaseData = new TableData([
            new TableColumn("type", "Art", TableCellType.text),
            new TableColumn("name", "Name", TableCellType.text),
            new TableColumn("value", "Wert", TableCellType.text)
        ], [
            ...Utils.getOwnPropertyNames(this.sel.baseParams).map(p => new TableRow(p, {
                type: "Base Params",
                name: p,
                value: this.sel.baseParams[p] instanceof Date ? Utils.dateFormatDefault(this.sel.baseParams[p] as Date) : this.sel.baseParams[p]
            })),
            ...Utils.getOwnPropertyNames(this.sel.fileProperties).map(p => new TableRow(p, {
                type: "File Properties",
                name: p,
                value: this.sel.fileProperties[p] instanceof Date ? Utils.dateFormatDefault(this.sel.fileProperties[p] as Date) : this.sel.fileProperties[p]
            })),
            ...Utils.getOwnPropertyNames(this.sel.documentStats).map(p => new TableRow(p, {
                type: "Doc Stats",
                name: p,
                value: this.sel.documentStats[p] instanceof Date ? Utils.dateFormatDefault(this.sel.documentStats[p] as Date) : this.sel.documentStats[p]
            }))]);

        this.tableHistory = null;
        this.tableIndexDataHistory = null;
        this.objectHistoryWithIndexData = null;
        await this.tabIndexChanged();
    }

    async tabIndexChanged(): Promise<void> {
        if (this.tabIndex == 2 && this.tableHistory == null) {
            let o = Utils.arrayGetSafe(await DhTools.enaioCall<EnaioDocument[]>(Utils.fromPlain(EnaioCallSelect, {
                selectObjectId: this.sel.id,
                getObjectHistory: true,
                lowLevel: true,
                includeTrash: true
            })), 0);
            this.tableHistory = o == null ? null : new TableData([
                new TableColumn("date", "Datum", TableCellType.dateTime),
                new TableColumn("userName", "Benutzer"),
                new TableColumn("stationId", "Stations-ID"),
                new TableColumn("station", "Station"),
                new TableColumn("actionTitle", "Aktion"),
                new TableColumn("info", "Info")
            ], o.history.map(h => new TableRow(h, {
                date: h.date,
                userName: h.userName,
                stationId: h.stationId,
                station: h.station,
                actionTitle: h.actionTitle,
                info: h.info
            })), [new TableSortColumn("date", false)]);
        }
        else if (this.tabIndex == 3 && this.tableIndexDataHistory == null) {
            this.objectHistoryWithIndexData = Utils.arrayGetSafe(await DhTools.enaioCall<EnaioDocument[]>(Utils.fromPlain(EnaioCallSelect, {
                selectObjectId: this.sel.id,
                getObjectHistory: true,
                lowLevel: true,
                includeTrash: true,
                getIndexDataHistory: Utils.fromPlain(EnaioIndexDataSelectParams, {
                    use: true,
                    diff: true
                })
            })), 0);
            this.fillIndexDataHistoryTable();
        }
    }

    fillIndexDataHistoryTable(): void {
        let varNames = Utils.arrayGetUnique(Utils.arrayExplode(this.objectHistoryWithIndexData.history, h => h.indexData != null ? Utils.getOwnPropertyNames(h.indexData) : []));

        this.tableIndexDataHistory = this.objectHistoryWithIndexData == null ? null : new TableData(Utils.arrayWithoutNull([
            new TableColumn("date", "Datum", TableCellType.dateTime, { frozen: true }),
            new TableColumn("userName", "Benutzer", TableCellType.text, { frozen: true }),
            this.simLogResult != null ? new TableColumn("simLog", "DH Aktionen", TableCellType.text) : null,
            new TableColumn("actionTitle", "Aktion"),
            ...varNames.map(vn => new TableColumn("var_" + vn, vn))
        ]), this.objectHistoryWithIndexData.history.filter(h => !Utils.isNoe(h.indexData)).map(h => new TableRow(h, {
            date: h.date,
            userName: h.userName,
            simLog: this.simLogResult != null ? Utils.arrayItemsToString(this.simLogResult.filter(sl => Math.abs(Utils.dateDiffMinutes(h.date, sl.date)) < 60), ", ", sl => sl.configName) : null,
            actionTitle: h.actionTitle,
            ...Utils.arrayConvertToOrdinaryObject(varNames, vn => "var_" + vn, vn => new TableCell(h.indexData != null ? h.indexData[vn] : null, { styleClass: h.indexDataDiff != null && vn in h.indexDataDiff ? "xbg-red-400" : null }))
        })), [new TableSortColumn("date", false)]);
    }

    cellEdited(): void {
        this.changed = this.tableMetaData.rows.filter(row => row.values.value.value != row.raw.value);
    }

    async download(all: boolean): Promise<void> {
        let fileName = await DhTools.saveDialog(all ? "docExport" : this.sel.id + ".pdf", all);
        for (let o of all ? this.objects : [this.sel]) {
            await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetDocument, {
                documentId: o.id,
                resultFileName: all ? fileName + "/" + o.id + ".pdf" : fileName
            }));
        }
    }

    async upload(): Promise<void> {
        let uploadedFile = (await this.fileUpload.chooseFile())[0];
        await DhTools.enaioCall(Utils.fromPlain(EnaioCallUpdate, {
            updateId: this.sel.id,
            documentBase64: Utils.bytesToBase64(uploadedFile.contents),
            documentFileName: Utils.getFileNameWithoutPath(uploadedFile.legacy.name)
        }));
    }

    async saveChanges(): Promise<void> {
        await DhTools.enaioCall(Utils.fromPlain(EnaioCallUpdate, {
            updateId: this.sel.id,
            fields: this.changed.map(item => new EnaioAssignment(EnaioObjectId.byInternalName((item.raw as EnaioMetaDataField).field.internalName), item.values.value))
        }));
    }

    async select(objectId: number): Promise<void> {
        this.objectIds = Utils.toString(objectId);
        await this.filter();
    }

    async getSimLog(): Promise<void> {
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/dh/simLogSearch", {
            dateIntvs: this.tableIndexDataHistory.rows.map(row => Utils.fromPlain(DateIntv, {
                start: Utils.dateAdd((row.raw as EnaioObjectHistoryEntry).date, "hour", -1),
                end: Utils.dateAdd((row.raw as EnaioObjectHistoryEntry).date, "hour", 1)
            })),
            regex: ".*",
            query: "{{enaioObjectId == " + this.sel.id + "}}",
            returnFullLog: true
        }).getText(), result => {
            this.simLogResult = Utils.fromPlainArray(SimLogResultEntry, Utils.fromJson(result.value) as any[]);
            this.fillIndexDataHistoryTable();
        });
    }
}
