import { Component, ViewChild } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DatabaseValueFound } from 'src/modules/enaio/shared/DatabaseValueFound';
import { DbConnectionConfig } from 'src/modules/enaio/shared/DbConnectionConfig';
import { EnaioAccessType } from 'src/modules/enaio/shared/EnaioAccessType';
import { EnaioCabinet } from 'src/modules/enaio/shared/EnaioCabinet';
import { EnaioCallGetClientEvents } from 'src/modules/enaio/shared/EnaioCallGetClientEvents';
import { EnaioCallGetDuplicateDatasets } from 'src/modules/enaio/shared/EnaioCallGetDuplicateDatasets';
import { EnaioCallGetObjectCounts } from 'src/modules/enaio/shared/EnaioCallGetObjectCounts';
import { EnaioCallGetObjectDef } from 'src/modules/enaio/shared/EnaioCallGetObjectDef';
import { EnaioCallGetUserGroupList } from 'src/modules/enaio/shared/EnaioCallGetUserGroupList';
import { EnaioCallGetUserPermissions } from 'src/modules/enaio/shared/EnaioCallGetUserPermissions';
import { EnaioCallSelect } from 'src/modules/enaio/shared/EnaioCallSelect';
import { EnaioCallUpdateClientEvent } from 'src/modules/enaio/shared/EnaioCallUpdateClientEvent';
import { EnaioCheckPermissionResult } from 'src/modules/enaio/shared/EnaioCheckPermissionResult';
import { EnaioClientEvent } from 'src/modules/enaio/shared/EnaioClientEvent';
import { EnaioCondition } from 'src/modules/enaio/shared/EnaioCondition';
import { EnaioDbDataType } from 'src/modules/enaio/shared/EnaioDbDataType';
import { EnaioDuplicateDataset } from 'src/modules/enaio/shared/EnaioDuplicateDataset';
import { EnaioGuiControlType } from 'src/modules/enaio/shared/EnaioGuiControlType';
import { EnaioGuiField } from 'src/modules/enaio/shared/EnaioGuiField';
import { EnaioMainType } from 'src/modules/enaio/shared/EnaioMainType';
import { EnaioObjectDef } from 'src/modules/enaio/shared/EnaioObjectDef';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { EnaioObjectType } from 'src/modules/enaio/shared/EnaioObjectType';
import { EnaioObjectTypeExportParams } from 'src/modules/enaio/shared/EnaioObjectTypeExportParams';
import { EnaioUserGroup } from 'src/modules/enaio/shared/EnaioUserGroup';
import { AceEditorComponent } from 'src/modules/sm-base/components/ace-editor/ace-editor.component';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { OrdinaryObject, OrdinaryObjectNumber } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';
import { EnaioObjectTypeId } from 'src/modules/enaio/shared/EnaioObjectTypeId';
import { Wrapper } from 'src/modules/utils/shared/wrapper.model';
import { Progressor } from 'src/modules/utils/shared/Progressor';
import { EnaioObjectDefChange } from 'src/modules/enaio/shared/EnaioObjectDefChange';

@Component({
    selector: 'app-enaio-object-def',
    templateUrl: './enaio-object-def.component.html',
    styleUrls: ['./enaio-object-def.component.scss']
})
export class EnaioObjectDefComponent extends ComponentView {

    _Utils = Utils;

    loading = true;
    def: EnaioObjectDef;
    tree: TreeNode2[];
    events: EnaioClientEvent[];
    visEvents: [string, EnaioClientEvent][];
    userRightsTable: TableData;
    fieldsTable: TableData;
    relationsFromTable: TableData;
    relationsToTable: TableData;
    fieldsSelectTable: TableData;
    duplicateDatasetsTable: TableData;
    userGroups: EnaioUserGroup[];
    selOldCode: string;
    duplicateDatasets: EnaioDuplicateDataset[];
    objectCounts: OrdinaryObject<number> = {};
    tabIndex = 0;
    findDupsTabIndex = 0;
    noDupIds = false;
    maxDups = 1000;
    dupType = 2;
    minCount = 2;
    findValueForm: FrontendFormDefinition;
    findValueTable: TableData;
    historyForm: FrontendFormDefinition;
    historyTable: TableData;
    progressor = new Wrapper<Progressor>();

    dhGeneratorItems: MenuItem[] = [];
    openDupsMenu: MenuItem[] = [];
    selectedObjectType: ArrayOrSingle<TreeNode>;
    selectedEvent: any;
    hasEvents: OrdinaryObjectNumber<boolean> = {};

    @ViewChild("aceEvent")
    aceEvent: AceEditorComponent;

    async initParams(): Promise<boolean> {
        await this.refresh();
        this.dhGeneratorItems = [
            {
                label: "Feldliste",
                command: this.generateDhFieldList.bind(this)
            },
            {
                label: "ImportSource",
                command: this.generateDhImportSource.bind(this)
            },
            {
                label: "FromEnaioWorker",
                command: this.generateDhFromEnaioWorker.bind(this)
            },
            {
                label: "ToEnaioWorker",
                command: this.generateDhToEnaioWorker.bind(this)
            }
        ];
        this.openDupsMenu = [
            {
                label: "Außer Älteste",
                command: async () => this.openDupsInEnaio(true)
            },
            {
                label: "Außer Neueste",
                command: async () => this.openDupsInEnaio(false)
            }
        ];
        this.findValueForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("query", "Suche nach", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("like", "LIKE Operator", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("searchArea", "Suchbereich", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: ["Dieser Objekttyp", "Dieser Schrank", "Alle Schränke"].map(s => new FrontendFieldListItem(s, s))})
        ]);

        this.historyForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("searchArea", "Suchbereich", FrontendFieldType.comboBox, { value: "Alle Schränke", mandatory: true, dropdownEditable: false,
                listItems: ["Dieser Objekttyp", "Dieser Schrank", "Alle Schränke"].map(s => new FrontendFieldListItem(s, s))}),
            new FrontendFieldDefinition("compareToFileName", "Vergleiche mit Definition aus Datei", FrontendFieldType.text)
        ]);

        this.loading = false;
        return true;
    }

    async refresh(): Promise<void> {
        this.def = await DhTools.enaioCall<EnaioObjectDef>(new EnaioCallGetObjectDef());
        this.userGroups = await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetUserGroupList, {}));
        this.events = await DhTools.enaioCall<EnaioClientEvent[]>(new EnaioCallGetClientEvents());

        for (let cab of this.def.cabinets) {
            for (let ot of cab.objectTypes) {
                ot.parent = cab;
                this.hasEvents[ot.Id] = this.events.some(e => e.objectTypeId == ot.Id);
            }
        }

        this.tree = GuiUtils.generateTree(Utils.arrayExplode(this.def.cabinets, c => c.objectTypes.map(ot => [ot, c])), tuple => [tuple[1].name, tuple[0]],
            (_, keys, index) => ({ key: keys[index], data: keys[index], label: Utils.isString(keys[index]) ? keys[index] : keys[index] instanceof EnaioCabinet ? keys[index].name : keys[index] instanceof EnaioObjectType ? keys[index].name : "<???>", sortText: keys[index] instanceof EnaioObjectType && (keys[index] as EnaioObjectType).mainType == EnaioMainType.folder ? "1" : "2" }), true);

        for (let node of GuiUtils.flattenTree(this.tree)) {
            node.icons = Utils.arrayWithoutNull([this.hasEvents[node.data?.Id] ? 'fas fa-code' : null, node.data instanceof EnaioObjectType && node.data.mainType == EnaioMainType.folder ? 'fas fa-folder' : null]);
        }

        if (this.tree.length > 0) {
            this.tree[0].expanded = true;
        }
    }

    getSelectedObjectType(): EnaioObjectType {
        let sel = Utils.asSingle(this.selectedObjectType);
        if (sel == null) {
            return null;
        }
        if (sel.data instanceof EnaioObjectType) {
            return sel.data;
        }
        else if (Utils.isString(sel.data)) {
            return sel.children.find(child => child.label == sel.label)?.data as EnaioObjectType;
        }
        else {
            return null;
        }
    }

    selectedObjectTypeChanged(_: any): void {
        let sel = this.getSelectedObjectType();
        this.visEvents = Utils.arraySortBy(sel != null ? this.events.filter(e => e.objectTypeId == -1 || e.objectTypeId == sel.Id).map(e => [e.getCaption(true), e]) : [], e => e[0]);
        this.userRightsTable = null;

        this.fieldsTable = sel == null ? null : new TableData([
            new TableColumn("index", "Index"),
            new TableColumn("tabOrder", "Tab Order"),
            new TableColumn("name", "Name"),
            new TableColumn("internalName", "Interner Name"),
            new TableColumn("dbFieldName", "Datenbankname"),
            new TableColumn("maxLength", "Länge"),
            new TableColumn("controlType", "GUI-Typ"),
            new TableColumn("dataType", "Datentyp")
        ], Utils.arrayMapWithIndex(sel.getAllFields(), (f, index) => new TableRow(f, ({
            index,
            tabOrder: f.tabOrder,
            name: f.name,
            internalName: f.internalName,
            dbFieldName: f.dbFieldName,
            maxLength: f.maxLength,
            controlType: EnaioGuiControlType.getName(f.controlType2),
            dataType: EnaioDbDataType.getName(f.dataType)
        }) as OrdinaryObject)), [new TableSortColumn("name")]);

        this.fieldsSelectTable = sel == null ? null : new TableData([
            new TableColumn("key", "Schlüssel", TableCellType.yesNo, { editable: true }),
            new TableColumn("index", "Index"),
            new TableColumn("name", "Name"),
            new TableColumn("internalName", "Interner Name"),
            new TableColumn("dbFieldName", "Datenbankname"),
            new TableColumn("condition", "Bedingung", TableCellType.text, { editable: true })
        ], Utils.arrayMapWithIndex(sel.getAllFields().filter(f => !Utils.isNoe(f.dbFieldName)), (f, index) => new TableRow(f, ({
            index,
            name: f.name,
            internalName: f.internalName,
            dbFieldName: f.dbFieldName,
            key: false,
            condition: ""
        }) as OrdinaryObject)), [new TableSortColumn("index")]);

        this.relationsFromTable = sel == null ? null : new TableData([
            new TableColumn("objectType", "Darf enthalten"),
            new TableColumn("max", "Anzahl")
        ], sel.mainType != EnaioMainType.folder && sel.mainType != EnaioMainType.register ? [] : sel.parent.objectTypes.filter(ot => ot.mainType != EnaioMainType.folder && Utils.arrayFindAndConvert(sel.objectLimits, ol => ol.typeId == ot.Id, ol => ol.max, 1) != 0).map(ot => new TableRow(ot, {
            objectType: ot.name,
            max: Utils.arrayFindAndConvert(sel.objectLimits, ol => ol.typeId == ot.Id, ol => Utils.toString(ol.max), "\u221E")
        })));

        this.relationsToTable = sel == null ? null : new TableData([
            new TableColumn("objectType", "Darf enthalten sein in"),
            new TableColumn("max", "Anzahl")
        ], sel.mainType == EnaioMainType.folder ? [] : sel.parent.objectTypes.filter(ot => (ot.mainType == EnaioMainType.folder || ot.mainType == EnaioMainType.register) && Utils.arrayFindAndConvert(ot.objectLimits, ol => ol.typeId == sel.Id, ol => ol.max, 1) != 0).map(ot => new TableRow(ot, {
            objectType: ot.name,
            max: Utils.arrayFindAndConvert(ot.objectLimits, ol => ol.typeId == sel.Id, ol => Utils.toString(ol.max), "\u221E")
        })));
    }

    selectedEventChanged(): void {
        this.selOldCode = this.selectedEvent != null ? this.selectedEvent[1].code : null;
    }

    async export(): Promise<void> {
        let optionsForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("includeChildren", "Mit Unterlementen", FrontendFieldType.checkBox, { value: true}),
            new FrontendFieldDefinition("documents", "Mit Dokumenten", FrontendFieldType.checkBox, { value: false})
        ]);
        if (await this.app.messageDialog.form(optionsForm, "Export-Optionen") == "ok") {
            let dirName = await DhTools.saveDialog(null, true);
            if (!Utils.isNoe(dirName)) {
                await DhTools.backendCall("api/enaio/export", { dirName, export: [Utils.fromPlain(EnaioObjectTypeExportParams, {
                    objectType: EnaioObjectId.byInternalName(this.getSelectedObjectType().internalName),
                    includeChildren: optionsForm.getField("includeChildren").value,
                    documents: optionsForm.getField("documents").value
                })]}).getText();
            }
        }
    }

    async import(): Promise<void> {
        let exportDir = await DhTools.saveDialog(null, true);

        let fixedParentId = await this.app.messageDialog.input(new FrontendFieldDefinition("fixedParentId", "Fester Ablageort (ID, 0 = kein fester Ablageort)", FrontendFieldType.number, { value: 0 }), "Import-Optionen");
        if (fixedParentId != null) {
            await DhTools.backendCall("api/enaio/import", {
                exportDir,
                fixedParentId
            }).getText();
        }
    }

    async loadUserRights(): Promise<void> {
        let call = Utils.fromPlain(EnaioCallGetUserPermissions, {
            objectTypeId: this.getSelectedObjectType().Id,
            detailed: true
        });
        let result = await DhTools.enaioCall<EnaioCheckPermissionResult[]>(call);

        this.userRightsTable = new TableData([
            new TableColumn("group", "Gruppe", TableCellType.text),
            new TableColumn("delete", "Objekt löschen", TableCellType.text),
            new TableColumn("readMetaData", "Indexdaten lesen", TableCellType.text),
            new TableColumn("writeMetaData", "Indexdaten schreiben", TableCellType.text),
            new TableColumn("update", "Objekt schreiben", TableCellType.text),
            new TableColumn("openExec", "Objekt ausgeben", TableCellType.text)
        ], result.map(p => new TableRow(p, ({
            group: Utils.arrayFindAndConvert(this.userGroups, ug => ug.id == p.fromUserGroup, ug => ug.name, "<" + p.fromUserGroup + ">"),
            delete: this.getUserRightCell(p, EnaioAccessType.delete),
            readMetaData: this.getUserRightCell(p, EnaioAccessType.readMetaData),
            writeMetaData: this.getUserRightCell(p, EnaioAccessType.writeMetaData),
            update: this.getUserRightCell(p, EnaioAccessType.update),
            openExec: this.getUserRightCell(p, EnaioAccessType.openExec)
        }) as OrdinaryObject)));
    }

    async getDuplicateDatasets(): Promise<void> {
        let fields = this.fieldsSelectTable.rows.filter(row => row.values.key).map(row => row.raw as EnaioGuiField);
        if (fields.length == 0) {
            await this.app.messageDialog.show("Bitte mindestens ein Schlüsselfeld auswählen", "Fehler", [this.app.messageDialog.ok]);
            return;
        }
        this.duplicateDatasets = await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetDuplicateDatasets, {
            archive: EnaioObjectId.byInternalName(this.getSelectedObjectType().parent.internalName),
            objectType: EnaioObjectId.byInternalName(this.getSelectedObjectType().internalName),
            fields: fields.map(f => EnaioObjectId.byInternalName(f.internalName)),
            conditions: this.fieldsSelectTable.rows.filter(row => !Utils.isNoe(row.values.condition)).map(row => new EnaioCondition(EnaioObjectId.byInternalName(row.raw.internalName as string), row.values.condition as string, "free")),
            maxResults: this.maxDups,
            minCount: this.dupType == 1 ? 1 : this.minCount
        }));
        this.duplicateDatasetsTable = new TableData([
            ...fields.map(f => new TableColumn(f.internalName, f.name)),
            new TableColumn("count", "Anzahl", TableCellType.number),
            new TableColumn("ids", "IDs", TableCellType.number)
        ], this.duplicateDatasets.map(p => new TableRow(p, {
            ...Utils.arrayToMap(fields, f => f.internalName, f => p.key[f.internalName]),
            count: p.count,
            ids: p.ids == null ? "" : Utils.arrayItemsToString(p.ids, ", ")
        })), [new TableSortColumn("count", false)]);
        this.noDupIds = this.duplicateDatasets.length > 0 && !this.duplicateDatasets.some(p => !Utils.isNoe(p.ids));
        GuiUtils.angularTimer(() => {
            this.findDupsTabIndex = 1;
        });
    }

    async copyDupIds(preserveOldest: boolean): Promise<void> {
        let result: number[] = [];
        for (let p of this.duplicateDatasets) {
            if (p.ids.length > 1) {
                result = [...result, ...preserveOldest ? Utils.arraySort(p.ids).slice(1) : Utils.arraySort(p.ids).slice(null, -1)];
            }
        }
        await GuiUtils.copyToClipboard(Utils.arrayItemsToString(result, "\r\n"));
    }

    async openDupsInEnaio(preserveOldest?: boolean): Promise<void> {
        let result: number[] = [];
        for (let p of this.duplicateDatasets) {
            if (p.ids.length > 1) {
                result = [...result, ...preserveOldest == null ? p.ids : preserveOldest ? Utils.arraySort(p.ids).slice(1) : Utils.arraySort(p.ids).slice(null, -1)];
            }
        }
        await DhTools.backendCall("api/enaio/openResultList", { ids: Utils.arrayItemsToString(result, ";")}).getText();
    }

    async generateDhFieldList(): Promise<void> {
        let result = await DhTools.backendCall("api/enaio/getDhConfigFieldList", {objectTypeId: this.getSelectedObjectType().Id}).getString();
        this.app.messageDialog.textArea("Vorlage", result, null, true);
    }

    async generateDhImportSource(): Promise<void> {
        let result = await DhTools.backendCall("api/enaio/getDhConfigImportSource", {objectTypeId: this.getSelectedObjectType().Id}).getString();
        this.app.messageDialog.textArea("Vorlage", result, null, true);
    }

    async generateDhFromEnaioWorker(): Promise<void> {
        let result = await DhTools.backendCall("api/enaio/getDhConfigFromEnaioWorker", {objectTypeId: this.getSelectedObjectType().Id}).getString();
        this.app.messageDialog.textArea("Vorlage", result, null, true);
    }

    async generateDhToEnaioWorker(): Promise<void> {
        let result = await DhTools.backendCall("api/enaio/getDhConfigToEnaioWorker", {objectTypeId: this.getSelectedObjectType().Id}).getString();
        this.app.messageDialog.textArea("Vorlage", result, null, true);
    }

    async exportObjectDef(): Promise<void> {
        let fileName = await DhTools.saveDialog("asobjdef.xml");
        if (!Utils.isNoe(fileName)) {
            await DhTools.backendCall("api/enaio/saveObjectDef", {fileName}).getString();
            await DhTools.backendCall("api/system/openFile", {dir: Utils.getFileNameWithoutPath(fileName)}).getString();
            this.app.showToast("success", "Erfolgreich", "Die Objektdefinition wurde exportiert");
        }
    }

    async syntaxCheck(showMessage = true): Promise<string> {
        let errors = await DhTools.backendCall("api/vbs/syntaxCheck", {code: this.aceEvent.text}).getString();
        if (showMessage) {
            await this.app.messageDialog.info("Es wurden " + (Utils.isNoe(errors) ? "keine " : "") + " Syntaxfehler gefunden" + (Utils.isNoe(errors) ? "" : ":<br><br>" + Utils.replaceAll(errors, "\n", "<br>")));
        }
        return errors;
    }

    async saveClientEvent(): Promise<void> {
        let errors = await this.syntaxCheck(false);
        if (Utils.isNoe(errors) || await this.app.messageDialog.yesNo("Es wurden Syntaxfehler gefunden. Soll dennoch gespeichert werden??:<br><br>" + Utils.replaceAll(errors, "\n", "<br>"), "Warnung")) {
            this.selectedEvent[1].code = this.aceEvent.text;
            await DhTools.enaioCall(Utils.fromPlain(EnaioCallUpdateClientEvent, { evt: this.selectedEvent[1] }));
        }
    }

    private getUserRightCell(p: EnaioCheckPermissionResult, type: EnaioAccessType): string {
        let data = p.accessTypes.find(t => t.accessType == type);
        return data?.allow ? !Utils.isNoe(data.readableClause) ? data.readableClause : !Utils.isNoe(data.clause) ? data.clause : "Ja" : "Nein";
    }

    async getObjectCounts(objectTypeInternalName?: string): Promise<void> {
        this.objectCounts = Utils.mapToOrdinaryObject(await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetObjectCounts, { objectTypeInternalNames: objectTypeInternalName != null ? [objectTypeInternalName] : null })));
    }

    async openResultList(objectType: EnaioObjectType): Promise<void> {
        await DhTools.backendCall("api/enaio/search", { select: Utils.fromPlain(EnaioCallSelect, {
            archive: EnaioObjectId.byInternalName(objectType.parent.internalName),
            objectType: EnaioObjectId.byId(objectType.Id)
        })}).getText();
    }

    async fixRenditionCache(objectType: EnaioObjectType): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Dies wird alle (potenziell viele) Dokumente in diesem Objekttyp prüfen und ggf. neu in die CPM-Queue stellen. Fortfahren?")) {
            return;
        }
        let count = await DhTools.backendCall("api/enaio/fixRenditionCache", { objectTypeId: new EnaioObjectTypeId(EnaioObjectId.byInternalName(objectType.parent.internalName), EnaioObjectId.byId(objectType.Id)) }).getNumber();
        this.app.showToast("success", "Rendition Cache gefixed", "Insgesamt " + count + " Dokumente wurden erneut in die CPM-Queue eingereiht");
    }

    async findValues(): Promise<void> {
        let connectionConfig = await DhTools.backendCall("api/dh/getEnaioDbConnection").get(DbConnectionConfig);
        let allOt = Utils.arrayExplode(this.def.cabinets, cab => cab.objectTypes);
        let searchArea = this.findValueForm.getValue("searchArea") as string;

        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/tools/databaseFindValue", {
            connectionConfig,
            query: this.findValueForm.getValue("query"),
            like: this.findValueForm.getValue("like"),
            tableNameRegex: "(" + Utils.arrayItemsToString((searchArea == "Dieser Objekttyp" ? [this.getSelectedObjectType()] : searchArea == "Dieser Schrank" ? this.getSelectedObjectType().parent.objectTypes : allOt).map(ot => ot.tableName), "|") + ")(list[0-9]+)?$"
        }).getText(), r => {
            let result = Utils.fromPlainArray(DatabaseValueFound, Utils.fromJson(r.value) as any[]);
            this.findValueTable = new TableData([
                new TableColumn("archive", "Archiv"),
                new TableColumn("objectType", "Objekttyp"),
                new TableColumn("field", "Feld"),
                new TableColumn("count", "Anzahl")
            ], result.map(item => new TableRow(item, {
                archive: allOt.find(ot => ot.tableName == item.tableName.tableName)?.parent?.name ?? "<???>",
                objectType: allOt.find(ot => ot.tableName == item.tableName.tableName)?.name ?? "<???>",
                field: allOt.find(ot => ot.tableName == item.tableName.tableName)?.getAllFields().find(f => Utils.stringEqualsCi(f.dbFieldName, item.columnName))?.name ?? "<???>",
                count: item.count
            })));
        });
    }

    async loadHistory(): Promise<void> {
        //let allOt = Utils.arrayExplode(this.def.cabinets, cab => cab.objectTypes);
        //let searchArea = this.historyForm.getValue("searchArea") as string;

        let compareToFileName = this.historyForm.getValue("compareToFileName");
        if (Utils.isNoe(compareToFileName)) {
            DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/enaio/getObjectDefHistory").getText(), r => {
                this.fillHistoryTable(Utils.fromPlainArray(EnaioObjectDefChange, Utils.fromJson(r.value) as any[]));
            });
        }
        else {
            this.fillHistoryTable(await DhTools.backendCall("api/enaio/compareObjectDefs", { compareToFileName }).list(EnaioObjectDefChange));
        }

    }

    fillHistoryTable(data: EnaioObjectDefChange[]): void {
        this.historyTable = new TableData([
            new TableColumn("date", "Datum", TableCellType.dateTime),
            new TableColumn("archive", "Archiv"),
            new TableColumn("objectType", "Objekttyp"),
            new TableColumn("field", "Feld"),
            new TableColumn("change", "Änderung")
        ], data.map(item => new TableRow(item, {
            date: item.date,
            archive: item.archive?.name,
            objectType: item.objectType?.name,
            field: item.field?.name,
            change: item.change
        })));
    }

    async activeTabChanged(): Promise<void> {
        if (this.tabIndex == 3) {
            await this.loadUserRights();
        }
    }

}
