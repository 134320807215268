import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Component, ViewChild, inject } from '@angular/core';
import { saveAs } from 'file-saver';
import { NgxJoditComponent } from 'ngx-jodit';
import { MenuItem, TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabView } from 'primeng/tabview';
import { delay, pipe, scan } from 'rxjs';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Config } from 'src/modules/app-template/models/config.model';
import { DatabaseUser } from 'src/modules/database/shared/database-user.entity';
import { WaitingFormStatus } from 'src/modules/sm-base/components/waiting-form/waiting-form.component';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { UploadedFile } from 'src/modules/utils/shared/uploaded-file.entity';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { CostCenter } from '../../shared/cost-center.model';
import { InspectionPhase } from '../../shared/inspection-phase.entity';
import { JobDescription } from '../../shared/job-description.entity';
import { LearningContentAttachment } from '../../shared/learning-content-attachment.entity';
import { LearningContentCategory } from '../../shared/learning-content-category.entity';
import { LearningContentChapter } from '../../shared/learning-content-chapter.entity';
import { LearningContentFinalQuestion } from '../../shared/learning-content-final-question.entity';
import { LearningContentInspectionPhase } from '../../shared/learning-content-inspection-phase.model';
import { LearningContentQuestion } from '../../shared/learning-content-question.entity';
import { LearningContentType } from '../../shared/learning-content-type.entity';
import { LearningContent } from '../../shared/learning-content.entity';
import { LearningContentStatus } from '../../shared/learning-content.status.entity';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';
import { MandatoryItem } from '../../shared/mandatory-item.model';
import { MandatoryLinkType } from '../../shared/mandatory-link-type.enum';
import { MessageToAuthor } from '../../shared/message-to-author.model';
import { UserGroup } from '../../shared/user-group.entity';
import { ChapterListComponent } from '../chapter-list/chapter-list.component';

@Component({
    selector: 'app-edit-learning-content',
    templateUrl: './edit-learning-content.component.html',
    styleUrls: ['./edit-learning-content.component.scss']
})
export class EditLearningContentComponent extends ComponentView {

    _Utils = Utils;
    _LearningContentStatus = LearningContentStatus;

    service = inject(EnaioLearnService);

    id: number;

    joditConfig: OrdinaryObject;
    profile: LearningUserFlat;
    item: LearningContent;
    users: LearningUserFlat[];
    authorMayChooseInspectorForCategories: number[];
    costCenters: CostCenter[];
    costCentersTree: TreeNode2[];
    contentTypes: LearningContentType[];
    categories: LearningContentCategory[];
    jobDescriptionGroups: string[];
    jobDescriptionsTree: TreeNode2[];
    userGroups: UserGroup[];
    userGroupsTree: TreeNode2[];
    form: FrontendFormDefinition;
    chapterForm: FrontendFormDefinition;
    finalExamForm: FrontendFormDefinition;
    selectedCostCenters: ArrayOrSingle<TreeNode> = [];
    selectedJobDescriptions: ArrayOrSingle<TreeNode> = [];
    selectedUserGroups: ArrayOrSingle<TreeNode> = [];
    contentPhases: LearningContentInspectionPhase[] = [];
    publishStatusText: string;
    publishStatusColor: string;
    forwardMessage: string;
    maySave: boolean;
    mayDelete = false;
    mandatoryType = 0;
    nextButtonItems: MenuItem[];
    attachmentsTable: TableData;

    originalJsonCompareString = "";

    lastTab: number = null;
    activeTab = 0;
    activeTabChapter = 0;
    deadlineType = 0;
    selectedChapter: LearningContentChapter;
    selectedChapterIndex = 0;
    selectedQuestion: LearningContentQuestion;
    selectedFinalExamQuestionIndex = 0;
    selectedFinalExamQuestion: LearningContentFinalQuestion;

    chapterItems: MenuItem[];

    targetAudienceTypes: any[] = [];

    @ViewChild("joditEditor")
    joditEditor: NgxJoditComponent;
    @ViewChild("tabView")
    tabView: TabView;
    @ViewChild("chapterlist")
    chapterListComponent: ChapterListComponent;

    emptyChapter = Utils.fromPlain(LearningContentChapter, {});
    emptyQuestion = Utils.fromPlain(LearningContentQuestion, {});

    constructor(private fileUpload: FileUploadService, private http: HttpClient) {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        pipe(delay(0), () => {
            this.app.showSpinner = true;
        });
        let result = await Promise.all([
            RestEndpoint.main().find().run("api/lrn/costcenter").list(CostCenter),
            RestEndpoint.main().find().run("api/lrn/jobdescription").list(JobDescription),
            RestEndpoint.main().find().run("api/lrn/learningcontenttype").list(LearningContentType),
            RestEndpoint.main().find().run("api/lrn/learningcontentcategory").list(LearningContentCategory),
            RestEndpoint.main().find().run("api/lrn/learninguserflat").list(LearningUserFlat),
            RestEndpoint.main().find().run("api/lrn/usergroup").list(UserGroup),
            RestEndpoint.main().run("api/lrn/inspectionphase/authormaychoosecategories").getText(),
            this.service.getMyProfile()
        ]);
        this.costCenters = result[0];
        this.costCentersTree = GuiUtils.generateTree(this.costCenters.filter(cc => cc.businessUnit != "" && cc.department != "" && cc.department != " -"), d => [this.app.t("lea.learningContent.targetAudienceAll"), d.businessUnit, d.department],
            (__, keys, index) => ({ label: Utils.toString(keys[index]), data: index == 0 ? null : new MandatoryItem(index == 1 ? MandatoryLinkType.businessUnit : MandatoryLinkType.department, keys[index] as string) }), true);
        this.costCentersTree[0].expanded = true;

        this.contentTypes = Utils.arraySortBy(result[2], item => item.name);
        this.categories = Utils.arraySortBy(result[3], item => item.name);

        this.jobDescriptionGroups = Utils.arraySort(Utils.arrayGetUnique(result[1].map(s => s.group)));
        this.jobDescriptionsTree = GuiUtils.generateTree(result[1], jd => [this.app.t("lea.learningContent.jobDescriptionsAll"), jd.group],
            (item, keys, index) => ({ label: keys[index], data: index == 0 ? null : new MandatoryItem(MandatoryLinkType.jobDescription, item.number) }), true);
        this.jobDescriptionsTree[0].expanded = true;

        this.userGroups = result[5];
        this.userGroupsTree = GuiUtils.generateTree(this.userGroups, ug => [this.app.t("lea.learningContent.userGroupsAll"), ug.name],
        (item, keys, index) => ({ label: keys[index], data: index == 0 ? null : new MandatoryItem(MandatoryLinkType.userGroup, Utils.toString(item.id)) }), true);

        this.users = result[4];
        this.authorMayChooseInspectorForCategories = Utils.fromJson(result[6]) as number[];

        this.profile = result[7];
        this.updateNextButtonIcons();

        let buttons = [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "eraser",
            "ul",
            "ol",
            "indent",
            "outdent",
            "left",
            "center",
            "right",
            "paragraph",
            "table",
            {
                name: 'uploadImage',
                icon: 'image',
                tooltip: "Bild hochladen",
                exec: async (__, ___, data) => {
                    this.uploadJodit = data.button.jodit;
                    await this.uploadImage();
                }
            },
            "video",
            {
                name: 'uploadVideo',
                icon: 'video',
                tooltip: "Video hochladen",
                exec: async (__, ___, data) => {
                    this.uploadJodit = data.button.jodit;
                    await this.uploadVideo();
                }
            },
            "link",
            "undo"
        ];
        this.joditConfig = {
            useSearch: false,
            spellcheck: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            buttons,
            buttonsMD: buttons,
            buttonsSM: buttons,
            buttonsXS: buttons,
            language: "de",
         //   askBeforePasteFromWord: false,
         //   defaultActionOnPasteFromWord: "insert_clear_html",
            placeholder: "Bitte geben Sie hier Ihren Text ein<ul><li>Verwenden Sie eine leicht verständliche Sprache</li><li>Geben Sie Ihre Quellen an</li></ul>"
        };

        this.targetAudienceTypes = [
            {label: this.app.t('lea.learningContent.targetAudienceTypes.0'), value: '01234'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.1'), value: '34'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.2'), value: '0124'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.3'), value: '4'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.4'), value: '3'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.5'), value: '012'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.6'), value: '0123'},
            {label: this.app.t('lea.learningContent.targetAudienceTypes.7'), value: '-'}
        ];

        await this.init();
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? this.app.t("lea.learningContent.messages.cancel") : null;
    }

    isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response;
      }

    isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
        return (
          event.type === HttpEventType.DownloadProgress ||
          event.type === HttpEventType.UploadProgress
        );
      }

    calculateStateVideo(status: WaitingFormStatus, event: HttpEvent<unknown>): WaitingFormStatus {
        if (this.isHttpProgressEvent(event)) {
            this.app.triggerChangeDetection();
            return {
                progress: event.total ? Math.round(100 * event.loaded) / event.total : status.progress,
                state: "Lade hoch"
            };
        }
        if (this.isHttpResponse(event)) {
            try {
                this.uploadDialog.close();
            }
            catch (ex) {
                //Fals uploadDialog zu diesem Zeitpunkt noch nicht fertig initialisiert war
            }
            this.uploadDialog = null;

            let file = Utils.fromPlain(UploadedFile, event.body);

            const p = this.uploadJodit.createInside.element('video');
            const source = this.uploadJodit.createInside.element("source");
            let backUrl = Config.get().backendUrl;
            source.setAttribute("src", (Utils.isNoe(backUrl) ? "" : backUrl) + "/api/app/fileupload/get/" + encodeURIComponent(file.path));
            p.setAttribute("width", "95%");
            p.setAttribute("controls", true);
            p.appendChild(source);
            this.uploadJodit.s.insertNode(p);

            this.app.messageDialog.infoNoWait("Das Video wurde hochgeladen. Falls der Inhalt im Texteditor noch nicht sichtbar ist, kann dies daran liegen, dass das Video im Hintergrund noch konvertiert werden muss. " +
                "Dies kann je nach Länge des Videos bis zu mehrere Minuten dauern. Sie können Ihre Arbeiten am Lerninhalt währenddessen fortsetzen");

            return {
                progress: 100,
                state: "Fertig"
            };
        }
        return status;
      }

      calculateStateImage(status: WaitingFormStatus, event: HttpEvent<unknown>): WaitingFormStatus {
        if (this.isHttpProgressEvent(event)) {
            this.app.triggerChangeDetection();
            return {
                progress: event.total ? Math.round(100 * event.loaded) / event.total : status.progress,
                state: "Lade hoch"
            };
        }
        if (this.isHttpResponse(event)) {
            this.uploadDialog.close();
            this.uploadDialog = null;

            let file = Utils.fromPlain(UploadedFile, event.body);

            const p = this.uploadJodit.createInside.element('img');

            let backUrl = Config.get().backendUrl;
            p.setAttribute("src", (Utils.isNoe(backUrl) ? "" : backUrl) + "/api/app/fileupload/get/" + encodeURIComponent(file.path));
            p.setAttribute("width", "300px");
            this.uploadJodit.s.insertNode(p);

          return {
            progress: 100,
            state: "Fertig"
          };
        }
        return status;
      }

    private uploadDialog: DynamicDialogRef;
    private uploadStatus: WaitingFormStatus;
    private uploadAttachmentTitle: string;
    private uploadJodit: any;

    async uploadImage(): Promise<void> {
        this.uploadStatus = {
            progress: 0,
            state: ""
        };

        let file = await this.fileUpload.chooseFile(false, [".jpg", ".jpeg", ".png"]);

        this.uploadDialog = this.app.messageDialog.waitingForm("Das Bild wird hochgeladen", "Bitte haben Sie einen Moment Geduld, während das Bild hochgeladen wird", null, () => this.uploadStatus);

        //Damit der Dialog angezeigt wird
        this.app.triggerChangeDetection();

        Utils.setTimerOnce(1000, () => {
            const data = new FormData();
            data.append('file', file[0].legacy);

            this.http.post(RestEndpoint.main().baseUrl + "/api/app/fileupload/upload", data, {
                reportProgress: true,
                observe: 'events'
            }).pipe(scan(this.calculateStateImage.bind(this), this.uploadStatus)).subscribe(u => { this.uploadStatus = u; });
        });
    }

    async uploadVideo(): Promise<void> {
        this.uploadStatus = {
            progress: 0,
            state: ""
        };
        let file = await this.fileUpload.chooseFile(false, [".mpg", ".mpeg", ".avi", ".mp4", "mov", ".wmv", ".mkv"]);

        this.uploadDialog = this.app.messageDialog.waitingForm("Das Video wird hochgeladen", "Bitte haben Sie einen Moment Geduld, während das Video hochgeladen wird", null, () => this.uploadStatus);

        //Damit der Dialog angezeigt wird
        this.app.triggerChangeDetection();

        Utils.setTimerOnce(1000, () => {
            const data = new FormData();
            data.append('file', file[0].legacy);

            this.http.post(RestEndpoint.main().baseUrl + "/api/app/fileupload/uploadEncode", data, {
                reportProgress: true,
                observe: 'events'
            }).pipe(scan(this.calculateStateVideo.bind(this), this.uploadStatus)).subscribe(u => { this.uploadStatus = u; });
        });
     }

    async init(): Promise<void> {
        this.app.showSpinner = true;

        this.item = await RestEndpoint.main().findByIdOrEmpty(LearningContent, this.id).run("api/lrn/learningcontent").get(LearningContent);

        if (this.item == null) {
            await this.app.loadDataError("Der angeforderte Lerninhalt wurde nicht gefunden", false, this);
            return;
        }

        if (this.id == 0) {
            this.item = Utils.fromPlain(LearningContent, {
                title: "",
                contentType: null, //Utils.arrayGetSafe(this.contentTypes, 0),
                category: this.categories[0],
                author: this.app.getUserName(),
                creationDate: new Date(),
                version: 1,
                chapters: [
                    Utils.fromPlain(LearningContentChapter, {
                        title: "Einführung"
                    })
                ]
            });
        }
        (this.item as any).inspectionResponsibleString = this.item.inspectionResponsible == null ? "" :
            Utils.arrayItemsToString(this.item.inspectionResponsible.map(r => this.users.find(u => u.id == r.id)).filter(u => u != null), ", ", u => u.firstName + " " + u.lastName);
        (this.item as any).finalExamNotifyUsersString = this.item.finalExamNotifyUsers == null ? "" :
            Utils.arrayItemsToString(this.item.finalExamNotifyUsers.map(r => this.users.find(u => u.id == r.id)).filter(u => u != null), ", ", u => u.firstName + " " + u.lastName);

        this.chapterItems = this.item.chapters.map(c => ({ label: c.title}));

        (this.item as any).deadlineType = this.item.deadlineRelativeToEntry != -1 ? 1 : this.item.deadlinePeriodicMonths > 0 ? 2 : this.item.deadlineFixed == null ? 3 : 0;
        let mis = this.item.getMandatoryItems();
        let flatCostCenters = GuiUtils.flattenTree(this.costCentersTree);
        this.selectedCostCenters = Utils.arrayWithoutNull(mis.map(mi => flatCostCenters.find(item => item.data != null && (item.data as MandatoryItem).type == mi.type && (item.data as MandatoryItem).value == mi.value)));
        let flatJobDescriptions = GuiUtils.flattenTree(this.jobDescriptionsTree);
        this.selectedJobDescriptions = Utils.arrayWithoutNull(mis.map(mi => flatJobDescriptions.find(item => item.data != null && (item.data as MandatoryItem).type == mi.type && (item.data as MandatoryItem).value == mi.value)));
        let flatUserGroups = GuiUtils.flattenTree(this.userGroupsTree);
        this.selectedUserGroups = Utils.arrayWithoutNull(mis.map(mi => flatUserGroups.find(item => item.data != null && (item.data as MandatoryItem).type == mi.type && (item.data as MandatoryItem).value == mi.value)));

        this.form = this.app.tForm("lea.learningContent", new FrontendFormDefinition([
            new FrontendFieldDefinition("title", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("description", null, FrontendFieldType.textArea, { mandatory: true }),
          //Nicht benötigt  new FrontendFieldDefinition("contentType", null, FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: this.contentTypes.map(ct => new FrontendFieldListItem(ct, ct.name))}),
            new FrontendFieldDefinition("category", null, FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItemValuePath: "id",
                listItems: this.categories.map(ct => new FrontendFieldListItem(ct.id, ct.name, { internalObject: ct })) }),
            new FrontendFieldDefinition("inspectionResponsibleString", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("deadlineType", null, FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: [
                new FrontendFieldListItem(0, this.app.t("lea.learningContent.deadlineTypes.0")),
                new FrontendFieldListItem(1, this.app.t("lea.learningContent.deadlineTypes.1")),
                new FrontendFieldListItem(2, this.app.t("lea.learningContent.deadlineTypes.2")),
                new FrontendFieldListItem(3, this.app.t("lea.learningContent.deadlineTypes.3"))
            ]}),
            new FrontendFieldDefinition("deadlineFixed", null, FrontendFieldType.datePicker),
            new FrontendFieldDefinition("deadlineRelativeToEntry", null, FrontendFieldType.number, { mandatory: true, minValue: 0, maxValue: 3650}),
            new FrontendFieldDefinition("deadlinePeriodicMonths", null, FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: [
                new FrontendFieldListItem(12, this.app.t("lea.learningContent.deadlinePeriodicMonthsTypes.12")),
                new FrontendFieldListItem(24, this.app.t("lea.learningContent.deadlinePeriodicMonthsTypes.24")),
                new FrontendFieldListItem(36, this.app.t("lea.learningContent.deadlinePeriodicMonthsTypes.36")),
                new FrontendFieldListItem(48, this.app.t("lea.learningContent.deadlinePeriodicMonthsTypes.48")),
                new FrontendFieldListItem(60, this.app.t("lea.learningContent.deadlinePeriodicMonthsTypes.60"))
            ]}),
            new FrontendFieldDefinition("deadlineFirstYear", null, FrontendFieldType.number, { mandatory: true, minValue: 2020}),
            new FrontendFieldDefinition("timeNeeded", null, FrontendFieldType.number, { mandatory: true, minValue: 0, minValueInclusive: false}),
            //Nicht benötigtnew FrontendFieldDefinition("canCharge", null, FrontendFieldType.checkBox),
            //new FrontendFieldDefinition("exposureTime", null, FrontendFieldType.number, { mandatory: true, minValue: 0, minValueInclusive: false}),
            new FrontendFieldDefinition("author", null, FrontendFieldType.text, { enabled: false, visible: this.item.id > 0 }),
            new FrontendFieldDefinition("creationDate", null, FrontendFieldType.datePicker, { enabled: false, visible: this.item.id > 0 }),
            new FrontendFieldDefinition("version", null, FrontendFieldType.text, { enabled: false, visible: this.item.id > 0 })
        ]));

     /*   this.form.getField("canCharge").onValueChanged = () => {
            this.form.getField("exposureTime").visible = this.form.getValue("canCharge");
        };
     */
        let updateContentPhasesOnEvent = false;
        this.form.getField("category").onValueChanged = async () => {
            let cat = this.form.getValue("category");
            this.form.getField("inspectionResponsibleString").visible = this.authorMayChooseInspectorForCategories.includes(0) || cat instanceof LearningContentCategory && this.authorMayChooseInspectorForCategories.includes(cat.id);
            if (updateContentPhasesOnEvent) {
                await this.updateContentPhases();
            }
        };
        this.form.getField("inspectionResponsibleString").onValueChanged = async () => {
            await this.updateContentPhases();
        };
        this.form.getField("deadlineType").onValueChanged = () => {
            let type = this.form.getField("deadlineType").value;
            this.form.getField("deadlineFixed").visible = type == 0;
            this.form.getField("deadlineRelativeToEntry").visible = type == 1;
            this.form.getField("deadlinePeriodicMonths").visible = type == 2;
            this.form.getField("deadlineFirstYear").visible = type == 2;
            if (type != 0) {
                this.form.setValue("deadlineFixed", null);
            }
            this.form.setValue("deadlineRelativeToEntry", type != 1 ? -1 : 0);
            if (type != 2) {
                this.form.setValue("deadlinePeriodicMonths", 0);
            }
        };

        this.form.fill(this.item);
     //  this.form.getField("canCharge").onValueChanged();
        this.form.getField("deadlineType").onValueChanged();
        this.form.getField("category").onValueChanged();
        updateContentPhasesOnEvent = true;

        this.chapterForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("title", this.app.t("lea.learningContent.chapter.title"), FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("allQuestions", this.app.t("lea.learningContent.chapter.allQuestions"), FrontendFieldType.checkBox),
            new FrontendFieldDefinition("numQuestions", this.app.t("lea.learningContent.chapter.numQuestions"), FrontendFieldType.number, { mandatory: true })
        ]);
        this.chapterForm.getField("allQuestions").onValueChanged = () => {
            this.chapterForm.getField("numQuestions").visible = !this.chapterForm.getValue("allQuestions");
        };

        this.selectChapter(0);
        this.selectFinalExamQuestion(0);

        this.finalExamForm = this.app.tForm("lea.learningContent.finalExam", new FrontendFormDefinition([
            new FrontendFieldDefinition("finalExam", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("finalExamAllQuestions", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("finalExamNumQuestions", null, FrontendFieldType.number),
            new FrontendFieldDefinition("finalExamPassPercentage", null, FrontendFieldType.number, { maxValue: 100 }),
            new FrontendFieldDefinition("finalExamNumTries", null, FrontendFieldType.number, { maxValue: 999 }),
            new FrontendFieldDefinition("finalExamNotifySupervisor", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("finalExamNotifyUsersString", null, FrontendFieldType.text)
        ]));

        this.finalExamForm.getField("finalExam").onValueChanged = () => {
            let v = this.finalExamForm.getValue("finalExam");
            this.finalExamForm.getField("finalExamAllQuestions").visible = v;
            this.finalExamForm.getField("finalExamNumQuestions").visible = v && !this.finalExamForm.getValue("finalExamAllQuestions");
            this.finalExamForm.getField("finalExamPassPercentage").visible = v;
            this.finalExamForm.getField("finalExamNumTries").visible = v;
            this.finalExamForm.getField("finalExamNotifySupervisor").visible = v;
            this.finalExamForm.getField("finalExamNotifyUsersString").visible = v;
            this.item.finalExam = v;
        };
        this.finalExamForm.getField("finalExamAllQuestions").onValueChanged = () => {
            this.finalExamForm.getField("finalExamNumQuestions").visible = this.finalExamForm.getValue("finalExam") && !this.finalExamForm.getValue("finalExamAllQuestions");
        };

        this.finalExamForm.fill(this.item);
        this.finalExamForm.getField("finalExam").onValueChanged();
        this.finalExamForm.getField("finalExamAllQuestions").onValueChanged();

        await this.updateContentPhases();
        let mayInspect = this.item.inspectionPhase != null && Utils.toBool(await RestEndpoint.main().query({id: this.item.inspectionPhase.id, contentId: this.item.id}).run("api/lrn/inspectionphase/mayinspect").get(InspectionPhase));
        switch (this.item.getStatus()) {
            case LearningContentStatus.published:
                this.publishStatusText = this.app.t("lea.learningContent.publishStatus.published");
                this.publishStatusColor = "success";
                this.forwardMessage = this.app.t("lea.learningContent.saveAndInspect");
                break;
            case LearningContentStatus.unpublished:
                this.publishStatusText = this.app.t("lea.learningContent.publishStatus.unpublished");
                this.publishStatusColor = "danger";
                this.forwardMessage = this.app.t("lea.learningContent.saveAndInspect");
                break;
            case LearningContentStatus.inInspection:
                this.publishStatusText = this.app.t("lea.learningContent.publishStatus.inInspection", { phaseName: this.item.inspectionPhase.name}) + ". " +
                    (mayInspect ? this.app.t("lea.learningContent.publishStatus.mayInspect") : this.app.t("lea.learningContent.publishStatus.mayNotInspect"));
                this.publishStatusColor = mayInspect ? "info" : "warning";
                this.forwardMessage = this.app.t("lea.learningContent." + (this.contentPhases.length == 0 || this.item.inspectionPhase.id == this.contentPhases[this.contentPhases.length - 1].phaseId ? "saveAndPublish" : "saveAndForward"));
                break;
        }

        this.maySave = this.profile.isAdmin || this.item.id == 0 || this.item.inspectionPhase == null || mayInspect;
        this.mayDelete = this.profile.isAdmin && this.item.id != 0;

        this.service.updateNavigation(true, [{ label: this.app.tCrudTitle(this.id == 0, this.item, "lea.learningContent"), routerLink: ["/enaio-learn", "learning-content", this.id]}]);

        if (this.id > 0 && this.lastTab != null) {
            this.activeTab = this.lastTab;
        }
        localStorage.setItem("lastLearningContentId", Utils.toString(this.id));

        this.originalJsonCompareString = Utils.toJson(this.item);

        this.app.showSpinner = false;
    }

    isChanged(): boolean {
        this.fillFromGui();
        return this.originalJsonCompareString != Utils.toJson(this.item);
    }

    fillFromGui(): void {
        this.item.mandatoryItems = Utils.arrayWithoutNull([...Utils.asArray(this.selectedCostCenters) || [], ...Utils.asArray(this.selectedJobDescriptions) || [], ...Utils.asArray(this.selectedUserGroups) || []].map(cc => cc.data as MandatoryItem)).map(mi => mi.toInternal());
        this.saveChapter();

        this.form.get(this.item);
        this.finalExamForm.get(this.item);
        let index = 0;
        for (let chapter of this.item.chapters) {
            chapter.index = ++index;
        }
        this.item.inspectionResponsible = this.getInspectionResponsible((this.item as any).inspectionResponsibleString as string);
        this.item.finalExamNotifyUsers = this.getFinalExamNotifyUsers((this.item as any).finalExamNotifyUsersString as string);
    }

    async save(forward = false, backToAuthor = false): Promise<void> {
        this.fillFromGui();
        if (!this.app.validateForm(this.form, () => {
            let result = this.item.getMandatoryErrors();
            this.getInspectionResponsible((this.item as any).inspectionResponsibleString as string, result);
            if (this.item.finalExam) {
                this.getFinalExamNotifyUsers((this.item as any).finalExamNotifyUsersString as string, result);
            }
            return result;
        })) {
            return;
        }

        let stayPublished = false;
        if (!backToAuthor) {
            stayPublished = this.profile.isAdmin && this.item.getStatus() == LearningContentStatus.published &&
                await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.messages.stayPublished"), this.app.t("general.info"));

            if (!stayPublished && this.item.getStatus() == LearningContentStatus.published &&
                !await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.messages.willUnpublish"), this.app.t("general.warning"))) {
                return;
            }
        }

        let [ok, result] = await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.item).query({forward, noDraft: stayPublished}).run("api/lrn/learningcontent").getText());
        if (ok && !backToAuthor) {
            this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
            if (this.id == 0) {
                this.skipCloseCheck = true;
                await this.app.navigateTo(["/enaio-learn", "learning-content", result]);
            }
            else {
                await this.init();
            }
        }
    }

    async saveAndForward(): Promise<void> {
        await this.save(true);
    }

    async backToAuthor(): Promise<void> {
        if (!await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.messages.backToAuthor"), this.app.t("general.warning"))) {
            return;
        }

        let text = await this.app.messageDialog.input<string>(new FrontendFieldDefinition("text", this.app.t("lea.learningContent.backToAuthorFeedback"), FrontendFieldType.textArea, { mandatory: true }), this.app.t("lea.learningContent.backToAuthor"));
        if (text == null) {
            return;
        }
        try {
            await this.save(false, true);

            await RestEndpoint.main().body(Utils.fromPlain(MessageToAuthor, {
                contentId: this.item.id,
                subject: "",
                text
            })).post().run("api/lrn/learningcontent/inspectionFeedbackAuthor").getText();
            this.app.showToast("success", this.app.t("lea.learningContent.messages.sendMessageOk_title"), this.app.t("lea.learningContent.messages.sendMessageOk"));

            await this.init();
        }
        catch (ex) {
            await this.app.messageDialog.info(this.app.t("lea.learningContent.messages.sendMessageError"), this.app.t("general.error"));
        }

    }

    async deleteContent(): Promise<void> {
        if (await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.messages.delete"), this.app.t("general.warning"))) {
            await this.app.deleteDataHandler(async () => RestEndpoint.main().delete().query({id: this.item.id}).run("api/lrn/learningcontent").getText(), this, true, true, null, "Der Lerninhalt wurde gelöscht");
        }
    }

    async cancel(): Promise<void> {
        if (!this.isChanged() || await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.messages.cancel"), this.app.t("general.warning"))) {
            this.skipCloseCheck = true;
            this.app.navigateBack();
        }
    }

    saveChapter(): void {
        if (this.selectedChapter != null) {
            this.chapterForm.get(this.selectedChapter);
            if (this.chapterForm.getField("allQuestions").value) {
                this.selectedChapter.numQuestions = 0;
            }
        }
    }

    selectChapter(index: number): void {
        this.saveChapter();
        this.selectedChapter = this.item.chapters[index];
        if (this.selectedChapter != null) {
            //Undo-History löschen. Dies muss zeitversetzt passieren, da sonst beim nächsten Angular-Lauf der geänderte Kapiteltext erkannt und die Historie aufgenommen wird
            Utils.setTimerOnce(100, () => this.joditEditor?.jodit?.history?.clear());
            (this.selectedChapter as any).allQuestions = this.selectedChapter.numQuestions == 0;
            this.chapterForm.fill(this.selectedChapter);
            this.chapterForm.getField("allQuestions").onValueChanged();
            this.selectQuestion(Math.min(0, this.selectedChapter.questions.length - 1));

            this.attachmentsTable = new TableData([
                new TableColumn("icon", "", TableCellType.icon, { width: "30px"}),
                new TableColumn("title", "Titel"),
                new TableColumn("_download", "", TableCellType.button, { sortable: false, filterable: false, width: "50px" })
            ], this.selectedChapter.attachments.map(a => new TableRow(a, {
                icon: "fas fa-" + a.getIcon(),
                title: a.title,
                _download: "fas fa-download"
            })), [], async () => this.addAttachment());
        }
        this.updateNextButtonIcons();
    }

    async downloadAttachment(event: any): Promise<void> {
        if (event[1] == "_download") {
            let att = event[0].raw as LearningContentAttachment;
           saveAs(await RestEndpoint.main().runBlob("api/app/fileupload/get/" + att.fileName), att.title + "." + Utils.getSubstringFromLast(att.fileName, "."));
       }
    }

    deleteAttachment(row: [TableRow, number]): void {
        this.selectedChapter.attachments.splice(row[1], 1);
    }

    async addAttachment(): Promise<TableRow> {
        this.uploadStatus = {
            progress: 0,
            state: ""
        };
        let file = await this.fileUpload.chooseFile(false, [".pdf", ".mp3", ".wav"]);

        let title = await this.app.messageDialog.input<string>(new FrontendFieldDefinition("title", "Titel", FrontendFieldType.text, { mandatory: true, value: Utils.getSubstringToLast(file[0].legacy.name, ".") }), "Name des Anhangs");
        if (title == null) {
            return null;
        }

        this.uploadAttachmentTitle = title;
        this.uploadDialog = this.app.messageDialog.waitingForm("Die Datei wird hochgeladen", "Bitte haben Sie einen Moment Geduld, während die Datei hochgeladen wird", null, () => this.uploadStatus);

        Utils.setTimerOnce(1000, () => {
            const data = new FormData();
            data.append('file', file[0].legacy);

            this.http.post(RestEndpoint.main().baseUrl + "/api/app/fileupload/upload", data, {
                reportProgress: true,
                observe: 'events'
            }).pipe(scan(this.calculateStateAttachment.bind(this), this.uploadStatus)).subscribe(u => { this.uploadStatus = u; });
        });

        return null;
    }

    calculateStateAttachment(status: WaitingFormStatus, event: HttpEvent<unknown>): WaitingFormStatus {
        if (this.isHttpProgressEvent(event)) {
          return {
            progress: event.total ? Math.round(100 * event.loaded) / event.total : status.progress,
            state: "Lade hoch"
          };
        }
        if (this.isHttpResponse(event)) {
            this.uploadDialog.close();
            this.uploadDialog = null;

            let file = Utils.fromPlain(UploadedFile, event.body);

            let att = Utils.fromPlain(LearningContentAttachment, {
                chapter: this.selectedChapter,
                fileName: file.path,
                title: this.uploadAttachmentTitle
            });
            att.calculateType();

            this.selectedChapter.attachments.push(att);
            this.attachmentsTable.rows.push(new TableRow(att, {
                icon: "fas fa-" + att.getIcon(),
                title: att.title,
                _download: "fas fa-download"
            }));

            return {
                progress: 100,
                state: "Fertig"
            };
        }
        return status;
    }

    selectQuestion(index: number): void {
        this.selectedQuestion = this.selectedChapter.questions[index];
    }

    selectFinalExamQuestion(index: number): void {
        this.selectedFinalExamQuestion = Utils.arrayGetSafe(this.item.finalExamQuestions, index);
    }

    getInspectionResponsible(s: string, fillErrorsTo?: OrdinaryObject): DatabaseUser[] {
        if (s == null) {
            return null;
        }
        let result: DatabaseUser[] = [];
        for (let name of Utils.arrayGetUnique(Utils.split(s, ","))) {
            name = name.trim();
            let user = this.users.find(u => u.firstName + " " + u.lastName == name);
            if (user != null) {
                result.push(Utils.fromPlain(DatabaseUser, { id: user.id }));
            }
            else if (fillErrorsTo != null) {
                fillErrorsTo.inspectionResponsible = "Im Feld 'Inhalt wird geprüft von' wurde der unbekannte Benutzer '" + name + "' angegeben";
                return null;
            }
        }
        return result;
    }

    getFinalExamNotifyUsers(s: string, fillErrorsTo?: OrdinaryObject): DatabaseUser[] {
        if (s == null) {
            return null;
        }
        let result: DatabaseUser[] = [];
        for (let name of Utils.split(s, ",")) {
            name = name.trim();
            let user = this.users.find(u => u.firstName + " " + u.lastName == name);
            if (user != null) {
                result.push(Utils.fromPlain(DatabaseUser, { id: user.id }));
            }
            else if (fillErrorsTo != null) {
                fillErrorsTo.finalExamNotifyUsers = "Im Feld 'Nach erfolglosem Absolvieren folgende Personen informieren' wurde der unbekannte Benutzer '" + name + "' angegeben";
                return null;
            }
        }
        return result;
    }

    async updateContentPhases(): Promise<void> {
        this.form.get(this.item);
        let resp = this.getInspectionResponsible((this.item as any).inspectionResponsibleString as string);
        this.contentPhases = await RestEndpoint.main().query({
            contentId: this.item.id,
            categoryId: this.item.category != null ? this.item.category.id : 0,
            responsibleUserIds: resp != null ? Utils.arrayItemsToString(resp, ",", s => Utils.toString(s.id)) : ""
        }).run("api/lrn/inspectionphase/forcontent").list(LearningContentInspectionPhase);
        let ip = this.item.inspectionPhase;
        for (let cp of this.contentPhases) {
            cp.done = this.item.getStatus() == LearningContentStatus.published || ip != null && cp.index < ip.index;
            cp.current = ip != null && cp.index == ip.index;
            cp.toDo = this.item.getStatus() != LearningContentStatus.published && (ip == null || cp.index > ip.index);
        }
        for (let cp of this.contentPhases) {
            cp.responsibleUserNames = Utils.arraySort(cp.responsibleUserNames);
        }
    }

    changeTab(): void {
        this.lastTab = this.activeTab;

    }
    previousTab(): void {
        this.activeTab--;
    }

    nextTab(): void {
        this.activeTab++;
    }

    addChapter(alreadyAdded: boolean): void {
        if (!alreadyAdded) {
            this.item.chapters.push(this.emptyChapter);
        }
        this.activeTabChapter = 0;
    }

    setCloseOk(): void {
        this.skipCloseCheck = true;
    }

    updateNextButtonIcons(): void {
        this.nextButtonItems = Utils.arrayWithoutNull([
            {
                tooltip: this.forwardMessage,
                icon: 'fas fa-glasses',
                command: async () => this.saveAndForward()
            },
            {
                tooltip: this.app.t('general.save'),
                icon: 'fas fa-save',
                command: async () => this.save()
            },
            this.item?.chapters == null || this.item?.chapters?.length === 0 || this.selectedChapter == this.item.chapters[this.item.chapters.length - 1] ? {
                tooltip: this.app.t('lea.learningContent.chapter.add'),
                icon: 'fas fa-plus',
                command: () => this.addChapter(false)
            } : null,
            this.item?.chapters?.length > 0 && this.selectedChapter != this.item.chapters[this.item.chapters.length - 1] ? {
                tooltip: this.app.t('lea.learningContent.chapter.next'),
                icon: 'fas fa-arrow-right',
                command: () => this.chapterListComponent.select(this.chapterListComponent.selectedIndex + 1)
            } : null
        ]);
    }

}
