import { TableCellType } from "src/modules/sm-base/shared/table-cell-type.enum";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { ConfigClassDefinition } from "./config-class-definition.model";
import { ConfigDefinition } from "./config-definition.model";
import { ConfigFieldType } from "./config-field-type.enum";
import { ConfigProperty } from "./config-property.model";
import { FieldFlags } from "./field-flags.enum";

export class ConfigFieldDefinition {
    name: string;
    type: ConfigFieldType;
    typeName: string;
    arrayDimensions: number;
    flags: FieldFlags;
    defaultValue: any;
    mandatory: boolean;
    mandatoryCondition: string;
    nullAllowed = true;
    specialValues: OrdinaryObject;

    speakingName: string;
    helpText: string;

    getPropertyName(useSpeaking: boolean): string {
        return useSpeaking && !Utils.isNoe(this.speakingName) ? this.speakingName : this.name;
    }

    getTableCellType(): TableCellType {
        switch (this.type) {
            case ConfigFieldType.string:
                return TableCellType.text;
            case ConfigFieldType.bool:
                return TableCellType.yesNo;
            case ConfigFieldType.enum:
                return TableCellType.dropdown;
            case ConfigFieldType.date:
                return TableCellType.date;
            case ConfigFieldType.int:
            case ConfigFieldType.long:
                return this.flags == FieldFlags.byteSize ? TableCellType.byteSize : this.flags == FieldFlags.duration ? TableCellType.duration : TableCellType.number;
            default:
                return TableCellType.text;
        }
    }

    allowNull(): boolean {
        return this.nullAllowed && !this.mandatory && (this.type == ConfigFieldType.string || this.type == ConfigFieldType.anything || this.type == ConfigFieldType.object);
    }

    tryGetTypeName(o: any): string {
        if (Utils.hasProperty(o, "type")) {
            let typeName = o.type;
            return Utils.stringIsInt(typeName as string) ? this.typeName : ConfigProperty.getActualTypeName(typeName as string, this.typeName);
        }
        else {
            return this.typeName;
        }
    }

    toProperty(o: any, globalDef: ConfigDefinition, indexOnArray = -1): ConfigProperty {
        let v = indexOnArray >= 0 ? o[indexOnArray] : o[this.name];
        let typeName = this.tryGetTypeName(v);
        let clazz = globalDef.getByName(typeName);
        if (clazz == null) {
            console.log("Es wurde keine Klasse mit dem Namen " + typeName + " gefunden");
            return new ConfigProperty(v, typeName, false, this, [], o as OrdinaryObject, indexOnArray);
        }
        return new ConfigProperty(v, typeName, true, indexOnArray == -1 ? this : this.getArrayElementDefinition(),
            this.type == ConfigFieldType.object && v != null ? (indexOnArray == -1 ? this : this.getArrayElementDefinition()).getProperties(clazz, v, globalDef) : [], o as OrdinaryObject, indexOnArray);
    }

    getProperties(classDef: ConfigClassDefinition, o: any, globalDef: ConfigDefinition): ConfigProperty[] {
         if (this.arrayDimensions > 0) {
            return Utils.isArray(o) ? Utils.arrayMapWithIndex(o as any[], (item, index) => this.toProperty(o, globalDef, index)) : [];
        }
        else {
            return classDef.getProperties(o, globalDef);
        }
    }

    getArrayElementDefinition(): ConfigFieldDefinition {
        let result = Utils.fromPlain(ConfigFieldDefinition, {...this});
        if (result.arrayDimensions > 0) {
            result.arrayDimensions--;
        }
        return result;
    }


    getSimpleTypeName(): string {
        switch (this.type) {
            case ConfigFieldType.string:
                return "string";
            case ConfigFieldType.int:
                return "int";
            case ConfigFieldType.long:
                return "long";
            case ConfigFieldType.bool:
                return "bool";
            case ConfigFieldType.date:
                return "DateTime";
            case ConfigFieldType.enum:
                return this.typeName == null ? "<Unbekannte enum>" : Utils.getSubstringFromLast(this.typeName, ".");
            case ConfigFieldType.enumValue:
                return this.typeName == null ? "<Unbekannter enumValue>" : Utils.getSubstringFromLast(this.typeName, ".");
            case ConfigFieldType.anything:
                return "object";
            case ConfigFieldType.object:
                return this.typeName == null ? "<Unbekannter Typ>" : Utils.getSubstringFromLast(this.typeName, ".");
            case ConfigFieldType.timeSpan:
                return "TimeSpan";
            default:
                return "<???>";
        }
    }
}
