import { Component, Inject, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { MessageDialogService } from 'src/modules/sm-base/services/message-dialog.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';
import { Course } from '../../shared/course.entity';
import { DanglingLesson } from '../../shared/dangling-lesson.model';
import { Teacher } from '../../shared/teacher.model';

@Component({
  selector: 'app-class-analyses-dangling-lessons',
  templateUrl: './class-analyses-dangling-lessons.component.html',
  styleUrls: ['./class-analyses-dangling-lessons.component.scss']
})
export class ClassAnalysesDanglingLessonsComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    lessons: DanglingLesson[];
    courses: Course[];
    teachers: Teacher[];
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, null, null, true);
        this.lessons = await RestEndpoint.main().query({id: this.id}).run("api/cer/classregister/danglinglessons").list(DanglingLesson);
        this.courses = await this.app.loadDataHandler(async () => RestEndpoint.main().query({year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: this.classRegister.isHalfYear, grade: this.classRegister.enaioSchoolClass.grade }).run("api/cer/classregister/course").list(Course), this);
        this.teachers = await this.service.restGetTeachers();

        this.table = new TableData([
            new TableColumn("date", "Datum", TableCellType.date),
            new TableColumn("weekDay", "Wochentag"),
            new TableColumn("hour", "Stunde", TableCellType.number),
            new TableColumn("domain", "Fach/Kurs"),
            new TableColumn("teacher", "Lehrkraft")
        ], this.lessons.map(lesson => new TableRow(lesson, {
            date: lesson.date,
            weekDay: Utils.getWeekDayName(Utils.dateDayOfWeek(lesson.date)),
            hour: lesson.hour + 1,
            domain: lesson.courseId > 0 ? Utils.arrayFindAndConvert(this.courses, c => c.id == lesson.courseId, c => c.title, "<Unbekannter Kurs>") : lesson.domain,
            teacher: lesson.teacher == null ? "" : Utils.arrayFindAndConvert(this.teachers, t => t.userName == lesson.teacher, t => t.getDisplayName(), lesson.teacher)
        })), [new TableSortColumn("date", false), new TableSortColumn("hour", false)]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Auswertungen", routerLink: ["/enaio-certificates", "class-registers", "analyses", this.id] },
            { label: "Nicht bearbeitete Unterrichtseinheiten", routerLink: ["/enaio-certificates", "class-registers", "analyses", "dangling-lessons", this.id] }
        ]);
        return true;
    }

    async selectRow(event: any): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Soll die Unterrichtseinheit angelegt werden?", "Frage")) {
            return;
        }

        let item = event.raw as DanglingLesson;
        let id = await this.service.restGetLessonId(this.id, item.date, item.hour, item.domain, item.courseId);
        if (id > 0) {
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "lesson", id, this.classRegister.id]);
        }
        else {
            await this.app.messageDialog.show("Die Unterrichtseinheit konnte nicht angelegt werden", "Fehler", this.app.messageDialog.ok);
        }
    }
}
