import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioIdSearchResult } from 'src/modules/enaio/shared/EnaioIdSearchResult';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-enaio-id-search',
  templateUrl: './enaio-id-search.component.html',
  styleUrls: ['./enaio-id-search.component.scss']
})
export class EnaioIdSearchComponent extends ComponentView {

    formIdSearch: FrontendFormDefinition;
    formBarcode: FrontendFormDefinition;
    tableDataIdSearchUsers: TableData;
    tableDataIdSearchOrganisations: TableData;
    tableDataIdSearchOrgObjects: TableData;
    tableDataIdSearchWfFamilies: TableData;
    tableDataIdSearchWfModels: TableData;
    tableDataIdSearchProcesses: TableData;
    tableDataIdSearchObjectTypes: TableData;
    tableDataIdSearchFields: TableData;
    serverInfo: string = null;
    smCodeBlob: SafeUrl;
    anythingFound: boolean = null;

    constructor() {
        super();
        this.formIdSearch = new FrontendFormDefinition([
            new FrontendFieldDefinition("id", "Name, interner Name, ID oder GUID", FrontendFieldType.text, { mandatory: true })
        ]);
    }

    async searchId(): Promise<void> {
        this.anythingFound = null;

        let data = await DhTools.backendCall("api/enaio/searchId", { id: this.formIdSearch.getValue("id") }).get(EnaioIdSearchResult);

        for (let m of data.wfModels) {
            (m as any).familyName = m.family.name;
        }

        this.tableDataIdSearchUsers = Utils.isNoe(data.users) ? null : TableData.fromList(data.users);
        this.tableDataIdSearchOrganisations = Utils.isNoe(data.organisations) ? null : TableData.fromList(data.organisations, ["id", "name", "active"]);
        this.tableDataIdSearchOrgObjects = Utils.isNoe(data.orgObjects) ? null : TableData.fromList(data.orgObjects, ["id", "name", "classId"]);
        this.tableDataIdSearchWfFamilies = Utils.isNoe(data.wfFamilies) ? null : TableData.fromList(data.wfFamilies, ["id", "name"]);
        this.tableDataIdSearchWfModels = Utils.isNoe(data.wfModels) ? null : TableData.fromList(data.wfModels, ["id", "name", "instanceName", "familyId", "familyName"]);
        this.tableDataIdSearchProcesses = Utils.isNoe(data.processes) ? null : TableData.fromList(data.processes, ["id", "name"]);
        this.tableDataIdSearchObjectTypes = Utils.isNoe(data.objectTypes) ? null : TableData.fromList(data.objectTypes, ["name", "internalName", "tableName", "Id"]);
        this.tableDataIdSearchFields = Utils.isNoe(data.fields) ? null : TableData.fromList(data.fields, ["objectType.name", "field.name", "field.internalName", "field.osGuid"], true);
        this.anythingFound = !Utils.isNoe(data.users) || !Utils.isNoe(data.organisations) || !Utils.isNoe(data.orgObjects) || !Utils.isNoe(data.wfFamilies) || !Utils.isNoe(data.wfModels) || !Utils.isNoe(data.processes) || !Utils.isNoe(data.objectTypes);
    }
}
