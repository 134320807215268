import { GeneralObjectKey } from "src/modules/enaio/shared/GeneralObjectKey";
import { GeneralObjectActionProvider } from "./general-object-action-provider.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";

export class TableCell implements Comparable<TableCell> {

    value: any;
    style: string;
    styleClass: string;
    keepHtml: boolean;
    internal: GeneralObjectKey[];
    actionProviders: GeneralObjectActionProvider[];

    constructor (value: any, props: Partial<TableCell> = null) {
        this.value = value;
        if (props != null) {
            Object.assign(this, props);
        }
    }

    compareTo(other: TableCell): number {
        return Utils.cmp(this.value, other instanceof TableCell ? other.value : other);
    }

}
