import { ApplicationRef, Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormDialogComponent } from '../components/form-dialog/form-dialog.component';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { WaitingFormComponent, WaitingFormStatus } from '../components/waiting-form/waiting-form.component';
import { ButtonDefinition } from '../models/button-definition.model';
import { MessageDialogOptions } from '../models/message-dialog-options.model';
import { FrontendFieldDefinition } from '../shared/frontend-field-definition.model';
import { FrontendFieldType } from '../shared/frontend-field-type.enum';
import { FrontendFormDefinition } from '../shared/frontend-form-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { ArrayOrSingle } from '../shared/array-or-single.model';

@Injectable()
export class MessageDialogService {

    ok = new ButtonDefinition("ok", "OK", true, "");
    yes = new ButtonDefinition("yes", "Ja", true, "");
    no = new ButtonDefinition("no", "Nein", false, "");
    cancel = new ButtonDefinition("cancel", "Abbrechen", false, "");
    abort = new ButtonDefinition("abort", "Abbrechen", false, "");
    retry = new ButtonDefinition("retry", "Wiederholen", true, "");
    ignore = new ButtonDefinition("ignore", "Ignorieren", false, "");
    confirm = new ButtonDefinition("confirm", "Bestätigen", true, "");
    save = new ButtonDefinition("save", "Speichern", true, "");
    delete = new ButtonDefinition("delete", "Löschen", false, "");

    okCancel = [this.ok, this.cancel];
    yesNoCancel = [this.yes, this.no, this.cancel];
    abortRetryIgnore = [this.abort, this.retry, this.ignore];
    retryCancel = [this.retry, this.cancel];

    constructor(private dialogService: DialogService, private applicationRef: ApplicationRef) {
    }

    infoNoWait(message: string, title?: string): void {
        this.showNoWait(message, Utils.stringDef(title, "Information"), this.ok);
    }

    showNoWait(message: string, caption: string, buttons: ArrayOrSingle<ButtonDefinition>, _icon = "", monospace?: boolean): DynamicDialogRef {
        return this.dialogService.open(MessageDialogComponent, {
            data: {
                message,
                buttons: Utils.asArray(buttons)
            },
            header: caption,
            // width: '70%',
            contentStyle: { "max-height": "350px", overflow: "auto", "font-family": monospace ? "monospace" : null }
        });
    }

    formNoWait(form: FrontendFormDefinition, caption: string, buttons: ArrayOrSingle<ButtonDefinition>, options: MessageDialogOptions = null): DynamicDialogRef {
        return this.dialogService.open(FormDialogComponent, {
            data: {
                form,
                buttons: Utils.asArray(buttons)
            },
            header: caption,
            width: options?.width ?? "600px",
            contentStyle: { width: "100%", "max-height": "90vh", overflow: "auto" }
        });
    }

    async info(message: string, title?: string, options?: { icon?: string; monospace?: boolean }): Promise<void> {
        await this.show(message, Utils.stringDef(title, "Information"), this.ok, options);
    }

    async yesNo(message: string, title?: string, closeOption = "no", options?: { icon?: string; monospace?: boolean }): Promise<boolean> {
        return await this.show(message, title, [this.yes, this.no], { closeOption, icon: options?.icon, monospace: options?.monospace }) == "yes";
    }

    async show(message: string, caption: string, buttons: ArrayOrSingle<ButtonDefinition>, options?: { icon?: string; monospace?: boolean; closeOption?: string }): Promise<any> {
        let ref = this.dialogService.open(MessageDialogComponent, {
            data: {
                message,
                buttons: Utils.asArray(buttons)
            },
            header: caption,
            closable: !Utils.isNoe(options?.closeOption),
            // width: '70%',
            contentStyle: { "max-height": "350px", overflow: "auto", "font-family": options?.monospace ? "monospace" : null }
        });
        return new Promise((resolve, _) => {
            this.applicationRef.tick();
            let result: any[] = [];
            //Es geht hier erst weiter wenn der Dialog destroyed wurde, ansonsten kann es sein, dass zwischen close und destroy
            //ein neuer Dialog geöffnet wird. Dann bleibt die GUI hängen.
            ref.onClose.subscribe(r => result.push(r ?? options?.closeOption));
            ref.onDestroy.subscribe(__ => resolve(result[0]));
        });
    }

    textArea(caption: string, text: string, buttons?: ArrayOrSingle<ButtonDefinition>, monospaced?: boolean, height?: number, options: MessageDialogOptions = null): DynamicDialogRef {
        return this.formNoWait(new FrontendFormDefinition([new FrontendFieldDefinition("text", "", FrontendFieldType.textArea, {
            hasLabel: false,
            value: text,
            monospaced,
            fixedHeight: height != null ? height + "px" : "80vh"
        })]), caption, buttons ?? this.ok, { ... options, width: "90%" } );
    }

    async form(form: FrontendFormDefinition, caption: string, buttons?: ArrayOrSingle<ButtonDefinition>, closable?: boolean): Promise<string> {
        let ref = this.dialogService.open(FormDialogComponent, {
            data: {
                form,
                buttons: Utils.asArray(buttons ?? this.okCancel)
            },
            header: caption,
            closable,
            contentStyle: { "max-width": "600px", "max-height": "90vh", overflow: "auto" }
        });
        return new Promise((resolve, _) => {
          //  this.applicationRef.tick(); Zum testen rausgenommen wegen Endlosschleife
            let result: any[] = [];
            //Es geht hier erst weiter wenn der Dialog destroyed wurde, ansonsten kann es sein, dass zwischen close und destroy
            //ein neuer Dialog geöffnet wird. Dann bleibt die GUI hängen.
            ref.onClose.subscribe(r => {
                result.push(r);
            });
            ref.onDestroy.subscribe(__ => {
                resolve(result[0] as string);
            });
        });
    }

    async input<T>(field: FrontendFieldDefinition, title: string): Promise<T> {
        let form = new FrontendFormDefinition([field]);
        return await this.form(form, title) != "ok" ? null : form.getValue(field.id) as T;
    }

    async formGetObject(form: FrontendFormDefinition, caption: string, closable?: boolean): Promise<OrdinaryObject> {
        let ref = this.dialogService.open(FormDialogComponent, {
            data: {
                form,
                buttons: this.okCancel
            },
            header: caption,
            closable,
            contentStyle: { "max-width": "600px", "max-height": "90vh", overflow: "auto" }
        });
        return new Promise((resolve, _) => {
          //  this.applicationRef.tick(); Zum testen rausgenommen wegen Endlosschleife
            let result: any[] = [];
            //Es geht hier erst weiter wenn der Dialog destroyed wurde, ansonsten kann es sein, dass zwischen close und destroy
            //ein neuer Dialog geöffnet wird. Dann bleibt die GUI hängen.
            ref.onClose.subscribe(r => {
                result.push(r);
            });
            ref.onDestroy.subscribe(__ => {
                resolve((result[0] as string) == "ok" ? form.getAsObject() : null);
            });
        });
    }

    waitingForm(caption: string, message: string, progressUrl: string, callback: () => WaitingFormStatus): DynamicDialogRef {
        return this.dialogService.open(WaitingFormComponent, {
            data: {
                message,
                progressUrl,
                callback
            },
            header: caption,
            modal: true,
            closable: false,
            contentStyle: { "max-height": "350px", overflow: "auto" }
        });
    }
}
