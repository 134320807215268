
import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioChangeEntry } from 'src/modules/enaio/shared/EnaioChangeEntry';
import { EnaioWatcher } from 'src/modules/enaio/shared/EnaioWatcher';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-enaio-watcher',
    templateUrl: './enaio-watcher.component.html',
    styleUrls: ['./enaio-watcher.component.scss']
})
export class EnaioWatcherComponent extends ComponentView {

    _Utils = Utils;

    formGeneral: FrontendFormDefinition;
    formSpecific: FrontendFormDefinition;
    formWatchObjectId: FrontendFormDefinition;
    registeredWatchers: EnaioWatcher[] = [];
    activeWatcherTab = 0;

    timer: SmTimer;

    result: EnaioChangeEntry[];
    resultsTable: TableData;

    async initParams(): Promise<boolean> {
        this.formGeneral = new FrontendFormDefinition([
            new FrontendFieldDefinition("type", "Art des Watchers", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: ["", "Objekt ID"].map(s => new FrontendFieldListItem(s, s)), onValueChanged: this.changeWatcher.bind(this) })
        ]);
        this.formWatchObjectId = new FrontendFormDefinition([
            new FrontendFieldDefinition("id", "Objekt ID", FrontendFieldType.number, { mandatory: true })
        ]);
        return Promise.resolve(true);
    }

    changeWatcher(): void {
        let type = this.formGeneral.getValue("type") as string;
        this.formSpecific = type == "Objekt ID" ? this.formWatchObjectId : null;
    }

    async start(): Promise<void> {
        let type = this.formGeneral.getValue("type") as string;
        this.registeredWatchers.push(await DhTools.backendCall("api/dh/addWatcher", { type: type == "Objekt ID" ? "CsUtils.EnaioWatchJobObjectId" : "", data: this.formSpecific.getAsObject()}).get(EnaioWatcher));
        if (this.timer == null) {
            this.timer = this.addTimer(new SmTimer(1000, 1000, this.onTimer.bind(this)));
        }
    }

    async changeTab(): Promise<void> {
        await this.onTimer();
    }

    async onTimer(): Promise<void> {
        this.registeredWatchers = await DhTools.backendCall("api/dh/getWatcherResults").list(EnaioWatcher);
        this.resultsTable = new TableData([
            new TableColumn("date", "Datum", TableCellType.text, { maxWidth: "100px"}),
            new TableColumn("userName", "Benutzer", TableCellType.text, { width: "150px"}),
            new TableColumn("instance", "Instanz", TableCellType.text, { width: "150px"}),
            new TableColumn("computer", "Computer", TableCellType.text, { width: "150px"}),
            new TableColumn("message", "Details")
        ], this.registeredWatchers[this.activeWatcherTab]?.results?.map(r => new TableRow(r, {
            date: r.jobResult.metaData.Time,
            userName: r.jobResult.metaData.UserName,
            instance: r.jobResult.metaData.Instance,
            computer: r.jobResult.metaData.Computer,
            message: r.message
        })) ?? []);
    }


}
