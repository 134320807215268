import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentType } from '../../shared/learning-content-type.entity';

@Component({
    selector: 'app-edit-learning-content-type',
    templateUrl: './edit-learning-content-type.component.html',
    styleUrls: ['./edit-learning-content-type.component.scss']
})
export class EditLearningContentTypeComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: LearningContentType;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = this.app.tForm("lea.learningContentType", new FrontendFormDefinition([
            new FrontendFieldDefinition("name", null, FrontendFieldType.text, {mandatory: true})
        ]));
        this.item = await this.service.adminInitEdit(LearningContentType, this.id, this.form, "lea.learningContentType", "learning-content-types", "learning-content-type", "api/lrn/learningcontenttype");
        return true;
    }

    save(): void {
        this.service.adminUpdate(this.form, LearningContentType, this.item, "api/lrn/learningcontenttype");
    }
}
