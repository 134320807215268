import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/shared/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/shared/table-column.model';
import { TableData } from 'src/modules/sm-base/shared/table-data.model';
import { TableRow } from 'src/modules/sm-base/shared/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/shared/table-sort-column.model';
import { MessageDialogService } from 'src/modules/sm-base/services/message-dialog.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';
import { CourseParticipantDto } from '../../shared/course-participant.dto';
import { MissDayDto } from '../../shared/miss-day.dto';
import { Pupil } from '../../shared/pupil.model';

@Component({
  selector: 'app-class-pupils',
  templateUrl: './class-pupils.component.html',
  styleUrls: ['./class-pupils.component.scss']
})
export class ClassPupilsComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    pupils: Pupil[];
    courses: CourseParticipantDto[];
    missDays: MissDayDto[];
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    override async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, null, null, true);
        this.pupils = await this.service.restGetPupils(this.classRegister.schoolClassId);
        this.courses = this.pupils.length == 0 ? [] : await RestEndpoint.main().query({year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: this.classRegister.isHalfYear, pupilIds: Utils.arrayItemsToString(this.pupils, ",", p => Utils.toString(p.id))}).run("api/cer/classregister/coursesforpupils").list(CourseParticipantDto);
        this.missDays = await RestEndpoint.main().query({id: this.id}).run("api/cer/classregister/missdays").list(MissDayDto);

        this.table = new TableData(Utils.arrayWithoutNull([
            new TableColumn("lastName", "Nachname"),
            new TableColumn("firstName", "Vorname"),
            new TableColumn("birthday", "Geburtsdatum", TableCellType.date),
            new TableColumn("street", "Straße"),
            new TableColumn("houseNumber", "Hausnummer"),
            new TableColumn("zipCode", "PLZ"),
            new TableColumn("city", "Ort"),
            new TableColumn("custodian1", "Sorgeberechtigter 1"),
            new TableColumn("custodian2", "Sorgeberechtigter 2"),
            new TableColumn("therapies", "Therapien", TableCellType.text, { editable: true, editableMaxLength: 100 }),
            new TableColumn("courses", "Kurse"),
            this.classRegister.enaioSchoolClass.getUseAfterSchoolCenter() ? new TableColumn("afterSchoolCenter", "Hort", TableCellType.yesNo) : null,
            new TableColumn("missDaysExcused", "Fehltage ent.", TableCellType.number),
            new TableColumn("missDaysUnexcused", "Fehltage unent.", TableCellType.number)
        ]), this.pupils.map(item => new TableRow(item, {
            lastName: item.lastName,
            firstName: item.firstName,
            birthday: item.birthday,
            street: item.street,
            houseNumber: item.houseNumber,
            zipCode: item.zipCode,
            city: item.city,
            custodian1: Utils.stringCombine(item.custodian1Phone, item.custodian1Mail, " / "),
            custodian2: Utils.stringCombine(item.custodian2Phone, item.custodian2Mail, " / "),
            therapies: item.therapies,
            courses: Utils.arrayItemsToString(this.courses.filter(c => c.pupilId == item.id), ", ", c => c.courseName),
            afterSchoolCenter: item.afterSchoolCenter,
            missDaysExcused: Math.floor(this.missDays.filter(md => md.pupilId == item.id && md.excused).reduce((p, c) => p + c.getNumDays(), 0)),
            missDaysUnexcused: Math.floor(this.missDays.filter(md => md.pupilId == item.id && !md.excused).reduce((p, c) => p + c.getNumDays(), 0))
        })), [new TableSortColumn("lastName"), new TableSortColumn("firstName")]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Schülerverzeichnis", routerLink: ["/enaio-certificates", "class-registers", "pupils", this.id] }
        ]);
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return this.table.rows.find(row => (row.raw as Pupil).therapies != row.values.therapies) != null;
    }

    async save(): Promise<void> {
        let changed = this.table.rows.filter(row => (row.raw as Pupil).therapies != row.values.therapies);
        await this.app.saveDataHandler(async () => Promise.all(changed.map(async row => RestEndpoint.main().post().query({ id: (row.raw as Pupil).id, value: row.values.therapies}).run("api/cer/classregister/pupiltherapies").getText())), this, true, true);

    }

}
