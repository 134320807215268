import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AppTemplateModule } from '../app-template/app-template.module';
import { AppTab } from '../app-template/models/app-tab.model';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { DocumentFilePreviewComponent } from './components/document-file-preview/document-file-preview.component';
import { DocumentMetaDataComponent } from './components/document-meta-data/document-meta-data.component';
import { EnaioDocumentsDefinitionComponent } from './components/enaio-documents-definition/enaio-documents-definition.component';
import { EnaioDocumentsMainComponent } from './components/enaio-documents-main/enaio-documents-main.component';
import { EnaioDocumentsRoutingModule } from './enaio-documents-routing.module';
import { CompletePersonellDataComponent } from './components/complete-personell-data/complete-personell-data.component';
import { AnonymousSearchComponent } from './components/anonymous-search/anonymous-search.component';

@NgModule({
    imports: [
        AppTemplateModule,
        CommonModule,
        SmBaseModule,
        PrimeModule,
        EnaioDocumentsRoutingModule,
        PdfJsViewerModule
    ],
    declarations: [
        EnaioDocumentsDefinitionComponent,
        EnaioDocumentsMainComponent,
        DocumentFilePreviewComponent,
        DocumentMetaDataComponent,
        CompletePersonellDataComponent,
        AnonymousSearchComponent
    ],
    exports: [
        DocumentFilePreviewComponent,
        DocumentMetaDataComponent
    ]
})
export class EnaioDocumentsModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("documents", "Dokumente", "fas fa-file-text", 110, [], ["/enaio-documents", "home"]));
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-documents", () => EnaioDocumentsRoutingModule);
    }
}
