<div class="grid">
    <sm-toolbar class="col-12">
        <p-button class="mr-3" icon="fas fa-filter" label="Filter" (onClick)="filter()"></p-button>
        <p-checkbox name="includeTrash" [(ngModel)]="includeTrash" [binary]="true"></p-checkbox>
        <label class="form-label mr-5">Auch im Papierkorb suchen</label>
        <p-button icon="fas fa-download" [disabled]="objects == null || objects.length == 0" label="Alle Dokumente herunterladen" (onClick)="download(true)">
        </p-button>
        <p-button icon="fas fa-download" [disabled]="sel == null" label="Selektiertes Dokument herunterladen" (onClick)="download(false)">
        </p-button>
        <p-button icon="fas fa-upload" [disabled]="sel == null" label="Selektiertes Dokument ersetzen" (onClick)="upload()">
        </p-button>
        <p-button *ngIf="changed.length > 0" icon="fas fa-save" label="Änderungen speichern" (onClick)="saveChanges()">
        </p-button>
        <p-message *ngIf="notFoundText != null" class="col-12" severity="error" [text]="'Die folgenden Objekt-IDs wurden nicht gefunden: ' + notFoundText"></p-message>
        <input pInputText class="p-inputtext-sm width400" type="text" placeholder="Suche Test" [(ngModel)]="aiPrompt" (keypress)="aiPromptKeyPress($event)"/>
        <p-checkbox name="indexDataInTable" [(ngModel)]="indexDataInTable" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
        <label class="form-label mr-5">Indexdaten in Tabelle anzeigen</label>
        </sm-toolbar>
    <div class="col-1 grid">
        <div class="col-12">Objekt-IDs:</div>
        <textarea pInputTextarea spellcheck=false class="col-12 full-height-220x monospace no-resize" [(ngModel)]="objectIds"></textarea>
    </div>
    <div class="col-11 grid ml-3">
         <sm-table-on-push #wfTableComponent *ngIf="table != null" class="col-12" [style.height]="'300px'" [data]="table"
            (onRowSelect)="selectObject($event)">
        </sm-table-on-push>
        <p-tabView *ngIf="tableMetaData != null" class="col-12" [(activeIndex)]="tabIndex" (activeIndexChange)="tabIndexChanged()">
            <p-tabPanel header="Indexdaten">
                <sm-table [deltaHeight]=540 [data]="tableMetaData" (onCellEdited)="cellEdited()"></sm-table>
            </p-tabPanel>
            <p-tabPanel header="Basis">
                <sm-table [deltaHeight]=540 [data]="tableBaseData"></sm-table>
            </p-tabPanel>
            <p-tabPanel header="Historie">
                <sm-table [deltaHeight]=540 [data]="tableHistory"></sm-table>
            </p-tabPanel>
            <p-tabPanel header="Indexdaten-Historie">
                <sm-toolbar class="col-12">
                    <p-button class="mr-5" icon="fas fa-cog" label="SimLog durchsuchen" (onClick)="getSimLog()"></p-button>
                    <p-message severity="error" text="Hinweis: Die roten Markierungen in der obersten Zeile sind u.U. nicht zuverlässig"></p-message>
                </sm-toolbar>
                <sm-table [deltaHeight]=600 [data]="tableIndexDataHistory"></sm-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay>
