import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';

@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-my-profile.component.html',
  styleUrls: ['./edit-my-profile.component.scss']
})
export class EditMyProfileComponent extends ComponentView {

    service = inject(EnaioLearnService);

    item: LearningUserFlat;
    form: FrontendFormDefinition;

    async initParams(): Promise<boolean> {
        this.item = await this.service.getMyProfile();

        this.form = this.app.tForm("lea.user", new FrontendFormDefinition([
            new FrontendFieldDefinition("sendNotificationMails", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("notificationDaysBeforeDeadline", null, FrontendFieldType.number)
        ]));

        this.form.fill(this.item);
        this.service.updateNavigation(null, [{ label: this.app.t("lea.myProfile.title"), routerLink: ['/enaio-learn', 'my-profile']}]);
        return true;
    }

    save(): void {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        RestEndpoint.main().put().body(this.item).run("api/lrn/myprofile");
        this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
        this.app.navigateBack();
    }
}
