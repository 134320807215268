import { TimedCache } from "./timed-cache.model";

export class TimedCacheMap<K, T> {
    maxAgeInMillis: number;
    fetcher: (key: K) => Promise<T>;
    data = new Map<K, TimedCache<T>>();

    constructor(maxAgeInMillis: number, fetcher: (key: K) => Promise<T>) {
        this.maxAgeInMillis = maxAgeInMillis;
        this.fetcher = fetcher;
    }

    async get(key: K, forceFetch?: boolean): Promise<T> {
        let c: TimedCache<T> = null;
        if (this.data.has(key)) {
            c = this.data.get(key);
        }
        else {
            c = new TimedCache<T>(this.maxAgeInMillis, async () => this.fetcher(key));
            this.data.set(key, c);
        }
        return c.get(forceFetch);
    }
}
