import { Type } from "class-transformer";
import { HandlerConfigMetrics } from "./HandlerConfigMetrics";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { SimLogStatistics } from "./SimLogStatistics";

export class HandlerConfigMetricsGroup {
    configName = "";
    count = 0;
    time = 0;
    cpuMillis = 0;
    usedMem = 0;
    numObjects = 0;
    numObjectsWithWork = 0;
    numErrorObjects = 0;
    numErrors = 0;
    timeAvg = 0;
    cpuMillisAvg = 0;
    usedMemAvg = 0;
    numObjectsAvg = 0;
    numObjectsWithWorkAvg = 0;
    numErrorObjectsAvg = 0;
    doneDirCount = 0;
    doneDirSize = 0;
    errorDirCount = 0;
    errorDirSize = 0;
    errorNoGroupDirCount = 0;
    errorNoGroupDirSize = 0;
    @Type(() => SimLogStatistics)
    stats: SimLogStatistics;
    objectIdWorkCount: OrdinaryObject<number>;
    mostUsedObjectId = "";
    mostUsedObjectIdCount = 0;
    mostUsedObjectIdRatio = 0;
    @Type(() => HandlerConfigMetrics)
    items: HandlerConfigMetrics[];
}
