import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DatabaseUser } from 'src/modules/database/shared/database-user.entity';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/shared/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/shared/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/shared/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/shared/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { InspectionPhase } from '../../shared/inspection-phase.entity';
import { LearningContentCategory } from '../../shared/learning-content-category.entity';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';

@Component({
    selector: 'app-edit-inspection-phase',
    templateUrl: './edit-inspection-phase.component.html',
    styleUrls: ['./edit-inspection-phase.component.scss']
})
export class EditInspectionPhaseComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: InspectionPhase;
    users: LearningUserFlat[];
    categories: LearningContentCategory[];
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        [this.users, this.categories] = await Promise.all([
            RestEndpoint.main().find().run("api/lrn/learninguserflat").list(LearningUserFlat),
            RestEndpoint.main().find().run("api/lrn/learningcontentcategory").list(LearningContentCategory)
        ]);
        this.form = this.app.tForm("lea.inspectionPhase", new FrontendFormDefinition([
            new FrontendFieldDefinition("name", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("index", null, FrontendFieldType.number, { minValue: 1}),
            new FrontendFieldDefinition("forCategory", null, FrontendFieldType.comboBox, { dropdownEditable: false, listItems: [new FrontendFieldListItem(null, "<Alle>"), ...this.categories.map(ct => new FrontendFieldListItem(ct, ct.name))] }),
            new FrontendFieldDefinition("authorMayChoose", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("responsible", null, FrontendFieldType.comboBoxMulti, { listItems: this.users.map(u => new FrontendFieldListItem(Utils.fromPlain(DatabaseUser, {id: u.id}), u.lastName + ", " + u.firstName)) })
        ]));

        this.categories = Utils.arraySortBy(this.categories, item => item.name);

        this.users = Utils.arraySortByMultiple(this.users, [u => u.lastName, u => u.firstName]);

        this.item = await this.service.adminInitEdit<InspectionPhase>(InspectionPhase, this.id, this.form, "lea.inspectionPhase", "inspection-phases", "inspection-phase", "api/lrn/inspectionphase");
        this.item.responsible = this.item.responsible?.map(r => Utils.fromPlain(DatabaseUser, {id: r.id}));
        this.form.fill(this.item);
        return true;
    }

    save(): void {
        this.service.adminUpdate(this.form, InspectionPhase, this.item, "api/lrn/inspectionphase");
    }
}
