import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnDestroy, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContextMenu } from 'primeng/contextmenu';
import { firstValueFrom } from 'rxjs';
import { Config } from 'src/modules/app-template/models/config.model';
import { LoginUserDatabase } from 'src/modules/database/shared/login-user-database.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { AuthenticationService } from 'src/modules/sm-base/services/authentication.service';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { UserInteractionItem } from 'src/modules/utils/shared/user-interaction-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { MenuService } from '../../services/app.menu.service';
import { MainAppService } from '../../services/main-app.service';

@Component({
    selector: 'app-main',
    styleUrls: ['./app-main.component.scss'],
    templateUrl: './app-main.component.html'
})
export class AppMainComponent implements AfterViewInit, OnDestroy {

    static instance: AppMainComponent;

    inited = false;

    activeTabIndex: number;

    sidebarActive: boolean;

    layoutMode = 'static';

    darkMenu = false;

    topbarMenuActive: boolean;

    sidebarClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    documentClickListener: any;

    inputStyle = 'outlined';

    ripple: boolean;

    configActive: boolean;

    configClick: boolean;

    invalidBrowser = false;

    privacyPolicyAccepted = false;
    privacyPolicy = false;
    privacyPolicyHtml: any;

    @ViewChild("cm") cm: ContextMenu;

    constructor(public app: MainAppService, public http: HttpClient, public renderer: Renderer2, public deviceService: DeviceDetectorService,
        public auth: AuthenticationService, public menuService: MenuService, public viewContainerRef: ViewContainerRef) {

        AppMainComponent.instance = this;
        this.invalidBrowser = this.deviceService.browser === "internetexplorer";
        this.privacyPolicy = Config.get().privacyPolicy;
    }

    @HostListener('document:keydown.shift.control.y')
    onHotkeyDeveloper(): void {
        let nodes = document.querySelectorAll(":hover");
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < nodes.length; i++) {
            let p = (window as any).ng.getComponent(nodes[i]);
            if (p != null) {
                console.log(p);
            }
        }
    }

    @HostListener('document:keydown.shift.control.s')
    onHotkeySidebar(): void {
        let model = [
            {
                label: "Sidebar ein-/ausblenden",
                command: () => {
                    this.app.showSidebar = !this.app.showSidebar;
                }
            },
            {
                label: "Angular-Routen debuggen",
                command: () => {
                    this.app.debugRoutes = !this.app.debugRoutes;
                }
            },
            {
                label: "Interaktions-Historie auf Konsole ausgeben",
                command: () => {
                    console.log(Utils.arrayReverse(Utils.arraySort(this.app.userInteractionHistory)).map(item => item.toString()));
                }
            },
            {
                label: "Erweitere Dev-Optionen freischalten/abschalten",
                command: () => {
                    this.app.advancedDeveloper = !this.app.advancedDeveloper;
                }
            }
        ];
        GuiUtils.showContextMenu(this.app.getCurrentMousePosEvent(), AppMainComponent.instance.viewContainerRef, model);
    }

    @HostListener('document:keydown.shift.alt.c')
    onHotkeyOnSelection(): void {
        if (this.app.onSelectionCallback != null) {
            this.app.onSelectionCallback();
        }
    }

    @HostListener('document:click', ['$event'])
    async onClick(event: Event): Promise<void> {
        if (event.target != null) {
            let debugString = GuiUtils.getHtmlElementDebugString(event.target as HTMLElement);
            this.app.userInteractionHistory.push(new UserInteractionItem(this.lastMouseDown ?? new Date(), "CLICK", debugString));
            this.lastMouseDown = null;
        }
        if (event.target instanceof HTMLAnchorElement && event.target.className === 'dynamic-router-link') {
            event.preventDefault();
            const route = event.target?.getAttribute('href');
            if (route) {
                await this.app.navigateTo([`/${route}`]);
            }
        }
    }

    lastMouseDown: Date;

    @HostListener('document:mousedown', ['$event'])
    public onMouseDown(event: Event): boolean {
        this.app.userInteracted();

        if (event.target != null) {
            this.lastMouseDown = new Date();
        }
        return true;
     }

    @HostListener('document:mousemove', ['$event'])
    public onMouseMove(event: Event): boolean {
        this.app.mouseX = (event as any).pageX;
        this.app.mouseY = (event as any).pageY;
        return true;
     }

     @HostListener('window:keydown', ['$event'])
     public onKeyPress(event: KeyboardEvent): boolean {
        this.app.userKeyPress(event);
        return true;
     }

     @HostListener('document:wheel', ['$event'])
     public onWheel(_: Event): boolean {
        this.app.userInteracted();
        return true;
     }

     @HostListener('document:touchstart', ['$event'])
     public onTouchStart(_: Event): boolean {
        this.app.userInteracted();
        return true;
     }

     @HostListener('document:scroll', ['$event'])
     public onScroll(_: Event): boolean {
        this.app.userInteracted();
        return true;
     }

    async ngAfterViewInit(): Promise<void> {
        if (this.invalidBrowser) {
            return;
        }

        this.documentClickListener = this.renderer.listen('body', 'click', _ => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.topbarItemClick = false;
            this.sidebarClick = false;
        });

        if (this.privacyPolicy) {
            let user = (await this.auth.getUserDetails())?.getAdditional("database", LoginUserDatabase);
            this.privacyPolicyAccepted = user?.raw.privacyPolicyAccepted;
            this.privacyPolicyHtml = this.privacyPolicyAccepted ? "" : await firstValueFrom(this.http.get("assets/portal/templates/privacyPolicy.html", { responseType: "text"}));
        }
        GuiUtils.angularTimer(() => {
            this.inited = true;
        });
    }

    async onTabClick(event, index: number): Promise<void> {
        if (this.menuService.visibleTabs[index].routerLink != null) {
            this.activeTabIndex = index;
            this.sidebarActive = false;
            await this.app.navigateTo(this.menuService.visibleTabs[index].routerLink);
        }
        else if (this.activeTabIndex === index) {
            this.sidebarActive = !this.sidebarActive;
        }
        else {
            this.activeTabIndex = index;
            this.sidebarActive = true;
        }

        event.preventDefault();
    }

    closeSidebar(event: Event): void {
        this.sidebarActive = false;
        event.preventDefault();
    }

    onSidebarClick(): void {
        this.sidebarClick = true;
    }

    onTopbarMenuButtonClick(event: Event): void {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item): void {
        this.topbarItemClick = true;

        this.activeTopbarItem = this.activeTopbarItem === item ? null : item;

        event.preventDefault();
    }

    onTopbarSubItemClick(event: Event): void {
        event.preventDefault();
    }

    onRippleChange(event: any): void {
        this.ripple = event.checked;
    }

    onConfigClick(_event: Event): void {
        this.configClick = true;
    }

    get overlay(): boolean {
        return this.layoutMode === 'overlay';
    }

    isDesktop(): boolean {
        return window.innerWidth > 1024;
    }

    ngOnDestroy(): void {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    async acceptPrivacyPolicy(): Promise<void> {
        let user = (await this.auth.getUserDetails())?.getAdditional("database", LoginUserDatabase);
        if (user != null) {
            user.raw.privacyPolicyAccepted = true;
            await RestEndpoint.main().post().body(user.raw).run("api/app/databaseUser/acceptPrivacyPolicy").getText();
        }
        this.privacyPolicyAccepted = true;
    }

    async declinePrivacyPolicy(): Promise<void> {
        let user = (await this.auth.getUserDetails())?.getAdditional("database", LoginUserDatabase);
        if (user != null) {
            await RestEndpoint.main().post().body(user.raw).run("api/app/databaseUser/declinePrivacyPolicy").getText();
        }
        await this.auth.logout();
    }


}
